import { message, Spin, Typography } from "antd";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import {
  getDeviceById,
  getMeasurementById,
  getStudies,
  getSurveyTemplate,
  getSurveyTemplateByStudy,
} from "../../services/ApiService";
import NewMeasurementPage from "../pages/new-measurement-page/new-measurement-page";
import {
  API_REQUESTS,
  GATEWAY_STATUS,
  MEASUREMENT_STATUS,
  MEASUREMENT_TYPE,
  NOTIFICATION_GROUP_TYPES,
  NOTIFICATION_TYPES,
  STUDY_STATUS_TYPES,
} from "../utils/utils";
import { AuthContext } from "./AuthProvider";
import { NotificationContext } from "./NotificationProvider";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

export const NewMeasurementContext = createContext();
const { Text } = Typography;

export const NewMeasurementContextProvider = (props) => {
  const { getAccessToken, user, isAdmin } = useContext(AuthContext);
  const {
    notificationHub,
    subscribeTo,
    unsubscribeFrom,
    isConnected,
    addNotification,
  } = useContext(NotificationContext);
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [currentStudy, setCurrentStudy] = useState(null);
  const [isSurveyJS, setIsSurveyJS] = useState(false);
  const [studyList, setStudyList] = useState(null);
  const [measurement, setMeasurement] = useState(null);
  const [measurementType, setMeasurementType] = useState(
    MEASUREMENT_TYPE.REGULAR
  );
  const [measurementStatus, setMeasurementStatus] = useState(
    MEASUREMENT_STATUS.IN_PROGRESS
  );
  const [areStudiesLoading, setAreStudiesLoading] = useState(true);
  const [onRecovery, setOnRecovery] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAuthorizedToStop, setIsAuthorizedToStop] = useState(false);
  const [isStartOfMeasurement, setStartOfMeasurement] = useState(false);
  const selectedDeviceRef = useRef(null);
  const measurementRef = useRef(null);
  const history = useHistory();
  // Used to allow up-to-date access to state from within a callback.
  const stateRef = useRef();
  stateRef.current = { isAuthorizedToStop };

  const loadStudies = async () => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);

      if (token) {
        let res = await getStudies(token, 0, 100, "");
        if (res && res.result && !res.error) {
          // Only active studies are allowed to be selected for new measurement
          const activeStudies = res.result.data.filter(
            (it) => it.status === STUDY_STATUS_TYPES.ACTIVE
          );
          setStudyList(activeStudies);
        } else {
          message.error(t("load-studies-error"));
        }
      }
    } catch {
      message.error(t("load-studies-error"));
    }
  };

  useEffect(() => {
    if (currentStudy) {
      loadSurveyTemplate();
    }
  }, [currentStudy]);

  const loadSurveyTemplate = async () => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);

      if (token) {
        let res = await getSurveyTemplateByStudy(token, currentStudy.id);
        if (res && res.result && !res.error) {
          setIsSurveyJS(res.result.template.isSurveyJS);
          console.log(res.result.template.isSurveyJS);
        }
      } else {
        SimpleMessage(
          "error",
          t("measurement-edit-survey-load-template-error")
        );
      }
    } catch {
      SimpleMessage("error", t("measurement-edit-survey-load-template-error"));
    }
  };

  useEffect(() => {
    setIsAuthorizedToStop(isAdmin || measurement?.userId === user?.id);
  }, [isAdmin, measurement, user]);

  useEffect(() => {
    if (studyList) {
      //Pre-set first study from list
      let firstStudy = studyList[0];
      setCurrentStudy(firstStudy);
    }
  }, [studyList]);

  useEffect(() => {
    if (props.match.params.id) {
      const mId = parseInt(props.match.params.id);
      initRecoveryMode(mId);
    } else {
      setLoading(false);
    }

    loadStudies();
    setAreStudiesLoading(false);

    return () => {
      setAreStudiesLoading(true);
    };
  }, [props]);

  useEffect(() => {
    selectedDeviceRef.current = selectedDevice;
  }, [selectedDevice]);

  useEffect(() => {
    measurementRef.current = measurement;
  }, [measurement]);

  // Used right at the start to initialize the SignalR subscriptions,
  // because the first device-change message is not catched unless using
  // this useEffect right at the start.
  useEffect(() => {
    initSignalRSubscriptions();
    return () => {
      disposeSignalRSubscriptions();
    };
  }, []);

  // Used after receving the measurement to join SignalR groups.
  useEffect(() => {
    if (measurement?.id) {
      joinSignalRGroups(measurement.id, measurement.deviceId);
    }

    return () => {
      if (measurement?.id) {
        leaveSignalRGroups(measurement.id, measurement.deviceId);
      }
    };
  }, [measurement?.id]);

  // Used only on internet disconnection.
  // Will dispose the SignalR connections when isConnected turns false.
  // Will reinitialize the SignalR connections on dispose
  // when last effect was for isConnected as false.
  useEffect(() => {
    if (!isConnected && measurement?.id) {
      leaveSignalRGroups(measurement.id, measurement.userId);
      disposeSignalRSubscriptions();
    }
    return () => {
      if (isConnected && measurement?.id) {
        initSignalRSubscriptions();
        joinSignalRGroups(measurement.id, measurement.userId);
      }
    };
  }, [isConnected, measurement?.id]);

  const initSignalRSubscriptions = () => {
    subscribeTo(
      NOTIFICATION_TYPES.MEASUREMENT_CHANGED,
      onMeasurementChangedRef.current
    );
    subscribeTo(NOTIFICATION_TYPES.RAW_EXPORT_READY, onRawDataReadyRef.current);
    subscribeTo(
      NOTIFICATION_TYPES.RECEIVE_ANALYSIS_RESULTS,
      onAnalysisResultsReadyRef.current
    );
  };

  const disposeSignalRSubscriptions = () => {
    unsubscribeFrom(
      NOTIFICATION_TYPES.MEASUREMENT_CHANGED,
      onMeasurementChangedRef.current
    );
    unsubscribeFrom(
      NOTIFICATION_TYPES.RAW_EXPORT_READY,
      onRawDataReadyRef.current
    );
    unsubscribeFrom(
      NOTIFICATION_TYPES.RECEIVE_ANALYSIS_RESULTS,
      onAnalysisResultsReadyRef.current
    );
  };

  const joinSignalRGroups = (measurementId, userId) => {
    notificationHub.send(NOTIFICATION_GROUP_TYPES.JOIN_GROUP, measurementId);
    notificationHub.send(NOTIFICATION_GROUP_TYPES.JOIN_GROUP, userId);
  };

  const leaveSignalRGroups = (measurementId, userId) => {
    notificationHub.send(NOTIFICATION_GROUP_TYPES.LEAVE_GROUP, measurementId);
    notificationHub.send(NOTIFICATION_GROUP_TYPES.LEAVE_GROUP, userId);
  };

  // Used to receive measurement-changed SignalR messages and the status of the measurement
  // will be changed that will trigger other actions on the perform measurement step.
  const onMeasurementChangedRef = useRef(async (messageString) => {
    const messageJson = JSON.parse(messageString);
    if (measurementRef.current?.id === messageJson.MeasurementId) {
      if (!stateRef.current.isAuthorizedToStop) {
        history.replace(`/measurements/${messageJson.MeasurementId}`);
        return;
      }
      if (messageJson.ModifiedByUserId !== messageJson.CreatedByUserId) {
        if (messageJson.IsDeleted) {
          onNewMeasurementClick();
        } else {
          next();
        }
        return;
      }
      setMeasurementStatus(messageJson.MeasurementStatus);
    }
  });

  const next = useCallback(() => {
    setCurrentStep((current) => current + 1);
  }, [setCurrentStep]);

  const prev = useCallback(() => {
    setCurrentStep((current) => current - 1);
  }, [setCurrentStep]);

  const skipQuestionnaire = () => {
    const current = currentStep + 2;
    setCurrentStep(current);
  };

  const onNewMeasurementClick = useCallback(() => {
    if (onRecovery) {
      props.history.replace("/new-measurement");
    }
    setMeasurementType(MEASUREMENT_TYPE.REGULAR);
    if (studyList) {
      setCurrentStudy(studyList[0]);
    }
    setMeasurement(null);
    setCurrentStep(0);
    setSelectedDevice(null);
    setMeasurementStatus(MEASUREMENT_STATUS.IN_PROGRESS);
    setReadyAnalysisResults([]);
    setIsRawDataReady(false);
  }, [onRecovery, studyList]);

  const initRecoveryMode = async (mId) => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        var measurementData = await getMeasurementById(mId, token);
        if (measurementData) {
          if (
            measurementData.measurementStatus !== MEASUREMENT_STATUS.IN_PROGRESS
          ) {
            props.history.replace("/measurements/" + mId);
            return;
          }

          if (measurementData.measurementType === MEASUREMENT_TYPE.REGULAR) {
            var surveyTemplateResponse = await getSurveyTemplate(
              token,
              measurementData.surveyTemplateId
            );
            if (surveyTemplateResponse) {
              measurementData.surveyTemplateJson =
                surveyTemplateResponse.result.template.templateJson;
            }
          }

          var device = await getDeviceById(measurementData.deviceId, token);
          if (device) {
            setSelectedDevice(device);
            setMeasurementStatus(MEASUREMENT_STATUS.IN_PROGRESS);
            setMeasurement(measurementData);
            setOnRecovery(true);
            setCurrentStep(1);
            setMeasurementType(measurementData.measurementType);
            setLoading(false);
          }
        }
      }
    } catch (ex) {
      message.error(t(ex.error ?? "error"));
    }
  };

  const refreshMeasurement = async (oldMeasurementData) => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);

      if (token) {
        var freshMeasurementData = await getMeasurementById(
          oldMeasurementData?.id,
          token
        );
        if (freshMeasurementData) {
          setMeasurement({ ...oldMeasurementData, ...freshMeasurementData });
        }
      }
    } catch (ex) {
      message.error(t(ex.error ?? "error"));
    }
  };

  const [isRawDataReady, setIsRawDataReady] = useState(false);
  const [readyAnalysisResults, setReadyAnalysisResults] = useState([]);

  const readyAnalysisResultsRef = useRef();
  readyAnalysisResultsRef.current = readyAnalysisResults;

  const onRawDataReadyRef = useRef((data) => {
    setIsRawDataReady(true);
  });

  const onAnalysisResultsReadyRef = useRef((diagnosticModel) => {
    const newReadyAnalysisResults = [
      ...readyAnalysisResultsRef.current,
      diagnosticModel,
    ];

    if (
      newReadyAnalysisResults.length ===
      measurementRef.current?.diagnosticModels?.length
    ) {
      refreshMeasurement(measurementRef.current);
    }

    setReadyAnalysisResults(newReadyAnalysisResults);
  });

  return (
    <NewMeasurementContext.Provider
      value={{
        next,
        prev,
        currentStep,
        skipQuestionnaire,
        onNewMeasurementClick,
        selectedDevice,
        setSelectedDevice,
        currentStudy,
        setCurrentStudy,
        isSurveyJS,
        setIsSurveyJS,
        studyList,
        setStudyList,
        measurement,
        setMeasurement,
        measurementType,
        setMeasurementType,
        measurementStatus,
        areStudiesLoading,
        refreshMeasurement,
        isRawDataReady,
        areAnalysisResultsReady:
          readyAnalysisResults.length ===
          measurementRef.current?.diagnosticModels?.length,
        readyAnalysisResults: readyAnalysisResults,
        isAuthorizedToStop,
        isStartOfMeasurement,
        setStartOfMeasurement,
      }}
    >
      {loading && (
        <div className="main-content">
          <Spin
            style={{ justifySelf: "center", alignSelf: "center" }}
            spinning={loading}
            tip={t("loading")}
          ></Spin>
        </div>
      )}
      {!loading && <NewMeasurementPage />}
    </NewMeasurementContext.Provider>
  );
};
