import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "./notification-list.css";
import { List, Space, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { NotificationContext } from "../../contexts/NotificationProvider";
import EmptyDescription from "../simple-components/empty-description/empty-description";
import EllipsisDate from "../simple-components/ellipsis-date/ellipsis-date";

const { Text } = Typography;

const NotificationList = (props) => {
  const { t } = useTranslation();

  const { notificationList, deleteNotification } = useContext(
    NotificationContext
  );

  return (
    <List
      dataSource={notificationList}
      locale={{
        emptyText: <EmptyDescription text={t("notifications-none")} />,
      }}
      itemLayout="vertical"
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={item.icon}
            title={item.title}
            description={
              <Space direction="vertical">
                <EllipsisDate textType="secondary" date={item.date} />
                <div className="notification-item">
                  <Text>{item.content}</Text>
                  <CloseCircleOutlined
                    id="delete-notifications"
                    style={{ fontSize: "18px" }}
                    onClick={() => deleteNotification(item.id)}
                  />
                  <div style={{ gridRow: 2 }}>{item.button}</div>
                </div>
              </Space>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default NotificationList;
