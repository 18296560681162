import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types"; // ES6
import "./measurement-details-bar.css";
import { Button, Row, Col, Progress } from "antd";
import Icon from "@ant-design/icons";
import {
  StopFilled,
  ClockCircleOutlined,
  SearchOutlined,
  ClusterOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
  BUFFER_SECONDS,
  DISPLAY_DATE_FORMAT,
  getMeasurementIdFormatted,
  getMeasurementTypeName,
  MEASUREMENT_PAGES,
  MEASUREMENT_STATUS,
  MEASUREMENT_TYPE,
} from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { ResearchContext } from "../../contexts/ResearchProvider";
import { getMeasurementStatus } from "../../pages/measurements-page/measurements-columns";
import Timer from "../timer/timer";
import { useHistory } from "react-router-dom";
import { TIMER_MODE } from "../../utils/utils";
import MeasurementItemMenu from "../measurement-item-menu/measurement-item-menu";

const MeasurementDetailsBar = (props) => {
  const { preferredLanguage } = useContext(ResearchContext);
  const { t } = useTranslation();
  const [percentAmbient, setPercentAmbient] = useState(0);
  const [ambientColor, setAmbientColor] = useState("var(--ambient-status-20)");
  const [timerMode, setTimerMode] = useState(TIMER_MODE.IDLE);
  const history = useHistory();

  const getPercentAmbient = () => {
    let dateCreated = moment.utc(props.measurement?.dateCreated);
    let now = moment().utc();
    let secs = now.diff(dateCreated, "seconds");
    return parseInt((secs / BUFFER_SECONDS) * 100);
  };

  const getAmbientColor = (percent) => {
    if (percent < 30) {
      return "var(--ambient-status-20)";
    }
    if (percent < 50) return "var(--ambient-status-40)";
    if (percent < 70) return "var(--ambient-status-60)";
    if (percent < 90) return "var(--ambient-status-80)";
    return "var(--ambient-status-100)";
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (props && props.measurement) {
        let percent = getPercentAmbient();
        setPercentAmbient(percent);
        setAmbientColor(getAmbientColor(percent));
        if (percent === 100) {
          clearInterval(timer);
        }
      }
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (props.measurementStatus === MEASUREMENT_STATUS.IN_PROGRESS) {
      if (props.hasSessionTimeout) {
        setTimerMode(TIMER_MODE.COUNTDOWN);
      } else {
        setTimerMode(TIMER_MODE.STOPWATCH);
      }
    } else if (
      props.measurementStatus === MEASUREMENT_STATUS.COMPLETED ||
      props.measurementStatus === MEASUREMENT_STATUS.ERROR
    ) {
      setTimerMode(TIMER_MODE.IDLE);
    }
  }, [props.hasSessionTimeout, props.measurementStatus]);

  const onAmbientIdClicked = () => {
    history.push(`/measurements/${props.measurement?.ambientMeasurementId}`);
  };

  const dateCreatedString = moment
    .utc(props.measurement?.dateCreated)
    .local()
    .locale(preferredLanguage)
    .format(DISPLAY_DATE_FORMAT);

  return (
    <Row justify="center" align="top" className="info-container">
      {props.currentPage === MEASUREMENT_PAGES.PERFORM_STEP && (
        <Col className="info-cell border-right">
          <Row className="info-cell-title">
            <ClockCircleOutlined className="info-cell-icon" />
            {props.hasSessionTimeout
              ? t("new-measurement-remaining-time")
              : t("new-measurement-elapsed-time")}
          </Row>
          <Row>
            <Timer
              mode={timerMode}
              startDate={props.measurement?.dateCreated}
              endDate={props.dateEnd}
              className="info-cell-content"
            />
          </Row>
        </Col>
      )}
      <Col className="info-cell border-right">
        <Row className="info-cell-title">
          <SearchOutlined className="info-cell-icon" />
          {t("new-measurement-measurement-id")}
        </Row>
        <Row className="info-cell-content">
          {getMeasurementIdFormatted(
            props.measurement?.id,
            props.measurement?.measurementType
          )}
        </Row>
      </Col>
      {props.measurement?.ambientMeasurementId && (
        <Col className="info-cell border-right">
          <Row className="info-cell-title">
            <SearchOutlined className="info-cell-icon" />
            {t("new-measurement-ambient-id")}
          </Row>
          <Row className="info-cell-content">
            <Button
              type="link"
              style={{ padding: 0, height: "auto" }}
              onClick={onAmbientIdClicked}
              disabled={props.currentPage === MEASUREMENT_PAGES.PERFORM_STEP}
            >
              {props.measurement?.ambientMeasurementId}
            </Button>
          </Row>
        </Col>
      )}
      <Col className="info-cell border-right">
        <Row className="info-cell-title">
          <ClusterOutlined className="info-cell-icon" />
          {props.measurement?.serialNumber}
        </Row>
        <Row className="info-cell-content">{dateCreatedString}</Row>
      </Col>
      {props.measurement?.measurementType === MEASUREMENT_TYPE.REGULAR && (
        <Col className="info-cell border-right">
          <Row className="info-cell-title">
            <FileTextOutlined className="info-cell-icon" />
            {t("new-measurement-survey-template")}
          </Row>
          <Row className="info-cell-content">
            {props.measurement?.surveyTemplateName}
          </Row>
        </Col>
      )}
      {props.measurement?.measurementType !== MEASUREMENT_TYPE.REGULAR && (
        <Col
          className="info-cell border-right"
          style={{ verticalAlign: "top" }}
        >
          <Row className="info-cell-title">
            <FileTextOutlined className="info-cell-icon" />
            {props.measurement?.noseId}
          </Row>
          <Row className="info-cell-content">{props.spiroNoseStatus}</Row>
        </Col>
      )}
      <Col className="info-cell border-right">
        <Row className="info-cell-title">
          <SearchOutlined className="info-cell-icon" />
          {t("new-measurement-measurement-type")}
        </Row>
        <Row className="info-cell-content">
          {getMeasurementTypeName(props.measurement?.measurementType, t)}
        </Row>
      </Col>
      <Col className="info-cell border-right">
        {props.currentPage !== MEASUREMENT_PAGES.PERFORM_STEP && (
          <Row className="info-cell-title">
            <Icon
              component={() => <img src="/img/measurementIcon.svg" />}
              className="info-cell-icon"
            />
            {t("measurement-status")}
          </Row>
        )}
        <Row>
          <div className="info-cell-center action-cell">
            {props.currentPage === MEASUREMENT_PAGES.PERFORM_STEP && (
              <Button
                disabled={props.isStopDisabled || !props.isAuthorizedToStop}
                type="danger"
                className="stop-button"
                icon={<StopFilled />}
                onClick={props.onStopButtonClicked}
              >
                {t("new-measurement-stop-measurement")}
              </Button>
            )}
            {props.currentPage === MEASUREMENT_PAGES.PERFORM_STEP &&
              props.measurement?.measurementType ===
              MEASUREMENT_TYPE.AMBIENT && (
                <Progress
                  steps={10}
                  percent={percentAmbient}
                  strokeColor={ambientColor}
                />
              )}
            {props.currentPage !== MEASUREMENT_PAGES.PERFORM_STEP &&
              getMeasurementStatus(
                props.measurement?.measurementStatus,
                "42px"
              )}
          </div>
        </Row>
      </Col>
      {props.currentPage === MEASUREMENT_PAGES.DETAILS_PAGE && (
        <Col className="info-cell action-cell item-menu">
          <MeasurementItemMenu record={props.measurement} iconSize="25px" />
        </Col>
      )}
    </Row>
  );
};

MeasurementDetailsBar.propTypes = {
  currentPage: PropTypes.number.isRequired,
  dateEnd: PropTypes.object,
  measurement: PropTypes.object,
  spiroNoseStatus: PropTypes.string,
  isStopDisabled: PropTypes.bool,
  onStopButtonClicked: PropTypes.func,
  hasSessionTimeout: PropTypes.bool,
  measurementStatus: PropTypes.number,
  isAuthorizedToStop: PropTypes.bool,
};

export default MeasurementDetailsBar;
