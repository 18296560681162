import React, { useEffect, useContext, useState } from "react";
import { Button, message, Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types"; // ES6
import "./stop-measurement-dialog.css";
import moment from "moment";
import { AuthContext } from "../../contexts/AuthProvider";
import { API_REQUESTS, APP_ROLES, BUFFER_SECONDS, MEASUREMENT_TYPE } from "../../utils/utils";
import { stopMeasurement } from "../../../services/ApiService";
const { Text } = Typography;

const StopMeasurementDialog = (props) => {
  const { t } = useTranslation();
  const { user, getAccessToken } = useContext(AuthContext);
  const [modalText, setModalText] = useState("new-measurement-manual-stop-dialog-message");
  const [isStopAndSaveLoading, setIsStopAndSaveLoading] = useState(false);
  const [isStopAndDeleteLoading, setIsStopAndDeleteLoading] = useState(false);
  const isAdmin = user.role === APP_ROLES.ADMIN;

  useEffect(() => {
    if (isAmbientAndInvalid(props.measurement)) {
      setModalText(isAdmin
        ? t("new-measurement-manual-stop-admin-ambient-dialog-message", { username: props.measurement.performedBy })
        : t("new-measurement-manual-stop-ambient-dialog-message"));
    } else {
      setModalText(isAdmin
        ? t("new-measurement-manual-stop-admin-dialog-message", { username: props.measurement.performedBy })
        : t("new-measurement-manual-stop-dialog-message"));
    }
  }, [props]);

  const handleStopAndSave = async () => {
    const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
    if (token) {
      const mId = parseInt(props.measurement?.id);
      try {
        setIsStopAndSaveLoading(true);
        await stopMeasurement(mId, true, token);
        props.onClose();
        props.onStopAndSave();
      } catch (ex) {
        message.error(t(ex.error));
      } finally {
        setIsStopAndSaveLoading(false);
      }
    }
  };

  const handleStopAndDelete = async () => {
    const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
    if (token) {
      const mId = parseInt(props.measurement?.id);
      try {
        setIsStopAndDeleteLoading(true);
        await stopMeasurement(mId, false, token);
        props.onClose();
        props.onStopAndDelete();
      } catch (ex) {
        message.error(t(ex.error));
      } finally {
        setIsStopAndDeleteLoading(false);
      }
    }
  };

  const handleCancel = () => {
    props.onClose();
  };

  const isAmbientAndInvalid = (measurement) => {
    if (!measurement) {
      return false;
    }
    let dateStopped = moment.utc();
    let dateCreated = moment.utc(measurement?.dateCreated);
    let differenceSec = dateStopped.diff(dateCreated, "seconds");
    return measurement.measurementType === MEASUREMENT_TYPE.AMBIENT
      && differenceSec < BUFFER_SECONDS;
  }

  return (
    <Modal
      closable={false}
      visible={props.visible}
      onCancel={handleCancel}
      footer={[
        <Button
          type="primary"
          disabled={isStopAndDeleteLoading}
          loading={isStopAndSaveLoading}
          onClick={handleStopAndSave}
        >
          {t("new-measurement-stop-and-save")}
        </Button>,
        <Button
          type="danger"
          disabled={isStopAndSaveLoading}
          loading={isStopAndDeleteLoading}
          onClick={handleStopAndDelete}
        >
          {t("new-measurement-stop-and-delete")}
        </Button>,
        <Button
          disabled={isStopAndSaveLoading || isStopAndDeleteLoading}
          onClick={handleCancel}
        >
          {t("new-measurement-stop-cancel")}
        </Button>,
      ]}
    >
      {modalText}
    </Modal>
  );
}

StopMeasurementDialog.propTypes = {
  onStopAndSave: PropTypes.func,
  onStopAndDelete: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  measurement: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
};

StopMeasurementDialog.defaultProps = {
  onStopAndSave: () => { },
  onStopAndDelete: () => { },
};

export default StopMeasurementDialog;