import React from "react";
import PropTypes from "prop-types"; // ES6
import { Empty } from "antd";
import Emoji from "../emoji/emoji";

const EmptyDescription = (props) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "grid",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Empty
        description={
          <span>
            <span>{props.text}</span>
            <Emoji symbol={props.symbol} label={props.label} />
          </span>
        }
      />
    </div>
  );
};

EmptyDescription.defaultProps = {
  text: "No data",
  symbol: "😥",
  label: "DISAPPOINTED BUT RELIEVED FACE",
};
EmptyDescription.propsTypes = {
  text: PropTypes.string.isRequired,
  symbol: PropTypes.string,
  label: PropTypes.string,
};

export default EmptyDescription;
