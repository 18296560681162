import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import EllipsisText from "../ellipsis-text/ellipsis-text";
import moment from "moment";
import { ResearchContext } from "../../../contexts/ResearchProvider";

const EllipsisDate = (props) => {
  const { preferredLanguage } = useContext(ResearchContext);
  const [dateText, setDateText] = useState("-");
  const [dateTooltip, setDateTooltip] = useState("-");

  useEffect(() => {
    if (props.date) {
      setDateText(
        moment.utc(props.date).local().locale(preferredLanguage).fromNow()
      );
      setDateTooltip(
        moment
          .utc(props.date)
          .local()
          .locale(preferredLanguage)
          .format("YYYY-MM-DD HH:mm:ss")
      );
    }
  }, [props]);

  return (
    <EllipsisText
      copyable={props.copyable}
      tooltip={dateTooltip}
      text={dateText}
      textType={props.textType}
    />
  );
};

EllipsisDate.propsTypes = {
  date: PropTypes.string.isRequired,
  copyable: PropTypes.bool,
  textType: PropTypes.string,
};

EllipsisDate.defaultProps = {
  copyable: false,
};

export default EllipsisDate;
