import React, { useEffect, useContext, useState } from "react";
import { List, Typography, Input, Skeleton, Button } from "antd";
import "./studies-page.css";
import i18n from "../../../i18n";
import { getStudies } from "../../../services/ApiService";
import { AuthContext } from "../../contexts/AuthProvider";
import { API_REQUESTS, APP_ROLES, ENTITY_ACTIONS } from "../../utils/utils";
import StudyCard from "../../components/study-card/study-card";
import { SolutionOutlined } from "@ant-design/icons";
import SimpleMessage from "../../components/simple-components/simple-message/simple-message";
import { v4 as uuidv4 } from "uuid";
import EmptyDescription from "../../components/simple-components/empty-description/empty-description";
import StudyDetailsDrawer from "../../components/study-details-drawer/study-details-drawer";
import { PageSettingsContext } from "../../contexts/PageSettingsProvider";
const { Title } = Typography;
const { Search } = Input;

const itemsPerPage = 10;

const StudiesPage = () => {
  const { getAccessToken, user } = useContext(AuthContext);
  const { resetFilters } = useContext(PageSettingsContext);

  const [pageState, setPageState] = useState({
    data: [],
    studylist: [],
    initLoading: true,
    totalCount: 0,
    startIndex: 0,
    search: null,
    loadingMore: false,
    canLoadMore: false,
  });

  useEffect(() => {
    resetFilters();
  }, []);

  useEffect(() => {
    if (initLoading || loadingMore) {
      loadStudies();
    }
  }, [pageState.initLoading, pageState.loadingMore]);

  const loadStudies = async () => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await getStudies(
          token,
          pageState.startIndex,
          itemsPerPage,
          pageState.search
        );
        if (res && res.result && !res.error) {
          let data = [];
          if (pageState.loadingMore)
            data = pageState.data.concat(res.result.data);
          else if (pageState.initLoading) {
            data = res.result.data;
          }
          setPageState({
            ...pageState,
            data,
            studylist: data,
            initLoading: false,
            loadingMore: false,
            totalCount: res.result.total,
            startIndex: data.length,
            canLoadMore: data.length < res.result.total,
          });
        } else {
          throw res?.error;
        }
      }
    } catch (err) {
      setPageState({
        ...pageState,
        initLoading: false,
        loadingMore: false,
        data: [],
        studylist: [],
      });
      SimpleMessage("error", i18n.t("load-studies-error"));
    }
    return null;
  };

  const onLoadMore = async () => {
    setPageState({
      ...pageState,
      loadingMore: true,
      studylist: pageState.data.concat(
        [...new Array(itemsPerPage)].map(() => ({
          loading: true,
          id: uuidv4(),
        }))
      ),
    });
  };

  const onSearchClick = async (value) => {
    setPageState({
      ...pageState,
      search: value,
      startIndex: 0,
      initLoading: true,
      loadingMore: false,
    });
  };

  const onAddStudyClick = () => {
    setPageState({
      ...pageState,
      drawerVisible: true,
      currentStudy: null,
    });
  };

  const openDrawer = (study) => {
    setPageState({
      ...pageState,
      drawerVisible: true,
      currentStudy: study,
    });
  };

  const closeDrawer = (study, action) => {
    switch (action) {
      case ENTITY_ACTIONS.CREATE:
        pageState.studylist.splice(0, 0, study);
        let allStudies = pageState.studylist.splice(0, itemsPerPage);
        setPageState({
          ...pageState,
          drawerVisible: false,
          studylist: allStudies,
          data: allStudies,
          totalCount: pageState.totalCount + 1,
        });
        break;
      case ENTITY_ACTIONS.UPDATE:
        setPageState({
          ...pageState,
          studylist: pageState.studylist.map((item) => {
            if (study.id === item.id) {
              return { ...study };
            }
            return item;
          }),
          drawerVisible: false,
          currentStudy: null,
        });
        break;
      case ENTITY_ACTIONS.DELETE:
        var updatedStudylist = pageState.studylist.filter(
          (e) => e.id != pageState.currentStudy.id
        );
        setPageState({
          ...pageState,
          studylist: updatedStudylist,
          drawerVisible: false,
          currentStudy: null,
          totalCount: pageState.totalCount - 1,
        });
        break;
      default:
        setPageState({
          ...pageState,
          drawerVisible: false,
          currentStudy: null,
        });
        break;
    }
  };

  const {
    initLoading,
    studylist,
    loadingMore,
    canLoadMore,
    totalCount,
  } = pageState;

  const loadMore =
    !initLoading && !loadingMore && canLoadMore ? (
      <div
        id="load-more-studies"
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore}>{i18n.t("load-more")}</Button>
      </div>
    ) : null;
  const isAdmin = user.role === APP_ROLES.ADMIN;

  return (
    <div className="studies-grid">
      <Title level={4} style={{ margin: "0px" }}>
        {isAdmin ? i18n.t("studies") : i18n.t("my-studies")} ({totalCount})
      </Title>
      {isAdmin && (
        <Button
          id="add-study"
          style={{ justifySelf: "start" }}
          type="primary"
          icon={<SolutionOutlined />}
          onClick={onAddStudyClick}
        >
          {i18n.t("add-study")}
        </Button>
      )}
      <Search
        loading={initLoading}
        placeholder={i18n.t("search-studies")}
        onSearch={onSearchClick}
        allowClear
        enterButton
      />
      <Skeleton loading={initLoading} active rows={10}>
        <List
          locale={{
            emptyText: (
              <EmptyDescription
                text={i18n.t("no-studies")}
                symbol="😥"
                label="DISAPPOINTED BUT RELIEVED FACE"
              />
            ),
          }}
          rowKey="id"
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 5,
            xxl: 7,
          }}
          loadMore={loadMore}
          dataSource={studylist}
          renderItem={(item) => (
            <List.Item>
              <Skeleton loading={item.loading} active>
                <StudyCard study={item} openDrawer={openDrawer} />
              </Skeleton>
            </List.Item>
          )}
        />
      </Skeleton>
      <StudyDetailsDrawer
        study={pageState.currentStudy}
        visible={pageState.drawerVisible}
        onClose={closeDrawer}
      />
    </div>
  );
};

export default StudiesPage;
