import React, { useContext, useState, useEffect } from "react";
import { Typography, Table, Input } from "antd";
import { API_REQUESTS, getSortField } from "../../utils/utils";
import { AuthContext } from "../../contexts/AuthProvider";
import { ResearchContext } from "../../contexts/ResearchProvider";
import { PageSettingsContext } from "../../contexts/PageSettingsProvider";
import { useTranslation } from "react-i18next";
import { getSpiroNoses } from "../../../services/ApiService";
import EmptyDescription from "../../components/simple-components/empty-description/empty-description";
import SimpleMessage from "../../components/simple-components/simple-message/simple-message";
import { getSpiroNoseColumns } from "./spironoses-columns";
import { useHistory } from "react-router-dom";
import "./spironoses-page.css";
import i18n from "../../../i18n";
const { Title } = Typography;
const { Search } = Input;

const SpiroNosesPage = () => {
  let history = useHistory();
  const accessedViaBackAction = history.action == "POP";
  const { getAccessToken } = useContext(AuthContext);
  const { preferredLanguage } = useContext(ResearchContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [datasource, setDatasource] = useState([]);
  const {
    spironosesFilterState,
    setSpironosesFilterState,
    resetFilters,
  } = useContext(PageSettingsContext);
  const [searchTerm, setSearchTerm] = useState(
    accessedViaBackAction ? spironosesFilterState.searchTerm : null
  );
  const [pageSettings, setPageSettings] = useState({
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} of ${total} ${t("spironoses")}`,
    pageSize: accessedViaBackAction ? spironosesFilterState.pageSize : 10,
    current: accessedViaBackAction ? spironosesFilterState.currentPage : 1,
  });
  const [wasComponentInitialized, setWasComponentInitialized] = useState(false);

  useEffect(() => {
    if (!accessedViaBackAction) {
      resetFilters();
    }
  }, []);

  useEffect(() => {
    const searchSpiroNoses = async () => {
      if (wasComponentInitialized) {
        await fetchSpiroNoses({
          results: pageSettings.pageSize,
          page: 1,
        });
      } else {
        await fetchSpiroNoses({
          results: pageSettings.pageSize,
          page: pageSettings.current,
        });
        setWasComponentInitialized(true);
      }
    };
    searchSpiroNoses();
  }, [searchTerm]);

  const handleTableChange = async (pagination, filters, sorter) => {
    let pager = { ...pageSettings };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    setPageSettings(pager);
    setSpironosesFilterState({
      ...spironosesFilterState,
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await fetchSpiroNoses({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: getSortField(sorter),
      sortOrder: sorter.order,
    });
  };

  const fetchSpiroNoses = async (params = {}) => {
    setLoading(true);
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await getSpiroNoses(
          token,
          params.page - 1,
          params.results,
          searchTerm,
          params.sortField,
          params.sortOrder
        );
        if (res && res.result && !res.error) {
          const pager = { ...pageSettings };
          pager.pageSize = params.results;
          pager.total = res.result.total;
          pager.current = params.page;
          setDatasource(res.result.data);
          setPageSettings(pager);
        } else {
          SimpleMessage("error", t("nose-load-error"));
        }
      }
    } catch (err) {
      SimpleMessage("error", t("nose-load-error"));
    }
    setLoading(false);
  };

  const searchClick = (term) => {
    setSearchTerm(term);
    setSpironosesFilterState({
      ...spironosesFilterState,
      searchTerm: term,
    });
  };

  return (
    <div className="measurements-grid">
      <Title level={4} style={{ margin: "0px" }}>
        SpiroNoses
      </Title>
      <Search
        defaultValue={searchTerm}
        loading={loading}
        placeholder={i18n.t("nose-search")}
        onSearch={searchClick}
        allowClear
        enterButton
      />
      <Table
        locale={{
          emptyText: (
            <EmptyDescription
              text={t("no-noses")}
              symbol="😥"
              label="DISAPPOINTED BUT RELIEVED FACE"
            />
          ),
        }}
        onChange={handleTableChange}
        pagination={pageSettings}
        loading={loading}
        columns={getSpiroNoseColumns(preferredLanguage)}
        dataSource={datasource}
        size="middle"
        bordered
      />
    </div>
  );
};

export default SpiroNosesPage;
