import React, { createContext, useState } from "react";
import SurveyCreatorPage from "../pages/survey-creator-page/survey-creator-page";

export const SurveyCreatorContext = createContext();

const SurveyCreatorProvider = (props) => {

    const [surveyCreator, setSurveyCreator] = useState(null);
    const [surveyTemplate, setSurveyTemplate] = useState(null);

    return (
        <SurveyCreatorContext.Provider value={{
            surveyCreator,
            setSurveyCreator,
            surveyTemplate,
            setSurveyTemplate,

        }}>
            {props.children}
            <SurveyCreatorPage />
        </SurveyCreatorContext.Provider>
    );
};

export default SurveyCreatorProvider;
