import React, { useContext } from "react";
import "./login-content.css";
import { AuthContext } from "../../contexts/AuthProvider";
import { useTranslation } from "react-i18next";

const LoginContent = () => {
  const { login } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <div className="login-content">
      <div className="login-background" />
      <div className="login-sidebar">
        <div className="login-header-grid">
          <h3 className="login-header">BreathBase®</h3>
          <h4 className="login-sub-header">Platform</h4>
        </div>
        <div className="login-section">
          <h4 className="welcome-header">{t("landing:welcome-text")}</h4>
          <span
            style={{
              gridRow: 3,
              color: "var(--header-color)",
              fontSize: "16px",
            }}
          >
            {t("landing:login-text")}
          </span>
          <input
            style={{ gridRow: 4, width: "180px" }}
            type="image"
            src="/img/ms-signin-light.svg"
            alt="Login"
            onClick={() => {
              login();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginContent;
