import React, { useState, useEffect, useContext } from "react";
import {
  Drawer,
  Skeleton,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Space,
  Tooltip,
  Descriptions,
  TimePicker,
  DatePicker,
} from "antd";
import { useTranslation } from "react-i18next";
import SimpleMessage from "../simple-components/simple-message/simple-message";
import {
  QuestionCircleOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { requiredRules } from "./device-details-drawer-rules";
import {
  getUsersByDevice,
  refreshDeviceStatus,
  updateDeviceDetails,
} from "../../../services/ApiService";
import {
  API_REQUESTS,
  VALIDATIONS,
  APP_ROLES,
  DEVICE_VERSION,
  getDeviceMode,
} from "../../utils/utils";
import { AuthContext } from "../../contexts/AuthProvider";
import NoseDetailsDrawer from "../nose-details-drawer/nose-details-drawer";
import moment from "moment";
import AssignDeviceUsers from "../assign-device-users/assign-device-users";
import DeviceDetailsDrawerConfirmationDialog from "./device-details-drawer-confirmation-dialog";

const { confirm } = Modal;
const { Option } = Select;

const DeviceDetailsDrawer = (props) => {
  const { getAccessToken, user } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [spiroNoseDrawerVisible, setSpiroNoseDrawerVisible] = useState(false);
  const [prodDeviceDialogVisible, setProdDeviceDialogVisible] = useState(false);

  const [assignUsersDrawerVisible, setAssignUsersDrawerVisible] = useState(
    false
  );
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [noseId, setNoseId] = useState(null);
  const [isDeviceReady, setIsDeviceReady] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [formValues, setFormValues] = useState(null);

  const [form] = Form.useForm();
  const { t } = useTranslation();
  let updatedDevice = null;

  useEffect(() => {
    if (props.device) {
      updatedDevice = null;
      setVisible(props.visible);
      let availableFrom = moment.utc(props.device.availableFrom).local();

      setIsDeviceReady(props.device.gatewayStatus === 1);

      // Set fields to read-only
      // Only when user is not an admin and a device is on production but Platform Environment is QA or Dev
      // Device environments should be UPPER_CASE : "DEV", "QA" and "PROD"
      // React app environments should be LOWER_CASE : "dev", "qa" and "prod"
      let isAuth = false;
      isAuth = user.role === APP_ROLES.ADMIN
      if (props.device.environment === "PROD" && process.env.REACT_APP_ENVIRONMENT !== "prod") {
        isAuth = false;
      }
      setIsAuthorized(isAuth);

      if (isAuth) {
        getAssignedUsers();
      }

      form.setFieldsValue({
        ...props.device,
        availableFromDate: availableFrom,
        availableFromTime: availableFrom,
      });
    }
  }, [props]);

  const getAssignedUsers = async () => {
    const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
    var users;
    if (token) {
      users = await getUsersByDevice(props.device.deviceId, token);
    }
    if (users.result) {
      setAssignedUsers(users.result.data);
    }
  };
  const closeDrawer = () => {
    if (dirty) {
      //show confirm
      confirm({
        cancelText: t("profile-assign-devices-cancel"),
        icon: (
          <QuestionCircleOutlined
            style={{ color: "var(--question-icon-color)" }}
          />
        ),
        title: t("profile-assign-devices-leave-title"),
        content: t("profile-assign-devices-leave-content"),
        onOk: () => clearAndClose(),
      });
    } else {
      clearAndClose();
    }
  };

  const openSpiroNoseDrawer = () => {
    setNoseId(props.device.noseId);
    setSpiroNoseDrawerVisible(true);
  };

  const closeSpiroNoseDrawer = () => {
    setSpiroNoseDrawerVisible(false);
  };

  const closeProdDeviceDialog = () => {
    setProdDeviceDialogVisible(false);
  }

  const closeAssignUsersDrawer = (users, shouldUpdate) => {
    if (shouldUpdate) {
      setAssignedUsers(users);
    }
    setAssignUsersDrawerVisible(false);
  };

  const clearAndClose = () => {
    setDirty(false);
    props.onClose(updatedDevice);
  };

  const onValuesChange = (changedValues) => {
    setDirty(form.isFieldsTouched());
  };

  const onSaveClick = () => {
    form.submit();
  };

  const onFinish = async (values) => {
    // Show popup confirmation for moving device to Prod
    setFormValues(values);
    if (values.environment === "PROD") {
      setProdDeviceDialogVisible(true);
      return;
    }
    await updateDevice(values);
  };

  const updateDevice = async (values) => {
    if (values == null) {
      values = formValues
    }
    setLoading(true);
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        values.deviceId = props.device.deviceId;
        values.availableFromDate
          ? (values.availableFrom = moment(
            `${values.availableFromDate.format(
              "YYYY-MM-DD"
            )} ${values.availableFromTime.format("HH:mm:ss")}`
          ).utc())
          : (values.availableFrom = form.getFieldValue("availableFromDate"));

        let res = await updateDeviceDetails(token, values);
        if (res && res.result) {
          updatedDevice = values;
          clearAndClose();
          SimpleMessage("success", t("device-details-success"));
        } else {
          SimpleMessage("error", t(res.error));
        }
      }
    } catch (err) {
      SimpleMessage("error", err.message);
    }
    setLoading(false);
  }

  const refreshStatus = async () => {
    const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
    let res = await refreshDeviceStatus(token, props.device.deviceId);
    if (res.result.succes) {
      props.device = res.result.device;
      let availableFrom = moment.utc(props.device.availableFrom).local();
      form.setFieldsValue({
        ...props.device,
        availableFromDate: availableFrom,
        availableFromTime: availableFrom,
      });
      SimpleMessage("success", t("device-refresh-success"));
    } else {
      SimpleMessage("error", t("device-refresh-error"));
    }
  };

  const onSaveProdDevice = async () => {
    setProdDeviceDialogVisible(false);
    await updateDevice();
  }

  return (
    <Drawer
      title={t("device-details-view", {
        device: props?.device?.serialNumber?.toUpperCase(),
      })}
      width={450}
      onClose={closeDrawer}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            id="refresh-status-device-details"
            icon={<ReloadOutlined />}
            onClick={refreshStatus}
            style={{ marginRight: 8 }}
          >
            {t("device-details-refresh")}
          </Button>
          {isAuthorized && (<Button
            id="cancel-device-details"
            onClick={closeDrawer}
            style={{ marginRight: 8 }}
          >
            {t("device-details-cancel")}
          </Button>)}
          {isAuthorized && (
            <Button
              id="save-device-details"
              disabled={!dirty}
              onClick={onSaveClick}
              type="primary"
            >
              {t("device-details-confirm")}
            </Button>
          )}
        </div>
      }
    >
      <Skeleton loading={loading} active paragraph={{ rows: 5 }}>
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
        >
          <Row>
            <Col span={24}>
              <Form.Item label={t("device-details-noseId")} name="noseId">
                <Button type="link" onClick={openSpiroNoseDrawer}>
                  {form.getFieldValue("noseId")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Descriptions layout="vertical" column={1} colon={false}>
                <Descriptions.Item label={t("device-details-hardwareVersion")}>
                  {props.device?.hardwareVersion}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isAuthorized ? (
                <Form.Item
                  rules={requiredRules(t)}
                  name="sessionTimeout"
                  label={
                    <Space>
                      {t("device-details-sessionTimeout")}
                      <Tooltip
                        title={t("device-details-sessionTimeout-tooltip")}
                      >
                        <InfoCircleOutlined
                          style={{ color: "var(--default-tooltip-color)" }}
                        />
                      </Tooltip>
                    </Space>
                  }
                >
                  <InputNumber
                    min={VALIDATIONS.MIN_DEVICE_TIMEOUT_LENGTH}
                    max={VALIDATIONS.MAX_DEVICE_TIMEOUT_LENGTH}
                    parser={value => parseInt(value)}
                  />
                </Form.Item>
              ) : (
                <Descriptions layout="vertical" column={1} colon={false}>
                  <Descriptions.Item label={t("device-details-sessionTimeout")}>
                    {props.device?.sessionTimeout}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isAuthorized &&
                !isDeviceReady &&
                props.device?.version == DEVICE_VERSION.V1 ? (
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={
                    <Space>
                      {t("device-details-availablefrom")}
                      <Tooltip
                        title={t("device-details-availablefrom-tooltip")}
                      >
                        <InfoCircleOutlined
                          style={{ color: "var(--default-tooltip-color)" }}
                        />
                      </Tooltip>
                    </Space>
                  }
                >
                  <Space>
                    <Form.Item
                      rules={requiredRules(t)}
                      name="availableFromDate"
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      rules={requiredRules(t)}
                      name="availableFromTime"
                    >
                      <TimePicker format="HH:mm" />
                    </Form.Item>
                  </Space>
                </Form.Item>
              ) : (
                <Descriptions layout="vertical" column={1} colon={false}>
                  <Descriptions.Item label={t("device-details-availablefrom")}>
                    {moment(props.device?.availableFrom).format("YYYY-MM-DD HH:mm")}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isAuthorized ? (
                <Form.Item
                  rules={requiredRules(t)}
                  name="environment"
                  label={
                    <Space>
                      {t("device-details-environment")}
                      <Tooltip title={t("device-details-environment-tooltip")}>
                        <InfoCircleOutlined
                          style={{ color: "var(--default-tooltip-color)" }}
                        />
                      </Tooltip>
                    </Space>
                  }
                >
                  <Select
                    style={{ width: "100%" }}
                    options={[
                      { value: "DEV" },
                      { value: "QA" },
                      { value: "PROD" },
                    ]}
                  />
                </Form.Item>
              ) : (
                <Descriptions layout="vertical" column={1} colon={false}>
                  <Descriptions.Item label={t("device-details-environment")}>
                    {props.device?.environment}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isAuthorized ? (
                <Form.Item
                  rules={requiredRules(t)}
                  name="location"
                  label={
                    <Space>
                      {t("device-details-location")}
                      <Tooltip title={t("device-details-location-tooltip")}>
                        <InfoCircleOutlined
                          style={{ color: "var(--default-tooltip-color)" }}
                        />
                      </Tooltip>
                    </Space>
                  }
                >
                  <Input />
                </Form.Item>
              ) : (
                <Descriptions layout="vertical" column={1} colon={false}>
                  <Descriptions.Item label={t("device-details-location")}>
                    {props.device?.location}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Col>
          </Row>
          <Col>
            <Row>
              {isAuthorized ? (
                <Form.Item
                  name="deviceMode"
                  label={
                    <Space>
                      {t("device-details-device-mode")}
                      <Tooltip title={t("device-details-device-mode-tooltip")}>
                        <InfoCircleOutlined
                          style={{ color: "var(--default-tooltip-color)" }}
                        />
                      </Tooltip>
                    </Space>
                  }
                >
                  <Select disabled={!isAuthorized} labelInValue={false}>
                    <Option value={0}>
                      {t("device-details-device-mode-normal")}
                    </Option>
                    <Option value={1}>
                      {t("device-details-device-mode-continuous")}
                    </Option>
                  </Select>
                </Form.Item>
              ) : (
                <Descriptions layout="vertical" column={1} colon={false}>
                  <Descriptions.Item label={t("device-details-device-mode")}>
                    {getDeviceMode(props.device?.deviceMode, t)}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Row>

            {isAuthorized && (
              <Row>
                <Form.Item name="assignedUsers">
                  <Button
                    type="link"
                    onClick={() => setAssignUsersDrawerVisible(true)}
                    style={{ paddingLeft: 0 }}
                  >
                    {t("device-assigned-users")}
                    {" ("}
                    {assignedUsers.length}
                    {")"}
                  </Button>
                </Form.Item>
              </Row>
            )}
          </Col>
        </Form>
      </Skeleton>
      <NoseDetailsDrawer
        noseId={noseId}
        visible={spiroNoseDrawerVisible}
        onClose={closeSpiroNoseDrawer}
      />
      <AssignDeviceUsers
        deviceId={props.device?.deviceId}
        users={assignedUsers}
        visible={assignUsersDrawerVisible}
        onClose={closeAssignUsersDrawer}
      />
      <DeviceDetailsDrawerConfirmationDialog
        visible={prodDeviceDialogVisible}
        onClose={closeProdDeviceDialog}
        onSave={onSaveProdDevice}
      />
    </Drawer>
  );
};

export default DeviceDetailsDrawer;
