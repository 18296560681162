import React from "react";
import i18n from "../../../i18n";
import { Tag, Tooltip, Typography } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { getMeasurementIdFormatted, MEASUREMENT_TYPE } from "../../utils/utils";
const { Text } = Typography;

export const getCalibrationStatus = (record) => {
  if (record.isCalibrationRequired) {
    return (
      <Tag
        color="var(--nose-calibration-required)"
        className="calibration-status"
      >
        <Text ellipsis strong style={{ color: "white" }}>
          {i18n.t("nose-calibration-required")}
        </Text>
      </Tag>
    );
  } else {
    if (record.isCalibrationAdvised) {
      return (
        <Tag
          color="var(--nose-calibration-advised)"
          className="calibration-status"
        >
          <Text ellipsis strong style={{ color: "white" }}>
            {i18n.t("nose-calibration-advised")}
          </Text>
        </Tag>
      );
    }
  }

  return (
    <Tag color="var(--nose-calibration-ready)" className="calibration-status">
      <Text ellipsis strong style={{ color: "white" }}>
        OK
      </Text>
    </Tag>
  );
};

export const getSpiroNoseColumns = (preferredLanguage) => [
  {
    title: i18n.t("device-details-noseId"),
    dataIndex: "noseId",
    key: "noseId",
    width: 70,
    sorter: true,
    render: (text) => <Link to={`/spironoses/${text}`}>{text}</Link>,
  },
  {
    title: i18n.t("nose-default-measurement"),
    dataIndex: "defaultMeasurementId",
    key: "defaultMeasurementId",
    width: 150,
    render: (text) =>
      text === 0 ? (
        "-"
      ) : (
        <Link to={"/measurements/" + text}>
          {getMeasurementIdFormatted(text, MEASUREMENT_TYPE.CALIBRATION)}
        </Link>
      ),
  },
  {
    title: i18n.t("nose-calibration-status"),
    dataIndex: "calibrationStatus",
    key: "calibrationStatus",
    width: 150,
    align: "center",
    sorter: true,
    render: (text, record) => getCalibrationStatus(record),
  },
  {
    title: i18n.t("nose-last-calibration-date"),
    dataIndex: "lastCalibrationDate",
    align: "center",
    key: "lastCalibrationDate",
    width: 150,
    render: (text) => {
      if (text) {
        let date = moment.utc(text).local().locale(preferredLanguage).fromNow();
        let tooltipdate = moment
          .utc(text)
          .local()
          .locale(preferredLanguage)
          .format("YYYY-MM-DD HH:mm");
        return (
          <Tooltip title={tooltipdate}>
            <Text ellipsis>{date}</Text>
          </Tooltip>
        );
      }
      return "-";
    },
  },
  {
    title: i18n.t("nose-default-calibration-date"),
    dataIndex: "defaultCalibrationDate",
    key: "defaultCalibrationDate",
    align: "center",
    width: 150,
    render: (text) => {
      if (text) {
        let date = moment.utc(text).local().locale(preferredLanguage).fromNow();
        let tooltipdate = moment
          .utc(text)
          .local()
          .locale(preferredLanguage)
          .format("YYYY-MM-DD HH:mm");
        return (
          <Tooltip title={tooltipdate}>
            <Text ellipsis>{date}</Text>
          </Tooltip>
        );
      }
      return "-";
    },
  },
  {
    title: i18n.t("measurement-performedby"),
    dataIndex: "performedBy",
    key: "performedBy",
    width: 150,
  },
  {
    title: i18n.t("device-details-organization"),
    dataIndex: "organization",
    key: "organization",
    width: 150,
  },
];
