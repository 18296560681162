import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import { Drawer, Button } from "antd";
import { NotificationContext } from "../../contexts/NotificationProvider";
import NotificationList from "../notification-list/notification-list";

const NotificationsDrawer = (props) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const { deleteAllNotifications } = useContext(NotificationContext);
  const closeDrawer = () => {
    props.onClose();
  };

  useEffect(() => {
    setVisible(props.visible);
  }, [props]);

  return (
    <Drawer
      title={t("notifications")}
      width={350}
      onClose={closeDrawer}
      visible={visible}
      footer={
        <Button
          id="delete-notifications"
          style={{ float: "right" }}
          onClick={deleteAllNotifications}
        >
          <DeleteOutlined />
          {t("notifications-delete-all")}
        </Button>
      }
    >
      <NotificationList />
    </Drawer>
  );
};

export default NotificationsDrawer;
