import React, { useContext } from "react";
import {
  CheckCircleOutlined,
  PlusCircleOutlined,
  MonitorOutlined,
} from "@ant-design/icons";
import "./new-measurement-step-finish.css";
import { Typography, Button, Space } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NewMeasurementContext } from "../../../contexts/NewMeasurementContext";
import { getMeasurementIdFormatted, getMeasurementTypeName } from "../../../utils/utils";

const { Title, Text } = Typography;

export const NewMeasurementStepFinish = (props) => {
  const { t } = useTranslation();
  const {
    measurement,
    onNewMeasurementClick,
    measurementType
  } = useContext(NewMeasurementContext);

  return (
    <div className="finish-step-container">
      <Space className="finish-step-contents" direction="vertical" align="center">
        <CheckCircleOutlined
          style={{ color: "var(--device-status-ready)", fontSize: "64px" }}
        />
        <Title level={2}>
          {t("new-measurement-completed", {
            measurementType: getMeasurementTypeName(measurementType, t),
            measurementId: getMeasurementIdFormatted(measurement?.id, measurementType)
          })}
        </Title>
        <Text>{t("measurement-completed-description")}</Text>
        <Button type="primary" onClick={onNewMeasurementClick}>
          <PlusCircleOutlined /> {t("measurement-start-new")}
        </Button>
        <Link to="/measurements">
          <Button type="primary">
            <MonitorOutlined /> {t("measurement-return-to-measurements")}
          </Button>
        </Link>
      </Space>
    </div>
  );
};

export default NewMeasurementStepFinish;
