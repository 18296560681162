import React, { useState, useEffect } from "react";
import { Space, Tooltip, Form, DatePicker } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import moment from "moment";

const QuestionItemDate = (props) => {
  const { t } = useTranslation();
  const [question, setQuestion] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);

  const onDateChange = (date) => {
    setSelectedDate(date);
  };

  const disabledDate = (current) => {
    switch (question.enabledDates) {
      case "future":
        return current < moment().startOf("day");
      case "past":
        return current > moment();
      default:
        return false;
    }
  };

  useEffect(() => {
    setQuestion(props.question);
  }, [props]);

  return (
    <Form.Item
      hasFeedback={question.feedback}
      colon={false}
      key={question.id}
      name={question.id}
      getValueProps={(value) => {
        if (!selectedDate && value) {
          setSelectedDate(moment(value));
        }
      }}
      label={
        question.label && (
          <Space>
            {t("survey:" + question.label)}
            {question.tooltip && (
              <Tooltip title={parse(t("survey:" + question.tooltip))}>
                <InfoCircleOutlined style={{ color: question.tooltipColor }} />
              </Tooltip>
            )}
          </Space>
        )
      }
      rules={
        question.rules
          ? question.rules.map((r) => {
            r.message = !r.message.startsWith("survey:") ? t("survey:" + r.message) : r.message;
            return r;
          })
          : null
      }
    >
      <DatePicker
        value={selectedDate}
        disabled={question.readonly}
        onChange={onDateChange}
        placeholder={t("survey:" + question.placeholder)}
        format={question.pickTime ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY"}
        disabledDate={disabledDate}
        showTime={question.pickTime}
      />
    </Form.Item>
  );
};

export default QuestionItemDate;
