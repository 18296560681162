import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types"; // ES6
import { ResearchContext } from "../../contexts/ResearchProvider";
import * as atlas from "azure-maps-control";
import "./location-card.css";

const LocationCard = (props) => {
  const { preferredLanguage } = useContext(ResearchContext);

  const loadAddress = async (map, lon, lat) => {
    map.resize();
    console.log(lat, lon);
    if (!isNaN(lat) && !isNaN(lon)) {
      /*Create a data source and add it to the map*/
      var ds = new atlas.source.DataSource();
      map.sources.add(ds);

      //Create a symbol layer using the data source and add it to the map
      map.layers.add(new atlas.layer.SymbolLayer(ds, null));

      var point = new atlas.Shape(new atlas.data.Point([lon, lat]));
      ds.add([point]);
      map.setCamera({
        center: [lon, lat],
        zoom: 11,
      });
      map.resize();
    }
  };

  useEffect(() => {
    const initChart = () => {
      return new atlas.Map("location-chart", {
        view: "Auto",
        language: preferredLanguage,
        authOptions: {
          authType: "subscriptionKey",
          subscriptionKey: process.env.REACT_APP_AZURE_MAPS_KEY,
        },
      });
    };
    let myMap = initChart();
    myMap.events.add("ready", () => loadAddress(myMap, props.lon, props.lat));

    return () => {
      if (myMap) {
        myMap.dispose();
      }
    };
  }, [props.lon, props.lat, preferredLanguage]);

  return <div id="location-chart"></div>;
};

LocationCard.propsTypes = {
  lon: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
};

export default LocationCard;
