import React from "react";
import moment from "moment";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
const { Title } = Typography;

const GreetingMessage = (props) => {
  const { t } = useTranslation();
  const getGreeting = () => {
    const currentTime = moment();
    const currentHour = currentTime.hours();
    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 17; // 24hr time to split the evening
    let greet = "";

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      // Between 12 PM and 5PM
      greet = t("greeting-afternoon");
    } else if (currentHour >= splitEvening) {
      // Between 5PM and Midnight
      greet = t("greeting-evening");
    } else {
      // Between dawn and noon
      greet = t("greeting-morning");
    }
    greet = `${greet}, ${props.name}!`;
    return greet;
  };

  return (
    <Title level={4} style={{ margin: "0px" }}>
      {getGreeting()}
    </Title>
  );
};

export default GreetingMessage;
