import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, Menu } from "antd";
import {
  APP_ROLES,
  DOWNLOAD_EXPORT_TYPES,
  DOWNLOAD_DATA_TYPES,
} from "../../utils/utils";
import { AuthContext } from "../../contexts/AuthProvider";
import {
  CloudDownloadOutlined,
  DownOutlined,
  FileExcelOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
const { SubMenu } = Menu;

const MeasurementDownloadDropdown = (props) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const isAdmin = user.role === APP_ROLES.ADMIN;

  const downloadMenu = (
    <Menu>
      {isAdmin && (
        <SubMenu
          onClick={({ key }) => {
            props.handleDownload(DOWNLOAD_DATA_TYPES.SENSOR, key);
          }}
          key="sensor-download"
          icon={<CloudDownloadOutlined style={{ fontSize: "14px" }} />}
          title={t("measurements-sensor-data")}
        >
          <Menu.Item
            key={DOWNLOAD_EXPORT_TYPES.CSV}
            icon={
              <FileTextOutlined
                style={{ fontSize: "14px", color: "var(--csv-icon-color)" }}
              />
            }
          >
            CSV
          </Menu.Item>
          <Menu.Item
            key={DOWNLOAD_EXPORT_TYPES.XLSX}
            icon={
              <FileExcelOutlined
                style={{ fontSize: "14px", color: "var(--xls-icon-color)" }}
              />
            }
          >
            Excel
          </Menu.Item>
        </SubMenu>
      )}
      <SubMenu
        onClick={({ key }) => {
          props.handleDownload(DOWNLOAD_DATA_TYPES.RAW, key);
        }}
        key="raw-download"
        icon={<CloudDownloadOutlined style={{ fontSize: "14px" }} />}
        title={t("measurements-raw-data")}
        // something should be changed here.
      >
        <Menu.Item
          key={DOWNLOAD_EXPORT_TYPES.CSV}
          icon={
            <FileTextOutlined
              style={{ fontSize: "14px", color: "var(--csv-icon-color)" }}
            />
          }
        >
          CSV
        </Menu.Item>
        <Menu.Item
          key={DOWNLOAD_EXPORT_TYPES.XLSX}
          icon={
            <FileExcelOutlined
              style={{ fontSize: "14px", color: "var(--xls-icon-color)" }}
            />
          }
        >
          Excel
        </Menu.Item>
      </SubMenu>
      <SubMenu
        onClick={({ key }) => {
          props.handleDownload(DOWNLOAD_DATA_TYPES.SURVEY, key);
        }}
        key="survey-download"
        icon={<CloudDownloadOutlined style={{ fontSize: "14px" }} />}
        title={t("measurements-survey-data")}
        disabled={
          props.hasOnlyEmptySurveys || (!isAdmin && props.hasNoSubmittedSurveys)
        }
      >
        {/* Downloading CSV files with download all is not implemented
          <Menu.Item
            key={DOWNLOAD_EXPORT_TYPES.CSV}
            icon={
              <FileTextOutlined style={{ fontSize: "14px", color: "var(--csv-icon-color)" }} />
            }
          >
            CSV
          </Menu.Item>
        */}
        <Menu.Item
          key={DOWNLOAD_EXPORT_TYPES.XLSX}
          icon={
            <FileExcelOutlined
              style={{ fontSize: "14px", color: "var(--xls-icon-color)" }}
            />
          }
        >
          Excel
        </Menu.Item>
      </SubMenu>
      {isAdmin && (
        <>
          <SubMenu
            onClick={({ key }) => {
              props.handleDownload(DOWNLOAD_DATA_TYPES.CALIBRATED, key);
            }}
            key="calibrated-download"
            icon={
              <CloudDownloadOutlined
                style={{
                  fontSize: "14px",
                  color: "var(--measurement-calibration-color)",
                }}
              />
            }
            title={t("measurement-analysis-calibrated")}
          >
            <Menu.Item
              key={DOWNLOAD_EXPORT_TYPES.JSON}
              icon={<FileTextOutlined style={{ fontSize: "14px" }} />}
            >
              JSON
            </Menu.Item>
            <Menu.Item
              key={DOWNLOAD_EXPORT_TYPES.CSV}
              icon={
                <FileTextOutlined
                  style={{ fontSize: "14px", color: "var(--csv-icon-color)" }}
                />
              }
            >
              CSV
            </Menu.Item>
            <Menu.Item
              key={DOWNLOAD_EXPORT_TYPES.XLSX}
              icon={
                <FileExcelOutlined
                  style={{ fontSize: "14px", color: "var(--xls-icon-color)" }}
                />
              }
            >
              Excel
            </Menu.Item>
          </SubMenu>
          <SubMenu
            onClick={({ key }) => {
              props.handleDownload(DOWNLOAD_DATA_TYPES.PROCESSED, key);
            }}
            key="processed-download"
            icon={
              <CloudDownloadOutlined
                style={{ fontSize: "14px", color: "#52c41a" }}
              />
            }
            title={t("measurement-analysis-processed")}
          >
            <Menu.Item
              key={DOWNLOAD_EXPORT_TYPES.JSON}
              icon={<FileTextOutlined style={{ fontSize: "14px" }} />}
            >
              JSON
            </Menu.Item>
            <Menu.Item
              key={DOWNLOAD_EXPORT_TYPES.CSV}
              icon={
                <FileTextOutlined
                  style={{ fontSize: "14px", color: "var(--csv-icon-color)" }}
                />
              }
            >
              CSV
            </Menu.Item>
            <Menu.Item
              key={DOWNLOAD_EXPORT_TYPES.XLSX}
              icon={
                <FileExcelOutlined
                  style={{ fontSize: "14px", color: "var(--xls-icon-color)" }}
                />
              }
            >
              Excel
            </Menu.Item>
          </SubMenu>
          <SubMenu
            onClick={({ key }) => {
              props.handleDownload(DOWNLOAD_DATA_TYPES.RESULTS, key);
            }}
            key="results-download"
            icon={
              <CloudDownloadOutlined
                style={{ fontSize: "14px", color: "#52c41a" }}
              />
            }
            title={t("results")}
          >
            <Menu.Item
              key={DOWNLOAD_EXPORT_TYPES.JSON}
              icon={<FileTextOutlined style={{ fontSize: "14px" }} />}
            >
              JSON
            </Menu.Item>
            <Menu.Item
              key={DOWNLOAD_EXPORT_TYPES.CSV}
              icon={
                <FileTextOutlined
                  style={{ fontSize: "14px", color: "var(--csv-icon-color)" }}
                />
              }
            >
              CSV
            </Menu.Item>
            <Menu.Item
              key={DOWNLOAD_EXPORT_TYPES.XLSX}
              icon={
                <FileExcelOutlined
                  style={{ fontSize: "14px", color: "var(--xls-icon-color)" }}
                />
              }
            >
              Excel
            </Menu.Item>
          </SubMenu>
        </>
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlay={downloadMenu}
      trigger={["click"]}
      disabled={props.disabled}
    >
      <Button type={props.buttonType}>
        {props.text} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

MeasurementDownloadDropdown.propsTypes = {
  buttonType: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleDownload: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

MeasurementDownloadDropdown.defaultProps = {
  buttonType: "primary",
  text: "",
  disabled: false,
};

export default MeasurementDownloadDropdown;
