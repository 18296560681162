import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

const LoadButton = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [buttonType, setButtonType] = useState(props.buttonType);

  const onBtnClick = async () => {
    setIsLoading(true);
    await props.onClick(props.id);
    setIsLoading(false);
    setButtonType("dashed");
  };

  return (
    <Button
      id={props.id}
      type={buttonType}
      onClick={onBtnClick}
      loading={isLoading}
    >
      {props.text}
    </Button>
  );
};

LoadButton.propsTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonType: PropTypes.string.isRequired,
};

LoadButton.defaultProps = {
  buttonType: "primary",
  text: "",
};

export default LoadButton;
