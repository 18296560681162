import React, { useState, useEffect, useContext } from "react";
import {
  Drawer,
  List,
  Row,
  Input,
  Skeleton,
  AutoComplete,
  Space,
  Tag,
  Button,
  Tooltip,
  Modal,
} from "antd";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthProvider";
import { API_REQUESTS } from "../../utils/utils";
import DeviceDetailsDrawer from "../../components/device-details-drawer/device-details-drawer";
import {
  getDevices,
  getAssignedDevices,
  assignUserDevices,
} from "../../../services/ApiService";
import { MinusCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import EmptyDescription from "../simple-components/empty-description/empty-description";
import SimpleMessage from "../simple-components/simple-message/simple-message";
const { confirm } = Modal;

const AssignUserDevice = (props) => {
  const { getAccessToken } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [deviceCount, setDeviceCount] = useState(0);

  const [foundDevices, setFoundDevices] = useState([]);
  const [options, setOptions] = useState([]);
  const [assignedDevices, setAssignedDevices] = useState([]);
  const [fetchingAssignedDevices, setFetchingAssignedDevices] = useState(false);
  const [deviceSearchTerm, setDeviceSearchTerm] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [deviceDrawerVisible, setDeviceDrawerVisible] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setVisible(props.visible);
    setFoundDevices([]);
    setOptions([]);
    setDeviceSearchTerm(null);
    fetchAssignedDevices(props.user.id);
  }, [props]);

  const fetchAssignedDevices = async (userId) => {
    try {
      setFetchingAssignedDevices(true);
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await getAssignedDevices(userId, token);
        if (res && res.result && !res.error) {
          setAssignedDevices(res.result.data);
          setDeviceCount(res.result.data.length);
        }
      }
    } catch (err) {
      console.error(err);
    }
    setFetchingAssignedDevices(false);
  };

  const openDeviceDrawer = (device) => {
    setSelectedDevice(device);
    setDeviceDrawerVisible(true);
  };

  const closeDeviceDrawer = () => {
    setDeviceDrawerVisible(false);
  };

  const getOptions = (devices) => {
    return devices.map((dev) => {
      return {
        label: (
          <Space>
            <Tag color={`var(--${dev.environment?.toLowerCase()}-color)`}>
              {dev.environment}
            </Tag>
            <span>{dev.serialNumber}</span>
          </Space>
        ),
        value: dev.deviceId,
      };
    });
  };

  useEffect(() => {
    const fetchDevices = async () => {
      if (deviceSearchTerm && deviceSearchTerm.length > 1) {
        try {
          const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
          if (token) {
            let res = await getDevices(token, 0, 100, deviceSearchTerm);
            if (res && res.result && !res.error) {
              let filteredDevices = res.result.data.filter(
                (p) =>
                  !assignedDevices.find((dev) => dev.deviceId === p.deviceId)
              );
              if (filteredDevices.length > 0) {
                setFoundDevices(filteredDevices);
                setOptions(getOptions(filteredDevices));
              }
            }
          }
        } catch (err) {
          console.error(err);
        }
      } else {
        setFoundDevices([]);
      }
    };
    const timer = setTimeout(() => {
      fetchDevices();
    }, 800);
    return () => clearTimeout(timer);
  }, [deviceSearchTerm]);

  const closeDrawer = () => {
    if (dirty) {
      //show confirm
      confirm({
        cancelText: t("profile-assign-devices-cancel"),
        icon: (
          <QuestionCircleOutlined
            style={{ color: "var(--question-icon-color)" }}
          />
        ),
        title: t("profile-assign-devices-leave-title"),
        content: t("profile-assign-devices-leave-content"),
        onOk: () => clearAndClose(),
      });
    } else {
      clearAndClose();
    }
  };

  const clearAndClose = (newCount) => {
    setDirty(false);
    setFoundDevices([]);
    setOptions([]);
    setDeviceSearchTerm(null);
    props.onClose(newCount ? newCount : deviceCount);
  };

  const onSaveClick = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let devices = assignedDevices.map((dev) => dev.deviceId);
        let res = await assignUserDevices(devices, props.user.id, token);
        if (res && res.result) {
          clearAndClose(assignedDevices.length);
          SimpleMessage(
            "success",
            t("profile-assign-devices-success", {
              count: devices.length,
              user: props.user.name,
            })
          );
        } else {
          SimpleMessage(
            "error",
            t("profile-assign-devices-error", {
              user: props.user.name,
            })
          );
        }
      }
    } catch (err) {
      SimpleMessage("error", err.message);
    }
    setLoading(false);
  };

  const handleSearchDevices = (value) => {
    setDeviceSearchTerm(value);
  };

  const onSelectDevice = (value) => {
    setAssignedDevices([
      ...assignedDevices,
      foundDevices.find((dev) => dev.deviceId === value),
    ]);
    setDeviceSearchTerm(null);
    setFoundDevices([]);
    setOptions([]);
    setDirty(true);
  };

  const removeDevice = (deviceId) => {
    setAssignedDevices(
      assignedDevices.filter((it) => it.deviceId !== deviceId)
    );
    setDirty(true);
  };

  return (
    <Drawer
      title={t("profile-assign-devices")}
      width={450}
      onClose={closeDrawer}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            id="cancel-assign-device"
            onClick={closeDrawer}
            style={{ marginRight: 8 }}
          >
            {t("profile-assign-devices-cancel")}
          </Button>
          <Button
            id="save-assign-device"
            disabled={!dirty}
            onClick={onSaveClick}
            type="primary"
          >
            {t("profile-assign-devices-confirm")}
          </Button>
        </div>
      }
    >
      <Skeleton loading={loading} active paragraph={{ rows: 5 }}>
        <Row>
          <AutoComplete
            id="search-device"
            dropdownMatchSelectWidth={250}
            style={{ width: "100%" }}
            onSelect={onSelectDevice}
            onSearch={handleSearchDevices}
            options={options}
            value={deviceSearchTerm}
          >
            <Input.Search
              placeholder={t("profile-assign-devices-search-placeholder")}
              enterButton
            />
          </AutoComplete>
        </Row>
        <Row>
          <Skeleton loading={fetchingAssignedDevices} active rows={10}>
            <List
              style={{ width: "100%" }}
              locale={{
                emptyText: (
                  <EmptyDescription
                    text={t("no-devices")}
                    symbol="😥"
                    label="DISAPPOINTED BUT RELIEVED FACE"
                  />
                ),
              }}
              rowKey="deviceId"
              dataSource={assignedDevices}
              renderItem={(item) =>
                item && (
                  <List.Item className="device-item">
                    <List.Item.Meta
                      avatar={
                        <div style={{ width: "50px" }}>
                          <Tag
                            color={`var(--${item.environment?.toLowerCase()}-color)`}
                          >
                            {item.environment}
                          </Tag>
                        </div>
                      }
                      title={
                        <Button
                          type="link"
                          onClick={() => openDeviceDrawer(item)}
                        >
                          {item.serialNumber}
                        </Button>
                      }
                    />
                    <Tooltip title={t("profile-assign-devices-remove")}>
                      <MinusCircleOutlined
                        className="remove-device"
                        style={{ margin: "0 8px", fontSize: "20px" }}
                        onClick={() => removeDevice(item.deviceId)}
                      />
                    </Tooltip>
                  </List.Item>
                )
              }
            />
          </Skeleton>
        </Row>
      </Skeleton>
      <DeviceDetailsDrawer
        device={selectedDevice}
        visible={deviceDrawerVisible}
        onClose={closeDeviceDrawer}
      />
    </Drawer>
  );
};

export default AssignUserDevice;
