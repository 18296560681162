function IcdWidget(Survey, $, HealthControl, Endpoint) {
  $ = $ || window.$;
  var widget = {
    name: "tagboxmedical",
    title: "ICD List",
    iconName: "icon-tagbox",
    widgetIsLoaded: function () {
      return typeof $ == "function" && !!$.fn.select2;
    },
    defaultJSON: {
      choices: ["Item 1", "Item 2", "Item 3"],
      url: "",
    },
    htmlTemplate: "<select multiple='multiple' style='width: 100%;'></select>",
    isFit: function (question) {
      return question.getType() === "tagboxmedical";
    },
    activatedByChanged: function (activatedBy) {
      Survey.JsonObject.metaData.addClass(
        "tagboxmedical",
        [
          {
            name: "hasOther",
            visible: false,
          },
        ],
        null,
        "checkbox"
      );
      Survey.JsonObject.metaData.addProperty("tagboxmedical", {
        name: "select2Config",
        default: null,
      });
    },
    fixStyles: function (el) {
      el.parentElement.querySelector(".select2-search__field").style.border =
        "none";
    },
    afterRender: function (question, el) {
      var self = this;
      var settings = {
        minimumInputLength: 3,
        ajax: {
          dataType: "json",
          type: "GET",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          delay: 250,
          url: Endpoint,
          data: function (params) {
            self.term = params.term;
            var query = {
              search: params.term,
            };
            return query;
          },
          processResults: function (data) {
            var list = data.destinationEntities;
            var results = list.map(function (dataItem) {
              // Remove HTML tags
              let title = dataItem.title.replace(/(<([^>]+)>)/gi, "");
              return {
                id: dataItem.theCode + ":" + title,
                text: dataItem.theCode + " " + title,
              };
            });

            results.push({ id: HealthControl, text: HealthControl });
            results.push({ id: self.term, text: self.term });

            return {
              results: results,
            };
          },
        },
      };

      var updateValueHandler = function (elem, quest) {
        const elm = $(elem);
        if (elm.find("option").length) {
          elm.val(quest.value).trigger("change");
        } else {
          for (var i = 0; i < quest.value.length; i++) {
            var newOption = new Option(
              quest.value[i], //TODO if question value is object then need to improve
              quest.value[i],
              true,
              true
            );

            elm.append(newOption);
          }
        }
      };

      var updateChoices = function (elem, quest) {
        let $el = initializeSelect2(elem, quest);
        $el.select2().empty();
        $el.select2(settings);

        updateValueHandler($el, quest);
      };

      question.readOnlyChangedCallback = function () {
        let $el = initializeSelect2(el, question);
        $el.prop("disabled", question.isReadOnly);
      };
      question.registerFunctionOnPropertyValueChanged(
        "visibleChoices",
        function () {
          updateChoices(el, question);
        }
      );
      question.valueChangedCallback = () => {
        if (question.isChangedFromUI) {
          updateChoices(el, question);
          question.isChangedFromUI = false;
        }
        else {
          question.value = $(el).find("select").val();
        }
      };

      updateChoices(el, question);
    },
    willUnmount: function (question, el) {
      $(el).find("select").off("select2:select").select2("destroy");
      question.readOnlyChangedCallback = null;
    },
  };

  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
}

if (typeof Survey !== "undefined") {
  IcdWidget(Survey, window.$);
}

function initializeSelect2(elem, question) {
  let $el = $(elem).is("select") ? $(elem) : $(elem).find("select");
  if ($el.is("select2")) {
    $el.select2("destroy");
  }
  $el.select2({
    tags: "true",
    disabled: question.isReadOnly,
    theme: "classic",
  });

  $el.on("select2:select", function (e) {
    question["isChangedFromUI"] = true;
    question.value = (question.value || []).concat(e.params.data.id);
  });

  $el.on("select2:unselect", function (e) {
    var index = (question.value || []).indexOf(e.params.data.id);
    if (index !== -1) {
      question["isChangedFromUI"] = true;
      var val = question.value;
      val.splice(index, 1);
      question.value = val;
    }
  });

  return $el;
}
export default IcdWidget;
