import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldHigh from "@amcharts/amcharts4-geodata/worldHigh";
import { AuthContext } from "../../contexts/AuthProvider";
import { API_REQUESTS, groupBy } from "../../utils/utils";
import { getUsersDashboard, getCountries } from "../../../services/ApiService";
import i18n from "../../../i18n";
import "./community-user-chart.css";
import { Spin } from "antd";

class CommunityUserChart extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    this.initChart();
    await this.fetchData();
  }

  fetchData = async () => {
    const token = await this.context.getAccessToken(
      API_REQUESTS.USER_IMPERSONATION
    );
    if (token) {
      this.countries = await getCountries(token);
      let res = await getUsersDashboard(token);
      if (res.result && !this.chart._disposed) {
        this.loadImageSeries(token, res.result);
        this.loadWorldSeries(res.result);
      }
    }

    this.setState({
      loading: false,
    });
  };

  goHome = () => {
    this.chart.goHome();
  };

  initChart = () => {
    this.chart = am4core.create("community-user-chart", am4maps.MapChart);
    // Set map definition
    this.chart.geodata = am4geodata_worldHigh;
    this.chart.zoomControl = new am4maps.ZoomControl();

    var homeButton = new am4core.Button();
    homeButton.events.on("hit", this.goHome);

    homeButton.icon = new am4core.Sprite();
    homeButton.padding(7, 5, 7, 5);
    homeButton.width = 30;
    homeButton.icon.path =
      "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
    homeButton.marginBottom = 10;
    homeButton.parent = this.chart.zoomControl;
    homeButton.insertBefore(this.chart.zoomControl.plusButton);

    // Set projection
    this.chart.projection = new am4maps.projections.Miller();

    // Series for World map
    this.worldSeries = this.chart.series.push(new am4maps.MapPolygonSeries());
    this.worldSeries.exclude = ["AQ"];
    this.worldSeries.useGeodata = true;

    let polygonTemplate = this.worldSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "[bold]{name}[/]\n{value}";
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.polygon.fillOpacity = 0.8;
    polygonTemplate.propertyFields.fill = "color";

    // Create hover state and set alternative fill color
    var hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#4E8C98");

    // Add image series
    this.imageSeries = this.chart.series.push(new am4maps.MapImageSeries());
    this.imageSeries.mapImages.template.nonScaling = true;
    this.imageSeries.mapImages.template.propertyFields.longitude = "longitude";
    this.imageSeries.mapImages.template.propertyFields.latitude = "latitude";
    this.imageSeries.mapImages.template.tooltipText =
      "[bold]{organization}[/]\n{name}";

    // Small map
    this.chart.smallMap = new am4maps.SmallMap();
    // Re-position to top right (it defaults to bottom left)
    this.chart.smallMap.align = "right";
    this.chart.smallMap.valign = "top";
    this.chart.smallMap.series.push(this.worldSeries);
    this.chart.smallMap.series.push(this.imageSeries);

    var circle = this.imageSeries.mapImages.template.createChild(
      am4core.Circle
    );
    circle.radius = 3;
    circle.propertyFields.fill = "color";

    var circle2 = this.imageSeries.mapImages.template.createChild(
      am4core.Circle
    );
    circle2.radius = 5;
    circle2.propertyFields.fill = "color";
  };

  loadImageSeries = async (token, userArray) => {
    this.imageSeries.data = [];
    let colorSet = new am4core.ColorSet();
    for (let element of userArray) {
      element.latitude = element.lat;
      element.longitude = element.lon;
      element.color = colorSet.next();
      //Create a point and add it to the data source.
      this.imageSeries.addData(element);
    }
  };

  loadWorldSeries = async (userArray) => {
    const datapoints = [];
    let colorSet = new am4core.ColorSet();
    var groupedCountries = groupBy(userArray, "country");
    var arr = Object.entries(groupedCountries);
    arr.forEach(([key, value]) => {
      let found = this.countries.find((c) => c.name === key || c.altSpellings.indexOf(key) >= 0);
      if (found) {
        let id = found.alpha2Code;
        datapoints.push({
          name: key,
          value: value.length,
          color: colorSet.next(),
          id,
        });
      }
    });
    this.worldSeries.data = datapoints;
  };

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div className="chart-user-container">
        <Spin tip={i18n.t("loading")} spinning={this.state.loading}>
          <div id="community-user-chart"></div>
        </Spin>
      </div>
    );
  }
}

export default CommunityUserChart;
