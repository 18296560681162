import { notification } from "antd";

const NotificationMessage = (type, title, content, placement) => {
  if (type === "error") {
    notification.error({
      message: title,
      description: content,
      placement: placement ?? "topRight",
      style: {
        width: 600,
        marginLeft: 335 - 600,
        color: "var(--header-color)",
      },
    });
  } else if (type === "info") {
    notification.info({
      message: title,
      description: content,
      placement: placement ?? "topRight",
      style: {
        color: "var(--header-color)",
      },
    });
  }
  else {
    notification[type]({
      message: title,
      description: content,
      placement: placement ?? "topRight",
      style: {
        color: "var(--header-color)",
      },
    });
  }
};

export default NotificationMessage;
