import React, { createContext, useState, useEffect } from "react";
import { API_REQUESTS, APP_ROLES } from "../utils/utils";
import { msalApp } from "../../services/AuthService";
import NotificationMessage from "../components/notification-message/notification-message";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useTranslation } from "react-i18next";
import {
  getUserProfileDetails,
  getUserProfilePhoto,
} from "../../services/ApiService";

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState();
  const [appLoading, setAppLoading] = useState(true);
  const [isFixedTranslation, setIsFixedTranslation] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  let accountObj = null;

  const login = async () => {
    try {
      // Login via redirect
      msalApp.loginRedirect({
        prompt: "select_account",
      });
    } catch (err) {
      console.info("Login exception: Not Authenticated");
      setIsAuthenticated(false);
      setAppLoading(false);
      setUser(null);
      let normalized = normalizeError(err);
      console.error("%c" + normalized.debug, "background: red;");
      NotificationMessage("error", t("login-error"), normalized.message);
    }
  };

  const logout = () => {
    if (user) {
      const currentAccount = msalApp.getAccountByUsername(user.account);
      msalApp.logout({
        account: currentAccount,
      });
    }
  };

  const setUserPhoto = async (url) => {
    console.log("setting new photo...", url);
    setPhotoUrl(url);
  };

  const getAccessToken = async (scopes, accountObj) => {
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      let username = accountObj && !user ? accountObj.username : user.account;
      var silentResult = await msalApp.acquireTokenSilent({
        scopes,
        account: msalApp.getAccountByUsername(username),
      });

      // Store token for ICD List
      localStorage.setItem('token', silentResult.accessToken)

      return silentResult.accessToken;
    } catch (err) {
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (err instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        console.log("acquiring token using redirect");
        var interactiveResult = await msalApp.acquireTokenRedirect({
          scopes,
        });
        if (interactiveResult) {
          return interactiveResult.accessToken;
        }

        return null;
      }
    }
  };

  const normalizeError = (error) => {
    var normalizedError = {};
    if (typeof error === "string") {
      var errParts = error.split("|");
      normalizedError =
        errParts.length > 1
          ? { message: errParts[1], debug: errParts[0] }
          : { message: error };
    } else {
      normalizedError = {
        message: error.message,
        debug: JSON.stringify(error),
      };
    }
    return normalizedError;
  };

  const handleResponse = async (tokenResponse) => {
    if (tokenResponse !== null) {
      accountObj = tokenResponse.account;
    } else {
      accountObj = getCurrentAccount();
    }
    // If MSAL already has an account, the user
    // is already logged in
    console.info("Authenticated", accountObj != null);
    setIsAuthenticated(accountObj != null);

    if (accountObj) {
      await loadUser(accountObj);
    }

    setAppLoading(false);
  };

  const loadUser = async (accountObj) => {
    let currentUser = null;
    try {
      const token = await getAccessToken(
        API_REQUESTS.USER_IMPERSONATION,
        accountObj
      );
      if (token) {
        // Store token for ICD List
        localStorage.setItem('token', token)

        // Enhance user object with data from Graph
        let id = accountObj.homeAccountId.split(".")[0];
        let res = await getUserProfileDetails(id, token);

        if (res && res.result) {
          currentUser = res.result;

          if (currentUser.language != null) {
            setRestrictedLanguage(currentUser.language);
          }

          setUser(currentUser);
          let photoBlob = await getUserProfilePhoto(id, token);
          if (photoBlob && photoBlob.result && photoBlob.result.size > 0) {
            setPhotoUrl(URL.createObjectURL(photoBlob.result));
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
    if (!currentUser) {
      //redirect to login page
      console.info("Not Authenticated");
      setIsAuthenticated(false);
    }
  };

  const setRestrictedLanguage = (code) => {
    console.log("setting language...", code);
    setIsFixedTranslation(true);
    i18n.changeLanguage(code);
    localStorage.setItem("lang", code);
  }

  const getCurrentAccount = () => {
    const currentAccounts = msalApp.getAllAccounts();
    if (currentAccounts !== null) {
      if (currentAccounts.length > 0) {
        return currentAccounts[0];
      }
    }
    return null;
  };

  const refreshUser = async () => {
    let acc = getCurrentAccount();
    if (acc) {
      await loadUser(acc);
    }
  };

  //call once
  useEffect(() => {
    const fetchData = () => {
      //handle redirect callback
      msalApp
        .handleRedirectPromise()
        .then(handleResponse)
        .catch((err) => {
          const errorMessage = err.errorMessage
            ? err.errorMessage
            : "Unable to acquire access token.";
          // setState works as long as navigateToLoginRequestUrl: false
          NotificationMessage("error", t("login-error"), errorMessage);
          setIsAuthenticated(false);
          setAppLoading(false);

          //redirect to login page
          console.info("Not Authenticated. Logging out...");
          logout();
        });
    };

    fetchData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        appLoading,
        user,
        refreshUser,
        isAuthenticated,
        getAccessToken,
        login,
        logout,
        photoUrl,
        isFixedTranslation,
        setUserPhoto,
        isAdmin: user?.role === APP_ROLES.ADMIN,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
