import React, { useContext, useEffect, useState, useRef } from "react";
import "./perform-measurement-step.css";
import { Modal, Icon, message, Row } from "antd";
import { Prompt } from "react-router-dom";
import {
  stopMeasurement,
  postSaveMeasurement,
  softDeleteEntity,
} from "../../../../services/ApiService";
import moment from "moment";
import {
  API_REQUESTS,
  BUFFER_SECONDS,
  DEVICE_MODE,
  MEASUREMENT_PAGES,
  MEASUREMENT_STATUS,
  MEASUREMENT_TYPE,
  ENTITY_TYPE,
  SIGNAL_CHART_MODE,
} from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../contexts/AuthProvider";
import MeasurementDetailsBar from "../../measurement-details-bar/measurement-details-bar";
import SignalChart from "../../signal-chart/signal-chart";
import { NewMeasurementContext } from "../../../contexts/NewMeasurementContext";
import StopMeasurementDialog from "../../stop-measurement-dialog/stop-measurement-dialog";

const { confirm } = Modal;

const PerformMeasurementStep = (props) => {
  const { getAccessToken } = useContext(AuthContext);
  const {
    next,
    measurement,
    onNewMeasurementClick,
    selectedDevice,
    measurementStatus,
    isAuthorizedToStop,
    isStartOfMeasurement,
    setStartOfMeasurement,
  } = useContext(NewMeasurementContext);
  const { t } = useTranslation();

  const [isStopDisabled, setIsStopDisabled] = useState(true);
  const [dateEnd, setDateEnd] = useState(null);
  const [isStopModalVisible, setIsStopModalVisible] = useState(false);
  const dateStoppedReff = useRef(null);

  useEffect(() => {
    if (
      selectedDevice?.sessionTimeout &&
      !dateEnd &&
      measurement &&
      measurementStatus === MEASUREMENT_STATUS.IN_PROGRESS
    ) {
      if (
        measurement.measurementType === MEASUREMENT_TYPE.AMBIENT &&
        selectedDevice.deviceMode === DEVICE_MODE.CONTINUOUS
      ) {
        setDateEnd(moment.utc(measurement.dateCreated).add(2, "hours"));
      } else {
        setDateEnd(
          moment
            .utc(measurement.dateCreated)
            .add(selectedDevice.sessionTimeout, "seconds")
        );
      }
    }
  }, [selectedDevice?.sessionTimeout, measurementStatus]);

  useEffect(() => {
    if (
      measurementStatus === MEASUREMENT_STATUS.COMPLETED ||
      measurementStatus === MEASUREMENT_STATUS.ERROR
    ) {
      openSaveMeasurementModal();
    }
  }, [measurementStatus]);

  const isAmbientAndInvalid = (measurement, dateStopped) => {
    if (!measurement) {
      return false;
    }
    if (!dateStopped) {
      dateStopped = moment.utc();
    }
    let dateCreated = moment.utc(measurement?.dateCreated);
    let differenceSec = dateStopped.diff(dateCreated, "seconds");
    return (
      measurement.measurementType === MEASUREMENT_TYPE.AMBIENT &&
      differenceSec < BUFFER_SECONDS
    );
  };

  const openStopModal = () => {
    setIsStopModalVisible(true);
  };

  const closeStopModal = () => {
    setIsStopModalVisible(false);
  };

  const onStopAndSave = () => {
    setIsStopDisabled(true);
    next();
  };

  const onStopAndDelete = () => {
    setIsStopDisabled(true);
    onNewMeasurementClick();
  };

  const getSpiroNoseStatus = () => {
    let statusText = "";
    if (selectedDevice?.isCalibrationRequired) {
      statusText = t("nose-status-calibration-required");
    } else if (selectedDevice?.isAmbientRequired) {
      statusText = t("nose-status-ambient-required");
    } else if (selectedDevice?.isCalibrationAdvised) {
      statusText = t("nose-status-calibration-advised");
    } else {
      statusText = t("nose-status-calibration-ok");
    }
    return statusText;
  };

  const checkIfMeasurmentJustStarted = () => {
    if (isStartOfMeasurement) {
      setStartOfMeasurement(false);
      return true;
    }
    return false;
  };

  const openSaveMeasurementModal = () => {
    setDateEnd(moment.utc());
    if (isAmbientAndInvalid(measurement, dateStoppedReff.current)) {
      onNewMeasurementClick();
      return;
    }

    confirm({
      icon: <Icon type="warning" style={{ fontSize: "40px", color: "red" }} />,
      content: t("new-measurement-save-dialog-message"),
      okText: t("new-measurement-save-dialog-save"),
      cancelText: t("new-measurement-save-dialog-discard"),
      onOk: async () => {
        const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
        if (token) {
          const mId = parseInt(measurement?.id);
          try {
            await postSaveMeasurement(mId, token);
            message.success(t("new-measurement-save-success"));
          } catch (ex) {
            message.error(t(ex.error));
          }
        }
        next();
      },
      onCancel: async () => {
        const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
        if (token) {
          const mId = parseInt(measurement?.id);
          try {
            // Make an API call to discard the measurement
            let data = {
              entityType: ENTITY_TYPE.MEASUREMENT,
              entityId: mId.toString(),
            };
            var res = await softDeleteEntity(data, token);
            if (res.success) {
              message.success(t("new-measurement-discard-success"));
            } else {
              message.error(t(res.error));
            }
          } catch (ex) {
            console.error(ex);
            message.error(t(ex.error));
          }
        }
        onNewMeasurementClick();
      },
    });
  };

  return (
    <div className="signal-container">
      {!isStopDisabled && isAuthorizedToStop && (
        <Prompt message={t("new-measurement-leave-dialog-message")} />
      )}
      <MeasurementDetailsBar
        currentPage={MEASUREMENT_PAGES.PERFORM_STEP}
        dateEnd={dateEnd}
        measurement={measurement}
        spiroNoseStatus={getSpiroNoseStatus()}
        isStopDisabled={isStopDisabled}
        onStopButtonClicked={openStopModal}
        measurementStatus={measurementStatus}
        hasSessionTimeout={selectedDevice?.sessionTimeout !== 0}
        isAuthorizedToStop={isAuthorizedToStop}
      />
      <Row>
        <SignalChart
          chartId="performSignalChart"
          chartMode={SIGNAL_CHART_MODE.REAL_TIME}
          sessionId={measurement?.sessionId}
          measurementType={measurement?.measurementType}
          onDataReceived={() => setIsStopDisabled(false)}
          deviceMode={selectedDevice?.deviceMode}
          startDate={moment.utc(measurement?.dateCreated)?.toDate()}
          isStartOfMeasurement={checkIfMeasurmentJustStarted()}
        />
      </Row>
      <StopMeasurementDialog
        visible={isStopModalVisible}
        onClose={closeStopModal}
        onStopAndDelete={onStopAndDelete}
        onStopAndSave={onStopAndSave}
        measurement={measurement}
      />
    </div>
  );
};

export default PerformMeasurementStep;
