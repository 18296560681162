import React, { useContext } from "react";
import i18n from "../../../i18n";
import { Typography, Tooltip, Space } from "antd";
import MeasurementItemMenu from "../../components/measurement-item-menu/measurement-item-menu";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
  EditOutlined,
  MonitorOutlined,
} from "@ant-design/icons";
import {
  MEASUREMENT_STATUS,
  SURVEY_STATUS,
  getMeasurementTypeColor,
  getMeasurementTypeName,
  getMeasurementIdFormatted,
  MEASUREMENT_TYPE,
} from "../../utils/utils";
import { Link } from "react-router-dom";
import EllipsisText from "../../components/simple-components/ellipsis-text/ellipsis-text";
import EllipsisDate from "../../components/simple-components/ellipsis-date/ellipsis-date";
import { PageSettingsContext } from "../../contexts/PageSettingsProvider";
const { Text } = Typography;

export const getMeasurementStatus = (measurementStatus, fontSize) => {
  switch (measurementStatus) {
    case MEASUREMENT_STATUS.ERROR:
      return (
        <Tooltip title={i18n.t("measurement-status-error")}>
          <ExclamationCircleOutlined
            style={{
              color: "var(--measurement-status-error)",
              fontSize,
            }}
          />
        </Tooltip>
      );
    case MEASUREMENT_STATUS.UNKNOWN:
      return (
        <Tooltip title={i18n.t("measurement-status-unknown")}>
          <QuestionCircleOutlined
            style={{
              color: "var(--measurement-status-unknown)",
              fontSize,
            }}
          />
        </Tooltip>
      );
    case MEASUREMENT_STATUS.IN_PROGRESS:
      return (
        <Tooltip title={i18n.t("measurement-status-inprogress")}>
          <SyncOutlined
            spin
            style={{
              color: "var(--measurement-status-inprogress)",
              fontSize,
            }}
          />
        </Tooltip>
      );
    case MEASUREMENT_STATUS.COMPLETED:
      return (
        <Tooltip title={i18n.t("measurement-status-completed")}>
          <CheckCircleOutlined
            style={{
              color: "var(--measurement-status-completed)",
              fontSize,
            }}
          />
        </Tooltip>
      );
    default:
      return null;
  }
};

const getSurveyStatus = (surveyStatus) => {
  switch (surveyStatus) {
    case SURVEY_STATUS.EMPTY:
      return (
        <Tooltip title={i18n.t("survey-status-empty")}>
          <EditOutlined
            style={{
              color: "var(--survey-status-empty)",
              fontSize: "20px",
            }}
          />
        </Tooltip>
      );
    case SURVEY_STATUS.IN_PROGRESS:
      return (
        <Tooltip title={i18n.t("survey-status-inprogress")}>
          <EditOutlined
            style={{
              color: "var(--survey-status-inprogress)",
              fontSize: "20px",
            }}
          />
        </Tooltip>
      );
    case SURVEY_STATUS.COMPLETED:
      return (
        <Tooltip title={i18n.t("survey-status-completed")}>
          <EditOutlined
            style={{
              color: "var(--survey-status-completed)",
              fontSize: "20px",
            }}
          />
        </Tooltip>
      );
    case SURVEY_STATUS.SUBMITTED:
      return (
        <Tooltip title={i18n.t("survey-status-submitted")}>
          <EditOutlined
            style={{
              color: "var(--survey-status-submitted)",
              fontSize: "20px",
            }}
          />
        </Tooltip>
      );
    default:
      return null;
  }
};

export const getMeasurementColumns = (
  preferredLanguage,
  surveyStatusChanged,
  refreshMeasurements = null,
  pageSettings = null
) => {
  const { measurementsFilterState, setMeasurementsFilterState } =
    useContext(PageSettingsContext);

  return [
    {
      title: i18n.t("measurement-id"),
      dataIndex: "id",
      key: "id",
      width: 120,
      sorter: true,
      render: (text, record, index) => {
        let title = getMeasurementTypeName(record.measurementType);
        let color = getMeasurementTypeColor(record.measurementType);
        return (
          <Space>
            <Tooltip title={title}>
              <MonitorOutlined style={{ color, fontSize: "16px" }} />
            </Tooltip>
            <Link
              to={
                record.measurementStatus === MEASUREMENT_STATUS.IN_PROGRESS
                  ? "/new-measurement/" + text
                  : "/measurements/" + text
              }
              onClick={() => {
                setMeasurementsFilterState({
                  ...measurementsFilterState,
                  selectedMeasurementIndex: index,
                  totalMeasurements: pageSettings.total,
                  pageSize: pageSettings.pageSize,
                  currentPage: pageSettings.current,
                });
              }}
            >
              {getMeasurementIdFormatted(text, record.measurementType)}
            </Link>
          </Space>
        );
      },
    },
    {
      title: i18n.t("new-measurement-ambient-id"),
      dataIndex: "ambientMeasurementId",
      key: "ambientMeasurementId",
      width: 110,
      sorter: true,
      render: (text, record, index) => {
        return (
          <Space>
            {text
              ? <Link to={"/measurements/" + text}>
                {getMeasurementIdFormatted(text, record.measurementType)}
              </Link>
              : i18n.t("none")}
          </Space>
        );
      },
    },
    {
      title: i18n.t("measurement-study"),
      dataIndex: "studyName", //maybe add color for status?
      key: "studyName",
      responsive: ["md"],
      width: 150,
      sorter: true,
      render: (text, record) => {
        if (!text) {
          text = i18n.t("none");
        }
        return <EllipsisText text={text} copyable={true} />;
      },
    },
    {
      title: i18n.t("measurement-state"),
      align: "center",
      dataIndex: "measurementStatus",
      key: "measurementStatus",
      render: (text, record) =>
        getMeasurementStatus(record.measurementStatus, "20px"),
      responsive: ["md"],
      width: 80,
      sorter: true,
    },
    {
      title: i18n.t("measurement-survey"),
      align: "center",
      dataIndex: "surveyStatus",
      key: "surveyStatus",
      render: (text, record) =>
        record?.measurementType === MEASUREMENT_TYPE.REGULAR
          ? getSurveyStatus(record.surveyStatus)
          : i18n.t("none"),
      responsive: ["md"],
      width: 80,
      sorter: true,
    },
    {
      title: i18n.t("measurement-submitted"),
      dataIndex: "dateSubmitted",
      key: "dateSubmitted",
      responsive: ["md"],
      width: 80,
      align: "center",
      sorter: true,
      render: (text, record) => {
        return <EllipsisDate date={record.dateSubmitted} />;
      },
    },
    {
      title: i18n.t("measurement-deviceid"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      responsive: ["md"],
      width: 135,
      sorter: true,
      render: (text, record) => <Text copyable>{text.toUpperCase()}</Text>,
    },
    {
      title: i18n.t("measurement-noseid"),
      dataIndex: "noseId",
      key: "noseId",
      responsive: ["md"],
      width: 100,
      sorter: true,
      render: (text, record) => <Text copyable>{text}</Text>,
    },
    {
      title: i18n.t("measurement-performedby"),
      dataIndex: "createdBy",
      key: "createdBy",
      responsive: ["md"],
      width: 150,
      sorter: true,
      render: (text, record) => <Text>{record.performedBy}</Text>,
    },
    {
      title: i18n.t("measurement-created"),
      dataIndex: "dateCreated",
      key: "dateCreated",
      responsive: ["md"],
      align: "center",
      sorter: true,
      render: (text, record) => {
        return <EllipsisDate date={record.dateCreated} />;
      },
    },
    {
      dataIndex: "",
      key: "x",
      width: 30,
      render: (text, record) => (
        <MeasurementItemMenu
          record={record}
          refreshMeasurements={refreshMeasurements}
          pageSettings={pageSettings}
          surveyStatusChanged={surveyStatusChanged}
        />
      ),
    },
  ];
};
