import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, List, Result, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import EmptyDescription from "../simple-components/empty-description/empty-description";
import AnalysisItem from "./analysis-item";

const AnalysisResults = (props) => {
  const { t } = useTranslation();
  const [analysisResults, setAnalysisResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props) {
      if (props.analyseResult) {
        var newAnalysisResults = [];
        newAnalysisResults.push(props.analyseResult);
        setAnalysisResults(newAnalysisResults);
      }
      setIsLoading(false);
    }
  }, [props?.analyseResult]);

  return (
    <Card
      className="responsive-card-shadow"
      bordered={false}
      size="small"
      title={t("results")}
    >
      {isLoading && <Skeleton style={{ height: "100%" }} />}
      {!isLoading && (!analysisResults || analysisResults?.length === 0) && (
        <Result status="warning" title={t("analysis-results-not-available")} />
      )}
      {!isLoading && analysisResults.length > 0 && (
        <List
          itemLayout="horizontal"
          style={{ height: "100%" }}
          locale={{
            emptyText: (
              <EmptyDescription
                text={t("none")}
                symbol="😥"
                label="DISAPPOINTED BUT RELIEVED FACE"
              />
            ),
          }}
          dataSource={analysisResults}
          renderItem={(analysisResult) => (
            <List.Item key={analysisResult?.moduleName}>
              <AnalysisItem diagnostic={analysisResult} />
            </List.Item>
          )}
        />
      )}
    </Card>
  );
};

AnalysisResults.defaultProps = {
  areAnalysisResultsReady: true,
};

AnalysisResults.propsTypes = {
  diagnosticModels: PropTypes.array.isRequired,
  measurementId: PropTypes.number.isRequired,
  areAnalysisResultsReady: PropTypes.bool,
  fetchMode: PropTypes.number.isRequired,
};

export default AnalysisResults;
