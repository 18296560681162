import React from "react";
import { notification, Button } from "antd";
import { WarningFilled } from "@ant-design/icons";
import Emoji from "../simple-components/emoji/emoji";

const CookieConsent = (onAgree, t) => {
  const cookieAgreeKey = `open${Date.now()}`;

  const btnAgreeClick = () => {
    notification.close(cookieAgreeKey);
    onAgree();
  };

  const btnCookieAgree = (
    <Button type="primary" size="small" onClick={btnAgreeClick}>
      {t("agree")}
    </Button>
  );

  notification.info({
    key: cookieAgreeKey,
    message: (
      <span>
        {t("cookie-title")}
        <Emoji symbol="🍪" label="cookie" />
      </span>
    ),
    description: t("cookie-description"),
    placement: "bottomRight",
    btn: btnCookieAgree,
    duration: 0,
    style: {
      width: 600,
      marginLeft: 335 - 600,
      opacity: ".9",
    },
    icon: <WarningFilled style={{ color: "var(--btn-color)" }} />,
  });
};

export default CookieConsent;
