import React from "react";
import {
  AlignCenterOutlined,
  LaptopOutlined,
  Loading3QuartersOutlined,
  MonitorOutlined,
  TrophyOutlined
} from "@ant-design/icons";
import AnalyzeMeasurementStep from "../../components/new-measurement-steps/analyze-measurement-step/analyze-measurement-step";
import NewMeasurementStepFinish from "../../components/new-measurement-steps/new-measurement-step-finish/new-measurement-step-finish";
import PerformMeasurementStep from "../../components/new-measurement-steps/perform-measurement-step/perform-measurement-step";
import SelectDeviceMeasurementStep from "../../components/new-measurement-steps/select-device-measurement-step/select-device-measurement-step";
import SurveyMeasurementStep from "../../components/new-measurement-steps/survey-measurement-step/survey-measurement-step";

export const getNewMeasurementSteps = (t) => {
  return {
    selectDeviceStep: {
      title: t("placeholder-select-device"),
      content: <SelectDeviceMeasurementStep />,
      icon: <LaptopOutlined />,
      disabled: false
    },
    performMeasurementStep: {
      title: t("perform-measurement"),
      content: <PerformMeasurementStep />,
      icon: <MonitorOutlined />,
      disabled: false
    },
    analysisResultStep: {
      title: t("new-measurement-analyze"),
      content: <AnalyzeMeasurementStep />,
      icon: <Loading3QuartersOutlined />,
      disabled: false
    },
    completeSurveyStep: {
      title: t("new-measurement-questionnaire"),
      content: <SurveyMeasurementStep />,
      icon: <AlignCenterOutlined />,
      disabled: false
    },
    finishStep: {
      title: t("completed"),
      content: <NewMeasurementStepFinish />,
      icon: <TrophyOutlined />,
      disabled: false
    }
  };
};