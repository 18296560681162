import React from "react";
import PropTypes from "prop-types";
import { Tooltip, Typography } from "antd";
const { Text } = Typography;

const EllipsisText = (props) => {
  return (
    <Tooltip title={props.tooltip}>
      <Text copyable={props.copyable} ellipsis type={props.textType}>
        {props.text}
      </Text>
    </Tooltip>
  );
};

EllipsisText.propsTypes = {
  tooltip: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  copyable: PropTypes.bool,
  textType: PropTypes.string,
};

EllipsisText.defaultProps = {
  copyable: false,
};

export default EllipsisText;
