import React, { useEffect, useState, useContext } from "react";
import { Breadcrumb, Tooltip } from "antd";
import "./header-breadcrumb.css";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HomeOutlined } from "@ant-design/icons";
import { ResearchContext } from "../../contexts/ResearchProvider";

const HeaderBreadcrumb = () => {
  const { preferredLanguage } = useContext(ResearchContext);
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const { t } = useTranslation();
  let location = useLocation();

  const breadcrumbNameMap = {
    "/devices": t("devices"),
    "/users": t("users"),
    "/studies": t("studies"),
    "/measurements": t("measurements"),
    "/new-measurement": t("new-measurement"),
    "/survey-templates": t("survey-templates"),
    "/users/details": t("user-details"),
    "/account": t("account"),
    "/survey-templates/creator": t("survey-creator"),
    "/spironoses": t("spironoses"),
    "/measurements/edit-survey": t("measurement-edit-survey"),
  };

  useEffect(() => {
    const onLocationChanged = () => {
      const pathSnippets = location.pathname.split("/").filter((i) => i);
      const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
        const title =
          breadcrumbNameMap[url] ?? pathSnippets[pathSnippets.length - 1];
        return (
          <Breadcrumb.Item key={url}>
            <Link to={url}>{title}</Link>
          </Breadcrumb.Item>
        );
      });
      const items = [
        <Breadcrumb.Item key="/">
          <Link to="/">
            <Tooltip title={t("dashboard")}>
              <HomeOutlined />
            </Tooltip>
          </Link>
        </Breadcrumb.Item>,
      ].concat(extraBreadcrumbItems);
      if (items.length > 1) {
        setBreadcrumbItems(items);
      } else {
        setBreadcrumbItems([]);
      }
    };
    onLocationChanged();
  }, [location, preferredLanguage]);

  return (
    <div className="breadcrumb-grid">
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    </div>
  );
};

export default HeaderBreadcrumb;
