import React, { useContext, useEffect, useState } from "react";
import { Card, Typography, Space, Tooltip, Statistic, Tag } from "antd";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/fr";
import "./device-card.css";
import {
  DatabaseTwoTone,
  CheckCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  SettingOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ResearchContext } from "../../contexts/ResearchProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import {
  APP_ROLES,
  DEVICE_DISPLAY_STATUS,
  GATEWAY_STATUS,
  getDeviceMode,
} from "../../utils/utils";

const { Text } = Typography;
const { Countdown } = Statistic;

const DeviceCard = (props) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [noseIdText, setNoseIdText] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [isDeviceAvailable, setIsDeviceAvailable] = useState(false);
  const isAdmin = user.role === APP_ROLES.ADMIN;

  const { preferredLanguage } = useContext(ResearchContext);

  useEffect(() => {
    setIsSelected(props.isSelectedDevice);
    setIsDeviceAvailable(getDeviceAvailability(props.device));
    if (props.device) {
      setDeviceDetails(props.device);
      if (props.device) {
        let str =
          props.device.noseId && props.device.noseId.length > 0
            ? props.device.noseId
            : t("none");
        setNoseIdText(str);
      }
    }
  }, [props]);

  const getDeviceIcon = (device) => {
    if (!device) {
      return null;
    }

    if (device.connected) {
      let deadline = moment.utc(device.availableFrom).local();
      switch (device.gatewayStatus) {
        case GATEWAY_STATUS.ERROR:
          return (
            <ExclamationCircleOutlined
              style={{ color: "var(--device-status-error)", fontSize: "64px" }}
            />
          );
        case GATEWAY_STATUS.OK:
          let buffering = moment.utc(device.availableFrom) > moment.utc();

          if (buffering) {
            return (
              <Space direction="vertical">
                <Countdown
                  valueStyle={{
                    fontSize: "38px",
                    color: "var(--device-status-buffer)",
                  }}
                  value={deadline}
                  onFinish={onTimeElapsed}
                />
              </Space>
            );
          }

          if (
            device.isCalibrationRequired ||
            device.isAmbientRequired ||
            !props.device.noseId
          ) {
            return (
              <ExclamationCircleOutlined
                style={{
                  color: "var(--device-status-error)",
                  fontSize: "64px",
                }}
              />
            );
          }

          if (device.isCalibrationAdvised) {
            return (
              <InfoCircleOutlined
                style={{
                  color: "var(--device-status-advised)",
                  fontSize: "64px",
                }}
              />
            );
          }

          return (
            <CheckCircleOutlined
              style={{ color: "var(--device-status-ready)", fontSize: "64px" }}
            />
          );
        case GATEWAY_STATUS.SENDING:
          return (
            <SyncOutlined
              style={{
                color: "var(--device-status-sending)",
                fontSize: "64px",
              }}
              spin
            />
          );
        case GATEWAY_STATUS.BUFFERING:
          return (
            <Space direction="vertical">
              <Countdown
                valueStyle={{
                  fontSize: "38px",
                  color: "var(--device-status-buffer)",
                }}
                value={deadline}
                onFinish={onTimeElapsed}
              />
            </Space>
          );
        default:
          break;
      }
    }
    return (
      <MinusCircleOutlined
        style={{ color: "var(--device-status-offline)", fontSize: "64px" }}
      />
    );
  };

  const getDeviceStatus = (device, displayMode) => {
    if (!device) {
      return null;
    }
    let statusText = "";
    let textType =
      displayMode === DEVICE_DISPLAY_STATUS.IN_CARD ? "secondary" : "";
    let textClassname =
      displayMode === DEVICE_DISPLAY_STATUS.IN_CARD
        ? "fontsize-10"
        : "device-status-color";

    if (device.connected) {
      switch (device.gatewayStatus) {
        case GATEWAY_STATUS.ERROR:
          textType =
            displayMode === DEVICE_DISPLAY_STATUS.IN_CARD ? "warning" : "";
          return (
            <Text type={textType} className={textClassname}>
              {t("nose-status-gateway-error")}
            </Text>
          );
        case GATEWAY_STATUS.OK:
          statusText = t("device-ready");
          let buffering = moment.utc(device.availableFrom) > moment.utc();

          if (buffering) {
            statusText = t("device-buffering");
          } else if (!device.noseId) {
            textType =
              displayMode === DEVICE_DISPLAY_STATUS.IN_CARD ? "warning" : "";
            statusText = t("error-enose");
          } else if (device.isCalibrationRequired) {
            textType =
              displayMode === DEVICE_DISPLAY_STATUS.IN_CARD ? "warning" : "";
            statusText = t("nose-status-calibration-required");
          } else if (device.isAmbientRequired) {
            textType =
              displayMode === DEVICE_DISPLAY_STATUS.IN_CARD ? "warning" : "";
            statusText = t("nose-status-ambient-required");
          } else if (device.isCalibrationAdvised) {
            statusText = t("nose-status-calibration-advised");
          }

          return (
            <Text type={textType} className={textClassname}>
              {statusText}
            </Text>
          );
        case GATEWAY_STATUS.SENDING:
          return (
            <Text type={textType} className={textClassname}>
              {t("device-sending")}
            </Text>
          );
        case GATEWAY_STATUS.BUFFERING:
          return (
            <Text type={textType} className={textClassname}>
              {t("device-buffering")}
            </Text>
          );
        default:
          return (
            <Text type={textType} className={textClassname}>
              {t("none")}
            </Text>
          );
      }
    } else {
      return (
        <Tooltip
          placement="top"
          title={
            t("device-details-last-active") +
            moment
              .utc(device.lastUpdated)
              .locale(preferredLanguage)
              .local()
              .format("YYYY-MM-DD HH:mm")
              .toString()
          }
        >
          <ClockCircleOutlined />
          <Text
            style={{ marginLeft: "3px" }}
            type={textType}
            className={textClassname}
          >
            {moment
              .utc(device.lastUpdated)
              .locale(preferredLanguage)
              .local()
              .format("YYYY-MM-DD")
              .toString()}
          </Text>
        </Tooltip>
      );
    }
  };

  const getDeviceEnvironment = (device) => {
    if (!device) {
      return null;
    }
    return (
      <Tooltip title={device.environment}>
        <Tag
          color={`var(--${device.environment?.toLowerCase()}-color)`}
          style={{ justifySelf: "start", alignSelf: "center" }}
        >
          {device.environment}
        </Tag>
      </Tooltip>
    );
  };

  const onTimeElapsed = () => {
    setDeviceDetails({
      ...deviceDetails,
      gatewayStatus: 0,
    });
    setIsDeviceAvailable(getDeviceAvailability(deviceDetails));
  };

  const onNoseClick = (e) => {
    //open drawer for noseId
    e.stopPropagation();
    props.openDrawerSpiroNose(noseIdText);
  };

  const onDeviceClick = async (e) => {
    //open drawer for deviceId
    e.stopPropagation();
    props.openDrawer(deviceDetails);
  };

  const getDeviceAvailability = (device) => {
    if (device.connected) {
      let buffering = moment.utc(device.availableFrom) > moment.utc();
      if (!buffering && device.gatewayStatus === 0 && device.noseId !== "")
        return true;
    }
    return false;
  };

  const deviceClass = `device-card-shadow ${
    isDeviceAvailable && props.onSelectDevice ? "device-available" : ""
  }  ${isSelected ? "device-selected" : ""}`;

  return (
    <Card
      size="small"
      bordered={false}
      onClick={() =>
        isDeviceAvailable && props.onSelectDevice
          ? props.onSelectDevice(deviceDetails)
          : null
      }
      className={deviceClass}
      title={
        <div className="title-grid">
          <Space>
            <DatabaseTwoTone twoToneColor="#108ee9" />
            <Text strong>{deviceDetails?.serialNumber?.toUpperCase()}</Text>
          </Space>
        </div>
      }
      extra={
        <Tooltip title={t("device-details-properties")} onClick={onDeviceClick}>
          <SettingOutlined />
        </Tooltip>
      }
    >
      <div className="device-card">
        <div style={{ gridRow: "1", gridColumn: "1", justifySelf: "start" }}>
          <Text
            onClick={onNoseClick}
            type="secondary"
            style={{ fontSize: "10px", gridRow: "1" }}
          >
            {noseIdText}
          </Text>
        </div>
        <div style={{ gridRow: "1", gridColumn: "2/3", justifySelf: "end" }}>
          {getDeviceStatus(deviceDetails, DEVICE_DISPLAY_STATUS.IN_CARD)}
        </div>
        <div
          style={{
            gridRow: "2",
            gridColumn: "1/4",
            alignSelf: "center",
            justifySelf: "center",
          }}
        >
          <Tooltip
            title={getDeviceStatus(
              deviceDetails,
              DEVICE_DISPLAY_STATUS.IN_TOOLTIP
            )}
          >
            {getDeviceIcon(deviceDetails)}
          </Tooltip>
        </div>
        <div style={{ gridRow: "3", gridColumn: "1" }}>
          {isAdmin && getDeviceEnvironment(deviceDetails)}
        </div>
        <div style={{ gridRow: "3", gridColumn: "2/3", justifySelf: "end" }}>
          <Text type="secondary" style={{ fontSize: "10px" }}>
            {getDeviceMode(deviceDetails?.deviceMode, t)}
          </Text>
        </div>
      </div>
    </Card>
  );
};

export default DeviceCard;
