import React, { createContext, useState } from "react";

export const PageSettingsContext = createContext();

const PageSettingsProvider = (props) => {
  const [measurementsFilterState, setMeasurementsFilterState] = useState({
    searchTerm: null,
    pageSize: 10,
    currentPage: 1,
    sortField: null,
    sortOrder: null,
    totalMeasurements: null,
    selectedMeasurementIndex: null
  });
  const [spironosesFilterState, setSpironosesFilterState] = useState({
    searchTerm: null,
    pageSize: 10,
    currentPage: 1,
  });
  const [usersFilterState, setUsersFilterState] = useState({
    searchTerm: null,
    dataLength: 10,
  });

  const resetFilters = () => {
    setMeasurementsFilterState({
      searchTerm: null,
      pageSize: 10,
      currentPage: 1,
      sortField: null,
      sortOrder: null,
      totalMeasurements: null,
      selectedMeasurementIndex: null
    });
    setSpironosesFilterState({
      searchTerm: null,
      pageSize: 10,
      currentPage: 1,
    });
    setUsersFilterState({
      searchTerm: null,
      dataLength: 10,
    });
  };

  return (
    <PageSettingsContext.Provider
      value={{
        measurementsFilterState,
        setMeasurementsFilterState,
        spironosesFilterState,
        setSpironosesFilterState,
        usersFilterState,
        setUsersFilterState,
        resetFilters,
      }}
    >
      {props.children}
    </PageSettingsContext.Provider>
  );
};

export default PageSettingsProvider;
