import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Spin, Grid } from "antd";
import { useTranslation } from "react-i18next";
import "./analyze-measurement-step.css";
import MeasurementDetailsBar from "../../measurement-details-bar/measurement-details-bar";
import SignalChart from "../../signal-chart/signal-chart";
import AnalysisResults from "../../analysis-results/analysis-results";
import {
  ANALYSIS_RESULT_FETCH_MODE,
  APP_ROLES,
  DEVICE_MODE,
  MEASUREMENT_PAGES,
  MEASUREMENT_TYPE,
  SIGNAL_CHART_MODE,
} from "../../../utils/utils";
import { NewMeasurementContext } from "../../../contexts/NewMeasurementContext";
import { AuthContext } from "../../../contexts/AuthProvider";
import moment from "moment";

const { useBreakpoint } = Grid;

const AnalyzeMeasurementStep = (props) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const { next, skipQuestionnaire, measurement, areAnalysisResultsReady } =
    useContext(NewMeasurementContext);
  const isClinician = user.role === APP_ROLES.CLINICIAN;
  const screens = useBreakpoint();
  const [signalGraphSpan, setSignalGraphSpan] = useState("100%");

  useEffect(() => {
    if (
      measurement.measurementType === MEASUREMENT_TYPE.REGULAR &&
      isClinician
    ) {
      if (screens?.xxl) {
        setSignalGraphSpan("70%");
        return;
      }
      if (screens?.xl) {
        setSignalGraphSpan("60%");
        return;
      }
      setSignalGraphSpan("50%");
      return;
    }
    setSignalGraphSpan("100%");
  }, [screens, isClinician, measurement]);

  return (
    <div className="container-analyze">
      <MeasurementDetailsBar
        currentPage={MEASUREMENT_PAGES.ANALYSIS_STEP}
        measurement={measurement}
      />
      <Spin tip="Analyzing data..." spinning={!areAnalysisResultsReady}>
        {measurement?.analyseResult && (
          <Row gutter={[24, 24]}>
            <Col flex={signalGraphSpan}>
              {measurement?.sensorData && (
                <SignalChart
                  chartId="analyzeSignalchart"
                  chartMode={SIGNAL_CHART_MODE.STATIC}
                  measurementId={measurement?.id}
                  sensorData={measurement?.sensorData}
                  measurementType={measurement?.measurementType}
                  deviceMode={
                    measurement?.ambientMeasurementId
                      ? DEVICE_MODE.CONTINUOUS
                      : DEVICE_MODE.NORMAL
                  }
                  startDate={moment.utc(measurement?.dateCreated)?.toDate()}
                />
              )}
            </Col>
            {measurement.measurementType === MEASUREMENT_TYPE.REGULAR &&
              isClinician && (
                <Col flex="auto">
                  <AnalysisResults
                    diagnosticModels={measurement?.diagnosticModels}
                    measurementId={measurement?.id}
                    analyseResult={measurement?.analyseResult}
                    areAnalysisResultsReady={areAnalysisResultsReady}
                    fetchMode={ANALYSIS_RESULT_FETCH_MODE.SIGNALR}
                  />
                </Col>
              )}
          </Row>
        )}
      </Spin>
      <Row justify="center" gutter={[24, 0]}>
        <Col>
          <Button onClick={() => skipQuestionnaire()}>
            {t("new-measurement-skip-survey")}
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={() => next()}>
            {t("new-measurement-goto-survey")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AnalyzeMeasurementStep;
