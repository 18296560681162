import {
  API_ENDPOINTS,
  getDownloadFullFilename,
  getDownloadFileName,
  DOWNLOAD_EXPORT_TYPES,
  DOWNLOAD_DATA_TYPES,
  ENTITY_TYPE,
  b64toBlob,
} from "../app/utils/utils";
import { createRequest } from "./helpers/RequestHelper";

export const getCountries = async (token) => {
  let result = [];
  try {
    //try retrieval from localstorage
    if (localStorage.getItem("countries")) {
      result = JSON.parse(localStorage.getItem("countries"));
    } else {
      let response = await fetch(API_ENDPOINTS.COUNTRIES, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response) {
        result = await response.json();
        //save to local storage
        localStorage.setItem("countries", JSON.stringify(result));
      }
    }
  } catch (error) {
    console.log(error);
  }
  return result;
};

export const getLocationByAddress = async (token, address) => {
  const response = {
    result: null,
  };
  try {
    let resp = await searchAddress(token, address);
    if (resp.results) {
      response.result = resp.results.find((p) => p.type === "Geography");
    }
  } catch (error) {
    console.log(error);
    response.error = error;
  }
  return response;
};

export const searchAddress = async (token, address) => {
  const response = {
    result: {
      data: null,
    },
  };
  try {
    let url = `${API_ENDPOINTS.MAP_SEARCH}/${address}`;
    console.info("%cGetting " + url, "background: green;");
    let mapsResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let jsonResponse = await mapsResponse.json();
    if (jsonResponse.results) {
      response.result.data = jsonResponse.results;
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
  }
  return response;
};

export const getUserProfileDetails = async (id, token) => {
  const response = {
    result: null,
  };
  let url = `${API_ENDPOINTS.USERS}/${id}`;
  console.info("%cGetting " + url, "background: green;");
  try {
    let devicesResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (devicesResponse.ok === true) {
      response.result = await devicesResponse.json();
    } else {
      response.error = {
        message: devicesResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const getUserProfilePhoto = async (id, token) => {
  const response = {
    result: null,
  };
  let url = `${API_ENDPOINTS.USER_PHOTO}/${id}`;
  console.info("%cGetting " + url, "background: green;");
  try {
    let photoResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (photoResponse.ok === true) {
      response.result = await photoResponse.blob();
    } else {
      response.error = {
        message: photoResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const updateUserProfilePhoto = async (id, token, file) => {
  const response = {
    result: null,
  };
  let url = `${API_ENDPOINTS.USER_PHOTO}/${id}`;
  console.info("%cPutting " + url, "background: green;");
  try {
    const formData = new FormData();
    formData.append("file", file);
    let updatePhotoResponse = await fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    });
    if (updatePhotoResponse.ok === true) {
      response.result = true;
    } else {
      response.error = {
        message: updatePhotoResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const getUsers = async (token, start, count, searchValue) => {
  console.info("%cGetting " + API_ENDPOINTS.USERS, "background: green;");
  let url = `${API_ENDPOINTS.USERS}?count=${count}&start=${start}`;

  if (searchValue) {
    url += `&searchValue=${searchValue}`;
  }

  return createRequest(token, url);
};

export const getOrganizations = async (token, page, count, searchValue) => {
  const response = {
    result: [],
  };
  console.info(
    "%cGetting " + API_ENDPOINTS.ORGANIZATIONS,
    "background: green;"
  );
  let url = `${API_ENDPOINTS.ORGANIZATIONS}?count=${count}&page=${page}`;
  if (searchValue) {
    url += `&searchValue=${searchValue}`;
  }
  try {
    let orgsResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (orgsResponse.ok === true) {
      let json = await orgsResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: orgsResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const getResearchAreas = async (token, page, count, searchValue) => {
  const response = {
    result: [],
  };
  console.info(
    "%cGetting " + API_ENDPOINTS.RESEARCH_AREAS,
    "background: green;"
  );
  let url = `${API_ENDPOINTS.RESEARCH_AREAS}?count=${count}&page=${page}`;
  if (searchValue) {
    url += `&searchValue=${searchValue}`;
  }
  try {
    let areasResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (areasResponse.ok === true) {
      let json = await areasResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: areasResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const getStudyTypes = async (token) => {
  const response = {
    result: [],
  };
  console.info("%cGetting " + API_ENDPOINTS.STUDY_TYPES, "background: green;");
  try {
    let studyTypesResponse = await fetch(API_ENDPOINTS.STUDY_TYPES, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (studyTypesResponse.ok === true) {
      let json = await studyTypesResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: studyTypesResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const getStudies = async (token, start, count, searchValue, userId) => {
  const response = {
    result: [],
  };
  console.info("%cGetting " + API_ENDPOINTS.STUDIES, "background: green;");
  let url = `${API_ENDPOINTS.STUDIES}?count=${count}&start=${start}`;
  if (searchValue) {
    url += `&searchValue=${searchValue}`;
  }
  if (userId) {
    url += `&userId=${userId}`;
  }
  try {
    let studiesResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (studiesResponse.ok === true) {
      let json = await studiesResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: studiesResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};


export const getMeasurements = async (
  token,
  start,
  count,
  searchValue,
  sortfield,
  sortorder
) => {
  const response = {
    result: [],
  };
  console.info("%cGetting " + API_ENDPOINTS.MEASUREMENTS, "background: green;");
  let url = `${API_ENDPOINTS.MEASUREMENTS}?count=${count}&start=${start}`;
  if (searchValue) {
    url += `&searchValue=${searchValue}`;
  }
  if (sortfield) {
    url += `&sortField=${sortfield}&sortOrder=${sortorder}`;
  }
  try {
    let measurementsResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (measurementsResponse.ok === true) {
      let json = await measurementsResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: measurementsResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const getDevices = async (token, start, count, searchValue) => {
  const response = {
    result: [],
  };
  console.info("%cGetting " + API_ENDPOINTS.DEVICES, "background: green;");
  let url = `${API_ENDPOINTS.DEVICES}?start=${start}&count=${count}`;
  if (searchValue) {
    url += `&searchValue=${searchValue}`;
  }
  try {
    let devicesResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (devicesResponse.ok === true) {
      let json = await devicesResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: devicesResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const getAssignedDevices = async (id, token) => {
  const response = {
    result: [],
  };
  console.info("%cGetting " + API_ENDPOINTS.USER_DEVICES, "background: green;");
  try {
    let url = `${API_ENDPOINTS.USER_DEVICES}/${id}`;
    let devicesResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (devicesResponse.ok === true) {
      let json = await devicesResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: devicesResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const assignUserDevices = async (devices, userId, token) => {
  const response = {
    result: [],
  };
  try {
    let url = `${API_ENDPOINTS.USER_DEVICES}/${userId}`;
    console.info("%cGetting " + url, "background: green;");
    let assignDevicesResponse = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(devices),
    });
    if (assignDevicesResponse.ok === true) {
      let json = await assignDevicesResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: assignDevicesResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const assignDeviceUsers = async (data, token) => {
  const response = {
    result: [],
  };
  try {
    let url = `${API_ENDPOINTS.DEVICE_ASSIGN_USERS}`;
    console.info("%cGetting " + url, "background: green;");
    let assignUsersResponse = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    if (assignUsersResponse.ok === true) {
      let json = await assignUsersResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: assignUsersResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const assignUserStudies = async (studies, userId, token) => {
  const response = {
    result: [],
  };
  try {
    let url = `${API_ENDPOINTS.USER_STUDIES}/${userId}`;
    console.info("%cPosting " + url, "background: green;");
    let assignStudiesResponse = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(studies),
    });
    if (assignStudiesResponse.ok === true) {
      let json = await assignStudiesResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: assignStudiesResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const getUsersDashboard = async (token) => {
  const response = {
    result: [],
  };
  console.info(
    "%cGetting " + API_ENDPOINTS.USER_DASHBOARD,
    "background: green;"
  );
  try {
    let dashboardResponse = await fetch(API_ENDPOINTS.USER_DASHBOARD, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (dashboardResponse.ok === true) {
      let json = await dashboardResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: dashboardResponse.title,
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const getMeasurementDashboard = async (token) => {
  const response = {
    result: null,
  };
  console.info(
    "%cGetting " + API_ENDPOINTS.MEASUREMENT_DASHBOARD,
    "background: green;"
  );
  try {
    let dashboardResponse = await fetch(API_ENDPOINTS.MEASUREMENT_DASHBOARD, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (dashboardResponse.ok === true) {
      let json = await dashboardResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: dashboardResponse.title,
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const getDeviceDashboard = async (token) => {
  const response = {
    result: null,
  };
  console.info(
    "%cGetting " + API_ENDPOINTS.DEVICE_DASHBOARD,
    "background: green;"
  );
  try {
    let dashboardResponse = await fetch(API_ENDPOINTS.DEVICE_DASHBOARD, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (dashboardResponse.ok === true) {
      let json = await dashboardResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: dashboardResponse.title,
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const deleteUserAccount = async (id, token) => {
  const response = {
    result: false,
    error: null,
  };
  try {
    let url = `${API_ENDPOINTS.USER_DELETE}/${id}`;
    console.info("%cDeleting " + url, "background: green;");
    let deleteUserResponse = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let json = await deleteUserResponse.json();
    if (deleteUserResponse.ok === true) {
      response.result = json;
    } else {
      response.error = json.errors;
    }
  } catch (error) {
    response.error = [error];
    console.error("%c" + error, "background: red;");
  }
  return response;
};

export const disableUserAccount = async (data, token) => {
  const response = {
    result: false,
    error: null,
  };
  try {
    console.info(
      "%cUpdating " + API_ENDPOINTS.USER_UPDATE_STATUS,
      "background: green;"
    );
    let updateResponse = await fetch(API_ENDPOINTS.USER_UPDATE_STATUS, {
      method: "PUT",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let json = await updateResponse.json();
    if (updateResponse.ok === true) {
      response.result = json;
    } else {
      response.error = json.errors;
    }
  } catch (error) {
    response.error = [error];
    console.error("%c" + error, "background: red;");
  }
  return response;
};

export const updateUserProfile = async (data, token) => {
  const response = {
    result: false,
    error: null,
  };
  try {
    console.info(
      "%cUpdating " + API_ENDPOINTS.USER_UPDATE,
      "background: green;"
    );
    let updateResponse = await fetch(API_ENDPOINTS.USER_UPDATE, {
      method: "PUT",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let json = await updateResponse.json();
    if (updateResponse.ok === true) {
      response.result = json;
    } else {
      response.error = json.errors;
    }
  } catch (error) {
    response.error = [error];
    console.error("%c" + error, "background: red;");
  }
  return response;
};

export const updateDeviceDetails = async (token, content) => {
  const response = {
    result: false,
    error: null,
  };
  try {
    let url = `${API_ENDPOINTS.DEVICE_UPDATE}/${content.deviceId}`;
    console.info("%cUpdating " + url, "background: green;");
    let updateResponse = await fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(content),
    });
    let json = await updateResponse.json();
    if (updateResponse.ok === true) {
      response.result = json;
    } else {
      response.error = json.error;
    }
  } catch (error) {
    response.error = [error];
    console.error("%c" + error, "background: red;");
  }
  return response;
};

export const updateNoseDetails = async (token, content) => {
  const response = {
    result: false,
    error: null,
  };
  try {
    let url = `${API_ENDPOINTS.NOSE_CALIBRATION_UPDATE}/${content.noseId}`;
    console.info("%cUpdating " + url, "background: green;");
    let updateResponse = await fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(content),
    });
    let json = await updateResponse.json();
    if (updateResponse.ok === true) {
      response.result = json;
    } else {
      response.error = json.errors;
    }
  } catch (error) {
    response.error = [error];
    console.error("%c" + error, "background: red;");
  }
  return response;
};

export const createUser = async (data, token) => {
  console.info("%cCreating " + API_ENDPOINTS.USER_CREATE, "background: green;");
  const response = {
    result: false,
    error: null,
  };
  try {
    let createUserResponse = await fetch(API_ENDPOINTS.USER_CREATE, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let json = await createUserResponse.json();
    if (createUserResponse.ok === true) {
      response.result = json;
    } else {
      response.error = json.errors ? json.errors : json.error;
    }
  } catch (error) {
    response.error = [error];
    console.error("%c" + error, "background: red;");
  }
  return response;
};

export const createStudy = async (data, token) => {
  console.info(
    "%cCreating " + API_ENDPOINTS.STUDY_CREATE,
    "background: green;"
  );
  const response = {
    result: false,
    error: null,
  };
  try {
    let createStudyResponse = await fetch(API_ENDPOINTS.STUDY_CREATE, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let json = await createStudyResponse.json();
    if (createStudyResponse.ok === true) {
      response.result = json;
    } else {
      response.error = json.errors ? json.errors : json.error;
    }
  } catch (error) {
    response.error = [error];
    console.error("%c" + error, "background: red;");
  }
  return response;
};

export const reassignMeasurementStudy = async (token, data) => {
  const response = {
    success: false,
    error: null,
  };
  try {
    let fetchResponse = await fetch(API_ENDPOINTS.MEASUREMENT_CHANGE_STUDY, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },

      body: JSON.stringify(data),
    });
    let json = await fetchResponse.json();
    if (fetchResponse.ok === true) {
      response.success = true;
    } else {
      response.error = json.errors ? json.errors : json.error;
    }
  } catch (error) {
    response.error = [error];
    console.error("%c" + error, "background: red;");
  }
  return response;
}


export const updateStudyDetails = async (data, token) => {
  const response = {
    result: false,
    error: null,
  };
  try {
    let url = `${API_ENDPOINTS.STUDY_UPDATE}/${data.id}`;
    console.info("%cUpdating " + url, "background: green;");
    let updateResponse = await fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let json = await updateResponse.json();
    if (updateResponse.ok === true) {
      response.result = json;
    } else {
      response.error = json.errors;
    }
  } catch (error) {
    response.error = [error];
    console.error("%c" + error, "background: red;");
  }
  return response;
};

export const deleteStudy = async (id, token) => {
  const response = {
    result: false,
    error: null,
  };
  try {
    let url = `${API_ENDPOINTS.STUDY_DELETE}/${id}`;
    console.info("%cDeleting " + url, "background: green;");
    let deleteStudyResponse = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let json = await deleteStudyResponse.json();
    if (deleteStudyResponse.ok === true) {
      response.result = json;
    } else {
      response.error = json.errors;
    }
  } catch (error) {
    response.error = [error];
    console.error("%c" + error, "background: red;");
  }
  return response;
};

export const icdSearch = async (token, term, lang) => {
  try {
    let url = `${API_ENDPOINTS.ICD_SEARCH}/${lang}/${term}`;
    console.info("%cGetting " + url, "background: green;");
    let authResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let result = await authResponse.json();
    if (authResponse.ok === true) {
      return result.destinationEntities;
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
  }
  return null;
};

export const getGeneralSurveyTemplate = async (token) => {
  try {
    console.info("%cGetting " + API_ENDPOINTS.SURVEY, "background: green;");
    let authResponse = await fetch(API_ENDPOINTS.SURVEY, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let result = await authResponse.json();
    if (authResponse.ok === true) {
      return result;
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
  }
  return null;
};

export const downloadMeasurementFiles = async (data, token) => {
  console.info(
    "%cGetting " + API_ENDPOINTS.MEASUREMENT_DOWNLOAD,
    "background: green;"
  );
  return fetch(API_ENDPOINTS.MEASUREMENT_DOWNLOAD, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const downloadExport = async (jobId, token) => {
  const response = {
    result: null,
  };
  try {
    console.info(
      "%cGetting " + API_ENDPOINTS.EXPORT_JOB_CREATE,
      "background: green;"
    );
    let exportResponse = await fetch(
      `${API_ENDPOINTS.EXPORT_JOB_CREATE}/${jobId}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (exportResponse.ok === true) {
      response.result = await exportResponse.text();
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
  }
  return response;
};

export const exportAllMeasurements = async (data, token) => {
  console.info(
    "%cPosting " + API_ENDPOINTS.EXPORT_JOB_CREATE,
    "background: green;"
  );
  const response = {
    result: false,
    error: null,
  };
  try {
    let url = `${API_ENDPOINTS.EXPORT_JOB_CREATE}`;
    let res = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    response.result = await res.json();
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const downloadMeasurements = async (data, token) => {
  console.info(
    "%cGetting " + API_ENDPOINTS.MEASUREMENT_DOWNLOAD,
    "background: green;"
  );
  const response = {
    result: false,
    error: null,
  };
  try {
    let measurementFileResponse = await downloadMeasurementFiles(data, token);

    if (measurementFileResponse.ok === true) {
      response.result = await measurementFileResponse.blob();
      let url = window.URL.createObjectURL(response.result);
      let a = document.createElement("a");
      a.href = url;
      if (data.measurements.length === 1) {
        a.download = getDownloadFullFilename(
          data.downloadType,
          data.exportType,
          data.measurements[0]
        );
      } else {
        a.download = `${getDownloadFileName(data.downloadType)}.zip`;
      }
      a.click();
    } else {
      response.error = {
        message: measurementFileResponse.err, //TODO
      };
    }
  } catch (error) {
    response.error = [error];
    console.error("%c" + error, "background: red;");
  }
  return response;
};

export const downloadSurveys = async (data, token) => {
  const response = {
    result: null,
  };
  console.info(
    "%cGetting " + API_ENDPOINTS.SURVEYS_DOWNLOAD,
    "background: green;"
  );
  let surveysResponse = await fetch(API_ENDPOINTS.SURVEYS_DOWNLOAD, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  if (surveysResponse.ok === true) {
    response.result = await surveysResponse.blob();
    let url = window.URL.createObjectURL(response.result);
    let a = document.createElement("a");
    a.href = url;
    a.download =
      data.exportType === 0
        ? (a.download = `SurveyData_${data.measurements[0]}.csv`)
        : (a.download = `SurveyData_${data.measurements[0]}.xlsx`);
    a.click();
  } else {
    let errorResponse = await surveysResponse.json();
    throw errorResponse.error;
  }
  return response;
};

export const getCalibrations = async (token, noseId) => {
  const response = {
    result: null,
  };
  console.info(
    "%cGetting " + API_ENDPOINTS.CALIBRATIONS_GET,
    "background: green;"
  );
  try {
    let url = `${API_ENDPOINTS.CALIBRATIONS_GET}?noseId=${noseId}`;
    let calibrations = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    response.result = await calibrations.json();
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const updateCalibration = async (token, data) => {
  const response = {
    result: null,
  };
  console.info(
    "%cGetting " + API_ENDPOINTS.CALIBRATIONS_UPDATE,
    "background: green;"
  );
  try {
    let url = `${API_ENDPOINTS.CALIBRATIONS_UPDATE}`;
    let res = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    response.result = await res.json();
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const getSpiroNoses = async (
  token,
  start,
  count,
  searchValue,
  sortfield,
  sortorder
) => {
  const response = {
    result: [],
  };
  console.info("%cGetting " + API_ENDPOINTS.NOSES, "background: green;");
  let url = `${API_ENDPOINTS.NOSES}?count=${count}&start=${start}`;
  if (searchValue) {
    url += `&searchValue=${searchValue}`;
  }
  if (sortfield) {
    url += `&sortField=${sortfield}&sortOrder=${sortorder}`;
  }
  try {
    let spironosesResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (spironosesResponse.ok === true) {
      let json = await spironosesResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: spironosesResponse.err,
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const getSurveyTemplates = async (token, start, count, searchValue) => {
  const response = {
    result: [],
  };
  console.info(
    "%cGetting " + API_ENDPOINTS.SURVEYS_TEMPLATES,
    "background: green;"
  );
  let url = `${API_ENDPOINTS.SURVEYS_TEMPLATES}?start=${start}&count=${count}`;
  if (searchValue) {
    url += `&searchValue=${searchValue}`;
  }
  try {
    let templatesResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (templatesResponse.ok === true) {
      let json = await templatesResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: templatesResponse.err,
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }

  return response;
};

export const getSurveyTemplate = async (token, templateId) => {
  const response = {
    result: [],
  };
  console.info(
    "%cGetting " + API_ENDPOINTS.SURVEY_TEMPLATE,
    "background: green;"
  );
  let url = `${API_ENDPOINTS.SURVEY_TEMPLATE}/${templateId}`;

  try {
    let templateResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (templateResponse.ok === true) {
      let json = await templateResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: templateResponse.err,
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }

  return response;
};

export const getSurveyTemplateByStudy = async (token, studyId) => {
  const response = {
    result: [],
  };
  console.info(
    "%cGetting " + `${API_ENDPOINTS.SURVEY_TEMPLATE}/study`,
    "background: green;"
  );
  let url = `${API_ENDPOINTS.SURVEY_TEMPLATE}/study/${studyId}`;

  try {
    let templateResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (templateResponse.ok === true) {
      let json = await templateResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: templateResponse.err,
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }

  return response;
};

export const saveSurveyData = async (data, token) => {
  const response = {
    result: false,
    error: null,
  };

  let url = `${API_ENDPOINTS.SURVEY_SAVE}`;
  try {
    let saveSurveyResponse = await fetch(url, {
      method: "post",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    let json = await saveSurveyResponse.json();
    if (saveSurveyResponse.ok === true) {
      response.result = json;
    } else {
      response.error = saveSurveyResponse.err;
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }

  return response;
};

export const getSurvey = async (data, token) => {
  const response = {
    result: [],
  };
  console.info("%cGetting " + API_ENDPOINTS.SURVEY_GET, "background: green;");
  let url = `${API_ENDPOINTS.SURVEY_GET}?measurementId=${data.measurementId}&deviceId=${data.deviceId}&version=${data.version}`;

  try {
    let surveyResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (surveyResponse.ok === true) {
      let json = await surveyResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: surveyResponse.err,
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }

  return response;
};

export const upsertSurveyTemplate = async (data, token) => {
  const response = {
    result: false,
    error: null,
  };
  let url = `${API_ENDPOINTS.SURVEYS_TEMPLATES_UPDATE}`;
  try {
    let surveyTemplates = await fetch(url, {
      method: "post",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    let json = await surveyTemplates.json();
    if (surveyTemplates.ok === true) {
      response.result = json;
    } else {
      response.error = {
        message: surveyTemplates.err,
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const getSensorData = async (sessionId, token) => {
  const response = {
    result: null,
    error: null,
  };
  try {
    let url = `${API_ENDPOINTS.MEASUREMENT_SENSOR_DATA}/${sessionId}`;
    let sensorDataResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    response.result = await sensorDataResponse.json();
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const startMeasurement = async (data, token) => {
  let url = API_ENDPOINTS.MEASUREMENT_START;
  let response = await fetch(url, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  let result = await response.json();
  if (response.ok === true) {
    return result;
  } else {
    throw result;
  }
};

export const stopMeasurement = async (mId, save = true, token) => {
  let url = API_ENDPOINTS.MEASUREMENT_STOP;
  let body = {
    measurementId: mId,
    saveOnStop: save,
  };
  let response = await fetch(url, {
    method: "put",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    throw await response.json();
  }
};

export const getSpiroNose = async (token, noseId) => {
  const response = {
    result: null,
  };
  console.info(
    "%cGetting " + API_ENDPOINTS.DEVICE_SPIRONOSES,
    "background: green;"
  );
  try {
    let url = `${API_ENDPOINTS.DEVICE_SPIRONOSES}/${noseId}`;
    let spironoseResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    response.result = await spironoseResponse.json();
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const downloadSensorData = async (measurementId, token) => {
  let data = {
    measurements: [parseInt(measurementId)],
    downloadType: DOWNLOAD_DATA_TYPES.RAW,
    exportType: DOWNLOAD_EXPORT_TYPES.JSON,
  };
  var response = await downloadMeasurementFiles(data, token);

  let result = await response.json();
  if (response.ok === true) {
    return result;
  } else {
    throw result;
  }
};

export const getMeasurementById = async (id, token) => {
  let url = API_ENDPOINTS.MEASUREMENTS;
  let response = await fetch(`${url}/${id}`, {
    method: "get",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  let result = await response.json();
  if (response.ok === true) {
    return result;
  } else {
    throw result;
  }
};

export const softDeleteEntity = async (data, token) => {
  let url;
  switch (data.entityType) {
    case ENTITY_TYPE.MEASUREMENT:
      url = `${API_ENDPOINTS.MEASUREMENT_DELETE}/${data.entityId}`;
      break;
    case ENTITY_TYPE.BREATHBASE_USER:
      url = `${API_ENDPOINTS.USER_DELETE}/${data.entityId}`;
      break;
    case ENTITY_TYPE.STUDY:
      url = `${API_ENDPOINTS.STUDY_DELETE}/${data.entityId}`;
      break;
    case ENTITY_TYPE.SURVEY_TEMPLATE:
      url = `${API_ENDPOINTS.SURVEY_TEMPLATE_DELETE}/${data.entityId}`;
      break;
  }
  let deleteResponse = await fetch(url, {
    method: "DELETE",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });

  return await deleteResponse.json();
};

export const setMeasurementSurveyStatus = async (data, token) => {
  let url = API_ENDPOINTS.MEASUREMENT_SURVEY_STATUS;
  let response = await fetch(url, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  let result = await response.json();
  if (response.ok === true) {
    return result;
  } else {
    throw result;
  }
};

export const refreshDeviceStatus = async (token, deviceId) => {
  const response = {
    result: [],
  };
  console.info(
    "%cGetting " + API_ENDPOINTS.REFRESH_DEVICE_DETAILS,
    "background: green;"
  );
  let url = `${API_ENDPOINTS.REFRESH_DEVICE_DETAILS}/${deviceId}`;
  try {
    let statusResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (statusResponse.ok === true) {
      let json = await statusResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: statusResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const getDeviceById = async (id, token) => {
  let url = API_ENDPOINTS.DEVICE_DETAILS;
  let response = await fetch(`${url}/${id}`, {
    method: "get",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  let result = await response.json();
  if (response.ok === true) {
    return result;
  } else {
    throw result;
  }
};
export const getAnalysisResult = async (mId, model, token) => {
  let data = {
    measurements: [parseInt(mId)],
    downloadType: DOWNLOAD_DATA_TYPES.RESULTS,
    exportType: DOWNLOAD_EXPORT_TYPES.JSON,
    diagnosticModel: model,
  };
  var response = await downloadMeasurementFiles(data, token);

  let result = await response.json();
  if (response.ok === true) {
    return result;
  } else {
    throw result;
  }
};

export const getUsersByDevice = async (deviceId, token) => {
  const response = {
    result: [],
  };
  console.info("%cGetting " + API_ENDPOINTS.USER_DEVICES, "background: green;");
  try {
    let url = `${API_ENDPOINTS.DEVICE_USERS}/${deviceId}`;
    let usersResponse = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (usersResponse.ok === true) {
      let json = await usersResponse.json();
      response.result = json;
    } else {
      response.error = {
        message: usersResponse.err, //TODO
      };
    }
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};

export const downloadImportUsersTemplate = async (token) => {
  console.info(
    "%cGetting " + API_ENDPOINTS.IMPORT_USERS_TEMPLATE,
    "background: green;"
  );
  let response = await fetch(API_ENDPOINTS.IMPORT_USERS_TEMPLATE, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  if (response.ok === true) {
    var fileResult = await response.json();
    let blob = b64toBlob(fileResult.data, fileResult.fileType);
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = fileResult.filename;
    a.click();
  } else {
    let errorResponse = await response.json();
    throw errorResponse.error;
  }
};

export const bulkImportUsers = async (file, language, token) => {
  console.info(
    "%cPosting " + API_ENDPOINTS.BULK_IMPORT_USERS,
    "background: green;"
  );
  const formData = new FormData();
  formData.append("file", file);
  formData.append("language", language);

  let response = await fetch(API_ENDPOINTS.BULK_IMPORT_USERS, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
    },
    body: formData,
  });

  if (response.ok === true) {
    return response.json();
  } else {
    let errorResponse = await response.json();
    throw errorResponse.error;
  }
};
export const postSaveMeasurement = async (mId, token) => {
  let url = API_ENDPOINTS.MEASUREMENT_POST_SAVE;
  let body = {
    measurementId: mId,
  };
  let response = await fetch(url, {
    method: "put",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    throw await response.json();
  }
};

export const downloadInformationPackage = async (language, token) => {
  var url = new URL(API_ENDPOINTS.EXPORT_INFO_PACKAGE);
  var params = { language: language };
  url.search = new URLSearchParams(params).toString();

  console.info("%cGetting " + url, "background: green;");
  let response = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  await downloadFile(response);
};

export const downloadManual = async (token) => {
  var url = new URL(API_ENDPOINTS.EXPORT_BREATHBASE_MANUAL);
  var params = { path: process.env.REACT_APP_BREATHBASE_MANUAL_PATH };
  url.search = new URLSearchParams(params).toString();

  console.info("%cGetting " + url, "background: green;");
  let response = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  await downloadFile(response);
};

export const getCalibrationMeasurements = async (token, noseId) => {
  const response = {
    result: null,
  };
  console.info(
    "%cGetting " + API_ENDPOINTS.MEASUREMENTS_CALIBRATIONS,
    "background: green;"
  );
  try {
    let url = `${API_ENDPOINTS.MEASUREMENTS_CALIBRATIONS}/${noseId}`;
    let calibrationMeasurements = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    response.result = await calibrationMeasurements.json();
  } catch (error) {
    console.error("%c" + error, "background: red;");
    response.error = error;
  }
  return response;
};
async function downloadFile(response) {
  if (response.ok === true) {
    var fileResult = await response.json();
    let blob = b64toBlob(fileResult.data, fileResult.fileType);
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = fileResult.filename;
    a.click();
  } else {
    let errorResponse = await response.json();
    throw errorResponse.error;
  }
}

