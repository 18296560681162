import {
  FileSearchOutlined,
  WarningOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { Card, Col, Row, Space, Statistic, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { ANALYSIS_STATUS, DIAGNOSTIC_RESULT_STATUS } from "../../utils/utils";
import { useTranslation } from "react-i18next";
const { Title, Text } = Typography;

const AnalysisItem = (props) => {
  const { t } = useTranslation();
  const [resultData, setResultData] = useState({});
  const [isResultOutOfRange, setIsResultOutOfRange] = useState(false);
  const [diagnosticRangeText, setDiagnosticRangeText] = useState("-");

  useEffect(() => {
    if (props) {
      try {
        props.diagnostic.result = JSON.parse(props.diagnostic.result);
        setResultData(props.diagnostic);
        setIsResultOutOfRange(
          props.diagnostic.result.status ===
            DIAGNOSTIC_RESULT_STATUS.INDETERMINATE
        );
      } catch {
        console.error("Error parsing result");
      }
      let text = t("diagnostic-result-range", {
        lowerLimit: props.diagnostic.result?.lowerLimit,
        upperLimit: props.diagnostic.result?.upperLimit,
      });
      setDiagnosticRangeText(text);
    }
  }, [props.diagnostic]);

  const getAnalysisResultText = () => {
    switch (resultData.status) {
      case ANALYSIS_STATUS.ERROR:
        return t("diagnostic-result-error");
      case ANALYSIS_STATUS.UNCERTAIN:
        return t("diagnostic-result-uncertain");
      case ANALYSIS_STATUS.SUCCESS:
        return t("diagnostic-result-success");
      default:
        return t("diagnostic-result-error");
    }
  };

  const getAnalysisResultStatus = () => {
    switch (resultData.status) {
      case ANALYSIS_STATUS.ERROR:
        return "danger";
      case ANALYSIS_STATUS.UNCERTAIN:
        return "warning";
      default:
        return "";
    }
  };

  return (
    <Card
      title={
        <Space direction="vertical" size={0}>
          <Title level={4} strong>
            {t(resultData.moduleName)}
          </Title>
          <Text type="secondary">{`${t("diagnostic-result-version")}: ${
            resultData.moduleVersion
          }`}</Text>
        </Space>
      }
      className="responsive-card-shadow"
      style={{ width: "100%", margin: "0 auto" }}
    >
      <Row gutter={16}>
        <Col span={12}>
          {resultData.status !== ANALYSIS_STATUS.SUCCESS ? (
            <Space>
              <FileSearchOutlined style={{ fontSize: "2em" }} />
              <MinusOutlined />
            </Space>
          ) : (
            <>
              <Statistic
                value={resultData.result?.value}
                precision={5}
                valueStyle={{
                  color: isResultOutOfRange
                    ? "var(--analysis-result-uncertain)"
                    : "var(--analysis-result-success)",
                }}
                prefix={<FileSearchOutlined />}
                suffix={
                  isResultOutOfRange ? (
                    <Tooltip
                      title={t(
                        "diagnostic-result-analysis-result-out-of-range"
                      )}
                    >
                      <WarningOutlined />
                    </Tooltip>
                  ) : null
                }
              />
              <Text type="secondary">{diagnosticRangeText}</Text>
            </>
          )}
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            <Title level={4} strong type={getAnalysisResultStatus()}>
              {getAnalysisResultText()}
            </Title>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

AnalysisItem.propsTypes = {
  diagnostic: PropTypes.object.isRequired,
};

export default AnalysisItem;
