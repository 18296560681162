import React, { useEffect, useContext, useState } from "react";
import { Card, Typography, Tooltip, Space } from "antd";
import "./study-card.css";
import { useTranslation } from "react-i18next";
import {
  MailTwoTone,
  TeamOutlined,
  MedicineBoxOutlined,
  GlobalOutlined,
  SolutionOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types"; // ES6
import SimpleStatistic from "../simple-statistic/simple-statistic";
import { AuthContext } from "../../contexts/AuthProvider";
import {
  STUDY_ROLES,
  getStudyStatusString,
  getStudyStatusColor,
} from "../../utils/utils";
const { Text } = Typography;

const StudyCard = (props) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [studyDetails, setStudyDetails] = useState(null);
  const [principalInvestigators, setPrincipalInvestigators] = useState([]);
  const [teamEmailList, setTeamEmailList] = useState([]);
  const [researchAreas, setResearchAreas] = useState([]);

  useEffect(() => {
    if (props && props.study) {
      setStudyDetails(props.study);
      setPrincipalInvestigators(
        props.study.team
          .filter((m) => m.role === STUDY_ROLES.PRINCIPAL_INVESTIGATOR)
          .map((m) => m.name)
      );
      setTeamEmailList(
        props.study.team
          .filter((m) => m.email !== user.mail)
          .map((m) => m.email)
      );
      setResearchAreas(props.study.researchAreas.map((ra) => ra.split(":")[1]));
    }
  }, [props]);

  const onStudyClick = () => {
    //open drawer for study
    props.openDrawer(studyDetails);
  };

  return (
    <Card
      size="small"
      className="study-card-shadow"
      bordered={false}
      title={
        <Space>
          <Tooltip title={getStudyStatusString(props.study.status, t)}>
            <SolutionOutlined
              style={{ color: getStudyStatusColor(props.study.status) }}
            />
          </Tooltip>
          <Tooltip title={props.study.name}>
            <Text strong ellipsis style={{ maxWidth: "145px" }}>
              {props.study.name}
            </Text>
          </Tooltip>
        </Space>
      }
      extra={
        <Tooltip title={t("study-details-properties")} onClick={onStudyClick}>
          <SettingOutlined />
        </Tooltip>
      }
    >
      <div className="study-content">
        <Space align="center" direction="horizontal">
          <Tooltip title={principalInvestigators.join(", ")}>
            <Text
              ellipsis
              type="secondary"
              style={{ maxWidth: "120px", fontWeight: "normal" }}
            >
              {principalInvestigators[0]}
            </Text>
          </Tooltip>
          <Text type="secondary">
            {principalInvestigators.length > 1
              ? ` +${principalInvestigators.length - 1}`
              : ""}
          </Text>
        </Space>
        <div className="study-content-stats">
          <SimpleStatistic
            icon={<GlobalOutlined style={{ fontSize: "24px" }} />}
            title={t("study-centers")}
            statisticValue={props.study.organizationCount}
            statisticColor="var(--org-color)"
          />
          <SimpleStatistic
            icon={<TeamOutlined style={{ fontSize: "24px" }} />}
            title={t("study-members")}
            statisticValue={props.study.team ? props.study.team.length : 0}
            statisticColor="var(--member-color)"
          />
          <SimpleStatistic
            icon={<MedicineBoxOutlined style={{ fontSize: "24px" }} />}
            title={t("measurements")}
            statisticValue={props.study.measurementCount}
            statisticColor="var(--measurement-color)"
          />
        </div>
        <div
          style={{
            display: "grid",
            width: "100%",
            gridTemplateColumns: "18px 1fr auto",
            gridRow: "3",
          }}
        >
          <Tooltip title={t("study-send-team-mail")}>
            <a href={`mailto:${teamEmailList}`}>
              <MailTwoTone style={{ color: "#1890ff" }} />
            </a>
          </Tooltip>
          <Tooltip title={researchAreas.join(", ")}>
            <Text type="secondary" ellipsis>
              {researchAreas[0]}
            </Text>
          </Tooltip>
          <Text type="secondary">
            {researchAreas.length > 1 ? ` +${researchAreas.length - 1}` : ""}
          </Text>
        </div>
      </div>
    </Card>
  );
};

StudyCard.propsTypes = {
  study: PropTypes.object.isRequired,
};

export default StudyCard;
