import React, { useState } from "react";
import { Slider } from "antd";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";

const ExperienceRating = ({ value, min, max, readonly, onChange }) => {
  const [rating, setRating] = useState(1);
  const [preColor, setPreColor] = useState("grey");
  const [nextColor, setNextColor] = useState("grey");

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const onRatingChange = (e) => {
    setRating(e);
    triggerChange(e);
    const mid = ((max - min) / 2).toFixed(5);
    setPreColor(e >= mid ? "grey" : "var(--rating-min-color)");
    setNextColor(e >= mid ? "var(--rating-max-color)" : "grey");
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        justifyContent: "center",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <FrownOutlined style={{ color: preColor, fontSize: "18px" }} />
      <Slider
        value={value || rating}
        onChange={onRatingChange}
        disabled={readonly}
        min={min}
        max={max}
      />
      <SmileOutlined style={{ color: nextColor, fontSize: "18px" }} />
    </div>
  );
};

export default ExperienceRating;
