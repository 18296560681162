import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SimpleMessage from "../simple-components/simple-message/simple-message";
import {
  API_REQUESTS,
  APP_ROLES,
  CALIBRATION_DURATION_TYPE,
  getDeviceCalibrationDurationType,
} from "../../utils/utils";
import { QuestionCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Drawer,
  Skeleton,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Space,
  Tooltip,
  Descriptions,
  Switch,
} from "antd";
import { getCalibrationStatus } from "../../pages/spironoses-page/spironoses-columns";
import { getSpiroNose, updateNoseDetails } from "../../../services/ApiService";
import moment from "moment";

const { confirm } = Modal;
const { Option } = Select;

const NoseDetailsDrawer = (props) => {
  const { getAccessToken, user } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [spiroNose, setSpiroNose] = useState({});
  const [maxAdvisedCalibration, setMaxAdvisedCalibration] = useState(1);
  const [maxRequiredCalibration, setMaxRequiredCalibration] = useState(1);
  const isAdmin = user.role === APP_ROLES.ADMIN;

  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setVisible(props.visible);
      if (props.visible && props.noseId) {
        setLoading(true);
        await fetchSpiroNose(props.noseId);
        setLoading(false);
      }
    };
    if (props) {
      fetchData();
    }
  }, [props]);

  const fetchSpiroNose = async (noseId) => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await getSpiroNose(token, noseId);
        if (res && res.result && !res.error) {
          setSpiroNose(res.result);
          setMaxAdvisedCalibration(
            getMaxCalibration(res.result.calibrationAdvisedPeriodType)
          );
          setMaxRequiredCalibration(
            getMaxCalibration(res.result.calibrationRequiredPeriodType)
          );
          form.setFieldsValue({
            ...res.result,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onFinish = async (values) => {
    if (validateCalibrationPeriods(values)) {
      setLoading(true);
      try {
        const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
        if (token) {
          let res = await updateNoseDetails(token, values);
          if (res && res.result) {
            clearAndClose();
            SimpleMessage("success", t("nose-calibration-update-success"));
          } else {
            SimpleMessage("error", t("nose-calibration-update-error"));
          }
        }
      } catch (err) {
        SimpleMessage("error", err.message);
      }
      setLoading(false);
    }
  };

  const getMaxCalibration = (target) => {
    switch (target) {
      case CALIBRATION_DURATION_TYPE.HOURS:
        return 8760;
      case CALIBRATION_DURATION_TYPE.DAYS:
        return 365;
      case CALIBRATION_DURATION_TYPE.WEEKS:
        return 52;
      case CALIBRATION_DURATION_TYPE.MONTHS:
        return 12;
      default:
        return 1;
    }
  };

  const validateCalibrationPeriods = (spiroNose) => {
    var requiredPeriodType = Object.keys(CALIBRATION_DURATION_TYPE).find(
      (key) =>
        CALIBRATION_DURATION_TYPE[key] ===
        spiroNose.calibrationRequiredPeriodType
    );
    var requiredPeriod = moment.duration(
      spiroNose.calibrationRequiredPeriod,
      requiredPeriodType
    );

    var advisedPeriodType = Object.keys(CALIBRATION_DURATION_TYPE).find(
      (key) =>
        CALIBRATION_DURATION_TYPE[key] ===
        spiroNose.calibrationAdvisedPeriodType
    );
    var advisedPeriod = moment.duration(
      spiroNose.calibrationAdvisedPeriod,
      advisedPeriodType
    );
    if (requiredPeriod < advisedPeriod) {
      SimpleMessage(
        "error",
        t("validation:nose-details-validation-period-invalid")
      );
      return false;
    }
    return true;
  };

  const closeDrawer = () => {
    if (dirty) {
      //show confirm
      confirm({
        cancelText: t("profile-assign-devices-cancel"),
        icon: (
          <QuestionCircleOutlined
            style={{ color: "var(--question-icon-color)" }}
          />
        ),
        title: t("profile-assign-devices-leave-title"),
        content: t("profile-assign-devices-leave-content"),
        onOk: () => clearAndClose(),
      });
    } else {
      clearAndClose();
    }
  };

  const clearAndClose = () => {
    setDirty(false);
    props.onClose();
  };

  const onValuesChange = (changedValues) => {
    setDirty(form.isFieldsTouched());
    if (changedValues.calibrationAdvisedPeriodType != null) {
      setMaxAdvisedCalibration(
        getMaxCalibration(changedValues.calibrationAdvisedPeriodType)
      );
    } else if (changedValues.calibrationRequiredPeriodType != null) {
      setMaxRequiredCalibration(
        getMaxCalibration(changedValues.calibrationRequiredPeriodType)
      );
    }
  };

  const onSaveClick = () => {
    form.submit();
  };
  return (
    <Drawer
      title={t("nose-calibration-view")}
      width={450}
      onClose={closeDrawer}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            id="cancel-device-details"
            onClick={closeDrawer}
            style={{ marginRight: 8 }}
          >
            {t("device-details-cancel")}
          </Button>
          {isAdmin && (
            <Button
              id="save-device-details"
              disabled={!dirty}
              onClick={onSaveClick}
              type="primary"
            >
              {t("device-details-confirm")}
            </Button>
          )}
        </div>
      }
    >
      <Skeleton loading={loading} active paragraph={{ rows: 5 }}>
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
        >
          <Row>
            <Col span={24}>
              <Form.Item name="noseId" label={t("device-details-noseId")}>
                {isAdmin && (
                  <Link to={"/spironoses/" + form.getFieldValue("noseId")}>
                    {form.getFieldValue("noseId")}
                  </Link>
                )}
                {!isAdmin && <span>{form.getFieldValue("noseId")}</span>}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Descriptions layout="vertical" column={1} colon={false}>
                <Descriptions.Item label={t("nose-calibration-status")}>
                  {getCalibrationStatus(spiroNose)}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Descriptions layout="vertical" column={1} colon={false}>
                <Descriptions.Item label={t("nose-last-calibration-date")}>
                  {form.getFieldValue("lastCalibrationDate")
                    ? `${moment(
                        form.getFieldValue("lastCalibrationDate")
                      ).format("YYYY-MM-DD")}`
                    : t("none")}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              {isAdmin ? (
                <Form.Item label={t("nose-details-next-calibration-advised")}>
                  <Input.Group compact>
                    <Form.Item name="calibrationAdvisedPeriod" noStyle>
                      <InputNumber
                        min={1}
                        max={maxAdvisedCalibration}
                        placeholder={t("placeholder-nose-advised-calibration")}
                        style={{ width: "60%" }}
                      />
                    </Form.Item>
                    <Form.Item name="calibrationAdvisedPeriodType" noStyle>
                      <Select
                        placeholder={t(
                          "placeholder-nose-duration-type-advised-calibration"
                        )}
                        style={{ width: "40%" }}
                      >
                        <Option key="hours" value={0}>
                          {t("calibration-hours")}
                        </Option>
                        <Option key="days" value={1}>
                          {t("calibration-days")}
                        </Option>
                        <Option key="weeks" value={2}>
                          {t("calibration-weeks")}
                        </Option>
                        <Option key="months" value={3}>
                          {t("calibration-months")}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              ) : (
                <Row>
                  <Col span={24}>
                    <Descriptions layout="vertical" column={1} colon={false}>
                      <Descriptions.Item
                        label={t("nose-details-next-calibration-advised")}
                      >
                        {form.getFieldValue("calibrationAdvisedPeriod") +
                          " " +
                          getDeviceCalibrationDurationType(
                            form.getFieldValue("calibrationAdvisedPeriodType"),
                            t
                          )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isAdmin ? (
                <Form.Item label={t("nose-details-next-calibration-required")}>
                  <Input.Group compact>
                    <Form.Item name="calibrationRequiredPeriod" noStyle>
                      <InputNumber
                        min={1}
                        max={maxRequiredCalibration}
                        placeholder={t("placeholder-nose-required-calibration")}
                        style={{ width: "60%" }}
                      />
                    </Form.Item>
                    <Form.Item name="calibrationRequiredPeriodType" noStyle>
                      <Select
                        placeholder={t(
                          "placeholder-nose-duration-type-required-calibration"
                        )}
                        style={{ width: "40%" }}
                      >
                        <Option key="hours" value={0}>
                          {t("calibration-hours")}
                        </Option>
                        <Option key="days" value={1}>
                          {t("calibration-days")}
                        </Option>
                        <Option key="weeks" value={2}>
                          {t("calibration-weeks")}
                        </Option>
                        <Option key="months" value={3}>
                          {t("calibration-months")}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              ) : (
                <Row>
                  <Col span={24}>
                    <Descriptions layout="vertical" column={1} colon={false}>
                      <Descriptions.Item
                        label={t("nose-details-next-calibration-required")}
                      >
                        {form.getFieldValue("calibrationRequiredPeriod") +
                          " " +
                          getDeviceCalibrationDurationType(
                            form.getFieldValue("calibrationRequiredPeriodType"),
                            t
                          )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isAdmin && (
                <Form.Item
                  name="forceCalibration"
                  valuePropName="checked"
                  initialValue={form.getFieldValue("forceCalibration")}
                  label={
                    <Space>
                      {t("nose-details-next-calibration-force")}
                      <Tooltip
                        title={t("nose-details-next-calibration-force-tooltip")}
                      >
                        <InfoCircleOutlined
                          style={{ color: "var(--default-tooltip-color)" }}
                        />
                      </Tooltip>
                    </Space>
                  }
                >
                  <Switch
                    checkedChildren={t("yes")}
                    unCheckedChildren={t("no")}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Drawer>
  );
};

export default NoseDetailsDrawer;
