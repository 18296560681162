import React, { useState, useEffect } from "react";
import { Space, Tooltip, Form, Switch } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const QuestionItemSwitch = (props) => {
  const { t } = useTranslation();
  const [question, setQuestion] = useState({});
  const [switchValue, setSwitchValue] = useState(false);

  useEffect(() => {
    setQuestion(props.question);
    // props.questionValue is null in preview
    if (props.questionValue !== null) { setSwitchValue(props.questionValue) };
  }, [props]);

  return (
    <Form.Item
      hasFeedback={question.feedback}
      colon={false}
      key={question.id}
      name={question.id}
      initialValue={props.questionValue ? props.questionValue : false}
      label={
        question.label && (
          <Space>
            {t("survey:" + question.label)}
            {question.tooltip && (
              <Tooltip
                title={parse(t("survey:" + question.tooltip))}
                trigger={["hover", "click"]}
              >
                <InfoCircleOutlined
                  style={{
                    color: question.tooltipColor,
                  }}
                />
              </Tooltip>
            )}
          </Space>
        )
      }
      rules={
        question.rules
          ? question.rules.map((r) => {
            r.message = !r.message.startsWith("survey:") ? t("survey:" + r.message) : r.message;
            return r;
          })
          : null
      }
    >
      <Switch
        checked={switchValue}
        onChange={(checked) => {
          setSwitchValue(checked);
        }}
        checkedChildren={t(question.yes)}
        unCheckedChildren={t(question.no)}
        disabled={question.readonly}
      />
    </Form.Item>
  );
};

export default QuestionItemSwitch;
