import React from "react";
import { Button, notification } from "antd";
import i18n from "../../../i18n";
import { InfoCircleOutlined } from "@ant-design/icons";

export const onSWUpdate = (registration) => {
  const key = `open${Date.now()}`;
  const reloadClick = () => {
    const registrationWaiting = registration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  const btn = (
    <Button size="small" type="primary" onClick={reloadClick}>
      {i18n.t("notification-app-update-reload")}
    </Button>
  );
  notification.info({
    key,
    message: i18n.t("notification-app-update-new-version"),
    placement: "topRight",
    duration: 0,
    btn,
    icon: <InfoCircleOutlined style={{ color: "#108ee9" }} />,
  });
};
