import React, { useState, useEffect } from "react";
import { Form, Checkbox } from "antd";
import { useTranslation } from "react-i18next";

const QuestionItemCheckbox = (props) => {
  const { t } = useTranslation();
  const [question, setQuestion] = useState({});

  useEffect(() => {
    setQuestion(props.question);
  }, [props]);

  return (
    <Form.Item
      hasFeedback={question.feedback}
      colon={false}
      key={question.id}
      name={question.id}
      label={
        question.label &&
        props.formInstance.getFieldValue(question.id) &&
        t("survey:" + question.label)
      }
      initialValue={false}
      valuePropName="checked"
      rules={
        question.rules
          ? question.rules.map((r) => {
            r.message = !r.message.startsWith("survey:") ? t("survey:" + r.message) : r.message;
            return r;
          })
          : null
      }
    >
      <Checkbox>{t("survey:not-available")}</Checkbox>
    </Form.Item>
  );
};

export default QuestionItemCheckbox;
