import React from "react";
import { Result } from "antd";

const NotFoundPage = () => {
  return (
    <div
      style={{
        display: "grid",
        height: "100%",
        width: "100%",
        background: "white",
      }}
    >
      <Result
        status="404"
        title="404"
        height="100%"
        subTitle="Sorry, the page you visited does not exist."
      />
    </div>
  );
};

export default NotFoundPage;
