import React, { useContext, useState, useEffect } from "react";
import { Menu, Layout } from "antd";
import {
  UserOutlined,
  MonitorOutlined,
  HomeOutlined,
  LaptopOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import "./sidebar-content.css";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthProvider";
import { ResearchContext } from "../../contexts/ResearchProvider";
import { APP_ROLES } from "../../utils/utils";
const { Sider } = Layout;

const SidebarContent = () => {
  const { t } = useTranslation();
  const [currentLocation, setCurrentLocation] = useState(null);
  const { collapsed } = useContext(ResearchContext);
  const { user } = useContext(AuthContext);
  const isAdmin = user && user.role === APP_ROLES.ADMIN;
  let location = useLocation();

  useEffect(() => {
    if (location) {
      let changed =
        currentLocation && currentLocation.pathname !== location.pathname;
      if (changed) {
        setCurrentLocation(location);
      }
    }
  }, [location]);

  return (
    <Sider
      trigger={null}
      collapsed={collapsed}
      className="sidebar"
      breakpoint="lg"
      collapsedWidth="64"
    >
      <div className="logo-grid">
        {collapsed ? (
          <img className="logo" alt="logo" src="/img/logo270.png" />
        ) : (
          <img className="logo" alt="logo" src="/img/logo-white.png" />
        )}
      </div>
      <Menu
        theme="light"
        mode="inline"
        activeKey={location.pathname}
        selectedKeys={[location.pathname]}
        defaultOpenKeys={["3"]}
      >
        <Menu.Item key="/" icon={<HomeOutlined />} id="dashboard-menu-item">
          <Link to="/">{t("dashboard")}</Link>
        </Menu.Item>
        {isAdmin && (
          <Menu.Item key="/users" icon={<TeamOutlined />} id="users-menu-item">
            <Link to="/users">{t("users")}</Link>
          </Menu.Item>
        )}
        <Menu.Item
          key="/devices"
          icon={<LaptopOutlined />}
          id="devices-menu-item"
        >
          <Link to="/devices">{t("devices")}</Link>
        </Menu.Item>
        <Menu.Item
          key="/studies"
          icon={<SolutionOutlined />}
          id="studies-menu-item"
        >
          <Link to="/studies">{t("studies")}</Link>
        </Menu.Item>
        {isAdmin && (
          <Menu.Item
            key="/spironoses"
            icon={<LaptopOutlined />}
            id="spironoses-menu-item"
          >
            <Link to="/spironoses">{t("spironoses")}</Link>
          </Menu.Item>
        )}
        {isAdmin && (
          <Menu.Item
            key="/survey-templates"
            icon={<SolutionOutlined />}
            id="surveys-templates-menu-item"
          >
            <Link to="/survey-templates">{t("survey-templates")}</Link>
          </Menu.Item>
        )}
        <Menu.Item key="/measurements" icon={<MonitorOutlined />}>
          <Link to="/measurements" id="all-measurements-menu-item">
            {t("measurements")}
          </Link>
        </Menu.Item>
        <Menu.Item
          key="/account"
          id="account-menu-item"
          icon={<UserOutlined />}
        >
          <Link
            to={{
              pathname: "/account",
              id: user.id,
            }}
          >
            {t("profile")}
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SidebarContent;
