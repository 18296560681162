import React, { useEffect, useState, useContext } from "react";
import { Form, Tabs, Skeleton, Button } from "antd";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/fr";
import "./survey-questionnaire.css";

import {
  InfoCircleOutlined,
  UserOutlined,
  MedicineBoxOutlined,
  FireOutlined,
  MessageOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { ResearchContext } from "../../contexts/ResearchProvider";
import { useTranslation } from "react-i18next";
import { SURVEY_STATUS } from "../../utils/utils";
import QuestionItem from "../question-item/question-item";
import SimpleMessage from "../../components/simple-components/simple-message/simple-message";
const { TabPane } = Tabs;

const SurveyQuestionnaire = (props) => {
  const { preferredLanguage } = useContext(ResearchContext);
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [template, setTemplate] = useState(null);
  const [form] = Form.useForm();
  const [wasInitialized, setWasInitialized] = useState(false);
  const [tab, setTab] = useState("1");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      if (props?.surveyTemplateJson) {
        setLoadingTemplate(true);
        setTemplate(props.surveyTemplateJson);
        setLoadingTemplate(false);
        if (props.surveyData) {
          if (
            props.surveyData.hasOwnProperty("measurementId") &&
            props.surveyData.measurementId
          ) {
            if (!wasInitialized) {
              form.resetFields();
              setTimeout(() => {
                form.setFieldsValue(props?.surveyData);
              }, 0);

              setWasInitialized(true);
            }
          } else {
            //EDGE CASE
            props.onGetSurveyData();
          }
        }
      }
    };
    fetchData();
  }, [props]);

  useEffect(() => {
    if (props.preloadCounter) {
      form.resetFields();
      setTimeout(() => {
        form.setFieldsValue(props?.surveyData);
      }, 0);
    }
  }, [props.preloadCounter]);

  useEffect(() => {
    moment.locale(preferredLanguage);
  }, [preferredLanguage]);

  useEffect(() => {
    if (props.saveOrSubmit) {
      validateSurvey(props.saveOrSubmit.buttonClicked);
    }
  }, [props.saveOrSubmit]);

  const validateSurvey = async (clickedButton) => {
    try {
      await form.validateFields();
      if (clickedButton == "saveButton" || clickedButton == "saveButtonStep") {
        props.onSurveyTrySave(SURVEY_STATUS.COMPLETED);
        SimpleMessage("success", t("survey:survey-completed-success"));
      } else {
        props.onSurveyTrySave(SURVEY_STATUS.SUBMITTED);
      }
    } catch (errorInfo) {
      if (clickedButton == "saveButton" || clickedButton == "saveButtonStep") {
        props.onSurveyTrySave(SURVEY_STATUS.IN_PROGRESS);
      } else {
        SimpleMessage("error", t("survey:survey-error-submit"));
      }
    }
  };

  const getSectionIcon = (id) => {
    switch (id) {
      case 1:
        return (
          <InfoCircleOutlined
            style={{
              color: "#108ee9",
              fontSize: "22px",
              verticalAlign: "middle",
            }}
          />
        );
      case 2:
        return (
          <UserOutlined
            style={{
              color: "#108ee9",
              fontSize: "22px",
              verticalAlign: "middle",
            }}
          />
        );
      case 3:
        return (
          <FireOutlined
            style={{
              color: "#108ee9",
              fontSize: "22px",
              verticalAlign: "middle",
            }}
          />
        );
      case 4:
        return (
          <MedicineBoxOutlined
            style={{
              color: "#108ee9",
              fontSize: "22px",
              verticalAlign: "middle",
            }}
          />
        );
      case 5:
        return (
          <MessageOutlined
            style={{
              color: "#108ee9",
              fontSize: "22px",
              verticalAlign: "middle",
            }}
          />
        );
      case 6:
        return (
          <PlusCircleOutlined
            style={{
              color: "#108ee9",
              fontSize: "22px",
              verticalAlign: "middle",
            }}
          />
        );
      default:
        return <InfoCircleOutlined />;
    }
  };

  const onTabChange = async (key) => {
    if (
      props.surveyStatus != SURVEY_STATUS.SUBMITTED &&
      props.onSurveyTrySave
    ) {
      props.onSurveyTrySave();
    }
    setTab(key);
  };
  const onValuesChange = (oldValues, currentValues) => {
    if (props.onSurveyDataChanged) props.onSurveyDataChanged(currentValues);
  };

  return (
    <div className="survey-grid">
      {template &&
        (Array.isArray(template) ? (
          <Skeleton active loading={loadingTemplate}>
            <Form
              className="survey-content"
              preserve={false}
              hideRequiredMark
              form={form}
              layout="vertical"
              onValuesChange={onValuesChange}
            >
              <Tabs
                tabPosition="left"
                activeKey={tab}
                onChange={onTabChange}
                defaultActiveKey={1}
              >
                {template.map((section) => (
                  <TabPane
                    forceRender={true}
                    key={section.id}
                    tab={
                      <span>
                        {getSectionIcon(section.id)}
                        {t("survey:" + section.title)}
                      </span>
                    }
                  >
                    {section.questions.map((q) => (
                      <QuestionItem
                        key={q.id}
                        question={q}
                        formInstance={form}
                        questionValue={
                          props.surveyData ? props.surveyData[q.id] : null
                        }
                        onSurveyDataChanged={props.onSurveyDataChanged}
                      />
                    ))}
                  </TabPane>
                ))}
              </Tabs>
              {parseInt(tab) < 6 && (
                <div className="survey-action">
                  <Button
                    className="next-step-button"
                    onClick={() => {
                      if (
                        props.surveyStatus != SURVEY_STATUS.SUBMITTED &&
                        props.onSurveyTrySave
                      ) {
                        props.onSurveyTrySave();
                      }
                      setTab((parseInt(tab) + 1).toString());
                    }}
                  >
                    {t("next")}
                  </Button>
                </div>
              )}
            </Form>
          </Skeleton>
        ) : (
          SimpleMessage("error", t("survey:survey-template-issurveyjs-error"))
        ))}
    </div>
  );
};

export default SurveyQuestionnaire;
