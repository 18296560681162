import React, { useEffect, useContext, useState } from "react";
import { Button, Typography, Modal } from "antd";
import { QuestionCircleOutlined, CloudUploadOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/fr";
import { useTranslation } from "react-i18next";

import SurveyQuestionnaire from "../../survey/survey-questionnaire/survey-questionnaire";
import SurveyJSQuestionnaire from "../../survey/survey-questionnaire/surveyjs-questionnaire";
import { AuthContext } from "../../contexts/AuthProvider";
import {
  API_REQUESTS,
  SURVEY_VERSION,
  SURVEY_STATUS,
  APP_ROLES,
} from "../../utils/utils";
import {
  getSurveyTemplateByStudy,
  saveSurveyData,
  getSurvey,
  setMeasurementSurveyStatus,
} from "../../../services/ApiService";
import SimpleMessage from "../../components/simple-components/simple-message/simple-message";
import "./edit-survey-page.css";
import PreloadSurveyModal from "../../components/preload-survey-modal/preload-survey-modal";
const { Text } = Typography;
const { confirm } = Modal;

const EditSurveyPage = (props) => {
  const { t } = useTranslation();
  const { getAccessToken, user } = useContext(AuthContext);
  const [currentMeasurement, setCurrentMeasurement] = useState(null);
  const [surveyData, setSurveyData] = useState(null);
  const [surveyTemplateJson, setSurveyTemplateJson] = useState(null);
  const [isSurveyJS, setIsSurveyJS] = useState(false);
  const [saveOrSubmit, setSaveOrSubmit] = useState(false);
  const [showPreloadModal, setShowPreloadModal] = useState(false);
  const [preloadCounter, setPreloadCounter] = useState(0);
  const [visitNoSurveyJS, setVisitNoSurveyJS] = useState("");

  const isNotAdmin = user.role !== APP_ROLES.ADMIN;

  useEffect(() => {
    if (props.location.state) {
      setCurrentMeasurement(props.location.state.measurement);
      setStorageForMeasurement();
    } else {
      let storageData = {
        id: parseInt(localStorage.getItem("MeasurementId")),
        deviceId: localStorage.getItem("DeviceId"),
        studyId: localStorage.getItem("StudyId"),
        studyName: localStorage.getItem("StudyName"),
        noseId: localStorage.getItem("NoseId"),
        dateCreated: localStorage.getItem("DateCreated"),
      };
      setCurrentMeasurement(storageData);
    }
  }, [props]);

  useEffect(() => {
    if (currentMeasurement) {
      onGetSurveyData();
      loadSurveyTemplate();
    }
  }, [currentMeasurement]);

  const setStorageForMeasurement = () => {
    localStorage.setItem("MeasurementId", props.location.state.measurement.id);
    localStorage.setItem(
      "DeviceId",
      props.location.state.measurement.serialNumber
    );
    localStorage.setItem("StudyId", props.location.state.measurement.studyId);
    localStorage.setItem(
      "StudyName",
      props.location.state.measurement.studyName
    );
    localStorage.setItem("NoseId", props.location.state.measurement.noseId);
    localStorage.setItem(
      "DateCreated",
      props.location.state.measurement.dateCreated
    );
  };

  const loadSurveyTemplate = async () => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);

      if (token) {
        let res = await getSurveyTemplateByStudy(
          token,
          currentMeasurement.studyId
        );
        if (res && res.result && !res.error) {
          setSurveyTemplateJson(JSON.parse(res.result.template.templateJson));
          setIsSurveyJS(res.result.template.isSurveyJS);
        }
      } else {
        SimpleMessage(
          "error",
          t("measurement-edit-survey-load-template-error")
        );
      }
    } catch {
      SimpleMessage("error", t("measurement-edit-survey-load-template-error"));
    }
  };

  const onGetSurveyData = async () => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      let data = {
        deviceId: currentMeasurement?.serialNumber,
        measurementId: currentMeasurement?.id,
        version: SURVEY_VERSION.V2,
      };
      if (token) {
        let res = await getSurvey(data, token);
        if (res && res.result && !res.error) {
          if (res.result.data && res.result.data.data) {
            let responseData = JSON.parse(res.result.data.data);
            setSurveyData(responseData);
          } else {
            setSurveyData({
              measurementId: currentMeasurement.id,
              deviceId: currentMeasurement.serialNumber,
              startDateTime: moment().format("LLL"),
              studyId: currentMeasurement.studyName,
              bmi: 0,
            });
          }
        }
      } else {
        SimpleMessage("error", t("measurement-edit-survey-load-survey-error"));
      }
    } catch {
      SimpleMessage("error", t("measurement-edit-survey-load-survey-error"));
    }
  };

  const onSaveSurveyData = async (shouldRedirect = true) => {
    try {
      localStorage.removeItem("MeasurementId");
      localStorage.removeItem("DeviceId");
      localStorage.removeItem("StudyId");
      localStorage.removeItem("StudyName");
      localStorage.removeItem("NoseId");
      localStorage.removeItem("DateCreated");
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      let postData = {
        deviceId: currentMeasurement?.serialNumber,
        measurementId: currentMeasurement?.id,
        noseId: currentMeasurement?.noseId,
        data: JSON.stringify(surveyData),
      };

      if (token) {
        let res = await saveSurveyData(postData, token);
        if (res && shouldRedirect) {
          SimpleMessage(
            "success",
            t("measurement-edit-survey-save-survey-success")
          );
          props.history.push("/measurements");
        }
      } else {
        SimpleMessage("error", t("measurement-edit-survey-save-survey-error"));
      }
    } catch {
      SimpleMessage("error", t("measurement-edit-survey-save-survey-error"));
    }
  };

  const goToMeasurementPage = () => {
    confirm({
      cancelText: t("measurement-edit-survey-cancel"),
      icon: (
        <QuestionCircleOutlined
          style={{ color: "var(--question-icon-color)" }}
        />
      ),
      title: t("measurement-edit-survey-leave-title"),
      content: t("measurement-edit-survey-leave-content"),
      onOk: () => {
        localStorage.removeItem("MeasurementId");
        localStorage.removeItem("DeviceId");
        localStorage.removeItem("StudyId");
        localStorage.removeItem("StudyName");
        localStorage.removeItem("NoseId");
        localStorage.removeItem("DateCreated");
        props.history.push("/measurements");
      },
    });
  };
  const onSurveyTrySave = async (surveyStatus) => {
    if (surveyStatus) {
      try {
        const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
        if (token) {
          let data = {
            measurementId: currentMeasurement.id,
            surveyStatus,
          };
          let res = await setMeasurementSurveyStatus(data, token);
          if (res && !res.error) {
            SimpleMessage("success", t("survey:survey-status-updated-success"));
            onSaveSurveyData();
          }
        } else {
          SimpleMessage("error", t("survey:survey-error-submit"));
        }
      } catch {
        SimpleMessage("error", t("survey:survey-error-submit"));
      }
    } else {
      onSaveSurveyData(false);
    }
  };
  const surveyDataChanged = (newValues) => {
    setSurveyData({ ...surveyData, ...newValues });
  };

  const onSubmitClicked = (target) => {
    setSaveOrSubmit({ buttonClicked: target.currentTarget.id });
  };

  const onWithdrawClicked = async () => {
    var data = {
      measurementId: currentMeasurement.id,
      surveyStatus: SURVEY_STATUS.COMPLETED,
    };
    const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
    var res = await setMeasurementSurveyStatus(data, token);
    if (res) {
      SimpleMessage("success", t("survey-withdraw-success"));
      setCurrentMeasurement({
        ...currentMeasurement,
        surveyStatus: SURVEY_STATUS.COMPLETED,
      });
    } else {
      SimpleMessage("error", res.error);
    }
  };

  const closePreloadModal = (preloadedData) => {
    if (preloadedData) {
      setSurveyData({
        ...preloadedData,
        measurementId: surveyData.measurementId,
        deviceId: surveyData.deviceId,
        startDateTime: surveyData.startDateTime,
        studyId: surveyData.studyId,
        visitNo: isSurveyJS ? visitNoSurveyJS : surveyData.visitNo,
        preloadedMeasurementId: preloadedData.measurementId,
      });
      setPreloadCounter(preloadCounter + 1);
    }
    setShowPreloadModal(false);
  };

  const openPreloadAnswersModal = async () => {
    if (surveyData.visitNo > 1 || visitNoSurveyJS > 1) {
      setShowPreloadModal(true);
    } else {
      SimpleMessage("error", t("survey-visit-number-error"));
    }
  };

  return (
    <div className="container-measurement-edit-survey">
      <div className="measurement-id-title">
        <Text>
          {t("measurement")} {currentMeasurement?.id}
        </Text>
      </div>
      <div className="edit-survey-details">
        {currentMeasurement && isSurveyJS ? (
          <SurveyJSQuestionnaire
            surveyTemplateJson={surveyTemplateJson}
            surveyData={surveyData}
            deviceId={currentMeasurement.serialNumber}
            noseId={currentMeasurement.noseId}
            measurementId={currentMeasurement.id}
            surveyStatus={currentMeasurement?.surveyStatus}
            saveOrSubmit={saveOrSubmit}
            onGetSurveyData={onGetSurveyData}
            fromMeasurementList={true}
            setVisitNo={setVisitNoSurveyJS}
          ></SurveyJSQuestionnaire>
        ) : (
          <SurveyQuestionnaire
            surveyTemplateJson={surveyTemplateJson}
            surveyData={surveyData}
            onSurveyDataChanged={surveyDataChanged}
            onSurveyTrySave={onSurveyTrySave}
            saveOrSubmit={saveOrSubmit}
            surveyStatus={currentMeasurement?.surveyStatus}
            onGetSurveyData={onGetSurveyData}
            preloadCounter={preloadCounter}
          />
        )}
      </div>

      <div className="edit-survey-action">
        {isNotAdmin && (
          <Button
            className="preload-survey-button"
            onClick={openPreloadAnswersModal}
          >
            <CloudUploadOutlined /> {t("survey-preload-answers")}
          </Button>
        )}
        <Button className="cancel-survey-button" onClick={goToMeasurementPage}>
          {t("measurement-edit-survey-cancel")}
        </Button>
        {currentMeasurement && (
          <Button
            disabled={
              currentMeasurement?.surveyStatus == SURVEY_STATUS.SUBMITTED
            }
            className="save-survey-button"
            id="saveButton"
            onClick={onSubmitClicked}
          >
            {t("measurement-edit-survey-save")}
          </Button>
        )}
        {currentMeasurement && !isSurveyJS && (
          <Button
            disabled={
              isNotAdmin &&
              currentMeasurement?.surveyStatus == SURVEY_STATUS.SUBMITTED
            }
            className="submit-survey-button"
            id="submitButton"
            onClick={
              currentMeasurement?.surveyStatus == SURVEY_STATUS.SUBMITTED
                ? onWithdrawClicked
                : onSubmitClicked
            }
          >
            {isNotAdmin ||
              currentMeasurement?.surveyStatus != SURVEY_STATUS.SUBMITTED
              ? t("survey:measurement-edit-survey-submit")
              : t("withdraw-survey")}
          </Button>
        )}
      </div>

      <PreloadSurveyModal
        showModal={showPreloadModal}
        closePreloadModal={closePreloadModal}
        measurementDate={currentMeasurement?.dateCreated}
      />
    </div>
  );
};

export default EditSurveyPage;
