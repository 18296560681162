import React, { useState, useEffect } from "react";
import { Space, Tooltip, Input, Form } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const QuestionItemText = (props) => {
  const { t } = useTranslation();
  const [question, setQuestion] = useState({});

  useEffect(() => {
    setQuestion(props.question);
  }, [props]);

  return (
    <Form.Item
      hasFeedback={question.feedback}
      colon={false}
      key={question.id}
      name={question.id}
      initialValue={question.value}
      label={
        question.label && (
          <Space>
            {t("survey:" + question.label)}
            {question.tooltip && (
              <Tooltip title={parse(t("survey:" + question.tooltip))}>
                <InfoCircleOutlined style={{ color: question.tooltipColor }} />
              </Tooltip>
            )}
          </Space>
        )
      }
      rules={
        question.rules
          ? question.rules.map((r) => {
            r.message = !r.message.startsWith("survey:") ? t("survey:" + r.message) : r.message;
            return r;
          })
          : null
      }
    >
      <Input
        disabled={question.readonly}
        placeholder={t("survey:" + question.placeholder)}
      />
    </Form.Item>
  );
};

export default QuestionItemText;
