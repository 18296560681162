import React, { useState, useEffect } from "react";
import {
  Space,
  Form,
  Input,
  Button,
  Typography,
  InputNumber,
  Tooltip,
  Select,
  DatePicker,
} from "antd";
import moment from "moment";
import {
  MinusCircleOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
const { Text } = Typography;
const { Option } = Select;

const QuestionItemMedication = (props) => {
  const { t } = useTranslation();
  const [question, setQuestion] = useState({});
  const [selectedDates, setSelectedDates] = useState([]);

  useEffect(() => {
    setQuestion(props.question);
  }, [props]);

  const onDateChange = (date, fieldKey) => {
    let dates = [...selectedDates];
    dates[fieldKey] = date;
    setSelectedDates(dates);
  };

  const disabledDate = (current) => {
    switch (question.enabledDates) {
      case "future":
        return current < moment().startOf("day");
      case "past":
        return current > moment();
      default:
        return false;
    }
  };

  const cleanMedicationAnswer = (index) => {
    var values = props.formInstance.getFieldsValue()[question.id];
    values.splice(index, 1);
    var newFieldValues = {};
    newFieldValues[question.id] = values;
    props.formInstance.setFieldsValue(newFieldValues);

    let dates = [...selectedDates];
    dates.splice(index, 1);
    setSelectedDates(dates);
  };
  const getInput = (subquestion, field) => {
    switch (subquestion.type) {
      case "text":
        return <Input style={{ width: "95%" }} />;
      case "dropdown":
        return (
          <Select style={{ width: 150 }}>
            {subquestion.options.map((o) => (
              <Option value={o.value}>{t("survey:" + o.key)}</Option>
            ))}
          </Select>
        );
      case "number":
        return (
          <InputNumber
            min={subquestion.min}
            max={subquestion.max}
            step={
              subquestion.decimals ? 1 / Math.pow(10, subquestion.decimals) : 1
            }
            precision={subquestion.decimals ? subquestion.decimals : 0}
          />
        );
      case "date":
        return (
          <DatePicker
            value={selectedDates[field.key]}
            disabled={subquestion.readonly}
            onChange={(date) => onDateChange(date, field.key)}
            placeholder={t("survey:" + subquestion.placeholder)}
            format={subquestion.pickTime ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY"}
            disabledDate={disabledDate}
            showTime={subquestion.pickTime}
          />
        );
      default:
        return <Input style={{ width: "95%" }} />;
    }
  };
  return (
    <Form.List name={question.id}>
      {(fields, { add, remove }) => (
        <Space direction="vertical">
          <Space>
            <Text strong>{t("survey:" + question.label)}</Text>
            {question.tooltip && (
              <Tooltip title={parse(t("survey:" + question.tooltip))}>
                <InfoCircleOutlined style={{ color: question.tooltipColor }} />
              </Tooltip>
            )}
          </Space>
          {fields.map((field) => (
            <Space style={{ alignItems: "flex-start" }}>
              {question.questions.map((q) => (
                <Space direction="vertical">
                  {q.label && (
                    <Space>
                      {t("survey:" + q.label)}
                      {q.tooltip && (
                        <Tooltip title={parse(t("survey:" + q.tooltip))}>
                          <InfoCircleOutlined
                            style={{
                              color: q.tooltipColor,
                            }}
                          />
                        </Tooltip>
                      )}
                    </Space>
                  )}
                  <Form.Item
                    {...field}
                    name={[field.name, q.id]}
                    rules={
                      q.rules
                        ? q.rules.map((r) => {
                          r.message = !r.message.startsWith("survey:") ? t("survey:" + r.message) : r.message;
                          return r;
                        })
                        : null
                    }
                    {...(q.type == "date"
                      ? {
                        getValueProps: (value) => {
                          if (!selectedDates[field.key] && value) {
                            onDateChange(moment(value), field.key);
                          }
                        },
                      }
                      : {})}
                  >
                    {getInput(q, field)}
                  </Form.Item>
                </Space>
              ))}
              <MinusCircleOutlined
                onClick={() => {
                  cleanMedicationAnswer(field.name);
                }}
              />
            </Space>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              {t("survey:medication-add")}
            </Button>
          </Form.Item>
        </Space>
      )}
    </Form.List>
  );
};

export default QuestionItemMedication;
