import React, { useContext } from "react";
import "./new-measurement-page.css";
import { Steps } from "antd";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthProvider";
import { MEASUREMENT_TYPE, APP_ROLES } from "../../utils/utils";
import { getNewMeasurementSteps } from "./new-measurement-steps";
import { NewMeasurementContext } from "../../contexts/NewMeasurementContext";
const { Step } = Steps;

const NewMeasurementPage = (props) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { currentStep, measurementType } = useContext(NewMeasurementContext);
  const isClinician = user.role === APP_ROLES.CLINICIAN;

  let reasercherSteps = [
    getNewMeasurementSteps(t).selectDeviceStep,
    getNewMeasurementSteps(t).performMeasurementStep,
    getNewMeasurementSteps(t).completeSurveyStep,
    getNewMeasurementSteps(t).finishStep
  ];

  let notRegularSteps = [
    getNewMeasurementSteps(t).selectDeviceStep,
    getNewMeasurementSteps(t).performMeasurementStep,
    getNewMeasurementSteps(t).finishStep
  ];

  let clinicianSteps = [
    getNewMeasurementSteps(t).selectDeviceStep,
    getNewMeasurementSteps(t).performMeasurementStep,
    getNewMeasurementSteps(t).analysisResultStep,
    getNewMeasurementSteps(t).completeSurveyStep,
    getNewMeasurementSteps(t).finishStep
  ];

  const getStepsByMeasurementType = (measurementType) => {
    if (measurementType === MEASUREMENT_TYPE.REGULAR) {
      if (isClinician) {
        return clinicianSteps;
      } else {
        return reasercherSteps;
      }
    } else {
      return notRegularSteps;
    }
  };

  return (
    <div className="stepper-grid">
      <Steps current={currentStep}>
        {getStepsByMeasurementType(measurementType).map(step => (
          <Step
            key={step.title}
            title={step.title}
            icon={step.icon}
          />
        ))}
      </Steps>
      <div className="steps-content">
        {getStepsByMeasurementType(measurementType)[currentStep].content}
      </div>
    </div>
  );
};

export default NewMeasurementPage;
