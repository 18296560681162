import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types"; // ES6
import { Statistic } from "antd";
import { TIMER_MODE } from "../../utils/utils";
import moment from "moment";

const { Countdown } = Statistic;

const Timer = (props) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const timerRef = useRef(null);

  useEffect(() => {
    if (props.mode === TIMER_MODE.STOPWATCH) {
      var newElapsedTime = getSecondsSince(props.startDate);
      setElapsedTime(newElapsedTime);
      timerRef.current = setInterval(() => {
        setElapsedTime(elapsedTime => elapsedTime + 1);
      }, 1000);
    }
    return () => {
      if (props.mode === TIMER_MODE.STOPWATCH) {
        clearInterval(timerRef.current);
        setElapsedTime(0);
      }
    }
  }, [props.mode, props.startDate]);

  const getSecondsSince = (date) => {
    let dateCreated = moment.utc(date);
    let now = moment().utc();
    return now.diff(dateCreated, "seconds");
  };

  const formatTime = (seconds) => {
    const getSeconds = `0${(seconds % 60)}`.slice(-2)
    const minutes = `${Math.floor(seconds / 60)}`
    const getMinutes = `0${minutes % 60}`.slice(-2)
    const getHours = `0${Math.floor(seconds / 3600)}`.slice(-2)

    return `${getHours}:${getMinutes}:${getSeconds}`
  }

  return (
    props.mode === TIMER_MODE.COUNTDOWN
      ? <Countdown
        className={props.className}
        value={props.endDate}
      />
      : <Statistic
        className={props.className}
        value={elapsedTime}
        formatter={formatTime}
      />
  );
};

Timer.propTypes = {
  mode: PropTypes.oneOf([...Object.values(TIMER_MODE)]).isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  className: PropTypes.string
};

export default Timer;