import React, { useEffect, useContext, useState } from "react";
import { Card, Typography, Space, Tooltip, Avatar, Tag } from "antd";
import "./user-card.css";
import PropTypes from "prop-types"; // ES6
import { useTranslation } from "react-i18next";
import { MailTwoTone, LaptopOutlined, UserOutlined } from "@ant-design/icons";
import { getUserProfilePhoto } from "../../../services/ApiService";
import { AuthContext } from "../../contexts/AuthProvider";
import { API_REQUESTS } from "../../utils/utils";
import { Link } from "react-router-dom";
const { Text } = Typography;

const UserCard = (props) => {
  const { getAccessToken } = useContext(AuthContext);
  const { t } = useTranslation();
  const [photo, setPhoto] = useState(null);
  const workingAt = t("working-at", {
    organization: props.user.organization,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessToken(
          API_REQUESTS.USER_IMPERSONATION
        );
        if (accessToken) {
          // Get the user's photo from Graph
          let photoBlob = await getUserProfilePhoto(props.user.id, accessToken);
          if (photoBlob && photoBlob.result && photoBlob.result.size > 0) {
            setPhoto(URL.createObjectURL(photoBlob.result));
          }
        }
      } catch (err) {
        if (err.code && err.code !== "MailboxNotEnabledForRESTAPI") {
          console.error("%c" + err.code, "background: red;");
        } else {
          console.warn(err.code);
        }
      }
    };
    fetchData();
  }, []);

  const configureRoleTag = (user) => {
    var role = user.role.toLowerCase();
    var roleColor = "var(--" + role + "-color)";
    return (
      <Tooltip title={t(role)}>
        <Tag color={roleColor}>{t(role).charAt(0)}</Tag>
      </Tooltip>
    );
  };

  return (
    <Card
      size="small"
      className="user-card-shadow"
      bordered={false}
      title={
        <Link
          to={{
            pathname: "/users/details",
            id: props.user.id,
          }}
        >
          <Tooltip
            title={
              props.user.name && props.user.name.trim() !== ""
                ? `${props.user.name} (${props.user.account})`
                : props.user.account
            }
          >
            <Text strong ellipsis style={{ width: "100%" }}>
              {props.user.name && props.user.name.trim() !== ""
                ? props.user.name
                : props.user.account}
            </Text>
          </Tooltip>
        </Link>
      }
      extra={
        <Space>
          {configureRoleTag(props.user)}
          <Tooltip title={props.user.mail}>
            <a href={`mailto:${props.user.mail}`}>
              <MailTwoTone style={{ color: "#1890ff" }} />
            </a>
          </Tooltip>
        </Space>
      }
    >
      <div className="user-card">
        <Avatar
          className="user-card-photo"
          src={photo ? photo : "/img/default-image-avatar.png"}
          size={84}
          icon={<UserOutlined />}
          style={{ justifySelf: "center" }}
        />
        <Space>
          <LaptopOutlined style={{ color: "#1890ff" }} />
          <span style={{ fontSize: "12px" }}>{props.user.deviceCount}</span>
        </Space>
        <Tooltip title={workingAt}>
          <Text
            type="secondary"
            ellipsis
            style={{ width: "100%", fontSize: "12px" }}
          >
            {workingAt}
          </Text>
        </Tooltip>
      </div>
    </Card>
  );
};

UserCard.propsTypes = {
  user: PropTypes.object.isRequired,
};

export default UserCard;
