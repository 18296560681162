import { PublicClientApplication } from "@azure/msal-browser";

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11 || isEdge;
};

export const msalApp = await PublicClientApplication.createPublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    validateAuthority: true,
    redirectUri: process.env.REACT_APP_URL, //make sure we always specify a redirect towards our domain
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: process.env.REACT_APP_URL,
  },
  cache: {
    cacheLocation: "localStorage",
    //By default, MSAL.js uses sessionStorage which does not allow the session to be shared between tabs. To get SSO between tabs, make sure to set the cacheLocation in MSAL.js to localStorage
    storeAuthStateInCookie: true,
  },
});
