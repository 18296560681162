import React, { useContext, useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types"; // ES6
import {
  Card,
  Tooltip,
  Form,
  Skeleton,
  Modal,
  Menu,
  Dropdown,
  Row,
} from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ResearchContext } from "../../contexts/ResearchProvider";
import {
  SyncOutlined,
  WarningOutlined,
  EllipsisOutlined,
  LockOutlined,
  UnlockOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../contexts/AuthProvider";
import { APP_ROLES, API_REQUESTS, ENTITY_TYPE } from "../../utils/utils";
import SimpleMessage from "../simple-components/simple-message/simple-message";
import {
  disableUserAccount,
  softDeleteEntity,
} from "../../../services/ApiService";
import { useHistory } from "react-router-dom";
import AssignUserDevice from "../assign-user-device/assign-user-device";
import AssignUserStudy from "../assign-user-study/assign-user-study";
const { confirm } = Modal;

const ProfileAccount = (props) => {
  const { preferredLanguage } = useContext(ResearchContext);
  const { user, getAccessToken } = useContext(AuthContext);
  const { t } = useTranslation();
  let history = useHistory();
  const [userDetails, setUserDetails] = useState({});
  const [deviceCount, setDeviceCount] = useState(0);
  const [studyCount, setStudyCount] = useState(0);
  const [deviceDrawerVisible, setDeviceDrawerVisible] = useState(false);
  const [studyDrawerVisible, setStudyDrawerVisible] = useState(false);

  const isAdmin = user.role === APP_ROLES.ADMIN;
  const mySelf = userDetails.id === user.id;
  const userIsResearcherOrClinician =
    userDetails.role === APP_ROLES.RESEARCHER ||
    userDetails.role === APP_ROLES.CLINICIAN;

  useEffect(() => {
    if (props.account) {
      setUserDetails(props.account);
      setDeviceCount(props.account.deviceCount);
      setStudyCount(props.account.studyCount);
    }
  }, [props]);

  const onAccountEnabledChanged = async (checked) => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await disableUserAccount(
          {
            userId: userDetails.id,
            enabled: checked,
          },
          token
        );
        if (res.result) {
          setUserDetails({ ...userDetails, enabled: checked });
          SimpleMessage(
            "success",
            checked
              ? t("profile-enabled-success")
              : t("profile-disabled-success")
          );
        } else {
          SimpleMessage(
            "error",
            checked ? t("profile-enabled-error") : t("profile-disabled-error")
          );
        }
      }
    } catch (err) {
      SimpleMessage("error", err.message);
    }
  };

  const deleteUser = async () => {
    //do the delete
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let data = {
          entityId: userDetails.id.toString(),
          entityType: ENTITY_TYPE.BREATHBASE_USER,
        };
        let res = await softDeleteEntity(data, token);
        if (res.success) {
          SimpleMessage("success", t("profile-delete-success"));
          history.push("/users");
        } else {
          SimpleMessage("error", res.error);
        }
      }
    } catch (err) {
      SimpleMessage("error", err.message);
    }
  };

  const onDeleteUser = async () => {
    //show confirm
    confirm({
      okText: t("delete-user-confirm"),
      okButtonProps: { id: "ok-delete-user" },
      cancelText: t("delete-user-cancel"),
      cancelButtonProps: { id: "cancel-delete-user" },
      icon: <WarningOutlined style={{ color: "#ff4d4f" }} />,
      title: t("delete-user-title", {
        user: userDetails.name,
      }),
      content: t("delete-user-content"),
      onOk: deleteUser,
    });
  };

  const onClick = ({ key }) => {
    switch (key) {
      case "enableAccount":
      case "disableAccount":
        onAccountEnabledChanged(!userDetails.enabled);
        break;
      case "deleteAccount":
        onDeleteUser();
        break;
      case "resetPassword":
        window.open(process.env.REACT_APP_PASS_RESET_LINK, "_blank");
        break;
      default:
        break;
    }
  };

  const userAccountOptionsMenu = (
    <Menu
      onClick={onClick}
      style={{ minWidth: "150px" }}
      id="user-menu-actions"
    >
      {!userDetails.enabled && !mySelf && (
        <Menu.Item key="enableAccount" id="enable-user">
          <UnlockOutlined style={{ fontSize: "14px" }} />
          {t("profile-enabled")}
        </Menu.Item>
      )}
      {userDetails.enabled && !mySelf && (
        <Menu.Item key="disableAccount" id="disable-user">
          <LockOutlined style={{ fontSize: "14px" }} />
          {t("profile-disabled")}
        </Menu.Item>
      )}
      {!mySelf && <Menu.Divider />}
      {!mySelf && isAdmin ? (
        <Menu.Item
          id="delete-user"
          key="deleteAccount"
          icon={
            <DeleteOutlined
              style={{
                color: "var(--delete-icon-color)",
                fontSize: "14px",
              }}
            />
          }
        >
          {t("profile-delete")}
        </Menu.Item>
      ) : (
        <Menu.Item
          id="reset-user-password"
          key="resetPassword"
          icon={<SyncOutlined style={{ fontSize: "14px" }} />}
        >
          {t("profile-password-reset")}
        </Menu.Item>
      )}
    </Menu>
  );

  const onManageDevicesClick = (e) => {
    e.preventDefault();
    setDeviceDrawerVisible(true);
  };

  const onCloseDeviceAssignDrawer = (deviceCount) => {
    setDeviceDrawerVisible(false);
    setDeviceCount(deviceCount);
  };

  const onManageStudiesClick = (e) => {
    e.preventDefault();
    setStudyDrawerVisible(true);
  };

  const onCloseStudyAssignDrawer = (studyCount) => {
    setStudyDrawerVisible(false);
    setStudyCount(studyCount);
  };

  return (
    <Card
      size="small"
      title={t("profile-account")}
      className="responsive-card-shadow"
      bordered={false}
      extra={
        <Dropdown overlay={userAccountOptionsMenu} trigger={["click"]}>
          <EllipsisOutlined
            rotate={90}
            style={{ color: "black", fontSize: "14px" }}
          />
        </Dropdown>
      }
    >
      <Skeleton active loading={props.loading}>
        <div style={{ display: "grid" }}>
          <Form size="small" layout="horizontal" colon={false}>
            <Form.Item label={t("profile-login")}>
              {userDetails.account}
            </Form.Item>
            <Form.Item label={t("profile-created")}>
              <Tooltip
                placement="top"
                title={moment
                  .utc(userDetails.createdDateTime)
                  .locale(preferredLanguage)
                  .local()
                  .fromNow()}
              >
                {moment
                  .utc(userDetails.createdDateTime)
                  .locale(preferredLanguage)
                  .local()
                  .format("D MMM YYYY")}
              </Tooltip>
            </Form.Item>
            <Form.Item label={t("profile-role")}>
              {userDetails.role && t(userDetails.role.toLowerCase())}
            </Form.Item>
          </Form>
          {isAdmin && userIsResearcherOrClinician && (
            <Fragment>
              <Row>
                <a
                  href="#manage-devices"
                  onClick={onManageDevicesClick}
                  id="manage-devices"
                >
                  {t("profile-manage-devices")} ({deviceCount})
                </a>
              </Row>
              <Row>
                <a
                  href="#manage-studies"
                  onClick={onManageStudiesClick}
                  id="manage-studies"
                >
                  {t("profile-manage-studies")} ({studyCount})
                </a>
              </Row>
              <AssignUserDevice
                user={userDetails}
                visible={deviceDrawerVisible}
                onClose={onCloseDeviceAssignDrawer}
              />
              <AssignUserStudy
                user={userDetails}
                visible={studyDrawerVisible}
                onClose={onCloseStudyAssignDrawer}
              />
            </Fragment>
          )}
        </div>
      </Skeleton>
    </Card>
  );
};

ProfileAccount.propsTypes = {
  account: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ProfileAccount;
