import { VALIDATIONS } from "../../utils/utils";

export const requiredRules = (t) => {
  return [
    {
      required: true,
      whitespace: true,
      message: t("validation:profile-details-validation-required"),
    },
  ];
};

export const requiredNoWhitespaceRules = (t) => {
  return [
    {
      required: true,
      message: t("validation:profile-details-validation-required"),
    },
  ];
};

export const organizationRules = (t) => {
  return [
    {
      required: true,
      message: t("validation:profile-details-validation-required"),
      type: "array",
    },
    {
      max: VALIDATIONS.MAX_STUDY_ORGANIZATIONS_LENGTH,
      type: "array",
      message: t("validation:study-details-organization-validation-max", {
        max: VALIDATIONS.MAX_STUDY_ORGANIZATIONS_LENGTH,
      }),
    },
  ];
};

export const principalInvestigatorRules = (t) => {
  return [
    {
      max: VALIDATIONS.MAX_STUDY_PRINCIPAL_INVESTIGATORS_LENGTH,
      type: "array",
      message: t("validation:study-details-investigator-validation-max", {
        max: VALIDATIONS.MAX_STUDY_PRINCIPAL_INVESTIGATORS_LENGTH,
      }),
    },
  ];
};

export const memberRules = (t) => {
  return [
    {
      max: VALIDATIONS.MAX_STUDY_TEAM_MEMBERS_LENGTH,
      type: "array",
      message: t("validation:study-details-members-validation-max", {
        max: VALIDATIONS.MAX_STUDY_TEAM_MEMBERS_LENGTH,
      }),
    },
  ];
};

export const requiredArrayRules = (t) => {
  return [
    {
      required: true,
      message: t("validation:profile-details-validation-required"),
      type: "array",
    },
    {
      max: VALIDATIONS.MAX_STUDY_FIELD_ARRAY_ITEM_COUNT,
      type: "array",
      message: t("validation:study-details-array-validation-max", {
        max: VALIDATIONS.MAX_STUDY_FIELD_ARRAY_ITEM_COUNT,
      }),
    },
  ];
};

export const commonRules = (t) => {
  return [
    ...requiredRules(t),
    {
      min: VALIDATIONS.MIN_STUDY_FIELD_LENGTH,
      message: t("validation:profile-details-validation-min", {
        min: VALIDATIONS.MIN_STUDY_FIELD_LENGTH,
      }),
    },
    {
      max: VALIDATIONS.MAX_STUDY_FIELD_LENGTH,
      message: t("validation:profile-details-validation-max", {
        max: VALIDATIONS.MAX_STUDY_FIELD_LENGTH,
      }),
    },
  ];
};

export const nameRules = (t) => [
  ...commonRules(t),
  {
    pattern: new RegExp("^[a-z0-9A-Z']*[ -]*[a-z0-9A-Z']*[ -]*[a-z0-9A-Z']*$"),
    message: t("validation:profile-details-validation-name-invalid"),
  },
];
