import React, { useContext, useState } from "react";
import { Menu, Avatar, Space, Typography, Dropdown, Badge } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  GlobalOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../contexts/AuthProvider";
import { ResearchContext } from "../../contexts/ResearchProvider";
import { useTranslation } from "react-i18next";
import { NotificationContext } from "../../contexts/NotificationProvider";
import NotificationsDrawer from "../../components/notifications-drawer/notifications-drawer";
import "./profile-menu.css";
import { Link } from "react-router-dom";
import { getFullLanguageString } from "../../utils/utils";

const { Text } = Typography;
const { SubMenu } = Menu;
const ProfileMenu = () => {
  const { user, photoUrl, logout, isFixedTranslation } = useContext(AuthContext);
  const { preferredLanguage, changeLanguage } = useContext(ResearchContext);
  const [notificationsDrawerVisible, setNotificationsDrawerVisible] =
    useState(false);
  const { notificationList } = useContext(NotificationContext);
  const { t } = useTranslation();

  const onCloseNotificationsDrawer = () => {
    setNotificationsDrawerVisible(false);
  };

  const onClickNotificationsIcon = () => {
    setNotificationsDrawerVisible(true);
  };

  return (
    <>
      <div mode="horizontal" className="profile-menu" id="dropdown-profile">
        <div className="badge-menu">
          <Badge
            count={notificationList.length}
            onClick={onClickNotificationsIcon}
          >
            <BellOutlined style={{ color: "white", fontSize: "18px" }} />
          </Badge>
        </div>
        <Dropdown
          className="profile-menu-item"
          overlay={
            <Menu>
              <Menu.Item key="/account" id="account-menu-item">
                <Link
                  to={{
                    pathname: "/account",
                    id: user?.id,
                  }}
                >
                  <Space>
                    <UserOutlined />
                    {t("account")}
                  </Space>
                </Link>
              </Menu.Item>
              { !isFixedTranslation && (<SubMenu
                key="language"
                icon={<GlobalOutlined />}
                title={
                  getFullLanguageString(t)[preferredLanguage.toUpperCase()] ??
                  getFullLanguageString(t)["EN"]
                }
              >
                <Menu.Item key="en" onClick={() => changeLanguage("en")}>
                  <Space>
                    <img src="/img/en.png" alt="GB" />
                    <span>{t("lang-english")}</span>
                  </Space>
                </Menu.Item>
                <Menu.Item key="nl" onClick={() => changeLanguage("nl")}>
                  <Space>
                    <img src="/img/nl.png" alt="NL" />
                    <span>{t("lang-dutch")}</span>
                  </Space>
                </Menu.Item>
                <Menu.Item key="fr" onClick={() => changeLanguage("fr")}>
                  <Space>
                    <img src="/img/fr.png" alt="FR" />
                    <span>{t("lang-french")}</span>
                  </Space>
                </Menu.Item>
              </SubMenu>
              )}
              <Menu.Item
                key="logout"
                onClick={logout}
                id="logout-menu-item"
                icon={<LogoutOutlined />}
              >
                <span>{t("logout")}</span>
              </Menu.Item>
            </Menu>
          }
        >
          <Space align="center">
            <Text ellipsis={true} style={{ color: "white" }}>
             {user?.name} | <span className="user-role-text">{user?.role}</span>
            </Text>
            {photoUrl ? (
              <Avatar src={photoUrl} alt={user?.name} />
            ) : (
              <Avatar src="/img/default-image-avatar.png" alt={user?.name} />
            )}
          </Space>
        </Dropdown>
      </div>
      <NotificationsDrawer
        visible={notificationsDrawerVisible}
        onClose={onCloseNotificationsDrawer}
      />
    </>
  );
};

export default ProfileMenu;
