import React from "react";
import "./maintenance-page.css"

const MaintenancePage = () => {
    return (
        <div>
            <header>
                <img src="img/logo.png" alt="Breathomix Logo" class="logo" />
            </header>

            <div class="content">
                <img src="img/maintenance-logo.png" alt="BreathBase Maintenance" />
                <h1>Just <span class="accent">breathe</span></h1>
                <h2>while we are improving your BreathBase experience</h2>
            </div>
        </div>
    );
};

export default MaintenancePage;
