import React, { useState, useContext, useEffect, useRef } from "react";
import { HubConnectionState } from "@microsoft/signalr";
import "./devices-page.css";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/fr";
import {
  List,
  Typography,
  Input,
  Skeleton,
  Button,
  Space,
  message,
} from "antd";
import { AuthContext } from "../../contexts/AuthProvider";
import { getDeviceById, getDevices } from "../../../services/ApiService";
import {
  APP_ROLES,
  API_REQUESTS,
  NOTIFICATION_GROUP_TYPES,
  NOTIFICATION_TYPES,
} from "../../utils/utils";
import DeviceCard from "../../components/device-card/device-card";
import EmptyDescription from "../../components/simple-components/empty-description/empty-description";
import { v4 as uuidv4 } from "uuid";
import DeviceDetailsDrawer from "../../components/device-details-drawer/device-details-drawer";
import NoseDetailsDrawer from "../../components/nose-details-drawer/nose-details-drawer";
import { NotificationContext } from "../../contexts/NotificationProvider";
import { PageSettingsContext } from "../../contexts/PageSettingsProvider";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Search } = Input;

const DevicesPage = (props) => {
  const { getAccessToken, user } = useContext(AuthContext);
  const { subscribeTo, unsubscribeFrom, notificationHub, isConnected } =
    useContext(NotificationContext);
  const { t } = useTranslation();
  const [subscribedDeviceIdList, setSubscribedDeviceIdList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [newSubscriptions, setNewSubscriptions] = useState(null);
  const { resetFilters } = useContext(PageSettingsContext);

  const [pageState, setPageState] = useState({
    data: [],
    devicelist: [],
    initLoading: true,
    totalCount: 0,
    startIndex: 0,
    search: null,
    loadingMore: false,
    canLoadMore: false,
  });

  const stateRef = useRef();
  stateRef.current = pageState;

  const selectedDeviceIdRef = useRef();
  selectedDeviceIdRef.current = props.selectedDeviceId;

  const { initLoading, loadingMore } = pageState;

  useEffect(() => {
    resetFilters();
  }, []);

  useEffect(() => {
    if (isConnected && notificationHub) {
      // initial subscribe
      subscribeTo(
        NOTIFICATION_TYPES.DEVICE_CHANGED,
        refreshDeviceDetailsRef.current
      );
      subscribedDeviceIdList.forEach((deviceId) => {
        notificationHub.send(NOTIFICATION_GROUP_TYPES.JOIN_GROUP, deviceId);
      });
    }
    return () => {
      if (isConnected && notificationHub) {
        // leave all the deviceId groups
        subscribedDeviceIdList.forEach((deviceId) => {
          notificationHub.send(NOTIFICATION_GROUP_TYPES.LEAVE_GROUP, deviceId);
        });

        // final unsubscribe
        unsubscribeFrom(
          NOTIFICATION_TYPES.DEVICE_CHANGED,
          refreshDeviceDetailsRef.current
        );
      }
    };
  }, [isConnected, notificationHub]);

  useEffect(() => {
    if (notificationHub && newSubscriptions) {
      initDeviceSubscriptions(newSubscriptions);
    }
  }, [notificationHub, newSubscriptions]);

  useEffect(() => {
    if (initLoading || loadingMore) {
      loadDevices();
    }
  }, [pageState.initLoading, pageState.loadingMore]);

  const loadDevices = async () => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await getDevices(
          token,
          pageState.startIndex,
          itemsPerPage,
          pageState.search
        );
        if (res && res.result && !res.error) {
          setNewSubscriptions(res.result.data);
          if (itemsPerPage != 10) {
            setItemsPerPage(10);
          }
          let data = [];
          if (pageState.loadingMore)
            data = pageState.data.concat(res.result.data);
          else if (pageState.initLoading) {
            data = res.result.data;
          }
          setPageState({
            ...pageState,
            data,
            devicelist: data,
            initLoading: false,
            loadingMore: false,
            totalCount: res.result.total,
            startIndex: data.length,
            canLoadMore: res.result.data.length >= itemsPerPage,
          });
        } else {
          setPageState({
            ...pageState,
            initLoading: false,
            loadingMore: false,
            data: [],
            devicelist: [],
          });
          message.error(t("load-devices-error"));
        }
      }
    } catch (err) {
      message.error(t("load-devices-error"));
    }
  };

  const initDeviceSubscriptions = (newDeviceList) => {
    // extract only the device ids which are not in subscribedDeviceIdList
    const newDeviceIdList = newDeviceList.map((it) => it.deviceId);

    newDeviceIdList.forEach((deviceId) => {
      if (!subscribedDeviceIdList.includes(deviceId)) {
        // join newer deviceId group
        notificationHub.send(NOTIFICATION_GROUP_TYPES.JOIN_GROUP, deviceId);
      }
    });

    subscribedDeviceIdList.forEach((deviceId) => {
      if (!newDeviceIdList.includes(deviceId)) {
        // leave older deviceId group that are not in newDeviceIdList
        notificationHub.send(NOTIFICATION_GROUP_TYPES.LEAVE_GROUP, deviceId);
      }
    });

    // add newDeviceIdList items to subscribedDeviceIdList
    setSubscribedDeviceIdList(newDeviceIdList);
  };

  // For every rerender the refreshDeviceDetails function will be a different instance.
  // Since the HubConnection 'off' function needs the exact handler instance that
  // was used in 'on' function, one instance will be assigned to a ref
  // and used for every subscribe and unsubscribe in order make it work properly.
  const refreshDeviceDetailsRef = useRef(async (deviceId) => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        // will fetch only the devices that are available on the page
        var deviceIndex = stateRef.current.devicelist.findIndex(
          (it) => it.deviceId === deviceId
        );
        if (deviceIndex !== -1) {
          const newDevice = await getDeviceById(deviceId, token);
          if (newDevice) {
            // insert newDevice into devicelist
            stateRef.current.devicelist[deviceIndex] = newDevice;
            const newDeviceList = stateRef.current.devicelist;

            // set the currentDevice used for device drawer information
            const newCurrentDevice =
              stateRef.current.currentDevice?.deviceId === deviceId
                ? newDevice
                : stateRef.current.currentDevice;

            // set the state with the new updates
            setPageState({
              ...stateRef.current,
              data: newDeviceList,
              devicelist: newDeviceList,
              currentDevice: newCurrentDevice,
            });

            // change the selected device used for select-device-new-measurement-step component
            if (selectedDeviceIdRef.current === deviceId) {
              props.selectDevice(newDevice);
            }
          }
        }
      }
    } catch (ex) {
      message.error(t(ex.error ?? "error"));
    }
  });

  const onLoadMore = async () => {
    setPageState({
      ...pageState,
      loadingMore: true,
      initLoading: false,
      devicelist: pageState.data.concat(
        [...new Array(itemsPerPage)].map(() => ({
          loading: true,
          id: uuidv4(),
        }))
      ),
    });
  };

  const onSearchClick = async (value) => {
    setPageState({
      ...pageState,
      search: value,
      startIndex: 0,
      initLoading: true,
      loadingMore: false,
    });
  };

  const openDrawerSpiroNose = (noseIdText) => {
    setPageState({
      ...pageState,
      drawerVisibleNose: true,
      currentNoseId: noseIdText,
    });
  };

  const closeDrawerSpiroNose = () => {
    setPageState({
      ...pageState,
      drawerVisibleNose: false,
    });
  };

  const openDrawer = (device) => {
    setPageState({
      ...pageState,
      drawerVisible: true,
      currentDevice: device,
    });
  };

  const closeDrawer = (device) => {
    if (device) {
      let newdevicelist = pageState.data.map((item) => {
        if (item.deviceId === device.deviceId) {
          item.environment = device.environment;
          if (device.availableFromDate) {
            //close aprox to server update time
            item.dateModified = moment.utc();
            item.availableFrom = `${device.availableFromDate
              .utc()
              .format("YYYY-MM-DD")} ${device.availableFromTime
              .utc()
              .format("HH:mm:ss")}`;
          }
          item.location = device.location;
          item.sessionTimeout = device.sessionTimeout;
          item.deviceMode = device.deviceMode;
        }
        return item;
      });
      setPageState({
        ...pageState,
        data: newdevicelist,
        devicelist: newdevicelist,
        drawerVisible: false,
      });
    } else {
      setPageState({
        ...pageState,
        drawerVisible: false,
      });
    }
  };

  const onSelectDevice = (device) => {
    props.selectDevice(device);
  };

  const listItem = (item) => {
    return (
      <List.Item key={item.deviceId} style={{ textAlign: "center" }}>
        <Skeleton loading={item.loading} active>
          <Space direction="vertical">
            {props.onNewMeasurementPage ? (
              <DeviceCard
                device={item}
                openDrawer={openDrawer}
                openDrawerSpiroNose={openDrawerSpiroNose}
                onSelectDevice={onSelectDevice}
                isSelectedDevice={
                  props.selectedDeviceId === item.deviceId ? true : false
                }
              />
            ) : (
              <DeviceCard
                device={item}
                openDrawer={openDrawer}
                openDrawerSpiroNose={openDrawerSpiroNose}
              />
            )}
          </Space>
        </Skeleton>
      </List.Item>
    );
  };
  const { devicelist, canLoadMore, totalCount } = pageState;
  const isAdmin = user.role === APP_ROLES.ADMIN;

  const loadMore =
    !initLoading && !loadingMore && canLoadMore ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore}>{t("load-more")}</Button>
      </div>
    ) : null;

  return (
    <div className="devices-grid">
      <Title level={4} style={{ margin: "0px" }}>
        {isAdmin ? t("devices") : t("my-devices")} ({totalCount})
      </Title>
      <Search
        loading={initLoading}
        placeholder={t("search-devices")}
        onSearch={onSearchClick}
        allowClear
        enterButton
      />
      <Skeleton loading={initLoading} active rows={10}>
        <List
          locale={{
            emptyText: (
              <EmptyDescription
                text={t("no-devices")}
                symbol="😥"
                label="DISAPPOINTED BUT RELIEVED FACE"
              />
            ),
          }}
          rowKey="deviceId"
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 5,
            xxl: 7,
          }}
          loadMore={loadMore}
          dataSource={devicelist}
          renderItem={(item) => listItem(item)}
        />
      </Skeleton>
      <DeviceDetailsDrawer
        device={pageState.currentDevice}
        visible={pageState.drawerVisible}
        onClose={closeDrawer}
      />
      <NoseDetailsDrawer
        noseId={pageState.currentNoseId}
        visible={pageState.drawerVisibleNose}
        onClose={closeDrawerSpiroNose}
      />
    </div>
  );
};

export default DevicesPage;
