import React, { useContext, useState, useEffect } from "react";
import { Typography, Table, Space, Row, Button } from "antd";
import {
  API_REQUESTS,
  CHART_TYPES,
  INNER_SENSORS,
  OUTER_SENSORS,
} from "../../utils/utils";
import { AuthContext } from "../../contexts/AuthProvider";
import { useTranslation } from "react-i18next";
import {
  getCalibrationMeasurements,
  getCalibrations,
  updateCalibration,
} from "../../../services/ApiService";
import EmptyDescription from "../../components/simple-components/empty-description/empty-description";
import SimpleMessage from "../../components/simple-components/simple-message/simple-message";
import { getCalibrationColumns } from "./calibrations-columns";
import CalibrationsChart from "./calibrations-chart";
import "./calibrations-page.css";
import CalibrationFilterPopover from "../../components/calibration-filter-popover/calibration-filter-popover";
const { Title, Text } = Typography;

const CalibrationsPage = (props) => {
  const { getAccessToken } = useContext(AuthContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [defaultCalibration, setDefaultCalibration] = useState(null);
  const [dataSourceChart, setDataSourceChart] = useState(null);
  const [dataSourceTable, setDataSourceTable] = useState([]);
  const [selectedFactors, setSelectedFactors] = useState(null);
  const [removedSeries, setRemovedSeries] = useState([]);
  const [calibrationChartData, setCalibrationChartData] = useState([]);
  const [verificationChartData, setVerificationChartData] = useState([]);
  const sensorList = INNER_SENSORS.concat(OUTER_SENSORS);
  const noseId = props.match.params.id;

  useEffect(() => {
    fetchCalibrations();
  }, []);

  useEffect(() => {
    if (selectedFactors) setDataForTable(selectedFactors);
  }, [selectedFactors]);

  useEffect(() => {
    if (defaultCalibration) setDataForTable();
  }, [defaultCalibration]);

  useEffect(() => {
    if (dataSourceChart != null) setChartSeries(dataSourceChart);
  }, [dataSourceChart]);

  const fetchCalibrations = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await getCalibrations(token, noseId);
        if (res && res.result && !res.error) {
          var calibrations = await mapCalibrationsWithUser(res.result.data);
          setDataSourceChart(calibrations);
          getDefaultCalibration(calibrations);
        } else {
          SimpleMessage("error", t("measurement-calibration-error"));
        }
      }
    } catch (err) {
      SimpleMessage("error", t("measurement-calibration-error"));
    }
    setLoading(false);
  };

  const fetchCalibrationMeasurements = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await getCalibrationMeasurements(token, noseId);
        if (res && res.result && !res.error) {
          return res.result.data;
        } else {
          SimpleMessage("error", t("measurement-calibration-error"));
        }
      }
    } catch (err) {
      SimpleMessage("error", t("measurement-calibration-error"));
    }
    setLoading(false);
  };

  const mapCalibrationsWithUser = async (calibrations) => {
    var measurements = await fetchCalibrationMeasurements();
    calibrations.forEach((c) => {
      let measurement = measurements.find((m) => m.id == c.measurementId);
      c.createdBy = measurement.performedBy;
      c.isVerification = measurement.isVerification;
    });
    return calibrations;
  };

  const setChartSeries = (data) => {
    data.sort(function (a, b) {
      return (
        new Date(a.dateCreated).getTime() > new Date(b.dateCreated).getTime()
      );
    }); // If the data is not sorted chronologically the chart will not render correctly
    var calibrationData = [];
    var verificationData = [];
    var chartDataPoint;
    data.forEach((record) => {
      chartDataPoint = {};
      var factors = JSON.parse(record.factors);
      chartDataPoint.date = new Date(record.dateCreated);
      factors.forEach((sensorObj) => {
        chartDataPoint[sensorObj.sensor] = sensorObj.value;
      });
      record.isVerification
        ? verificationData.push(chartDataPoint)
        : calibrationData.push(chartDataPoint);
    });
    setVerificationChartData(verificationData);
    setCalibrationChartData(calibrationData);
  };

  const setDataForTable = (selectedCalibration = null) => {
    var factorsDefault = defaultCalibration?.factors
      ? JSON.parse(defaultCalibration.factors)
      : [];
    var factorsSelected = selectedCalibration
      ? JSON.parse(selectedCalibration.factors)
      : [];
    var dataSource = [];
    var factorsDelta = {};
    var extractedSensorValuesDefault = {};
    var extractedSensorValuesSelected = {};
    factorsDefault.forEach((sensorObj) => {
      extractedSensorValuesDefault[sensorObj.sensor] = sensorObj.value;
    });
    factorsDefault = extractedSensorValuesDefault;
    factorsSelected.forEach((sensorObj) => {
      extractedSensorValuesSelected[sensorObj.sensor] = sensorObj.value;
    });
    factorsSelected = extractedSensorValuesSelected;
    if (selectedCalibration) {
      calculateDelta(factorsDefault, factorsSelected, factorsDelta);
    }

    INNER_SENSORS.forEach((sensor) =>
      dataSource.push({
        sensor: sensor,
        default: factorsDefault[sensor],
        selected: factorsSelected[sensor],
        delta: factorsDelta[sensor],
      })
    );
    OUTER_SENSORS.forEach((sensor) =>
      dataSource.push({
        sensor: sensor,
        default: factorsDefault[sensor],
        selected: factorsSelected[sensor],
        delta: factorsDelta[sensor],
      })
    );
    setDataSourceTable(dataSource);
  };

  const calculateDelta = (factorsDefault, factorsSelected, resultObject) => {
    for (var i = 0; i < 28; i++) {
      resultObject[sensorList[i]] =
        factorsSelected[sensorList[i]] - factorsDefault[sensorList[i]];
    }
  };

  const getDefaultCalibration = (calibrations) => {
    var defaultCalibrationValues = calibrations.filter((obj) => obj.isDefault);
    setDefaultCalibration(defaultCalibrationValues[0]);
  };

  const onSelectFactorsFromChart = (chartFactors) => {
    setSelectedFactors(chartFactors);
  };

  const removeChartSeries = (seriesToRemove) => {
    setRemovedSeries(seriesToRemove);
  };

  const setNewDefaultFactors = async () => {
    var newDefaultFactors = {
      noseId: selectedFactors.noseId,
      newMeasurementId: parseInt(selectedFactors.measurementId),
      currentMeasurementId: parseInt(defaultCalibration.measurementId),
    };
    setLoading(true);
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await updateCalibration(token, newDefaultFactors);
        if (res && res.result && !res.result.error) {
          setDefaultCalibration(selectedFactors);
          SimpleMessage("success", t("measurement-calibration-update-success"));
        } else {
          SimpleMessage("error", t("measurement-calibration-update-error"));
        }
      }
    } catch (err) {
      SimpleMessage("error", t("measurement-calibration-update-error"));
    }
    setLoading(false);
  };
  return (
    <div style={{ textAlign: "center" }} className="calibration-page">
      <Title level={4} style={{ margin: "0px", textAlign: "left" }}>
        <Text>SpiroNose {noseId}</Text>
      </Title>
      <div style={{ gridColumn: 2 }}>
        <Space style={{ float: "right", paddingRight: "5px" }}>
          <CalibrationFilterPopover removeChartSeries={removeChartSeries} />
          <Button type="primary" onClick={setNewDefaultFactors}>
            {t("save")}
          </Button>
        </Space>
      </div>

      <Row style={{ gridRow: 2 }}>
        <CalibrationsChart
          id="calibration"
          onSelectFactorsFromChart={onSelectFactorsFromChart}
          chartData={calibrationChartData}
          removedSeries={removedSeries}
          dataSourceChart={dataSourceChart}
          chartType={CHART_TYPES.CALIBRATION}
        />
      </Row>
      <Row style={{ gridRow: 3 }}>
        <CalibrationsChart
          id="verification"
          onSelectFactorsFromChart={onSelectFactorsFromChart}
          chartData={verificationChartData}
          removedSeries={removedSeries}
          dataSourceChart={dataSourceChart}
          chartType={CHART_TYPES.VERIFICATION}
        />
      </Row>
      <Table
        className="calibration-table"
        locale={{
          emptyText: (
            <EmptyDescription
              text={t("no-measurements")}
              symbol="😥"
              label="DISAPPOINTED BUT RELIEVED FACE"
            />
          ),
        }}
        pagination={false}
        loading={loading}
        rowKey="sensorId"
        columns={getCalibrationColumns(defaultCalibration, selectedFactors)}
        dataSource={dataSourceTable}
        size="small"
        bordered
      />
    </div>
  );
};

export default CalibrationsPage;
