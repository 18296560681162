import React from "react";
import { Col, Row, Button, Alert, Modal } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types"; // ES6

const DeviceDetailsDrawerConfirmationDialog = (props) => {
  const { t } = useTranslation();
  const handleCancel = () => {
    props.onClose();
  };

  const onSave = () => {
    props.onSave();
  }

  return (
    <Modal
      title={t("warning")}
      visible={props.visible}
      onCancel={handleCancel}
      footer={[
        <Button
          type="primary"
          onClick={onSave}
        >
          {t("save")}
        </Button>,
        <Button
          onClick={handleCancel}
        >
          {t("cancel")}
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Alert
            message={t("device-details-prod-warning")}
            type="warning"
            showIcon
          />
        </Col>
      </Row>
    </Modal>
  );
}

DeviceDetailsDrawerConfirmationDialog.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default DeviceDetailsDrawerConfirmationDialog;