import React, { useState, useEffect, useContext } from "react";
import {
  Drawer,
  List,
  Row,
  Input,
  Skeleton,
  AutoComplete,
  Space,
  Typography,
  Tag,
  Button,
  Tooltip,
  Modal,
  Col,
  Switch,
} from "antd";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthProvider";
import StudyDetailsDrawer from "../../components/study-details-drawer/study-details-drawer";
import {
  API_REQUESTS,
  getStudyStatusColor,
  getStudyStatusString,
  STUDY_ROLES,
} from "../../utils/utils";
import { getStudies, assignUserStudies } from "../../../services/ApiService";
import { MinusCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import EmptyDescription from "../simple-components/empty-description/empty-description";
import SimpleMessage from "../simple-components/simple-message/simple-message";
const { confirm } = Modal;
const { Text } = Typography;

const AssignUserStudy = (props) => {
  const { getAccessToken } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [studyCount, setStudyCount] = useState(0);

  const [foundStudies, setFoundStudies] = useState([]);
  const [options, setOptions] = useState([]);
  const [assignedStudies, setAssignedStudies] = useState([]);
  const [fetchingAssignedStudies, setFetchingAssignedStudies] = useState(false);
  const [studySearchTerm, setStudySearchTerm] = useState(null);
  const [studiesAssignedInBeginning, setStudiesAssignedInBeginning] =
    useState(null);

  const [selectedStudy, setSelectedStudy] = useState(null);
  const [studyDrawerVisible, setStudyDrawerVisible] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setVisible(props.visible);
    setFoundStudies([]);
    setOptions([]);
    setStudySearchTerm(null);
    fetchAssignedStudies(props.user.id);
  }, [props]);

  const fetchAssignedStudies = async (userId) => {
    try {
      setFetchingAssignedStudies(true);
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await getStudies(token, 0, 100, null, userId);
        if (res && res.result && !res.error) {
          setAssignedStudies(res.result.data);
          setStudiesAssignedInBeginning(res.result.data);
          setStudyCount(res.result.data.length);
        }
      }
    } catch (err) {
      console.error(err);
    }
    setFetchingAssignedStudies(false);
  };

  const getOptions = (studies) => {
    return studies.map((study) => {
      return {
        label: (
          <Space>
            <Tag color={getStudyStatusColor(study.status)}>
              <Text ellipsis style={{ maxWidth: "200px", color: "white" }}>
                {getStudyStatusString(study.status, t)}
              </Text>
            </Tag>
            <span>{study.name}</span>
          </Space>
        ),
        value: study.id,
      };
    });
  };

  useEffect(() => {
    const fetchStudies = async () => {
      if (studySearchTerm && studySearchTerm.length > 2) {
        try {
          const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
          if (token) {
            let res = await getStudies(token, 0, 100, studySearchTerm);
            if (res && res.result && !res.error) {
              let filteredStudies = res.result.data.filter(
                (p) => !assignedStudies.find((s) => s.id === p.id)
              );
              if (filteredStudies.length > 0) {
                setFoundStudies(filteredStudies);
                setOptions(getOptions(filteredStudies));
              }
            }
          }
        } catch (err) {
          console.error(err);
        }
      } else {
        setFoundStudies([]);
      }
    };
    const timer = setTimeout(() => {
      fetchStudies();
    }, 800);
    return () => clearTimeout(timer);
  }, [studySearchTerm]);

  const closeDrawer = () => {
    if (dirty) {
      //show confirm
      confirm({
        cancelText: t("profile-assign-studies-cancel"),
        icon: (
          <QuestionCircleOutlined
            style={{ color: "var(--question-icon-color)" }}
          />
        ),
        title: t("profile-assign-studies-leave-title"),
        content: t("profile-assign-studies-leave-content"),
        onOk: () => clearAndClose(),
      });
    } else {
      clearAndClose();
    }
  };

  const clearAndClose = (newCount) => {
    setDirty(false);
    setFoundStudies([]);
    setOptions([]);
    setStudySearchTerm(null);
    props.onClose(newCount ? newCount : studyCount);
  };

  const onSaveClick = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let studies = assignedStudies.map((s) => {
          return {
            studyId: parseInt(s.id),
            role: Number(getRole(s)),
          };
        });
        let res = await assignUserStudies(
          {
            studies,
          },
          props.user.id,
          token
        );
        if (res && res.result) {
          clearAndClose(assignedStudies.length);
          SimpleMessage(
            "success",
            studiesAssignedInBeginning.length > assignedStudies.length
              ? t("profile-removed-studies-success", {
                count:
                  studiesAssignedInBeginning.length - assignedStudies.length,
                user: props.user.name,
              })
              : t("profile-assign-studies-success", {
                count: assignedStudies.length,
                user: props.user.name,
              })
          );
        } else {
          SimpleMessage(
            "error",
            t("profile-assign-studies-error", {
              user: props.user.name,
            })
          );
        }
      }
    } catch (err) {
      SimpleMessage("error", err.message);
    }
    setLoading(false);
  };

  const handleSearchStudies = (value) => {
    setStudySearchTerm(value);
  };

  const onSelectStudy = (value) => {
    let found = foundStudies.find((s) => s.id === value);
    found.team.push({
      userId: props.user.id,
      role: STUDY_ROLES.MEMBER,
    });
    setAssignedStudies([...assignedStudies, found]);
    setStudySearchTerm(null);
    setFoundStudies([]);
    setOptions([]);
    setDirty(true);
  };

  const removeStudy = (studyId) => {
    setAssignedStudies(assignedStudies.filter((it) => it.id !== studyId));
    setDirty(true);
  };

  const onRoleChanged = (v, item) => {
    let changedStudy = assignedStudies.find((it) => it.id === item.id);
    changedStudy.team
      .filter((obj) => {
        return obj.userId === props.user.id;
      })
      .shift().role = v
        ? STUDY_ROLES.PRINCIPAL_INVESTIGATOR
        : STUDY_ROLES.MEMBER;
    setAssignedStudies([...assignedStudies]);
    setDirty(true);
  };

  const openStudyDrawer = (study) => {
    console.log("Open Study Drawer");
    setSelectedStudy(study);
    setStudyDrawerVisible(true);
  };
  const closeStudyDrawer = () => {
    setStudyDrawerVisible(false);
  };

  const getRole = (item) => {
    // When the study doesn't have yet members
    return (
      item.team
        .filter((obj) => {
          return obj.userId === props.user.id;
        })
        .shift().role === STUDY_ROLES.PRINCIPAL_INVESTIGATOR
    );
  };

  return (
    <Drawer
      title={t("profile-assign-studies")}
      width={450}
      onClose={closeDrawer}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            id="cancel-assign-study"
            onClick={closeDrawer}
            style={{ marginRight: 8 }}
          >
            {t("profile-assign-studies-cancel")}
          </Button>
          <Button
            id="save-assign-study"
            disabled={!dirty}
            onClick={onSaveClick}
            type="primary"
          >
            {t("profile-assign-studies-confirm")}
          </Button>
        </div>
      }
    >
      <Skeleton loading={loading} active paragraph={{ rows: 5 }}>
        <Row>
          <AutoComplete
            id="search-study"
            dropdownMatchSelectWidth={250}
            style={{ width: "100%" }}
            onSelect={onSelectStudy}
            onSearch={handleSearchStudies}
            options={options}
            value={studySearchTerm}
          >
            <Input.Search
              placeholder={t("profile-assign-studies-search-placeholder")}
              enterButton
            />
          </AutoComplete>
        </Row>
        <Row>
          <Skeleton loading={fetchingAssignedStudies} active rows={10}>
            <List
              style={{ width: "100%" }}
              locale={{
                emptyText: (
                  <EmptyDescription
                    text={t("no-studies")}
                    symbol="😥"
                    label="DISAPPOINTED BUT RELIEVED FACE"
                  />
                ),
              }}
              rowKey="id"
              dataSource={assignedStudies}
              renderItem={(item) =>
                item && (
                  <List.Item className="study-item">
                    <List.Item.Meta
                      avatar={
                        <div
                          style={{
                            width: "70px",
                            justifyContent: "start",
                            display: "grid",
                          }}
                        >
                          <Tooltip title={getStudyStatusString(item.status, t)}>
                            <Tag color={getStudyStatusColor(item.status)}>
                              <Text
                                ellipsis
                                style={{ maxWidth: "60px", color: "white" }}
                              >
                                {getStudyStatusString(item.status, t)}
                              </Text>
                            </Tag>
                          </Tooltip>
                        </div>
                      }
                      title={
                        <Row>
                          <Col flex="auto">
                            <Button
                              type="link"
                              onClick={() => openStudyDrawer(item)}
                            >
                              {item.name}
                            </Button>
                          </Col>
                          <Col flex="50px">
                            <Switch
                              className="switch-role"
                              onChange={(v) => onRoleChanged(v, item)}
                              checked={getRole(item)}
                              checkedChildren={t(
                                "study-role-principal-investigator"
                              )}
                              unCheckedChildren={t("study-role-member")}
                            />
                          </Col>
                        </Row>
                      }
                    />
                    <Tooltip title={t("profile-assign-studies-remove")}>
                      <MinusCircleOutlined
                        className="remove-study"
                        style={{ margin: "0 8px", fontSize: "20px" }}
                        onClick={() => removeStudy(item.id)}
                      />
                    </Tooltip>
                  </List.Item>
                )
              }
            />
          </Skeleton>
        </Row>
      </Skeleton>
      <StudyDetailsDrawer
        study={selectedStudy}
        visible={studyDrawerVisible}
        onClose={closeStudyDrawer}
      />
    </Drawer>
  );
};

export default AssignUserStudy;
