import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import QuestionItemDropdown from "../question-item-dropdown/question-item-dropdown";
import QuestionItemNumberScale from "../question-item-number-scale/question-item-number-scale";
import QuestionItemText from "../question-item-text/question-item-text";
import QuestionItemDate from "../question-item-date/question-item-date";
import QuestionItemScale from "../question-item-scale/question-item-scale";
import QuestionItemGroupCheckbox from "../question-item-group-checkbox/question-item-group-checkbox";
import QuestionItemNumber from "../question-item-number/question-item-number";
import QuestionItemSwitch from "../question-item-switch/question-item-switch";
import QuestionItemRating from "../question-item-rating/question-item-rating";
import SmokeCalculator from "../smoke-calculator/smoke-calculator";
import QuestionItemCheckbox from "../question-item-checkbox/question-item-checkbox";
import QuestionItemMedication from "../question-item-medication/question-item-medication";
import QuestionItemLabel from "../question-item-label/question-item-label";

const QuestionItem = (props) => {
  const [question, setQuestion] = useState({});
  const [expressionValue, setExpressionValue] = useState(null);

  useEffect(() => {
    if (props) {
      setQuestion(props.question);
    }
  }, [props]);

  useEffect(() => {
    if (expressionValue) {
      props.formInstance.setFieldsValue({ [question.id]: expressionValue });
    }
  }, [expressionValue]);

  const shouldUpdate = (prevValues, curValues) => {
    if (question.dependency && prevValues) {
      return question.dependency.some((dep) => {
        if (
          typeof prevValues[dep] === "boolean" ||
          typeof curValues[dep] === "boolean"
        ) {
          return prevValues[dep] !== curValues[dep];
        }
        let modified =
          prevValues[dep] &&
          curValues[dep] &&
          (prevValues[dep] !== curValues[dep] ||
            prevValues[question.id] !== curValues[question.id]);
        let added = !prevValues[dep] && curValues[dep];
        return modified || added;
      });
    }
    return prevValues && prevValues[question.id] !== curValues[question.id];
  };

  const getQuestionType = () => {
    switch (question.type) {
      case "rating":
        return (
          <QuestionItemRating
            question={question}
            questionValue={props.questionValue}
          />
        );
      case "switch":
        return (
          <QuestionItemSwitch
            question={question}
            questionValue={props.questionValue}
          />
        );
      case "number":
        return (
          <QuestionItemNumber
            question={question}
            formInstance={props.formInstance}
          />
        );
      case "groupCheckbox":
        return <QuestionItemGroupCheckbox question={question} />;
      case "scale":
        return <QuestionItemScale question={question} />;
      case "date":
        return <QuestionItemDate question={question} />;
      case "dropdown":
        return <QuestionItemDropdown question={question} />;
      case "checkbox":
        return (
          <QuestionItemCheckbox
            question={question}
            formInstance={props.formInstance}
          />
        );
      case "medication":
        return (
          <QuestionItemMedication
            question={question}
            formInstance={props.formInstance}
          />
        );
      case "numberscale":
        return (
          <QuestionItemNumberScale
            question={question}
            questionValue={props.questionValue}
          />
        );
      case "text":
        return <QuestionItemText question={question} />;
      case "list":
        return (
          <SmokeCalculator
            question={question}
            formInstance={props.formInstance}
            onSurveyDataChanged={props.onSurveyDataChanged}
          />
        );
      case "label":
        return <QuestionItemLabel question={question} />;
      default:
        return null;
    }
  };

  const computeExpressionValue = (getFieldValue) => {
    let firstValue = question.value;
    if (question.expression) {
      //compute new value based on expression
      var expressionToEvaluate = question.expression;
      question.dependency.forEach((dep) => {
        expressionToEvaluate = expressionToEvaluate.replaceAll(
          `{${dep}}`,
          getFieldValue(dep)
        );
      });

      let canUpdateValue = false;

      if (question.assign && props.questionValue === undefined) {
        firstValue =
          expressionToEvaluate !== "undefined" ? expressionToEvaluate : null;
        canUpdateValue = true;
      } else {
        try {
          firstValue = eval(expressionToEvaluate).toFixed(2);
          canUpdateValue = firstValue && !isNaN(firstValue);
        } catch (err) {
          canUpdateValue = false;
        }
      }
      if (canUpdateValue) {
        setExpressionValue(firstValue);
      }
    }
  };

  const isItemVisible = (getFieldValue) => {
    let visible = true;
    if (question.dependency) {
      if (question.visibleIf) {
        let visibleIfExpression = question.visibleIf;
        question.dependency.forEach((dep) => {
          let depValue = getFieldValue(dep);
          if (Array.isArray(depValue)) {
            let mappedArray = depValue.map((el) =>
              visibleIfExpression.replaceAll(`{${dep}}`, el)
            );
            visibleIfExpression = mappedArray.join("||");
          } else {
            visibleIfExpression = visibleIfExpression.replaceAll(
              `{${dep}}`,
              depValue
            );
          }
        });
        try {
          visible = eval(visibleIfExpression);
        } catch (error) {
          visible = false;
        }
      }
    }
    return visible;
  };

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate}>
      {({ getFieldValue }) => {
        if (isItemVisible(getFieldValue)) {
          computeExpressionValue(getFieldValue);
          return getQuestionType();
        }
        return null;
      }}
    </Form.Item>
  );
};

QuestionItem.propsTypes = {
  question: PropTypes.object.isRequired,
  formInstance: PropTypes.object.isRequired,
};

export default QuestionItem;
