import React, { useEffect, useContext, useState } from "react";
import { List, Typography, Input, Skeleton, Button, Space } from "antd";
import "./users-page.css";
import i18n from "../../../i18n";
import { getUsers } from "../../../services/ApiService";
import { AuthContext } from "../../contexts/AuthProvider";
import { API_REQUESTS } from "../../utils/utils";
import UserCard from "../../components/user-card/user-card";
import { UserAddOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import SimpleMessage from "../../components/simple-components/simple-message/simple-message";
import { v4 as uuidv4 } from "uuid";
import EmptyDescription from "../../components/simple-components/empty-description/empty-description";
import CreateUser from "../../components/create-user/create-user";
import { PageSettingsContext } from "../../contexts/PageSettingsProvider";
import BulkImportUsersDrawer from "../../components/bulk-import-users-drawer/bulk-import-users-drawer";
import { useHistory } from "react-router-dom";
const { Title } = Typography;
const { Search } = Input;

const UsersPage = () => {
  const history = useHistory();
  const accessedViaBackAction = history.action == "POP";
  const { getAccessToken } = useContext(AuthContext);
  const { usersFilterState, setUsersFilterState, resetFilters } = useContext(
    PageSettingsContext
  );
  const [pageState, setPageState] = useState({
    data: [],
    userlist: [],
    initLoading: true,
    totalCount: 0,
    startIndex: 0,
    search: accessedViaBackAction ? usersFilterState.searchTerm : null,
    loadingMore: false,
    canLoadMore: false,
  });
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [importUsersVisible, setImportUsersVisible] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(
    accessedViaBackAction ? usersFilterState.dataLength : 10
  );

  const {
    initLoading,
    loadingMore,
    userlist,
    canLoadMore,
    totalCount,
  } = pageState;

  useEffect(() => {
    if (!accessedViaBackAction) {
      resetFilters();
    }
  }, []);

  const loadUsers = async () => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await getUsers(
          token,
          pageState.startIndex,
          itemsPerPage,
          pageState.search
        );
        if (res && res.data && !res.error) {
          if (itemsPerPage != 10) {
            setItemsPerPage(10);
          }
          let data = [];
          if (pageState.loadingMore) data = pageState.data.concat(res.data);
          else if (pageState.initLoading) {
            data = res.data;
          }
          setPageState({
            ...pageState,
            data,
            userlist: data,
            initLoading: false,
            loadingMore: false,
            totalCount: res.total,
            startIndex: data.length,
            canLoadMore: data.length < res.total,
          });
          setUsersFilterState({ ...usersFilterState, dataLength: data.length });
        } else {
          setPageState({
            ...pageState,
            initLoading: false,
            loadingMore: false,
            data: [],
            userlist: [],
          });
          setUsersFilterState({ ...usersFilterState, dataLength: 10 });
          SimpleMessage("error", i18n.t("load-users-error"));
        }
      }
    } catch (err) {
      SimpleMessage("error", i18n.t("load-users-error"));
    }
    return null;
  };

  useEffect(() => {
    if (initLoading || loadingMore) {
      loadUsers();
    }
  }, [pageState.initLoading, pageState.loadingMore]);

  const onLoadMore = async () => {
    setPageState({
      ...pageState,
      loadingMore: true,
      initLoading: false,
      userlist: pageState.data.concat(
        [...new Array(itemsPerPage)].map(() => ({
          loading: true,
          id: uuidv4(),
        }))
      ),
    });
  };

  const onAddUserClick = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const onImportUsersClick = () => {
    setImportUsersVisible(true);
  };

  const onImportUsersClose = () => {
    setImportUsersVisible(false);
  };

  const onSearchClick = async (value) => {
    setUsersFilterState({ ...usersFilterState, searchTerm: value });
    setPageState({
      ...pageState,
      search: value,
      startIndex: 0,
      initLoading: true,
      loadingMore: false,
    });
  };

  const loadMore =
    !initLoading && !loadingMore && canLoadMore ? (
      <div
        id="load-more-users"
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore}>{i18n.t("load-more")}</Button>
      </div>
    ) : null;

  return (
    <div className="users-grid">
      <Title level={4} style={{ margin: "0px" }}>
        {i18n.t("users")} ({totalCount})
      </Title>
      <Space>
        <Button
          id="add-more-users"
          style={{ justifySelf: "start" }}
          type="primary"
          icon={<UserAddOutlined />}
          onClick={onAddUserClick}
        >
          {i18n.t("add-user")}
        </Button>
        <Button
          id="bulk-add-users"
          style={{ justifySelf: "start" }}
          type="primary"
          icon={<UsergroupAddOutlined />}
          onClick={onImportUsersClick}
        >
          {i18n.t("bulk-import-users-title")}
        </Button>
      </Space>
      <Search
        defaultValue={pageState.search}
        loading={initLoading}
        placeholder={i18n.t("search-users")}
        onSearch={onSearchClick}
        allowClear
        enterButton
      />
      <Skeleton loading={initLoading} active rows={10}>
        <List
          locale={{
            emptyText: (
              <EmptyDescription
                text={i18n.t("no-users")}
                symbol="😥"
                label="DISAPPOINTED BUT RELIEVED FACE"
              />
            ),
          }}
          rowKey="id"
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 5,
            xxl: 7,
          }}
          loadMore={loadMore}
          dataSource={userlist}
          renderItem={(item) => (
            <List.Item>
              <Skeleton loading={item.loading} active>
                <UserCard user={item} />
              </Skeleton>
            </List.Item>
          )}
        />
      </Skeleton>
      <CreateUser visible={drawerVisible} onClose={onClose} />
      <BulkImportUsersDrawer
        visible={importUsersVisible}
        onClose={onImportUsersClose}
      />
    </div>
  );
};

export default UsersPage;
