import React, { useEffect, useContext, useState } from "react";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/fr";
import SurveyQuestionnaire from "../../../survey/survey-questionnaire/survey-questionnaire";
import SurveyJSQuestionnaire from "../../../survey/survey-questionnaire/surveyjs-questionnaire";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Typography, Button } from "antd";
import "./survey-measurement-step.css";
import { useTranslation } from "react-i18next";
import { API_REQUESTS } from "../../../utils/utils";
import SimpleMessage from "../../simple-components/simple-message/simple-message";
import { AuthContext } from "../../../contexts/AuthProvider";
import {
  saveSurveyData,
  setMeasurementSurveyStatus,
} from "../../../../services/ApiService";
import { NewMeasurementContext } from "../../../contexts/NewMeasurementContext";
import PreloadSurveyModal from "../../../components/preload-survey-modal/preload-survey-modal";
const { Text } = Typography;

const SurveyMeasurementStep = (props) => {
  const { t } = useTranslation();
  const { getAccessToken } = useContext(AuthContext);
  const { next, selectedDevice, currentStudy, measurement, isSurveyJS } =
    useContext(NewMeasurementContext);
  const [surveyTemplateJson, setSurveyTemplateJson] = useState(null);
  const [surveyData, setSurveyData] = useState(null);
  const [saveOrSubmit, setSaveOrSubmit] = useState(false);
  const [showPreloadModal, setShowPreloadModal] = useState(false);
  const [preloadCounter, setPreloadCounter] = useState(0);
  const [visitNoSurveyJS, setVisitNoSurveyJS] = useState("");

  useEffect(() => {
    if (measurement.surveyTemplateJson) {
      var jsonObject = JSON.parse(measurement.surveyTemplateJson);
      setSurveyTemplateJson(jsonObject);
    }
  }, [measurement]);

  useEffect(() => {
    if (measurement.id && selectedDevice?.serialNumber) {
      setSurveyData({
        measurementId: measurement.id,
        deviceId: selectedDevice?.serialNumber,
        startDateTime: moment().format("LLL"),
        studyId: currentStudy.name,
        bmi: 0,
      });
    }
  }, [measurement, selectedDevice, currentStudy]);

  const saveData = async (postData) => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        await saveSurveyData(postData, token);
      } else {
        SimpleMessage("error", t("new-measurement-save-survey-error"));
      }
    } catch {
      SimpleMessage("error", t("new-measurement-save-survey-error"));
    }
  };

  const onSurveyTrySave = async (surveyStatus) => {
    onSaveSurveyData();
    if (surveyStatus) {
      try {
        const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
        if (token) {
          let data = {
            measurementId: measurement.id,
            surveyStatus,
          };
          let res = await setMeasurementSurveyStatus(data, token);
          if (res && !res.error) {
            next();
            SimpleMessage("success", t("survey:survey-status-updated-success"));
          }
        } else {
          SimpleMessage("error", t("survey:survey-error-submit"));
        }
      } catch {
        SimpleMessage("error", t("survey:survey-error-submit"));
      }
    }
  };

  const onSaveSurveyData = async () => {
    let postData = {
      data: JSON.stringify(surveyData),
      measurementId: measurement.id,
      deviceId: selectedDevice.serialNumber,
      noseId: selectedDevice?.noseId,
    };

    await saveData(postData);
  };
  const surveyDataChanged = (newValues) => {
    setSurveyData({ ...surveyData, ...newValues });
  };
  const onSubmit = (target) => {
    setSaveOrSubmit({ buttonClicked: target.currentTarget.id });
    if (isSurveyJS) {
      next();
    }
  };

  const closePreloadModal = (preloadedData) => {
    if (preloadedData) {
      setSurveyData({
        ...preloadedData,
        measurementId: surveyData.measurementId,
        deviceId: surveyData.deviceId,
        startDateTime: surveyData.startDateTime,
        studyId: surveyData.studyId,
        visitNo: isSurveyJS ? visitNoSurveyJS : surveyData.visitNo,
        preloadedMeasurementId: preloadedData.measurementId,
      });
      setPreloadCounter(preloadCounter + 1);
    }
    setShowPreloadModal(false);
  };

  const openPreloadAnswersModal = async () => {
    if (surveyData.visitNo > 1 || visitNoSurveyJS > 1) {
      setShowPreloadModal(true);
    } else {
      SimpleMessage("error", t("survey-visit-number-error"));
    }
  };

  return (
    <div className="container-survey">
      <div className="survey-study-title">
        <Text className="study-title">{currentStudy?.name}</Text>
      </div>
      {surveyTemplateJson && surveyData && (
        <div className="survey-details">
          {isSurveyJS ? (
            <SurveyJSQuestionnaire
              deviceId={selectedDevice.serialNumber}
              noseId={selectedDevice.noseId}
              measurementId={measurement.id}
              surveyTemplateJson={surveyTemplateJson}
              surveyData={surveyData}
              saveOrSubmit={saveOrSubmit}
              fromMeasurementList={false}
              surveyJSSubmit={() => next()}
              setVisitNo={setVisitNoSurveyJS}
            />
          ) : (
            <SurveyQuestionnaire
              surveyTemplateJson={surveyTemplateJson}
              surveyData={surveyData}
              onSurveyDataChanged={surveyDataChanged}
              onSurveyTrySave={onSurveyTrySave}
              saveOrSubmit={saveOrSubmit}
              preloadCounter={preloadCounter}
            />
          )}
        </div>
      )}
      <div className="survey-action">
        <Button
          className="preload-survey-button"
          onClick={openPreloadAnswersModal}
        >
          <CloudUploadOutlined /> {t("survey-preload-answers")}
        </Button>
        <Button
          id="saveButtonStep"
          className="save-step-button"
          onClick={(e) => {
            onSubmit(e);
          }}
        >
          {t("Save")}
        </Button>
        {!isSurveyJS && (
          <Button
            id="submitButtonStep"
            className="submit-button"
            onClick={(e) => {
              onSubmit(e);
            }}
          >
            {t("Submit")}
          </Button>
        )}
      </div>
      <PreloadSurveyModal
        showModal={showPreloadModal}
        closePreloadModal={closePreloadModal}
      />
    </div>
  );
};

export default SurveyMeasurementStep;
