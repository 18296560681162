import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { getMeasurementDashboard } from "../../../services/ApiService";
import i18n from "../../../i18n";
import { API_REQUESTS } from "../../utils/utils";
import { AuthContext } from "../../contexts/AuthProvider";
import { Spin } from "antd";
import "./measurements-chart.css";

class MeasurementsChart extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    this.initChart();
    await this.fetchData();
  }

  componentDidUpdate(){
    this.loadChartData();
  }

  initChart = () => {
    this.chart = am4core.create("measurements-chart", am4charts.PieChart);
    this.chart.startAngle = 160;
    this.chart.endAngle = 380;

    // Let's cut a hole in our Pie chart the size of 40% the radius
    this.chart.innerRadius = am4core.percent(60);

    // Add second series
    var pieSeries = this.chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "measurements";
    pieSeries.dataFields.category = "status";
    pieSeries.slices.template.stroke = new am4core.InterfaceColorSet().getFor(
      "background"
    );
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.states.getKey(
      "hover"
    ).properties.shiftRadius = 0.05;
    pieSeries.slices.template.states.getKey("hover").properties.scale = 1;

    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
  };

  loadChartData = () => {
    // Add data
    this.chart.data = [
      {
        status: i18n.t("empty"),
        measurements: this.state.emptyMeasurementsCount,
      },
      {
        status: i18n.t("in-progress"),
        measurements: this.state.ongoingMeasurementsCount,
      },
      {
        status: i18n.t("completed"),
        measurements: this.state.completedMeasurementsCount,
      },
      {
        status: i18n.t("submitted"),
        measurements: this.state.submittedMeasurementsCount,
      },
    ];
  };

  addChartLabel = () => {
    var label = this.chart.seriesContainer.createChild(am4core.Label);
    label.textAlign = "middle";
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";

    var totalMeasurements = this.state.totalMeasurementsCount;
    label.adapter.add("text", function (text, target) {
      let count = totalMeasurements
        ? totalMeasurements
        : 0;
      return "[font-size:18px]Total[/]\n[bold font-size:30px]" + count + "[/]";
    });
  }
  fetchData = async () => {
    //this access token must be reused until there is a need to get a new one
    const token = await this.context.getAccessToken(
      API_REQUESTS.USER_IMPERSONATION
    );
    if (token) {
      let res = await getMeasurementDashboard(token);
      if (res.result && !this.chart._disposed) {
        this.setState({
          emptyMeasurementsCount: res.result.emptyMeasurementsCount,
          ongoingMeasurementsCount: res.result.ongoingMeasurementsCount,
          submittedMeasurementsCount: res.result.submittedMeasurementsCount,
          completedMeasurementsCount: res.result.completedMeasurementsCount,
          totalMeasurementsCount: res.result.totalMeasurementsCount
        });
        this.addChartLabel();
      }
    }
    this.setState({
      loading: false,
    });
  };

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div className="measurements-chart-container">
        <Spin tip={i18n.t("loading")} spinning={this.state.loading}>
          <div id="measurements-chart"></div>
        </Spin>
      </div>
    );
  }
}

export default MeasurementsChart;
