import { VALIDATIONS } from "../../utils/utils";

export const requiredRules = (t) => {
  return [
    {
      required: true,
      whitespace: true,
      message: t("validation:profile-details-validation-required"),
    },
  ];
};

export const commonRules = (t) => {
  return [
    ...requiredRules(t),
    {
      min: VALIDATIONS.MIN_PROFILE_FIELD_LENGTH,
      message: t("validation:profile-details-validation-min", {
        min: VALIDATIONS.MIN_PROFILE_FIELD_LENGTH,
      }),
    },
    {
      max: VALIDATIONS.MAX_PROFILE_FIELD_LENGTH,
      message: t("validation:profile-details-validation-max", {
        max: VALIDATIONS.MAX_PROFILE_FIELD_LENGTH,
      }),
    },
  ];
};

export const firstNameRules = (t) => [
  ...requiredRules(t),
  {
    min: VALIDATIONS.MIN_PROFILE_FIELD_LENGTH,
    message: t("validation:profile-details-validation-min", {
      min: VALIDATIONS.MIN_PROFILE_FIELD_LENGTH,
    }),
  },
  {
    max: VALIDATIONS.MAX_PROFILE_FIRSTNAME_LENGTH,
    message: t("validation:profile-details-validation-max", {
      max: VALIDATIONS.MAX_PROFILE_FIRSTNAME_LENGTH,
    }),
  },
  {
    pattern: new RegExp("^[A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ0-9']*[ -]*[A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ0-9']*[ -]*[A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ0-9']*$"),
    message: t("validation:profile-details-validation-name-invalid"),
  },
];

export const lastNameRules = (t) => [
  ...requiredRules(t),
  {
    min: VALIDATIONS.MIN_PROFILE_FIELD_LENGTH,
    message: t("validation:profile-details-validation-min", {
      min: VALIDATIONS.MIN_PROFILE_FIELD_LENGTH,
    }),
  },
  {
    max: VALIDATIONS.MAX_PROFILE_LASTNAME_LENGTH,
    message: t("validation:profile-details-validation-max", {
      max: VALIDATIONS.MAX_PROFILE_LASTNAME_LENGTH,
    }),
  },
  {
    pattern: new RegExp("^[A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ0-9']*[ -]*[A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ0-9']*[ -]*[A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ0-9']*$"),
    message: t("validation:profile-details-validation-name-invalid"),
  },
];

export const organizationRules = (t) => {
  return [
    {
      required: true,
      whitespace: true,
      message: t("validation:profile-details-validation-required"),
    },
    {
      min: VALIDATIONS.MIN_ORGANIZATION_INPUT_LENGTH,
      message: t("validation:profile-details-validation-min", {
        min: VALIDATIONS.MIN_ORGANIZATION_INPUT_LENGTH,
      }),
    },
    {
      max: VALIDATIONS.MAX_ORGANIZATION_INPUT_LENGTH,
      message: t("validation:profile-details-validation-max", {
        max: VALIDATIONS.MAX_ORGANIZATION_INPUT_LENGTH,
      }),
    },
  ];
};
export const departmentRules = (t) => [
  ...requiredRules(t),
  {
    min: VALIDATIONS.MIN_PROFILE_FIELD_LENGTH,
    message: t("validation:profile-details-validation-min", {
      min: VALIDATIONS.MIN_PROFILE_FIELD_LENGTH,
    }),
  },
  {
    max: VALIDATIONS.MAX_PROFILE_DEPARTMENT_LENGTH,
    message: t("validation:profile-details-validation-max", {
      max: VALIDATIONS.MAX_PROFILE_DEPARTMENT_LENGTH,
    }),
  },
];
export const accountRules = (t) => [
  ...requiredRules(t),
  {
    min: VALIDATIONS.MIN_PROFILE_FIELD_LENGTH,
    message: t("validation:profile-details-validation-min", {
      min: VALIDATIONS.MIN_PROFILE_FIELD_LENGTH,
    }),
  },
  {
    max: VALIDATIONS.MAX_PROFILE_ACCOUNT_LENGTH,
    message: t("validation:profile-details-validation-max", {
      max: VALIDATIONS.MAX_PROFILE_ACCOUNT_LENGTH,
    }),
  },
  {
    pattern: new RegExp("^[a-z0-9A-Z._\-]*$"),
    message: t("validation:profile-details-validation-account-invalid"),
  },
];
export const emailRules = (t) => [
  ...commonRules(t),
  {
    pattern: new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
    message: t("validation:profile-details-validation-email-invalid"),
  },
];
export const jobRules = (t) => [
  ...requiredRules(t),
  {
    min: VALIDATIONS.MIN_PROFILE_FIELD_LENGTH,
    message: t("validation:profile-details-validation-min", {
      min: VALIDATIONS.MIN_PROFILE_FIELD_LENGTH,
    }),
  },
  {
    max: VALIDATIONS.MAX_PROFILE_JOBTITLE_LENGTH,
    message: t("validation:profile-details-validation-max", {
      max: VALIDATIONS.MAX_PROFILE_JOBTITLE_LENGTH,
    }),
  },
];
export const phoneRules = (t) => [
  {
    required: true,
    whitespace: true,
    message: t("validation:profile-details-validation-required"),
  },
  {
    pattern: new RegExp("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$"),
    message: t("validation:profile-details-validation-phone-invalid"),
  },
  {
    min: VALIDATIONS.MIN_PROFILE_PHONE_LENGTH,
    message: t("validation:profile-details-validation-min", {
      min: VALIDATIONS.MIN_PROFILE_PHONE_LENGTH,
    }),
  },
  {
    max: VALIDATIONS.MAX_PROFILE_PHONE_LENGTH,
    message: t("validation:profile-details-validation-max", {
      max: VALIDATIONS.MAX_PROFILE_PHONE_LENGTH,
    }),
  },
];

export const addressRules = (t) => [...commonRules(t)];
