import i18n from "../../../i18n";
import React from "react";
import { Tooltip, Typography, Space } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
const { Title, Text } = Typography;

const headerTooltip = (measurement) => (
  <Space direction="vertical">
    <Title level={5}>
      <Link to={"/measurements/" + measurement.measurementId}>
        {i18n.t("measurement") + measurement.measurementId}
      </Link>
    </Title>
    <Text style={{ color: "white" }}>
      {i18n.t("measurement-type") +
        ": " +
        (measurement.isVerification
          ? i18n.t("calibration-chart-verification")
          : i18n.t("calibration-chart-calibration"))}
    </Text>
    <Text style={{ color: "white" }}>
      {i18n.t("measurement-created") +
        ": " +
        moment(measurement.dateCreated).format("YYYY-MM-DD")}
    </Text>
    <Text style={{ color: "white" }}>
      {" "}
      {i18n.t("measurement-by") + ": " + measurement.createdBy}
    </Text>
  </Space>
);

export const getCalibrationColumns = (defaultCalibration, selectedFactors) => [
  {
    title: i18n.t("sensors-filter-sensor"),
    dataIndex: "sensor",
    key: "sensor",
  },
  {
    title: defaultCalibration ? (
      <Tooltip title={headerTooltip(defaultCalibration)}>
        {i18n.t("default")}
      </Tooltip>
    ) : (
      i18n.t("default")
    ),
    dataIndex: "default",
    key: "default",
    render: (text, record) =>
      text ? (
        <Tooltip title={Number(text)}>{Number(text).toFixed(4)}</Tooltip>
      ) : (
        "-"
      ),
  },
  {
    title: selectedFactors ? (
      <Tooltip title={headerTooltip(selectedFactors)}>
        {i18n.t("selected")}
      </Tooltip>
    ) : (
      i18n.t("selected")
    ),
    dataIndex: "selected",
    key: "selected",
    render: (text, record) => (
      <div style={{ backgroundColor: "var(--calibration-selected-color)" }}>
        {text ? (
          <Tooltip title={Number(text)}>{Number(text).toFixed(4)}</Tooltip>
        ) : (
          "-"
        )}
      </div>
    ),
  },
  {
    title: i18n.t("delta"),
    dataIndex: "delta",
    key: "delta",
    render: (text, record) =>
      text ? (
        <Tooltip title={Number(text)}>{Number(text).toFixed(4)}</Tooltip>
      ) : (
        "-"
      ),
  },
];
