import React from "react";
import { Badge, Tooltip } from "antd";
import PropTypes from "prop-types";

const SimpleStatistic = (props) => {
  return (
    <Tooltip title={props.title}>
      <Badge
        showZero
        count={props.statisticValue}
        style={{ backgroundColor: props.statisticColor }}
      >
        {props.icon}
      </Badge>
    </Tooltip>
  );
};

SimpleStatistic.propsTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  statisticValue: PropTypes.number.isRequired,
  statisticColor: PropTypes.string.isRequired,
};

export default SimpleStatistic;
