import { Checkbox, Space, Button, Popover, Typography, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  SENSOR_TYPE_CONFIGURATION,
  OUTER_SENSORS,
  INNER_SENSORS,
} from "../../utils/utils";
import { useTranslation } from "react-i18next";
import "./calibration-filter-popover.css";
const { Title, Text } = Typography;

const CalibrationFilterPopover = (props) => {
  const { t } = useTranslation();
  const [filterSelectState, setFilterSelectState] = useState({});
  useEffect(() => {
    var selectState = {};
    OUTER_SENSORS.forEach((sensor) => (selectState[sensor.toString()] = true));
    INNER_SENSORS.forEach((sensor) => (selectState[sensor.toString()] = true));
    setFilterSelectState(selectState);
  }, []);

  useEffect(() => {
    var seriesToRemove = [];
    Object.keys(filterSelectState).forEach((key) => {
      if (!filterSelectState[key]) seriesToRemove.push(key);
    });
    props.removeChartSeries(seriesToRemove);
  }, [filterSelectState]);

  const selectSensorGroup = (selectedSensors) => {
    var selectionState = { ...filterSelectState };
    var collectedSensors = [];

    if (selectedSensors.length === 0) {
      Object.keys(selectionState).forEach((key) => {
        selectionState[key] = false;
      });
    } else {
      // Check if the group contains sensors that already are selected
      // If the sensors are selected, put them in a list
      selectedSensors.forEach((sensor) => {
        if (selectionState[sensor]) {
          collectedSensors.push(sensor);
        }
      });
      // If all the sensors are already selected then we set the entire group on false
      // Otherwise we set the whole group (so the other unselected sensors from the group) on true
      var isSameLength = collectedSensors.length === selectedSensors.length;
      selectedSensors.forEach((sensor) => {
        if (isSameLength) {
          selectionState[sensor] = false;
        } else {
          selectionState[sensor] = true;
        }
      });
    }
    setFilterSelectState(selectionState);
  };

  const onChangeSingleSensor = (checked, text) => {
    var selectState = { ...filterSelectState };
    selectState[text.toString()] = checked;
    setFilterSelectState(selectState);
  };

  const getSensorDoubleCheckbox = (value) => {
    return (
      <>
        <Checkbox
          onChange={(event) =>
            onChangeSingleSensor(event.target.checked, value[0])
          }
          checked={filterSelectState[value[0].toString()]}
        >
          {value[0]}
        </Checkbox>
        <Checkbox
          onChange={(event) =>
            onChangeSingleSensor(event.target.checked, value[1])
          }
          checked={filterSelectState[value[1].toString()]}
        >
          {value[1]}
        </Checkbox>
      </>
    );
  };
  const getCalibrationFilterColumns = () => [
    {
      title: t("sensors-filter-sensor"),
      dataIndex: "sensorType",
      key: "sensorType",
      render: (text, record) => (
        <Button
          type="text"
          onClick={() =>
            selectSensorGroup(
              [...record.inner, ...record.outer].map((x) => x.toString())
            )
          }
        >
          {text}
        </Button>
      ),
    },
    {
      title: (
        <Button type="text" onClick={() => selectSensorGroup(INNER_SENSORS)}>
          {t("sensors-filter-inner")}
        </Button>
      ),
      dataIndex: "inner",
      key: "inner",
      render: (text, record) => getSensorDoubleCheckbox(text),
    },
    {
      title: (
        <Button type="text" onClick={() => selectSensorGroup(OUTER_SENSORS)}>
          {t("sensors-filter-outer")}
        </Button>
      ),
      dataIndex: "outer",
      key: "outer",
      render: (text, record) => getSensorDoubleCheckbox(text),
    },
  ];
  const content = (
    <>
      <div style={{ width: "100%" }}>
        <Title level={3} style={{ display: "inline" }}>
          <Text>{t("sensors-filter-select")}</Text>
        </Title>
        <div style={{ display: "inline", float: "right" }}>
          <Space>
            <Button type="text" onClick={() => selectSensorGroup([])}>
              {t("sensors-filter-none")}
            </Button>{" "}
            /{" "}
            <Button
              type="text"
              onClick={() =>
                selectSensorGroup([...OUTER_SENSORS, ...INNER_SENSORS])
              }
            >
              {t("all")}
            </Button>
          </Space>
        </div>
      </div>

      <Table
        className="filter-table"
        pagination={false}
        rowKey="sensorType"
        columns={getCalibrationFilterColumns()}
        dataSource={SENSOR_TYPE_CONFIGURATION}
        size="small"
      />
    </>
  );

  return (
    <Popover placement="bottomLeft" trigger="click" content={content}>
      <Button>{t("sensors-filter")}</Button>
    </Popover>
  );
};

export default CalibrationFilterPopover;
