import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Drawer, Button, Space, message, Typography, Steps, Upload, Divider } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { bulkImportUsers, downloadImportUsersTemplate } from "../../../services/ApiService";
import { AuthContext } from "../../contexts/AuthProvider";
import { API_REQUESTS, b64toBlob, BULK_IMPORT_MAX_RECORDS } from "../../utils/utils";
import { ResearchContext } from "../../contexts/ResearchProvider";

const { Text } = Typography;
const { Step } = Steps;

const BulkImportUsersDrawer = (props) => {
  const { getAccessToken } = useContext(AuthContext);
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const [result, setResult] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [downloadTemplateLoading, setDownloadTemplateLoading] = useState(false);

  const { preferredLanguage } = useContext(ResearchContext);

  const closeDrawer = () => {
    setFileList([]);
    props.onClose();
  };

  const downloadTemplate = async () => {
    setDownloadTemplateLoading(true);
    setResult(null);
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        await downloadImportUsersTemplate(token);
      }
    } catch (ex) {
      message.error(t(ex));
    }
    setDownloadTemplateLoading(false);
  };

  const downloadReportFile = () => {
    let blob = b64toBlob(result.reportFileData, result.reportFileType);
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = result.reportFilename;
    a.click();
  };

  const beforeUpload = (file) => {
    setResult(null);
    setFileList([file]);
    return false;
  };

  const removeFile = (file) => {
    setFileList([]);
  };

  const handleUpload = async () => {
    setSubmitLoading(true);
    const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
    if (token) {
      try {
        var res = await bulkImportUsers(fileList[0], preferredLanguage, token);
        setResult(res);
      } catch (error) {
        message.error(t(error, { count: BULK_IMPORT_MAX_RECORDS }));
      }
    }
    setSubmitLoading(false);
    setFileList([]);
  };

  return (
    <Drawer
      title={t("bulk-import-users-title")}
      width={450}
      visible={props.visible}
      onClose={closeDrawer}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <Space style={{ marginRight: 8, float: "right" }}>
          <Button
            type="primary"
            id="close-drawer"
            onClick={handleUpload}
            loading={submitLoading}
            disabled={fileList?.length === 0}
          >
            {t("bulk-import-users-submit-button")}
          </Button>
          <Button
            id="close-drawer"
            onClick={closeDrawer}
          >
            {t("bulk-import-users-close-button")}
          </Button>
        </Space>
      }
    >
      <Space direction="vertical">
        <Steps direction="vertical">
          <Step status="process" title={t("bulk-import-users-step", { stepNumber: 1 })} description={<Space direction="vertical">
            <Text>{t("bulk-import-users-download-template")}</Text>
            <Button
              icon={<DownloadOutlined />}
              type={'primary'}
              loading={downloadTemplateLoading}
              onClick={downloadTemplate}
            >
              {t("bulk-import-users-download-button")}
            </Button>
          </Space>} />
          <Step
            status="process"
            title={t("bulk-import-users-step", { stepNumber: 2 })}
            description={t("bulk-import-users-edit")}
          />
          <Step status="process" title={t("bulk-import-users-step", { stepNumber: 3 })} description={<Space direction="vertical">
            <Text>{t("bulk-import-users-upload-file")}</Text>
            <Upload
              beforeUpload={beforeUpload}
              onRemove={removeFile}
              maxCount={1}
              accept=".csv"
              fileList={fileList}
            >
              <Button icon={<UploadOutlined />}>{t("bulk-import-users-upload-button")}</Button>
            </Upload>
          </Space>} />
        </Steps>
        {result && (
          <>
            <Divider></Divider>
            <Text strong>{t("bulk-import-users-report-title")}</Text>
            <ul>
              <li><Text>{t("bulk-import-users-report-total-records", { count: result.totalCount })}</Text></li>
              <li><Text>{t("bulk-import-users-report-records-succeeded", { count: result.succeededCount })}</Text></li>
              <li><Text>{t("bulk-import-users-report-records-invalid", { count: result.invalidCount })}</Text></li>
              <li><Text>{t("bulk-import-users-report-records-already-exist", { count: result.alreadyAddedCount })}</Text></li>
            </ul>
          </>
        )}
        {result && result.totalCount !== result.succeededCount && (
          <>
            <Text>{t("bulk-import-users-download-report")}</Text>
            <Button
              icon={<DownloadOutlined />}
              type={'primary'}
              onClick={downloadReportFile}
            >
              {t("bulk-import-users-download-button")}
            </Button>
          </>
        )}
      </Space>
    </Drawer>
  );
};

BulkImportUsersDrawer.PropsTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default BulkImportUsersDrawer;