import React, { useContext, useEffect, useRef, useState } from "react";
import "./signal-chart.css";
import { getSensorData } from "../../../services/ApiService";
import { message, Spin, Progress } from "antd";
import { AuthContext } from "../../contexts/AuthProvider";
import PropTypes from "prop-types";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import {
  DISPLAY_DATE_FORMAT,
  NOTIFICATION_GROUP_TYPES,
  NOTIFICATION_TYPES,
  SIGNAL_CHART_MODE,
  DEVICE_MODE,
  API_REQUESTS,
  BUFFER_SECONDS,
  MEASUREMENT_TYPE,
} from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { NotificationContext } from "../../contexts/NotificationProvider";

const SignalChart = (props) => {
  const { t } = useTranslation();
  const { getAccessToken } = useContext(AuthContext);
  const { subscribeTo, notificationHub, unsubscribeFrom, isConnected } =
    useContext(NotificationContext);

  const lastDataPointTs = useRef(null);
  const ambientBufferRef = useRef([]);

  const [isChartLoading, setIsChartLoading] = useState(true);
  const [isBuffering, setIsBuffering] = useState(false);
  const [bufferingProgress, setBufferingProgress] = useState(0);
  const [amountBufferingDataPoints, setAmountBufferingDataPoints] = useState(0);

  const chartRef = useRef(null);
  const valueAxisRef = useRef(null);
  const bufferDataPoint = useRef(null);
  const isChartLoadingRef = useRef(true);
  const isBufferingRef = useRef(false);
  const progress = useRef(0);
  const isAmbientDisplayedRef = useRef(false);

  const sensors = [
    { number: 100, color: "rgb(255, 99, 132)" },
    { number: 101, color: "rgb(255, 159, 64)" },
    { number: 102, color: "rgb(255, 205, 86)" },
    { number: 103, color: "rgb(75, 192, 192)" },
    { number: 300, color: "rgb(54, 162, 235)" },
    { number: 301, color: "rgb(153, 102, 255)" },
    { number: 303, color: "rgb(201, 203, 207)" },
  ];

  useEffect(() => {
    isChartLoadingRef.current = isChartLoading;
  }, [isChartLoading]);

  useEffect(() => {
    isBufferingRef.current = isBuffering;
  }, [isBuffering]);

  useEffect(() => {
    bufferDataPoint.current = amountBufferingDataPoints;
    progress.current = bufferingProgress;
  }, [amountBufferingDataPoints, bufferingProgress]);

  useEffect(() => {
    if (!chartRef.current) {
      chartRef.current = initChart();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);

  // Used for signalr initial connect and next reconnect events
  useEffect(() => {
    if (props.chartMode === SIGNAL_CHART_MODE.REAL_TIME && isConnected) {
      initSignalRSubscription();
    }

    return () => {
      if (props.chartMode === SIGNAL_CHART_MODE.REAL_TIME && isConnected) {
        disposeSignalRSubscription();
      }
    };
  }, [isConnected]);

  useEffect(() => {
    if (chartRef.current && chartRef.current?.data?.length === 0) {
      initializeStaticChartData();
    }
  }, [props.chartMode, props.sensorData]);

  const initSignalRSubscription = () => {
    //join group first
    notificationHub.send(NOTIFICATION_GROUP_TYPES.JOIN_GROUP, props.sessionId);
    //then subscribe
    subscribeTo(NOTIFICATION_TYPES.RECEIVE_DATA, pushSensorDataRef.current);
  };

  const disposeSignalRSubscription = () => {
    notificationHub.send(NOTIFICATION_GROUP_TYPES.LEAVE_GROUP, props.sessionId);
    unsubscribeFrom(NOTIFICATION_TYPES.RECEIVE_DATA, pushSensorDataRef.current);
  };

  const initializeStaticChartData = async () => {
    if (
      !props.isStartOfMeasurement &&
      props.chartMode == SIGNAL_CHART_MODE.REAL_TIME
    ) {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      let res = await getSensorData(props.sessionId, token);
      if (res && res.result && !res.error) {
        props.sensorData = res.result;
        props.onDataReceived();
      } else {
        message.error(t(res.error ?? "Could not retrieve older data"));
      }
    }
    let chartData = [];
    if (props.sensorData == null || props.sensorData.length == 0) {
      return;
    }
    if (
      props.sensorData.length < BUFFER_SECONDS &&
      props.chartMode === SIGNAL_CHART_MODE.REAL_TIME &&
      props.measurementType === MEASUREMENT_TYPE.REGULAR
    ) {
      setIsChartLoading(true);
      setIsBuffering(true);
      setBufferingProgress(
        Math.ceil((props.sensorData.length / BUFFER_SECONDS) * 100)
      );
      return;
    } else {
      setBufferingProgress(100);
      setIsBuffering(false);
    }
    try {
      const sortedData = props.sensorData.sort((a, b) => a.timestamp - b.timestamp);
      sortedData.forEach((item) => {
        var sensorDateTime;
        var sensorValues;
        var currentDataPointTs = null;
        //This check is for compatibility with historic/past data
        if (item.measurements) {
          currentDataPointTs = item.timestampMs;
          sensorDateTime = new Date(item.timestampMs);
          sensorValues = filterSensorData(item.measurements, false);
        } else {
          currentDataPointTs = item.timestamp;
          sensorDateTime = new Date(item.timestamp);
          sensorValues = item.measurements.reduce((map, obj) => {
            map[`sensor${obj.sensor}`] = obj.value;
            return map;
          }, {});
        }

        const data = {
          date: sensorDateTime,
          ...sensorValues,
        };
        if (
          props.deviceMode === DEVICE_MODE.NORMAL &&
          currentDataPointTs < props.startDate.getTime()
        ) {
          ambientBufferRef.current.push(data);
        } else {
          fillDataPointGap(currentDataPointTs, null, chartData);
          chartData.push(data);
        }
      });
    } catch (ex) {
      console.error(ex);
      message.error(t(ex.error ?? "error"));
    }

    if (isChartLoading) {
      setIsChartLoading(false);
    }

    if (chartRef.current) chartRef.current.data = chartData;
  };

  const fillDataPointGap = (currentDataPointTs, chart, chartData) => {
    const differenceMs = currentDataPointTs - lastDataPointTs.current;
    if (lastDataPointTs.current === null || differenceMs === 1000) {
      lastDataPointTs.current = currentDataPointTs;
    } else {
      for (let index = 1000; index < differenceMs; index += 1000) {
        let defaultSensorValues = sensors.reduce((map, obj) => {
          map[`sensor${obj.number}`] = 0;
          return map;
        }, {});
        let emptyDataPoint = {
          date: new Date(lastDataPointTs.current + index),
          ...defaultSensorValues,
        };
        if (chart) chart.addData(emptyDataPoint);
        else if (chartData) {
          chartData.push(emptyDataPoint);
        }
      }
      lastDataPointTs.current = currentDataPointTs;
    }
  };

  const pushSensorDataRef = useRef((sensorData) => {
    sensorData = JSON.parse(sensorData);
    var sensorDateTime = new Date(sensorData.TimestampMs);

    const datapoint = {
      date: sensorDateTime,
      ...filterSensorData(sensorData.Measurements),
    };
    if (isChartLoadingRef.current) {
      if (
        props.deviceMode === DEVICE_MODE.NORMAL &&
        datapoint.date < props.startDate
      ) {
        if (!isBufferingRef.current) {
          setIsBuffering(true);
        }
        fillDataPointGap(
          sensorData.TimestampMs,
          null,
          ambientBufferRef.current
        );
        ambientBufferRef.current?.push(datapoint);
        if (amountBufferingDataPoints <= BUFFER_SECONDS) {
          setAmountBufferingDataPoints(bufferDataPoint.current + 1);
          setBufferingProgress(
            Math.ceil((bufferDataPoint.current / BUFFER_SECONDS) * 100)
          );
        }
        return;
      } else {
        setBufferingProgress(100);
        setIsChartLoading(false);
        setIsBuffering(false);
        props.onDataReceived();
      }
    }
    fillDataPointGap(sensorData.TimestampMs, chartRef.current, null);
    chartRef.current.addData(datapoint);
  });

  const filterSensorData = (sensorArray, isSignalR = true) => {
    const sensorNumberArray = sensors.map((it) => it.number);

    //TODO Because SignalR message is not in camelcase and the sensor data from the api is,
    // we need to have one for upper case and lower case -- obj.(S)sensor and obj.(V)value
    if (isSignalR) {
      return sensorArray
        .filter((it) => sensorNumberArray.includes(it.Sensor))
        .reduce((map, obj) => {
          map[`sensor${obj.Sensor}`] = obj.Value;
          return map;
        }, {});
    } else {
      return sensorArray
        .filter((it) => sensorNumberArray.includes(it.sensor))
        .reduce((map, obj) => {
          map[`sensor${obj.sensor}`] = obj.value;
          return map;
        }, {});
    }
  };

  const initChart = () => {
    //themes begin
    am4core.useTheme(am4themesAnimated);

    let newChart = am4core.create(props.chartId, am4charts.XYChart);
    newChart.dateFormatter.inputDateFormat = DISPLAY_DATE_FORMAT;
    newChart.numberFormatter.numberFormat = "##.#####";

    newChart.colors.list = sensors.map((sensor) => am4core.color(sensor.color));
    newChart.hiddenState.properties.opacity = 0;
    newChart.padding(0, 0, 0, 0);

    //construct date axis
    let dateAxis = newChart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.dateFormats.setKey("second", "ss");
    dateAxis.periodChangeDateFormats.setKey("second", "[bold]h:mm a");
    dateAxis.periodChangeDateFormats.setKey("minute", "[bold]h:mm a");
    dateAxis.periodChangeDateFormats.setKey("hour", "[bold]h:mm a");
    dateAxis.renderer.inside = true;
    dateAxis.renderer.axisFills.template.disabled = true;
    dateAxis.renderer.ticks.template.disabled = true;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.extraMax = 0.18;
    dateAxis.extraMin = 0.18;

    //construct value axis
    let valueAxis = newChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.interpolationDuration = 500;
    valueAxis.rangeChangeDuration = 500;
    valueAxis.renderer.inside = true;
    valueAxis.renderer.minLabelPosition = 0.05;
    valueAxis.renderer.maxLabelPosition = 0.95;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.extraTooltipPrecision = 3;
    valueAxis.extraMax = 0.2;
    valueAxis.extraMin = 0.3;
    valueAxisRef.current = valueAxis;

    dateAxis.interpolationDuration = 500;
    dateAxis.rangeChangeDuration = 500;

    sensors.forEach((sensor) => {
      let series = newChart.series.push(new am4charts.LineSeries());
      series.tooltip.pointerOrientation = "vertical";
      series.dataFields.dateX = "date";
      series.dataFields.valueY = `sensor${sensor.number}`;
      series.name = t("new-measurement-chart-sensor-name", {
        sensorNumber: sensor.number,
      });
      series.interpolationDuration = 500;
      series.defaultState.transitionDuration = 0;
      series.tensionX = 0.8;
      series.stroke = sensor.color;
      series.strokeWidth = 2;

      // bullet at the front of the line
      let bullet = series.createChild(am4charts.CircleBullet);
      bullet.circle.radius = 5;
      bullet.fillOpacity = 1;
      bullet.fill = sensor.color;
      bullet.isMeasured = false;

      series.events.on("validated", function () {
        if (series.dataItems.last) {
          bullet.moveTo(series.dataItems.last.point);
          bullet.validatePosition();
        }
      });
    });

    if (props.chartMode === SIGNAL_CHART_MODE.REAL_TIME) {
      newChart.events.on("datavalidated", function () {
        dateAxis.zoom({ start: 1 / 15, end: 1.2 }, false, true);
      });
    } else {
      // Add scrollbar
      newChart.scrollbarX = new am4core.Scrollbar();
      newChart.scrollbarX.parent = newChart.bottomAxesContainer;
      newChart.events.on("datavalidated", function () {
        dateAxis.zoom({ start: 1 / 15, end: 1 }, false, true);
      });
    }

    // this makes date axis labels to fade out
    dateAxis.renderer.labels.template.adapter.add(
      "fillOpacity",
      function (fillOpacity, target) {
        let dataItem = target.dataItem;
        return dataItem.position;
      }
    );

    // need to set this, otherwise fillOpacity is not changed and not set
    dateAxis.events.on("validated", function () {
      am4core.iter.each(dateAxis.renderer.labels.iterator(), function (label) {
        label.fillOpacity = label.fillOpacity;
      });
    });

    // this makes date axis labels which are at equal minutes to be rotated
    dateAxis.renderer.labels.template.adapter.add(
      "rotation",
      function (rotation, target) {
        var dataItem = target.dataItem;
        if (
          dataItem.date &&
          dataItem.date.getTime() ===
          am4core.time
            .round(new Date(dataItem.date.getTime()), "minute")
            .getTime()
        ) {
          target.verticalCenter = "middle";
          target.horizontalCenter = "left";
          return -90;
        } else {
          target.verticalCenter = "bottom";
          target.horizontalCenter = "middle";
          return 0;
        }
      }
    );

    //Enable amCharts' built-in responsivity
    newChart.responsive.enabled = true;

    // Add legend
    newChart.legend = new am4charts.Legend();
    newChart.legend.itemContainers.template.paddingTop = 5;
    newChart.legend.itemContainers.template.paddingBottom = 5;

    //Add a cursor to allow hovering on the newChart
    newChart.cursor = new am4charts.XYCursor();
    newChart.cursor.behavior = "none";

    if (props.deviceMode === DEVICE_MODE.NORMAL) {
      let buttonContainer = newChart.legend.createChild(am4core.Container);
      buttonContainer.shouldClone = false;
      buttonContainer.align = "right";
      buttonContainer.valign = "bottom";
      buttonContainer.zIndex = Number.MAX_SAFE_INTEGER;
      buttonContainer.marginTop = 5;
      buttonContainer.marginRight = 5;
      buttonContainer.layout = "horizontal";

      if (props.measurementType !== MEASUREMENT_TYPE.AMBIENT) {
        let toggleAmbient = buttonContainer.createChild(am4core.SwitchButton);
        toggleAmbient.rightLabel.text = t("measurement-type-ambient");
        toggleAmbient.events.on("hit", function (ev) {
          if (isAmbientDisplayedRef.current) {
            chartRef.current.data.splice(0, ambientBufferRef.current.length);
            chartRef.current.invalidateData();
            isAmbientDisplayedRef.current = false;
          } else {
            chartRef.current.data = [
              ...ambientBufferRef.current,
              ...chartRef.current.data,
            ];
            chartRef.current.invalidateData();
            isAmbientDisplayedRef.current = true;
          }
        });
      }

    }

    return newChart;
  };

  return (
    <div class="full-width">
      {isBufferingRef.current && bufferingProgress < 100 && (
        <Progress
          strokeColor={{
            from: "var(--loading-bar-begin-color)",
            to: "var(--loading-bar-end-color)",
          }}
          percent={bufferingProgress}
          status="active"
        />
      )}
      <Spin
        tip={t("loading")}
        wrapperClassName="graph-spinner"
        spinning={isChartLoading}
      >
        <div id={props.chartId} className="graph" />
      </Spin>
    </div>
  );
};

SignalChart.defaultProps = {
  isStartOfMeasurement: false,
  chartMode: SIGNAL_CHART_MODE.STATIC,
};

SignalChart.propsTypes = {
  chartId: PropTypes.string.isRequired,
  chartMode: PropTypes.number.isRequired,
  measurementType: PropTypes.number.isRequired,
  deviceMode: PropTypes.number.isRequired,
  startDate: PropTypes.object.isRequired,
  sessionId: PropTypes.string,
  measurementId: PropTypes.string,
  onDataReceived: PropTypes.func,
  isStartOfMeasurement: PropTypes.bool,
};

export default SignalChart;
