import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Modal,
  Button,
  Input,
  Divider,
  Row,
  Col,
  Typography,
  Form,
  Spin,
} from "antd";
const { Text } = Typography;
import { API_REQUESTS, SURVEY_VERSION } from "../../utils/utils";
import { AuthContext } from "../../contexts/AuthProvider";
import { getMeasurementById, getSurvey } from "../../../services/ApiService";
import { useTranslation } from "react-i18next";
import moment from "moment";

const PreloadSurveyModal = (props) => {
  const { getAccessToken } = useContext(AuthContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [preloadGender, setPreloadGender] = useState("");
  const [preloadAge, setPreloadAge] = useState("");
  const [preloadHeight, setPreloadHeight] = useState("");
  const [preloadedAnswers, setPreloadedAnswers] = useState(false);
  const [preloadedData, setPreloadedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [preloadMeasurementId, setPreloadMeasurementId] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setIsModalVisible(props.showModal ?? false);
  }, [props]);

  const onChangeMeasurementId = (event) => {
    setPreloadMeasurementId(event.target.value);
  };
  const closeModal = () => {
    clearModal();
    props.closePreloadModal(null);
  };

  const adaptPreloadSurvey = async (responseData) => {
    setPreloadedAnswers(true);
    setPreloadGender(
      responseData.gender ? getGenderName(responseData.gender) : ""
    );
    setPreloadHeight(responseData.height);
    setPreloadAge(responseData.age);
    setPreloadedData(responseData);
    // The lines underneath will clear the fields (/reset to default) of pregnancy, consumption time, last smoke, last vape, common cold/flu, sputum,
    // blocked nose, pulmonary mediation, antibiotic courses, OCS courses, VAS scale dyspnea, VAS scale cough. 
    // This will automatically be saved into the database when the user goes to another survey tab/clicks on save, the preloaded measurement itself does not change in the database.
    // Required fields still have to be filled in before the survey can be submitted.
    if (responseData.gender !== 1) {
      responseData.currentPregnancy = false;
    }
    responseData.commonCold = false;
    responseData.sputum = false;
    responseData.blockedNose = false;
    responseData.usePulmonaryMedication = false;

    delete responseData.consumptionTime;
    delete responseData.whatAteDrunk;
    delete responseData.lastSmoke;
    delete responseData.lastVape;
    delete responseData.sputumColor;
    delete responseData.medicationLastUse;
    delete responseData.receivedCoursesOfAntibiotics;
    delete responseData.ocsCourses;
    delete responseData.vasScaleDyspnea;
    delete responseData.vasScaleCough;

    // Loops through all the properties and removes the data of the dates. 
    // To check if a string (coming from JSON format) is a date, we convert it to a date object and then convert it back to a json format.
    // Only dates will give the same value when they are transformed and this transformation is reverted. 
    // The "Date and Time"-variable on the first page will not be deleted, as that one is not in JSON format. 
    for (const i in responseData) {
      let variableInDateFormat = new Date(responseData[i]);
      let variableInJSONFormat = variableInDateFormat.toJSON();
      if (responseData[i] === variableInJSONFormat) {
        delete responseData[i];
      }
    }
  }

  const onClickPreloadSurvey = async () => {
    setIsLoading(true);
    var measurementId = parseInt(preloadMeasurementId);
    const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
    if (token) {
      try {
        var measurement = await getMeasurementById(measurementId, token);
        let data = {
          measurementId: measurementId,
          deviceId: measurement.deviceId,
          version: SURVEY_VERSION.V2
        }
        //Overwrite device id for serial number if V2 device
        if (!measurement.deviceId.toLowerCase().startsWith("gw-1")) {
          data.deviceId = measurement.serialNumber;
        };
        if (moment(props.measurementDate).isBefore(moment(measurement.dateCreated))) {
          setPreloadedAnswers(false);
          setErrorMessage(t("measurement-should-be-older"));
          return;
        }
        var res = await getSurvey(data, token);
        if (res && res.result && !res.error) {
          if (res.result.data && res.result.data.data) {
            let responseData = JSON.parse(res.result.data.data);
            adaptPreloadSurvey(responseData);
            setErrorMessage("");
          } else {
            setPreloadedAnswers(false);
            setErrorMessage(t("measuremenet-no-survey-data"));
          }
        } else {
          setPreloadedAnswers(false);
          setErrorMessage(t(res.error));
        }
      }
      catch (err) {
        setErrorMessage(t("measurement-not-found"));
        setPreloadedAnswers(false);
        console.log(measurement.deviceId);

      }
    }
    setIsLoading(false);
  };

  const getGenderName = (gender) => {
    switch (gender) {
      case 1:
        return t("survey:patient-male");
      case 2:
        return t("survey:patient-female");
      default:
        return t("survey:patient-neutral");
    }
  };

  const confirmPreload = () => {
    clearModal();
    props.closePreloadModal(preloadedData);
  };

  const clearModal = () => {
    setPreloadMeasurementId(null);
    setErrorMessage("");
    setPreloadedAnswers(false);
  };

  return (
    <>
      <Modal
        title={t("survey-preload-answers")}
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button onClick={closeModal}>
            {t("survey-template-preview-cancel")}
          </Button>,
          <Button
            disabled={!preloadedAnswers}
            type="primary"
            onClick={confirmPreload}
          >
            {t("survey-preload-answers")}
          </Button>,
        ]}
      >
        <Row gutter={(4, 4)}>
          <Col>
            <Form.Item label={t("new-measurement-measurement-id")}>
              <Input
                type="number"
                value={preloadMeasurementId}
                onChange={onChangeMeasurementId}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button onClick={onClickPreloadSurvey}>
              {isLoading && (
                <Spin style={{ marginRight: "5px" }} size="small" />
              )}
              {t("survey-load-answers")}
            </Button>
          </Col>
        </Row>
        <Row gutter={(16, 16)}>
          <Text type="danger">{errorMessage}</Text>
        </Row>

        {preloadedAnswers && (
          <>
            <Divider />
            <Row>
              <Text>{t("gender") + " " + preloadGender}</Text>
            </Row>
            <Row>
              <Text>{t("age") + " " + preloadAge}</Text>
            </Row>
            <Row>
              <Text>{t("height") + " " + preloadHeight}</Text>
            </Row>
          </>
        )}
        <Row gutter={(16, 16)}>
          <Text type="warning">{t("survey-preload-warning")}</Text>
        </Row>
      </Modal>
    </>
  );
};

export default PreloadSurveyModal;
