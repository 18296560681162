import i18n from "../../i18n";

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const getDeviceMode = (deviceMode, t) => {
  switch (deviceMode) {
    case DEVICE_MODE.NORMAL:
      return t("device-details-device-mode-normal");
    case DEVICE_MODE.CONTINUOUS:
      return t("device-details-device-mode-continuous");
    default:
      return t("none");
  }
};

//temporary fix - replaceAll is available in chrome 85 and
//new v8 browsers
String.prototype.replaceAll = function (search, replace) {
  return this.replace(new RegExp(search, "g"), replace);
};

export const API_SCOPES = {
  IMPERSONATION: "user_impersonation",
};

export const API_ENDPOINTS = {
  MEASUREMENT_DASHBOARD: `${process.env.REACT_APP_API_URL}/statistics/measurements`,
  DEVICE_DASHBOARD: `${process.env.REACT_APP_API_URL}/statistics/devices`,
  USER_DASHBOARD: `${process.env.REACT_APP_API_URL}/statistics/users`,
  MAIL_SEND: `${process.env.REACT_APP_API_URL}/mail/send`,
  USER_UPDATE: `${process.env.REACT_APP_API_URL}/users`,
  USER_CREATE: `${process.env.REACT_APP_API_URL}/users`,
  DEVICES: `${process.env.REACT_APP_API_URL}/devices`,
  USER_DEVICES: `${process.env.REACT_APP_API_URL}/devices/assigned`,
  USER_STUDIES: `${process.env.REACT_APP_API_URL}/studies/assigned`,
  USERS: `${process.env.REACT_APP_API_URL}/users`,
  STUDIES: `${process.env.REACT_APP_API_URL}/studies`,
  STUDY_CREATE: `${process.env.REACT_APP_API_URL}/studies`,
  STUDY_DELETE: `${process.env.REACT_APP_API_URL}/studies`,
  USER_PHOTO: `${process.env.REACT_APP_API_URL}/users/photo`,
  USER_UPDATE_STATUS: `${process.env.REACT_APP_API_URL}/users/status`,
  USER_DELETE: `${process.env.REACT_APP_API_URL}/users`,
  ORGANIZATIONS: `${process.env.REACT_APP_API_URL}/organizations`,
  STUDY_TYPES: `${process.env.REACT_APP_API_URL}/static-files/study-types`,
  ICD_SEARCH: `${process.env.REACT_APP_API_URL}/icd`,
  SURVEY: `${process.env.REACT_APP_API_URL}/static-files/general-survey-template`,
  SURVEY_ADDITIONAL: `${process.env.REACT_APP_API_URL}/static-files/additional-survey-template`,
  DEVICE_UPDATE: `${process.env.REACT_APP_API_URL}/devices`,
  MAP_SEARCH: `${process.env.REACT_APP_API_URL}/maps`,
  STUDY_UPDATE: `${process.env.REACT_APP_API_URL}/studies`,
  MEASUREMENTS: `${process.env.REACT_APP_API_URL}/measurements`,
  MEASUREMENT_DELETE: `${process.env.REACT_APP_API_URL}/measurements`,
  MEASUREMENT_DOWNLOAD: `${process.env.REACT_APP_API_URL}/measurements/download`,
  SURVEYS_DOWNLOAD: `${process.env.REACT_APP_API_URL}/surveys/download`,
  SURVEY_GET: `${process.env.REACT_APP_API_URL}/surveys`,
  CALIBRATIONS_GET: `${process.env.REACT_APP_API_URL}/calibrations/get`,
  CALIBRATIONS_UPDATE: `${process.env.REACT_APP_API_URL}/calibrations/update`,
  NOSE_CALIBRATION_UPDATE: `${process.env.REACT_APP_API_URL}/spironoses`,
  NOSES: `${process.env.REACT_APP_API_URL}/spironoses`,
  SURVEYS: `${process.env.REACT_APP_API_URL}/surveys/templates`,
  SURVEYS_TEMPLATES: `${process.env.REACT_APP_API_URL}/templates`,
  SURVEY_TEMPLATE: `${process.env.REACT_APP_API_URL}/templates`,
  SURVEY_TEMPLATE_DELETE: `${process.env.REACT_APP_API_URL}/templates`,
  SURVEY_SAVE: `${process.env.REACT_APP_API_URL}/surveys/save`,
  SURVEYS_TEMPLATES_UPDATE: `${process.env.REACT_APP_API_URL}/templates/update`,
  MEASUREMENT_START: `${process.env.REACT_APP_API_URL}/measurements/start`,
  MEASUREMENT_STOP: `${process.env.REACT_APP_API_URL}/measurements/stop`,
  MEASUREMENT_POST_SAVE: `${process.env.REACT_APP_API_URL}/measurements/post-save`,
  MEASUREMENT_SENSOR_DATA: `${process.env.REACT_APP_API_URL}/measurements/sensor-data`,
  DEVICE_SPIRONOSES: `${process.env.REACT_APP_API_URL}/spironoses/assigned`,
  DEVICE_USERS: `${process.env.REACT_APP_API_URL}/users/assigned`,
  SUBSCRIBE_GROUP: `${process.env.REACT_APP_API_URL}/subscribe/group`,
  REFRESH_DEVICE_DETAILS: `${process.env.REACT_APP_API_URL}/devices/status`,
  DEVICE_DETAILS: `${process.env.REACT_APP_API_URL}/devices/details`,
  MEASUREMENT_SURVEY_STATUS: `${process.env.REACT_APP_API_URL}/measurements/status/survey`,
  DEVICE_ASSIGN_USERS: `${process.env.REACT_APP_API_URL}/devices/assign`,
  IMPORT_USERS_TEMPLATE: `${process.env.REACT_APP_API_URL}/users/import/template`,
  BULK_IMPORT_USERS: `${process.env.REACT_APP_API_URL}/users/import/upload`,
  EXPORT_JOB_CREATE: `${process.env.REACT_APP_API_URL}/exports`,
  EXPORT_INFO_PACKAGE: `${process.env.REACT_APP_API_URL}/exports/info-package`,
  EXPORT_BREATHBASE_MANUAL: `${process.env.REACT_APP_API_URL}/exports/manual`,
  MEASUREMENTS_CALIBRATIONS: `${process.env.REACT_APP_API_URL}/measurements/calibrations`,
  COUNTRIES: `${process.env.REACT_APP_API_URL}/countries`,
  MEASUREMENT_CHANGE_STUDY: `${process.env.REACT_APP_API_URL}/measurements/study`,
};

export const API_REQUESTS = {
  USER_IMPERSONATION: [
    `api://${process.env.REACT_APP_CLIENT_ID}/${API_SCOPES.IMPERSONATION}`,
  ],
};

export const APP_ROLES = {
  ADMIN: "Admin",
  RESEARCHER: "Researcher",
  CLINICIAN: "Clinician",
};

export const SURVEYJS_SUPPORTED_LANGUAGES = {
  SUPPORTED: ["en", "fr", "nl"],
};

export const STUDY_ROLES = {
  MEMBER: 0,
  PRINCIPAL_INVESTIGATOR: 1,
};

export const STUDY_STATUS_TYPES = {
  DRAFT: 0,
  PREVIEW: 1,
  ACTIVE: 2,
  COMPLETED: 3,
};

export const SURVEY_VERSION = {
  V1: 1,
  V2: 2,
};

export const AUTHORIZED_ROLES = [
  APP_ROLES.ADMIN,
  APP_ROLES.RESEARCHER,
  APP_ROLES.CLINICIAN,
];

export const CHART_TYPES = {
  VERIFICATION: 0,
  CALIBRATION: 1,
};

export const GRAPH_SCOPES = {
  OPENID: "openid",
  PROFILE: "profile",
  USER_READ: "User.Read",
  USER_READWRITE: "User.ReadWrite",
};

export const GRAPH_ENDPOINTS = {
  ME: `${process.env.REACT_APP_GRAPH_API_URL}/me`,
  PHOTO: `${process.env.REACT_APP_GRAPH_API_URL}/me/photo/$value`,
};

export const GRAPH_REQUESTS = {
  LOGIN: [GRAPH_SCOPES.OPENID, GRAPH_SCOPES.PROFILE, GRAPH_SCOPES.USER_READ],
  PROFILE: [GRAPH_SCOPES.USER_READWRITE],
};

export const groupBy = (objectArray, property) => {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

export const VALIDATIONS = {
  MAX_PROFILE_PHOTO_FILE_SIZE_KB: 100,
  MAX_PROFILE_ORGANIZATION_LENGTH: 128,
  MAX_PROFILE_FIRSTNAME_LENGTH: 64,
  MAX_PROFILE_LASTNAME_LENGTH: 64,
  MAX_PROFILE_ACCOUNT_LENGTH: 64,
  MAX_PROFILE_JOBTITLE_LENGTH: 64,
  MAX_PROFILE_DEPARTMENT_LENGTH: 64,
  MAX_PROFILE_FIELD_LENGTH: 100,
  MIN_PROFILE_FIELD_LENGTH: 2,
  MIN_PROFILE_PHONE_LENGTH: 7,
  MAX_PROFILE_PHONE_LENGTH: 15,
  MAX_STUDY_FIELD_LENGTH: 100,
  MIN_STUDY_FIELD_LENGTH: 2,
  MAX_STUDY_ORGANIZATIONS_LENGTH: 50,
  MAX_STUDY_PRINCIPAL_INVESTIGATORS_LENGTH: 10,
  MAX_STUDY_TEAM_MEMBERS_LENGTH: 20,
  MAX_STUDY_FIELD_ARRAY_ITEM_LENGTH: 50,
  MAX_STUDY_FIELD_ARRAY_ITEM_COUNT: 10,
  MAX_DEVICE_FIELD_LENGTH: 100,
  MIN_DEVICE_FIELD_LENGTH: 2,
  MAX_DEVICE_TIMEOUT_LENGTH: 24 * 60 * 60,
  MIN_DEVICE_TIMEOUT_LENGTH: 0,
  MIN_ORGANIZATION_INPUT_LENGTH: 2,
  MAX_ORGANIZATION_INPUT_LENGTH: 64,
};

export const getAddressString = (userDetails) => {
  if (userDetails) {
    let addr = [
      userDetails.streetAddress,
      userDetails.city,
      userDetails.state,
      userDetails.country,
    ];
    return addr.filter((a) => a).join(",");
  }
  return null;
};

export const getMeasurementTypeName = (measurementType) => {
  switch (measurementType) {
    case MEASUREMENT_TYPE.REGULAR:
      return i18n.t("measurement-type-regular");
    case MEASUREMENT_TYPE.TEST:
      return i18n.t("measurement-type-test");
    case MEASUREMENT_TYPE.CALIBRATION:
      return i18n.t("measurement-type-calibration");
    case MEASUREMENT_TYPE.AMBIENT:
      return i18n.t("measurement-type-ambient");
    default:
      return "";
  }
};

export const getMeasurementTypeColor = (measurementType) => {
  switch (measurementType) {
    case MEASUREMENT_TYPE.REGULAR:
      return "var(--measurement-regular-color)";
    case MEASUREMENT_TYPE.TEST:
      return "var(--measurement-test-color)";
    case MEASUREMENT_TYPE.CALIBRATION:
      return "var(--measurement-calibration-color)";
    case MEASUREMENT_TYPE.AMBIENT:
      return "var(--measurement-ambient-color)";
    default:
      return "";
  }
};

export const getStudyStatusList = (t) => {
  return [
    {
      value: STUDY_STATUS_TYPES.DRAFT,
      text: t("study-status-draft"),
    },
    {
      value: STUDY_STATUS_TYPES.PREVIEW,
      text: t("study-status-preview"),
    },
    {
      value: STUDY_STATUS_TYPES.ACTIVE,
      text: t("study-status-active"),
    },
    {
      value: STUDY_STATUS_TYPES.COMPLETED,
      text: t("study-status-completed"),
    },
  ];
};

export const getStudyStatusString = (status, t) => {
  switch (status) {
    case STUDY_STATUS_TYPES.DRAFT:
      return t("study-status-draft");
    case STUDY_STATUS_TYPES.PREVIEW:
      return t("study-status-preview");
    case STUDY_STATUS_TYPES.ACTIVE:
      return t("study-status-active");
    case STUDY_STATUS_TYPES.COMPLETED:
      return t("study-status-completed");
    default:
      return t("none");
  }
};

export const getStudyDurationType = (studyDuration, t) => {
  switch (studyDuration) {
    case 0:
      return t("study-weeks");
    case 1:
      return t("study-months");
    case 2:
      return t("study-years");
    default:
      return t("none");
  }
};

export const getChartName = (chartType, t) => {
  switch (chartType) {
    case CHART_TYPES.VERIFICATION:
      return t("calibration-chart-verification");
    case CHART_TYPES.CALIBRATION:
      return t("calibration-chart-calibration");
    default:
      return t("none");
  }
};

export const getEmptyStateForChart = (chartType, t) => {
  switch (chartType) {
    case CHART_TYPES.VERIFICATION:
      return t("calibration-chart-empty-state-verification");
    case CHART_TYPES.CALIBRATION:
      return t("calibration-chart-empty-state-calibration");
    default:
      return t("none");
  }
};

export const getDeviceCalibrationDurationType = (calibrationDuration, t) => {
  switch (calibrationDuration) {
    case CALIBRATION_DURATION_TYPE.HOURS:
      return t("calibration-hours");
    case CALIBRATION_DURATION_TYPE.DAYS:
      return t("calibration-days");
    case CALIBRATION_DURATION_TYPE.WEEKS:
      return t("calibration-weeks");
    case CALIBRATION_DURATION_TYPE.MONTHS:
      return t("calibration-months");
    default:
      return t("none");
  }
};

export const getStudyStatusColor = (status) => {
  switch (status) {
    case STUDY_STATUS_TYPES.DRAFT:
      return "var(--study-draft-color)";
    case STUDY_STATUS_TYPES.PREVIEW:
      return "var(--study-preview-color)";
    case STUDY_STATUS_TYPES.ACTIVE:
      return "var(--study-active-color)";
    case STUDY_STATUS_TYPES.COMPLETED:
      return "var(--study-completed-color)";
    default:
      return "var(--unknown-color)";
  }
};

export const getStudyRoleString = (role, t) => {
  switch (role) {
    case STUDY_ROLES.MEMBER:
      return t("study-role-member");
    case STUDY_ROLES.PRINCIPAL_INVESTIGATOR:
      return t("study-role-pi");
    default:
      return t("none");
  }
};

export const getStudyRoleLongString = (role, t) => {
  switch (role) {
    case STUDY_ROLES.MEMBER:
      return t("study-role-member");
    case STUDY_ROLES.PRINCIPAL_INVESTIGATOR:
      return t("study-role-principal-investigator");
    default:
      return t("none");
  }
};

export const getStudyRoleColor = (role) => {
  switch (role) {
    case STUDY_ROLES.MEMBER:
      return "var(--member-color)";
    case STUDY_ROLES.PRINCIPAL_INVESTIGATOR:
      return "var(--investigator-color)";
    default:
      return "var(--unknown-color)";
  }
};

export const getAppRoleString = (role, t) => {
  switch (role) {
    case APP_ROLES.ADMIN:
    case APP_ROLES.RESEARCHER:
    case APP_ROLES.CLINICIAN:
      return t(role.toLowerCase());
    default:
      return t("none");
  }
};

export const getAppRoleColor = (role) => {
  switch (role) {
    case APP_ROLES.ADMIN:
      return "var(--admin-color)";
    case APP_ROLES.RESEARCHER:
      return "var(--researcher-color)";
    case APP_ROLES.CLINICIAN:
      return "var(--clinician-color)";
    default:
      return "var(--unknown-color)";
  }
};

export const getMeasurementType = (type, t) => {
  switch (type) {
    case MEASUREMENT_TYPE.REGULAR:
      return t("measurement-type-regular");
    case MEASUREMENT_TYPE.CALIBRATION:
      return t("measurement-type-calibration");
    case MEASUREMENT_TYPE.BUFFER:
      return t("measurement-type-ambient");
    case MEASUREMENT_TYPE.TEST:
      return t("measurement-type-test");
    default:
      return t("measurement-type-unknown");
  }
};

export const NOTIFICATION_GROUP_TYPES = {
  JOIN_GROUP: "joinGroup",
  LEAVE_GROUP: "leaveGroup",
  JOIN_GROUP_ADMINS: "joinGroupAdmins",
  LEAVE_GROUP_ADMINS: "leaveGroupAdmins",
};

export const NOTIFICATION_GROUPS = {
  JOIN_GROUP_ADMINS: "admins",
  JOIN_GROUP_ALL_USERS: "allusers",
};

export const NOTIFICATION_TYPES = {
  STUDY_CREATED: "StudyCreated",
  STUDY_MEMBER_ADDED: "StudyMemberAdded",
  STUDY_USER_ASSIGNED: "StudyUserAssigned",
  RECEIVE_DATA: "ReceiveDataPoint",
  RECEIVE_ANALYSIS_RESULTS: "ReceiveAnalysisResults",
  RAW_EXPORT_READY: "RawExportReady",
  EXPORT_JOB_READY: "ReceiveExportReady",
  DEVICE_CHANGED: "DeviceChanged",
  MEASUREMENT_CHANGED: "MeasurementChanged",
  SURVEY_STATUS_CHANGED: "SurveyStatusChanged",
};

export const SURVEY_STATUS = {
  EMPTY: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  SUBMITTED: 3,
};

export const ENTITY_TYPE = {
  BREATHBASE_USER: 0,
  STUDY: 1,
  SURVEY_TEMPLATE: 2,
  MEASUREMENT: 3,
};

export const MEASUREMENT_STATUS = {
  ERROR: -1,
  UNKNOWN: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
};

export const DOWNLOAD_DATA_TYPES = {
  SENSOR: 0,
  RAW: 1,
  CALIBRATED: 2,
  PROCESSED: 3,
  RESULTS: 4,
  FACTORS: 5,
  SURVEY: 6,
};

export const DOWNLOAD_EXPORT_TYPES = {
  CSV: 0,
  XLSX: 1,
  JSON: 2,
};

export const TOBACCO_TYPES = {
  CIGARETTES: "1",
  CIGARS: "2",
  TOBACCO: "3",
  PIPES: "4",
  VAPING: "5",
};

export const CALIBRATION_DURATION_TYPE = {
  HOURS: 0,
  DAYS: 1,
  WEEKS: 2,
  MONTHS: 3,
};

export const DEVICE_MODE = {
  NORMAL: 0,
  CONTINUOUS: 1,
};

export const MEASUREMENT_TYPE = {
  REGULAR: 0,
  CALIBRATION: 1,
  AMBIENT: 2,
  TEST: 3,
};

export const MEASUREMENT_STEPS = {
  SelectDevice: 0,
  PerformMeasurement: 1,
  AnalyzeMeasurement: 2,
  FillQuestionarie: 3,
  Completed: 4,
};

export const DEVICE_DISPLAY_STATUS = {
  IN_CARD: 0,
  IN_TOOLTIP: 1,
};

export const INNER_SENSORS = [
  "100",
  "101",
  "102",
  "103",
  "300",
  "301",
  "303",
  "500",
  "501",
  "502",
  "503",
  "700",
  "701",
  "703",
];

export const OUTER_SENSORS = [
  "200",
  "201",
  "203",
  "400",
  "401",
  "402",
  "403",
  "600",
  "601",
  "603",
  "800",
  "801",
  "802",
  "803",
];

export const DISPLAY_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const SHORT_DATE_FORMAT = "YYYY-MM-DD";

// Used only for frontend rendering
export const ANALYSIS_STATUS = {
  SUCCESS: 0,
  ERROR: 1,
  UNCERTAIN: 2,
};

// Status resceived in analysis result body
export const ANALYSIS_RESULT_STATUS = {
  SUCCEDED: 0,
  FAILED: 1,
};

export const getDownloadFileExtension = (exportType) => {
  let ext = "";
  switch (exportType) {
    case DOWNLOAD_EXPORT_TYPES.CSV:
      ext = "csv";
      break;
    case DOWNLOAD_EXPORT_TYPES.XLSX:
      ext = "xlsx";
      break;
    case DOWNLOAD_EXPORT_TYPES.JSON:
      ext = "json";
      break;
    default:
      break;
  }
  return ext;
};

export const getDownloadFileName = (downloadType) => {
  let name = "";
  switch (downloadType) {
    case DOWNLOAD_DATA_TYPES.SENSOR:
      name = "SensorData_Measurement";
      break;
    case DOWNLOAD_DATA_TYPES.RAW:
      name = "RawData_Measurement";
      break;
    case DOWNLOAD_DATA_TYPES.CALIBRATED:
      name = "CalibratedData_Measurement";
      break;
    case DOWNLOAD_DATA_TYPES.PROCESSED:
      name = "ProcessedData_Measurement";
      break;
    case DOWNLOAD_DATA_TYPES.RESULTS:
      name = "DiagnosticData_Measurement";
      break;
    case DOWNLOAD_DATA_TYPES.FACTORS:
      name = "CalibrationFactors_Measurement";
      break;
    default:
      break;
  }
  return name;
};

export const getDownloadFullFilename = (downloadType, exportType, id) => {
  return `${getDownloadFileName(downloadType)}_${id}.${getDownloadFileExtension(
    exportType
  )}`;
};

export const getFullLanguageString = (t) => {
  return {
    NL: t("lang-dutch"),
    EN: t("lang-english"),
    FR: t("lang-french"),
  };
};

export const SIGNAL_CHART_MODE = {
  REAL_TIME: 0,
  STATIC: 1,
};

export const getFormattedSurveyTemplate = (text) => {
  let parsed = text;
  try {
    parsed = JSON.parse(text);
    return JSON.stringify(parsed, null, 4);
  } catch (error) {
    console.error(error);
  }
  return parsed;
};

export const MEASUREMENT_PAGES = {
  PERFORM_STEP: 0,
  ANALYSIS_STEP: 1,
  DETAILS_PAGE: 2,
};

export const ENTITY_ACTIONS = {
  CREATE: 0,
  UPDATE: 1,
  DELETE: 2,
};

export const getMeasurementIdFormatted = (mId, mType) => {
  // TODO: Find a way of displaying the measurement ID in correct format.

  return mId;
};

export const BUFFER_SECONDS = 900; //15 minutes

export const DEVICE_VERSION = {
  V1: 1,
  V2: 2,
};

export const ANALYSIS_RESULT_FETCH_MODE = {
  SIGNALR: 0,
  REST: 1,
};

export const DIAGNOSTIC_RESULT_STATUS = {
  NEGATIVE: 0,
  INDETERMINATE: 1,
};
export const SIGNAL_CHART_VALUE_AXIS_MAX = 10;
export const SIGNAL_CHART_VALUE_AXIS_MIN = -7;
export const SIGNAL_CHART_CALIBRATION_VALUE_AXIS_MAX = 20;
export const SIGNAL_CHART_CALIBRATION_VALUE_AXIS_MIN = -10;

export const GATEWAY_STATUS = {
  ERROR: -1,
  OK: 0,
  SENDING: 1,
  BUFFERING: 2,
};

export const EXPORT_JOB_STATUS = {
  STARTED: 0,
  COMPLETED: 1,
  FAILED: 2,
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const BULK_IMPORT_MAX_RECORDS = 100;

export const TIMER_MODE = {
  IDLE: 0,
  COUNTDOWN: 1,
  STOPWATCH: 2,
};

export const humanReadableFileSize = (sizeKB, decimals = 2) => {
  const unitsIndex =
    sizeKB < 1 ? 0 : Math.floor(Math.log(sizeKB) / Math.log(1024));

  return {
    units: ["KB", "MB", "GB", "TB"][unitsIndex],
    size: parseFloat((sizeKB / Math.pow(1024, unitsIndex)).toFixed(decimals)),
  };
};

export const SENSOR_TYPE_CONFIGURATION = [
  {
    sensorType: "TGS 2602",
    inner: [100, 500],
    outer: [400, 800],
  },
  {
    sensorType: "TGS 2610",
    inner: [101, 501],
    outer: [401, 801],
  },
  {
    sensorType: "TGS 2611-COO",
    inner: [102, 502],
    outer: [402, 802],
  },
  {
    sensorType: "TGS 2600",
    inner: [103, 503],
    outer: [403, 803],
  },
  {
    sensorType: "TGS 2603",
    inner: [300, 700],
    outer: [200, 600],
  },
  {
    sensorType: "TGS 2620",
    inner: [301, 701],
    outer: [201, 601],
  },
  {
    sensorType: "TGS 2612",
    inner: [303, 703],
    outer: [203, 603],
  },
];

// 6485 - There shouldn't be any sorting applied if the button is set to it's default state, hence SortField shouldn't be present in the request.
export const getSortField = (sorter) => sorter.order && sorter.field;
