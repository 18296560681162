import React, { useEffect, useContext, useState } from "react";
import i18n from "../../../i18n";
import "./survey-templates-page.css";
import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/fr";
import { List, Typography, Input, Skeleton, Button, Space, Modal } from "antd";
import { AuthContext } from "../../contexts/AuthProvider";
import { getSurveyTemplates } from "../../../services/ApiService";
import { API_REQUESTS, ENTITY_ACTIONS } from "../../utils/utils";
import { SolutionOutlined, FormOutlined } from "@ant-design/icons";
import SurveyTemplateCard from "../../components/survey-template-card/survey-template-card";
import EmptyDescription from "../../components/simple-components/empty-description/empty-description";
import SimpleMessage from "../../components/simple-components/simple-message/simple-message";
import SurveyTemplateDrawer from "../../components/survey-template-drawer/survey-template-drawer";
import { PageSettingsContext } from "../../contexts/PageSettingsProvider";
import { v4 as uuidv4 } from "uuid";
import SurveyCreator from "../../components/survey-creator/survey-creator";
import { useHistory } from "react-router-dom";


const { Title } = Typography;
const { Search } = Input;
const itemsPerPage = 10;

const SurveyTemplatesPage = () => {
  const { getAccessToken } = useContext(AuthContext);
  const { resetFilters } = useContext(PageSettingsContext);
  const [isEditorModalVisible, setIsEditorModalVisible] = useState(false);
  const history = useHistory();


  const [pageState, setPageState] = useState({
    data: [],
    templatelist: [],
    initLoading: true,
    totalCount: 0,
    startIndex: 0,
    search: null,
    loadingMore: false,
    canLoadMore: false,
  });

  useEffect(() => {
    resetFilters();
  }, []);

  useEffect(() => {
    if (initLoading || loadingMore) {
      loadTemplates();
    }
  }, [pageState.initLoading, pageState.loadingMore]);

  const showEditorModal = () => {
    setIsEditorModalVisible(true);
  };

  const redirectToSurveyJSCreator = () => {
    let path = "/survey-templates/creator";
    history.push(path);
  };

  const handleOk = () => {
    setIsEditorModalVisible(false);
  };

  const loadTemplates = async () => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await getSurveyTemplates(
          token,
          pageState.startIndex,
          itemsPerPage,
          pageState.search
        );
        if (res && res.result && !res.error) {
          let data = [];
          if (pageState.loadingMore)
            data = pageState.data.concat(res.result.data);
          else if (pageState.initLoading) {
            data = res.result.data;
          }
          setPageState({
            ...pageState,
            data,
            templatelist: data,
            initLoading: false,
            loadingMore: false,
            totalCount: res.result.total,
            startIndex: pageState.startIndex + res.result.data.length,
            canLoadMore: res.result.data.length >= itemsPerPage,
          });
        } else {
          setPageState({
            ...pageState,
            initLoading: false,
            loadingMore: false,
            data: [],
            templatelist: [],
          });
          SimpleMessage("error", i18n.t("load-templates-error"));
        }
      }
    } catch (err) {
      SimpleMessage("error", i18n.t("load-templates-error"));
    }
    return null;
  };

  const onLoadMore = async () => {
    setPageState({
      ...pageState,
      loadingMore: true,
      templatelist: pageState.data.concat(
        [...new Array(itemsPerPage)].map(() => ({
          loading: true,
          id: uuidv4(),
        }))
      ),
    });
  };

  const onSearchClick = async (value) => {
    setPageState({
      ...pageState,
      search: value,
      startIndex: 0,
      initLoading: true,
      loadingMore: false,
    });
  };

  const onAddTemplateClick = () => {
    setPageState({
      ...pageState,
      drawerVisible: true,
      currentTemplate: null,
    });
  };

  const openDrawer = (template) => {
    setPageState({
      ...pageState,
      drawerVisible: true,
      currentTemplate: template,
    });
  };

  const closeDrawer = (template, action) => {
    switch (action) {
      case ENTITY_ACTIONS.CREATE:
      case ENTITY_ACTIONS.UPDATE:
        var startIndex = pageState.startIndex;
        var templateList = pageState.templatelist;
        let oldDefaultTemplate = templateList.find((tmp) => tmp.isDefault);
        if (
          template.isDefault &&
          template.surveyTemplateId !== oldDefaultTemplate.surveyTemplateId
        ) {
          let oldDefaultIndex = templateList.findIndex((tmp) => tmp.isDefault);
          if (template.surveyTemplateId > 0) {
            let newDefaultIndex = templateList.findIndex(
              (tmp) =>
                !tmp.isDefault &&
                template.surveyTemplateId === tmp.surveyTemplateId
            );
            templateList.splice(newDefaultIndex, 1, template);
            templateList[oldDefaultIndex].isDefault = false;
          } else {
            templateList.splice(0, 0, template);
            templateList[oldDefaultIndex + 1].isDefault = false;
          }
        } else {
          let oldIndex = templateList.findIndex(
            (tmp) => template.surveyTemplateId === tmp.surveyTemplateId
          );
          if (oldIndex == -1) {
            startIndex++;
          } else {
            templateList.splice(oldIndex, 1);
          }
          templateList.splice(0, 0, template);
        }

        setPageState({
          ...pageState,
          drawerVisible: false,
          templatelist,
          data: templateList,
          totalCount: pageState.totalCount + 1,
          startIndex,
        });
        break;
      case ENTITY_ACTIONS.DELETE:
        let templates = pageState.templatelist.filter(
          (tmp) => tmp.surveyTemplateId != template.surveyTemplateId
        );
        setPageState({
          ...pageState,
          drawerVisible: false,
          templatelist: templates,
          data: templates,
          totalCount: pageState.totalCount - 1,
        });
        break;
      default:
        setPageState({
          ...pageState,
          drawerVisible: false,
        });
        break;
    }
  };

  const { initLoading, templatelist, loadingMore, canLoadMore, totalCount } =
    pageState;

  const loadMore =
    !initLoading && !loadingMore && canLoadMore ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore}>{i18n.t("load-more")}</Button>
      </div>
    ) : null;

  return (
    <div className="survey-templates-grid">
      <Title level={4} style={{ margin: "0px" }}>
        {i18n.t("survey-templates")} ({totalCount})
      </Title>
      <Space>
        <Button
          id="add-template"
          style={{ justifySelf: "start" }}
          type="primary"
          icon={<SolutionOutlined />}
          onClick={onAddTemplateClick}
        >
          {i18n.t("add-template")}
        </Button>
        <Button
          id="surveyCreatorModal"
          style={{ justifySelf: "start" }}
          type="primary"
          icon={<FormOutlined />}
          onClick={redirectToSurveyJSCreator}
        >
          Survey Creator
        </Button>
      </Space>
      <Search
        loading={initLoading}
        placeholder={i18n.t("search-templates")}
        onSearch={onSearchClick}
        allowClear
        enterButton
      />
      <Skeleton loading={initLoading} active rows={10}>
        <List
          locale={{
            emptyText: (
              <EmptyDescription
                text={i18n.t("no-templates")}
                symbol="😥"
                label="DISAPPOINTED BUT RELIEVED FACE"
              />
            ),
          }}
          rowKey="surveyTemplateId"
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 5,
            xxl: 7,
          }}
          loadMore={loadMore}
          dataSource={templatelist}
          renderItem={(item) => (
            <List.Item style={{ textAlign: "center" }}>
              <Skeleton loading={item.loading} active>
                <Space direction="vertical">
                  <SurveyTemplateCard template={item} openDrawer={openDrawer} />
                </Space>
              </Skeleton>
            </List.Item>
          )}
        />
      </Skeleton>
      <SurveyTemplateDrawer
        template={pageState.currentTemplate}
        visible={pageState.drawerVisible}
        onClose={closeDrawer}
      />
    </div>
  );
};

export default SurveyTemplatesPage;
