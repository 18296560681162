import React, { useContext } from "react";
import "./support.css";
import {
  MailTwoTone,
  FilePdfTwoTone
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthProvider";
import {
  downloadManual
} from "../../../services/ApiService";
import {
  API_REQUESTS
} from "../../utils/utils";

const Support = () => {
  const { t } = useTranslation();
  const supportMail = "support@breathomix.com";
  const infoMail = "info@breathomix.com";
  const manualName = process.env.REACT_APP_BREATHBASE_MANUAL_PATH;
  const { getAccessToken } = useContext(AuthContext);

  const btnManualClick = async () => {
    const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
    await downloadManual(token);
  };

  return (
    <div className="support-grid">
      <span>{t("support-first-line")}</span>
      <span>
        <MailTwoTone
          style={{
            fontSize: "20px",
            verticalAlign: "middle",
            marginRight: "10px",
          }}
        />
        <a
          className="breathbase-link"
          href={`mailto:${supportMail}`}
        >
          {supportMail}
        </a>
      </span>
      <span>{t("support-second-line")}</span>
      <span>
        <MailTwoTone
          style={{
            rotate: "180",
            fontSize: "20px",
            verticalAlign: "middle",
            marginRight: "10px",
          }}
        />
        <a
          className="breathbase-link"
          href={`mailto:${infoMail}`}
        >
          {infoMail}
        </a>
      </span>
      <span>{t("bb-manual")}</span>
      <span>
        <FilePdfTwoTone
          style={{
            fontSize: "20px",
            verticalAlign: "middle",
            marginRight: "10px",
          }}
        />
        <a
          className="breathbase-link"
          href="#"
          onClick={btnManualClick}
        >
          {manualName}
        </a>
      </span>
    </div>
  );
};
export default Support;
