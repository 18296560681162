import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SurveyQuestionnaire from "../../survey/survey-questionnaire/survey-questionnaire";
import SurveyJSQuestionnaire from "../../survey/survey-questionnaire/surveyjs-questionnaire";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

const SurveyTemplatePreview = (props) => {
  const { t } = useTranslation();
  const [templateJson, setTemplateJson] = useState({});
  const [title, setTitle] = useState(t("survey-templates-preview"));
  const [previewVisible, setPreviewVisible] = useState(false);

  useEffect(() => {
    if (props.template) {
      setPreviewVisible(props.visible);
      if (props.template.templateJson && props.visible) {
        setTemplateJson(JSON.parse(props.template.templateJson));
        setTitle(t("survey-templates-preview") + " - " + props.template.name);
      }
    }
  }, [props]);

  return (
    <Modal
      title={title}
      centered
      visible={previewVisible}
      onOk={props.onPreviewClosed}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={props.onPreviewClosed}
      bodyStyle={{
        height: "600px",
        overflowY: props.isSurveyJS ? "scroll" : null,
      }}
      width={1000}
    >
      {props.isSurveyJS === true ? (
        <SurveyJSQuestionnaire
          surveyTemplateJson={templateJson}
          preview={true}
        />
      ) : (
        <SurveyQuestionnaire surveyTemplateJson={templateJson} />
      )}
    </Modal>
  );
};

SurveyTemplatePreview.propTypes = {
  visible: PropTypes.bool.isRequired,
  onPreviewClosed: PropTypes.func.isRequired,
  isSurveyJS: PropTypes.func.isRequired,
};

export default SurveyTemplatePreview;
