import React, { createContext, useState } from "react";
import { useTranslation } from "react-i18next";

export const ResearchContext = createContext();

const ResearchProvider = (props) => {
  const { i18n } = useTranslation();
  const [theme, setTheme] = useState("dark");
  const [preferredLanguage, setPreferredLanguage] = useState(
    localStorage.getItem("lang")
  );
  const [collapsed, setCollapsed] = useState(false);
  const [isInMaintenance, setIsInMaintenance] = useState(false);

  const { fetch: originalFetch } = window;

  // Fetch intercepter for Maintenance Check
  window.fetch = async (...args) => {
    let [resource, config] = args;
    const response = await originalFetch(resource, config);
    if (response.status === 503) {
      setIsInMaintenance(true);
    }
    return response;
  }

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
    localStorage.setItem("lang", code);
    setPreferredLanguage(code);
  };

  return (
    <ResearchContext.Provider
      value={{
        theme,
        setTheme,
        preferredLanguage,
        changeLanguage,
        isInMaintenance,
        collapsed,
        setCollapsed: () => {
          setCollapsed(!collapsed);
        },
      }}
    >
      {props.children}
    </ResearchContext.Provider>
  );
};

export default ResearchProvider;