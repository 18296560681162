import React, { useState, useEffect, useContext } from "react";
import { Space, Tooltip, Form, Tag, Typography, Select, Spin } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthProvider";
import { API_REQUESTS } from "../../utils/utils";
import {
  getStudies,
  icdSearch,
  getCountries,
} from "../../../services/ApiService";
const { Text } = Typography;
const { Option } = Select;

const QuestionItemDropdown = (props) => {
  const { t } = useTranslation();
  const { getAccessToken } = useContext(AuthContext);
  const [question, setQuestion] = useState({});
  const [sourceOptions, setSourceOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);

  const loadOptions = async () => {
    if (props.question.options) {
      setSourceOptions(
        props.question.options.map((d) => (
          <Option key={d.value} value={d.value}>
            {t("survey:" + d.key)}
          </Option>
        ))
      );
    } else {
      setLoading(true);
      let result = null;
      switch (props.question.source) {
        case "studies":
          result = await loadStudies();
          if (result) {
            setSourceOptions(
              result.data.map((s) => <Option key={s.id}>{s.name}</Option>)
            );
          }
          break;
        case "countries":
          const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
          if (token) {
            result = await getCountries(token);
          }
          if (result) {
            setSourceOptions(
              result.map((s) => <Option key={s.alpha2Code}>{s.name}</Option>)
            );
          }
          break;
        default:
          break;
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setQuestion(props.question);
    loadOptions();
  }, [props]);

  const loadStudies = async () => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await getStudies(token, 0, 50, null);
        if (res && res.result && !res.error) {
          return res.result;
        }
      }
    } catch (err) {
      console.error(err);
    }
    return null;
  };

  const loadICD = async () => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      return await icdSearch(token, searchTerm, "en");
    } catch (err) {
      console.error(err);
    }
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (searchTerm && searchTerm.length > 2) {
        setLoading(true);
        let result = null;
        switch (question.source) {
          case "icd":
            result = await loadICD();
            break;
          default:
            break;
        }
        if (result) {
          let healthItem = {
            title: t("survey:survey-health-control"),
            theCode: "",
          };
          result = [...result, healthItem];
          setSourceOptions(
            result.map((icd) => (
              <Option
                key={icd.theCode + ":" + icd.title.replace(/(<([^>]+)>)/gi, "")}
              >
                {parse(`<strong>${icd.theCode}</strong> ${icd.title}`)}
              </Option>
            ))
          );
        }
        setLoading(false);
      }
    };
    const timer = setTimeout(() => {
      fetchData();
    }, 800);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  const tagRender = (opt) => {
    const { label, closable, onClose } = opt;

    return (
      <Tooltip title={label}>
        <Tag
          color={question.tagColor}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          <Text ellipsis={true} style={{ maxWidth: "100px", color: "white" }}>
            {label}
          </Text>
        </Tag>
      </Tooltip>
    );
  };

  return (
    <Form.Item
      hasFeedback={question.feedback}
      colon={false}
      key={question.id}
      name={question.id}
      initialValue={question.value}
      label={
        question.label && (
          <Space>
            {t("survey:" + question.label)}
            {question.tooltip && (
              <Tooltip title={parse(t("survey:" + question.tooltip))}>
                <InfoCircleOutlined style={{ color: question.tooltipColor }} />
              </Tooltip>
            )}
          </Space>
        )
      }
      rules={
        question.rules
          ? question.rules.map((r) => {
            r.message = !r.message.startsWith("survey:") ? t("survey:" + r.message) : r.message;
            return r;
          })
          : null
      }
    >
      <Select
        mode={question.mode === "single" ? null : question.mode}
        notFoundContent={loading ? <Spin size="small" /> : null}
        loading={loading}
        tagRender={tagRender}
        allowClear
        disabled={question.readonly}
        placeholder={t("survey:" + question.placeholder)}
        filterOption={(input, option) =>
          question.source == "countries"
            ? option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0 ||
            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            : true
        }
        showSearch={
          question.mode !== "single" || question.source === "countries"
        }
        onSearch={
          question.mode !== "single" || question.source === "countries"
            ? (e) => setSearchTerm(e)
            : null
        }
      >
        {sourceOptions}
      </Select>
    </Form.Item>
  );
};

export default QuestionItemDropdown;
