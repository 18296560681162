import { Button, Col, message, Row, Spin, Typography, Grid } from "antd";
import "./measurement-details-page.css";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MeasurementDetailsBar from "../../components/measurement-details-bar/measurement-details-bar";
import SignalChart from "../../components/signal-chart/signal-chart";
import {
  ANALYSIS_RESULT_FETCH_MODE,
  API_REQUESTS,
  APP_ROLES,
  DEVICE_MODE,
  MEASUREMENT_PAGES,
  MEASUREMENT_STATUS,
  SIGNAL_CHART_MODE,
} from "../../utils/utils";
import AnalysisResults from "../../components/analysis-results/analysis-results";
import { AuthContext } from "../../contexts/AuthProvider";
import {
  getMeasurementById,
  getMeasurements,
} from "../../../services/ApiService";
import { PageSettingsContext } from "../../contexts/PageSettingsProvider";
import moment from "moment";

const { Title } = Typography;
const { useBreakpoint } = Grid;

const MeasurementDetailsPage = (props) => {
  const { getAccessToken, user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [measurement, setMeasurement] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [measurementId, setMeasurementId] = useState(null);
  const [hasDiagnosticModels, setHasDiagnosticModels] = useState(false);
  const screens = useBreakpoint();
  const [signalGraphSpan, setSignalGraphSpan] = useState("100%");
  const { measurementsFilterState, setMeasurementsFilterState } =
    useContext(PageSettingsContext);
  const [hasPrevMeasurement, setHasPrev] = useState(false);
  const [hasNextMeasurement, setHasNext] = useState(false);

  useEffect(() => {
    if (measurement && user) {
      const newEvaluation =
        measurement?.diagnosticModels?.length > 0 &&
        (user.role === APP_ROLES.ADMIN || user.role === APP_ROLES.CLINICIAN);
      setHasDiagnosticModels(newEvaluation);
    }
  }, [measurement, user]);

  useEffect(() => {
    if (hasDiagnosticModels) {
      if (screens?.xxl) {
        setSignalGraphSpan("70%");
        return;
      }
      if (screens?.xl) {
        setSignalGraphSpan("60%");
        return;
      }
      setSignalGraphSpan("50%");
      return;
    }
    setSignalGraphSpan("100%");
  }, [screens, hasDiagnosticModels]);

  useEffect(() => {
    setMeasurementId(props.match.params.id);
  }, [props.match.params.id]);

  useEffect(() => {
    if (measurementId) initMeasurement();
  }, [measurementId]);

  const initMeasurement = async () => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      updateAdjacentMeasurementsState(measurementsFilterState);
      if (token) {
        var response = await getMeasurementById(measurementId, token);
        setMeasurement(response);
      }
    } catch (ex) {
      setMeasurement(null);
      message.error(t(ex.error));
    } finally {
      setIsPageLoading(false);
    }
  };

  const updateAdjacentMeasurementsState = ({
    pageSize,
    currentPage,
    totalMeasurements,
    selectedMeasurementIndex,
  }) => {
    if (typeof selectedMeasurementIndex != "number") {
      return;
    }

    const lastPage = Math.ceil(totalMeasurements / pageSize);
    const lastIndex =
      pageSize - (pageSize * currentPage - totalMeasurements) - 1;
    setHasPrev(currentPage < lastPage || selectedMeasurementIndex != lastIndex);
    setHasNext(selectedMeasurementIndex > 0 || currentPage > 1);
  };

  const getNextMeasurement = (token, measurementIndex) => {
    const { searchTerm, sortField, sortOrder } = measurementsFilterState;
    return getMeasurements(
      token,
      measurementIndex,
      1,
      searchTerm,
      sortField,
      sortOrder
    );
  };

  const onMeasurementChange = async (isNext) => {
    try {
      setIsPageLoading(true);
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let { pageSize, currentPage, selectedMeasurementIndex } =
          measurementsFilterState;
        const indexDeviation = isNext ? -1 : 1;
        const measurementListIndex =
          pageSize * (currentPage - 1) +
          selectedMeasurementIndex +
          indexDeviation;
        const measurement = (
          await getNextMeasurement(token, measurementListIndex)
        ).result.data[0];

        if (measurement?.measurementStatus === MEASUREMENT_STATUS.IN_PROGRESS) {
          props.history.push(`/new-measurement/${measurement.id}`);
          return;
        }

        const lastIndex = pageSize - 1;
        selectedMeasurementIndex = selectedMeasurementIndex + indexDeviation;
        if (!isNext && selectedMeasurementIndex === lastIndex + 1) {
          currentPage++;
          selectedMeasurementIndex = 0;
        }

        if (isNext && selectedMeasurementIndex < 0) {
          currentPage--;
          selectedMeasurementIndex = lastIndex;
        }

        setMeasurement(measurement);
        setMeasurementsFilterState({
          ...measurementsFilterState,
          selectedMeasurementIndex,
          currentPage,
        });

        props.history.replace(`/measurements/${measurement.id}`);
        updateAdjacentMeasurementsState({
          pageSize,
          currentPage,
          totalMeasurements: measurementsFilterState.totalMeasurements,
          selectedMeasurementIndex,
        });
      }
    } catch (ex) {
      setMeasurement(null);
      message.error(t(ex.error));
    } finally {
      setIsPageLoading(false);
    }
  };

  return (
    <div className="details-container">
      <Title level={4}>
        {t("measurement")} {measurementId}
      </Title>
      <div className="measurement-content">
        <Spin
          style={{ justifySelf: "center", alignSelf: "center" }}
          spinning={isPageLoading}
          tip={t("loading")}
        >
          {measurement && (
            <>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <MeasurementDetailsBar
                    currentPage={MEASUREMENT_PAGES.DETAILS_PAGE}
                    measurement={measurement}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col flex={signalGraphSpan}>
                  <SignalChart
                    chartId="detailsSignalchart"
                    chartMode={SIGNAL_CHART_MODE.STATIC}
                    measurementId={measurement?.id}
                    measurementType={measurement?.measurementType}
                    key={measurement?.id}
                    sensorData={measurement?.sensorData}
                    deviceMode={
                      measurement?.ambientMeasurementId
                        ? DEVICE_MODE.CONTINUOUS
                        : DEVICE_MODE.NORMAL
                    }
                    startDate={moment.utc(measurement?.dateCreated)?.toDate()}
                  />
                </Col>
                {hasDiagnosticModels && (
                  <Col flex="auto">
                    <AnalysisResults
                      diagnosticModels={measurement?.diagnosticModels}
                      measurementId={measurement?.id}
                      areAnalysisResultsReady={true}
                      fetchMode={ANALYSIS_RESULT_FETCH_MODE.REST}
                      key={measurement?.id}
                      analyseResult={measurement.analyseResult}
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
        </Spin>
      </div>
      <Row justify="center" gutter={[24, 0]}>
        <Col>
          <Button onClick={() => props.history.goBack()}>{t("back")}</Button>
        </Col>
        <Col>
          <Button
            type="primary"
            disabled={!hasPrevMeasurement || isPageLoading}
            onClick={() => {
              onMeasurementChange(false);
            }}
          >
            {t("previous")}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{ padding: "0px 27px 0px 27px" }}
            disabled={!hasNextMeasurement || isPageLoading}
            onClick={() => {
              onMeasurementChange(true);
            }}
          >
            {t("next")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default MeasurementDetailsPage;
