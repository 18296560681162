import React, { useState, useEffect, useContext } from "react";
import {
  Drawer,
  List,
  Row,
  Input,
  Skeleton,
  AutoComplete,
  Space,
  Tag,
  Typography,
  Button,
  Tooltip,
  Modal,
} from "antd";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthProvider";
import { API_REQUESTS } from "../../utils/utils";
import { Link } from "react-router-dom";
import { assignDeviceUsers, getUsers } from "../../../services/ApiService";
import { MinusCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import EmptyDescription from "../simple-components/empty-description/empty-description";
import SimpleMessage from "../simple-components/simple-message/simple-message";
const { Text } = Typography;
const { confirm } = Modal;

const AssignDeviceUsers = (props) => {
  const { getAccessToken } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [foundUsers, setFoundUsers] = useState([]);
  const [options, setOptions] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [userSearchTerm, setUserSearchTerm] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (!visible) setAssignedUsers(props.users);
    setVisible(props.visible);
    setFoundUsers([]);
    setOptions([]);
    setUserSearchTerm(null);
  }, [props]);

  const getOptions = (users) => {
    return users.map((user) => {
      return {
        label: (
          <Space>
            <Tag color={`var(--${user.role.toLowerCase()}-color)`}>
              {user.role.charAt(0)}
            </Tag>
            <span>{user.name}</span>
          </Space>
        ),
        value: user.id,
      };
    });
  };

  useEffect(() => {
    const fetchUsers = async () => {
      if (userSearchTerm && userSearchTerm.length > 1) {
        try {
          const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
          if (token) {
            let res = await getUsers(token, 0, 100, userSearchTerm);
            if (res && res.data && !res.error) {
              let filteredUsers = res.data.filter(
                (p) =>
                  !assignedUsers.find((user) => user.id === p.id) &&
                  p.role != "Admin"
              );
              if (filteredUsers.length > 0) {
                setFoundUsers(filteredUsers);
                setOptions(getOptions(filteredUsers));
              }
            }
          }
        } catch (err) {
          console.error(err);
        }
      } else {
        setFoundUsers([]);
      }
    };
    const timer = setTimeout(() => {
      fetchUsers();
    }, 800);
    return () => clearTimeout(timer);
  }, [userSearchTerm]);

  const closeDrawer = () => {
    if (dirty) {
      //show confirm
      confirm({
        cancelText: t("profile-assign-devices-cancel"),
        icon: (
          <QuestionCircleOutlined
            style={{ color: "var(--question-icon-color)" }}
          />
        ),
        title: t("profile-assign-devices-leave-title"),
        content: t("profile-assign-devices-leave-content"),
        onOk: () => clearAndClose(),
      });
    } else {
      clearAndClose();
    }
  };

  const clearAndClose = (shouldUpdateParent = false) => {
    setDirty(false);
    setFoundUsers([]);
    setOptions([]);
    setUserSearchTerm(null);
    props.onClose(assignedUsers, shouldUpdateParent);
  };

  const onSaveClick = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let data = {
          deviceId: props.deviceId,
          userIds: assignedUsers.map((user) => {
            return user.id;
          }),
        };
        let res = await assignDeviceUsers(data, token);
        if (res && res.result) {
          clearAndClose(true);
          SimpleMessage(
            "success",
            t("assign-user-devices-success", {
              count: assignedUsers.length,
              device: props.serialNumber,
            })
          );
        } else {
          SimpleMessage("error", t("assign-user-devices-success"));
        }
      }
    } catch (err) {
      SimpleMessage("error", err.message);
    }
    setLoading(false);
  };

  const handleSearchUsers = (value) => {
    setUserSearchTerm(value);
  };

  const onSelectUser = (value) => {
    setAssignedUsers([
      ...assignedUsers,
      foundUsers.find((user) => user.id === value),
    ]);
    setUserSearchTerm(null);
    setFoundUsers([]);
    setOptions([]);
    setDirty(true);
  };

  const removeUser = (id) => {
    setAssignedUsers(assignedUsers.filter((user) => user.id !== id));
    setDirty(true);
  };

  return (
    <Drawer
      title={t("assign-user-devices")}
      width={450}
      onClose={closeDrawer}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            danger
            onClick={() => {
              setAssignedUsers([]);
              setDirty(true);
            }}
            style={{ marginRight: 8, float: "left" }}
          >
            {t("assign-users-remove-all")}
          </Button>
          <Button
            id="cancel-assign-user"
            onClick={closeDrawer}
            style={{ marginRight: 8 }}
          >
            {t("profile-assign-devices-cancel")}
          </Button>
          <Button
            id="save-assign-user"
            disabled={!dirty}
            onClick={onSaveClick}
            type="primary"
          >
            {t("profile-assign-devices-confirm")}
          </Button>
        </div>
      }
    >
      <Skeleton loading={loading} active paragraph={{ rows: 5 }}>
        <Row>
          <AutoComplete
            id="search-users"
            dropdownMatchSelectWidth={250}
            style={{ width: "100%" }}
            onSelect={onSelectUser}
            onSearch={handleSearchUsers}
            options={options}
            value={userSearchTerm}
          >
            <Input.Search
              placeholder={t("profile-assign-users-search-placeholder")}
              enterButton
            />
          </AutoComplete>
        </Row>
        <Row>
          <Skeleton loading={false} active rows={10}>
            <List
              style={{ width: "100%" }}
              locale={{
                emptyText: (
                  <EmptyDescription
                    text={t("no-users")}
                    symbol="😥"
                    label="DISAPPOINTED BUT RELIEVED FACE"
                  />
                ),
              }}
              rowKey="name"
              dataSource={assignedUsers}
              renderItem={(item) =>
                item && (
                  <List.Item className="user-item">
                    <List.Item.Meta
                      avatar={
                        <div style={{ width: "50px" }}>
                          <Tag
                            color={`var(--${item.role.toLowerCase()}-color)`}
                          >
                            {item.role.charAt(0)}
                          </Tag>
                        </div>
                      }
                      title={
                        <Link
                          to={{
                            pathname: "/users/details",
                            id: item.id,
                          }}
                        >
                          <Text>{item.name}</Text>
                        </Link>
                      }
                    />
                    <Tooltip title={t("assign-user-devices-remove")}>
                      <MinusCircleOutlined
                        className="remove-users"
                        style={{ margin: "0 8px", fontSize: "20px" }}
                        onClick={() => removeUser(item.id)}
                      />
                    </Tooltip>
                  </List.Item>
                )
              }
            />
          </Skeleton>
        </Row>
      </Skeleton>
    </Drawer>
  );
};

export default AssignDeviceUsers;
