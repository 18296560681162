import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import React, { useEffect, useState, useRef } from "react";
import {
  INNER_SENSORS,
  OUTER_SENSORS,
  SENSOR_TYPE_CONFIGURATION,
  getChartName,
  getEmptyStateForChart,
} from "../../utils/utils";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "./calibrations-page.css";

const CalibrationsChart = (props) => {
  const { t } = useTranslation();
  const [removedSeries, setRemovedSeries] = useState([]);
  const chartContainer = useRef(null);

  useEffect(() => {
    if (props.chartData.length > 0) {
      chartContainer.current = am4core.create(props.id, am4charts.XYChart);
      createChart(props.chartData);
    }
  }, [props.chartData]);

  useEffect(() => {
    if (props.removedSeries) {
      var seriesToAdd = removedSeries.filter(
        (e) => !props.removedSeries.includes(e)
      );
      var seriesToRemove = props.removedSeries.filter(
        (e) => !removedSeries.includes(e)
      );
      if (seriesToRemove.length > 0) {
        seriesToRemove.forEach((series) =>
          chartContainer.current?.map.getKey(series).hide()
        );
      }
      if (seriesToAdd.length > 0) {
        seriesToAdd.forEach((series) =>
          chartContainer.current?.map.getKey(series).show()
        );
      }
      setRemovedSeries(props.removedSeries);
    }
  }, [props.removedSeries]);

  const createChart = (chartData) => {
    let chart = chartContainer.current;
    // Add data
    chart.data = chartData;

    var title = chart.titles.create();
    title.align = "left";
    title.fontSize = 20;
    title.marginBottom = 15;

    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.baseInterval = {
      timeUnit: "second",
      count: 1,
    };
    chart.yAxes.push(new am4charts.ValueAxis());
    addSeriesToChart(INNER_SENSORS, chart.series);
    addSeriesToChart(OUTER_SENSORS, chart.series);

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.maxTooltipDistance = -1;

    chart.plotContainer.events.on("hit", function (ev) {
      var selectedFactorsFromChart = props.dataSourceChart.find((element) => {
        return (
          moment.utc(element.dateCreated).unix() ==
          moment.utc(dateAxis.tooltipDate).unix()
        );
      });
      dateAxis.axisRanges.clear();
      let selectedRange = dateAxis.axisRanges.create();
      selectedRange.date = dateAxis.tooltipDate;
      selectedRange.grid.stroke = am4core.color("#A96478");
      selectedRange.grid.strokeWidth = 2;
      selectedRange.grid.strokeOpacity = 1;
      props.onSelectFactorsFromChart(selectedFactorsFromChart);
    });
    chart.logo.disabled = true;
  };

  const addSeriesToChart = (mapper, targetSeries) => {
    var lineSeries;
    for (let element of mapper) {
      var sensorType = SENSOR_TYPE_CONFIGURATION.filter(
        (conf) =>
          conf.inner.includes(parseInt(element)) ||
          conf.outer.includes(parseInt(element))
      )[0].sensorType;
      lineSeries = targetSeries.push(new am4charts.LineSeries());
      lineSeries.id = element;
      lineSeries.dataFields.valueY = element;
      lineSeries.dataFields.dateX = "date";
      lineSeries.name = "Sensor" + element;
      lineSeries.tooltipSensor = sensorType + ": " + element;
      lineSeries.strokeWidth = 2;

      var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = am4core.color("#fff");
      bullet.circle.strokeWidth = 2;
      lineSeries.tooltipHTML = `<center><strong><div>{tooltipSensor}</div><div>{dateX}</div></strong></center>`;
    }
  };

  return (
    <div className="calibration-section">
      <h1>{getChartName(props.chartType, t)}</h1>
      <div className="calibration-chart">
        <div id={props.id} />
        {props.chartData.length == 0 && (
          <div className="calibration-empty-state">
            {getEmptyStateForChart(props.chartType, t)}
          </div>
        )}
      </div>
    </div>
  );
};

export default CalibrationsChart;
