import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { getDeviceDashboard } from "../../../services/ApiService";
import i18n from "../../../i18n";
import { API_REQUESTS } from "../../utils/utils";
import { AuthContext } from "../../contexts/AuthProvider";
import { Tag, Spin } from "antd";
import "./devices-chart.css";
const { CheckableTag } = Tag;

class DevicesChart extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      devChecked: true,
      qaChecked: true,
      prodChecked: true,
      loading: false,
      deviceData: null
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    this.initChart();
    await this.fetchData();
  }

  componentDidUpdate() {
    this.loadChartData();
  }

  initChart = () => {
    this.chart = am4core.create("devices-chart", am4charts.PieChart);
    this.chart.startAngle = 160;
    this.chart.endAngle = 380;

    // Let's cut a hole in our Pie chart the size of 40% the radius
    this.chart.innerRadius = am4core.percent(30);

    // Add and configure Series
    this.pieSeries0 = this.chart.series.push(new am4charts.PieSeries());
    this.pieSeries0.dataFields.value = "dev";
    this.pieSeries0.dataFields.category = "statisticType";
    this.pieSeries0.slices.template.stroke = new am4core.InterfaceColorSet().getFor(
      "background"
    );
    this.pieSeries0.slices.template.strokeWidth = 1;
    this.pieSeries0.slices.template.strokeOpacity = 1;

    // Disabling labels and ticks on inner circle
    this.pieSeries0.labels.template.disabled = true;
    this.pieSeries0.ticks.template.disabled = true;

    // Disable sliding out of slices
    this.pieSeries0.slices.template.states.getKey(
      "hover"
    ).properties.shiftRadius = 0;
    this.pieSeries0.slices.template.states.getKey("hover").properties.scale = 1;
    this.pieSeries0.radius = am4core.percent(30);
    this.pieSeries0.innerRadius = am4core.percent(20);

    // Add and configure Series
    this.pieSeries = this.chart.series.push(new am4charts.PieSeries());
    this.pieSeries.dataFields.value = "qa";
    this.pieSeries.dataFields.category = "statisticType";
    this.pieSeries.slices.template.stroke = new am4core.InterfaceColorSet().getFor(
      "background"
    );
    this.pieSeries.slices.template.strokeWidth = 1;
    this.pieSeries.slices.template.strokeOpacity = 1;

    // Disabling labels and ticks on inner circle
    this.pieSeries.labels.template.disabled = true;
    this.pieSeries.ticks.template.disabled = true;

    // Disable sliding out of slices
    this.pieSeries.slices.template.states.getKey(
      "hover"
    ).properties.shiftRadius = 0;
    this.pieSeries.slices.template.states.getKey("hover").properties.scale = 1;
    this.pieSeries.radius = am4core.percent(55);
    this.pieSeries.innerRadius = am4core.percent(40);

    // Add second series
    this.pieSeries2 = this.chart.series.push(new am4charts.PieSeries());
    this.pieSeries2.dataFields.value = "prod";
    this.pieSeries2.dataFields.category = "statisticType";
    this.pieSeries2.slices.template.stroke = new am4core.InterfaceColorSet().getFor(
      "background"
    );
    this.pieSeries2.slices.template.strokeWidth = 1;
    this.pieSeries2.slices.template.strokeOpacity = 1;
    this.pieSeries2.slices.template.states.getKey(
      "hover"
    ).properties.shiftRadius = 0.05;
    this.pieSeries2.slices.template.states.getKey("hover").properties.scale = 1;

    this.pieSeries2.labels.template.disabled = true;
    this.pieSeries2.ticks.template.disabled = true;
  };

  loadChartData = () => {
    let deviceData = this.state.deviceData;
    if (deviceData) {
      // Add data
      this.chart.data = [
        {
          statisticType: i18n.t("ready"),
          dev: deviceData.dev.readyDevicesCount,
          qa: deviceData.qa.readyDevicesCount,
          prod: deviceData.prod.readyDevicesCount,
        },
        {
          statisticType: i18n.t("buffering"),
          dev: deviceData.dev.bufferingDevicesCount,
          qa: deviceData.qa.bufferingDevicesCount,
          prod: deviceData.prod.bufferingDevicesCount,
        },
        {
          statisticType: i18n.t("in-progress"),
          dev: deviceData.dev.inProgressDevicesCount,
          qa: deviceData.qa.inProgressDevicesCount,
          prod: deviceData.prod.inProgressDevicesCount,
        },
        {
          statisticType: i18n.t("offline"),
          dev: deviceData.dev.offlineDevicesCount,
          qa: deviceData.qa.offlineDevicesCount,
          prod: deviceData.prod.offlineDevicesCount,
        },
      ];
    }

    
  };

  addChartLabel = () => {
    this.totalLabel = this.chart.seriesContainer.createChild(am4core.Label);
    this.totalLabel.textAlign = "middle";
    this.totalLabel.horizontalCenter = "middle";
    this.totalLabel.verticalCenter = "middle";

    let count = this.state.deviceData ? this.state.deviceData.totalDevicesCount : 0;
    this.totalLabel.text =
      "[font-size:18px]Total[/]\n[bold font-size:30px]" + count + "[/]";
  }

  setTotalDevices = () => {
    let total = 0;
    if (this.chart && this.totalLabel) {
      if (this.state.devChecked) {
        this.chart.data.forEach((el) => {
          total += el.dev;
        });
      }
      if (this.state.qaChecked) {
        this.chart.data.forEach((el) => {
          total += el.qa;
        });
      }
      if (this.state.prodChecked) {
        this.chart.data.forEach((el) => {
          total += el.prod;
        });
      }
      this.totalLabel.text =
        "[font-size:18px]Total[/]\n[bold font-size:30px]" + total + "[/]";
    }
  };

  fetchData = async () => {
    //this access token must be reused until there is a need to get a new one
    const token = await this.context.getAccessToken(
      API_REQUESTS.USER_IMPERSONATION
    );
    if (token) {
      let res = await getDeviceDashboard(token);
      if (res.result && !this.chart._disposed) {
        this.setState({ deviceData: res.result});
        this.addChartLabel();
      }
    }

    this.setState({
      loading: false,
    });
  };

  setDevChecked = () => {
    this.setState({ devChecked: !this.state.devChecked });
    if (!this.state.devChecked) {
      this.pieSeries0.show();
    } else {
      this.pieSeries0.hide();
    }
  };

  setQaChecked = () => {
    this.setState({ qaChecked: !this.state.qaChecked });
    if (!this.state.qaChecked) {
      this.pieSeries.show();
    } else {
      this.pieSeries.hide();
    }
  };
  setProdChecked = () => {
    this.setState({ prodChecked: !this.state.prodChecked });
    if (!this.state.prodChecked) {
      this.pieSeries2.show();
    } else {
      this.pieSeries2.hide();
    }
  };

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    this.setTotalDevices();

    return (
      <div className="device-chart-container">
        <Spin tip={i18n.t("loading")} spinning={this.state.loading}>
          <div
            style={{
              display: "grid",
              height: "100%",
              gridTemplateRows: "1fr auto",
            }}
          >
            <div id="devices-chart"></div>
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                gap: "5px",
                gridTemplateColumns: "auto auto auto",
              }}
            >
              <CheckableTag
                className="dev-tag"
                key="DEV"
                checked={this.state.devChecked}
                onChange={this.setDevChecked}
              >
                DEV
              </CheckableTag>
              <CheckableTag
                className="qa-tag"
                key="QA"
                checked={this.state.qaChecked}
                onChange={this.setQaChecked}
              >
                QA
              </CheckableTag>
              <CheckableTag
                className="prod-tag"
                key="PROD"
                checked={this.state.prodChecked}
                onChange={this.setProdChecked}
              >
                PROD
              </CheckableTag>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default DevicesChart;
