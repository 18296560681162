import React, { createContext, useState, useEffect } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { useHistory } from "react-router-dom";

export const TelemetryContext = createContext();

const TelemetryProvider = (props) => {
  const [appInsights, setAppInsights] = useState(null);
  let history = useHistory();

  useEffect(() => {
    var reactPlugin = new ReactPlugin();
    let ai = new ApplicationInsights({
      config: {
        instrumentationKey: process.env.REACT_APP_AZURE_APPINSIGHTS_KEY,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: history },
        },
      },
    });
    ai.loadAppInsights();
    setAppInsights(ai);
  }, []);

  return (
    <TelemetryContext.Provider value={appInsights}>
      {props.children}
    </TelemetryContext.Provider>
  );
};

export default TelemetryProvider;
