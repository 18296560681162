import React, { useState, useEffect } from "react";
import { Typography, Tooltip, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;
const QuestionItemLabel = (props) => {
  const { t } = useTranslation();
  const [question, setQuestion] = useState({});

  useEffect(() => {
    setQuestion(props.question);
  }, [props]);

  return (
    <Row>
      <Space>
        {question.isTitle ? (
          <Title
            strong
            level={question.level ?? 4}
            style={{ display: "inline" }}
          >
            {t(`survey:${question.label}`)}
          </Title>
        ) : (
          <Text>{t(`survey:${question.label}`)}</Text>
        )}
        {question.tooltip && (
          <Tooltip title={parse(t(`survey:${question.tooltip}`))}>
            <InfoCircleOutlined style={{ color: question.tooltipColor }} />
          </Tooltip>
        )}
      </Space>
    </Row>
  );
};

export default QuestionItemLabel;
