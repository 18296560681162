import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import "./dashboard-page.css";
import { Card, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import NewsFeed from "../../components/news-feed/news-feed";
import MeasurementsChart from "../../components/measurements-chart/measurements-chart";
import CommunityChart from "../../components/community-chart/community-chart";
import GreetingMessage from "../../components/greeting-message/greeting-message";
import Support from "../../components/support/support";
import { APP_ROLES } from "../../utils/utils";
import DevicesChart from "../../components/devices-chart/devices-chart";
import CommunityUserChart from "../../components/community-user-chart/community-user-chart";
import { PageSettingsContext } from "../../contexts/PageSettingsProvider";

const DashboardPage = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const { resetFilters } = useContext(PageSettingsContext);

  useEffect(() => {
    resetFilters();
  }, []);

  return (
    <div className="dashboard-content">
      <GreetingMessage name={user.name} />
      <div style={{ gridRow: "2" }}>
        <Row gutter={[20, 20]}>
          <Col flex="400px">
            <Card
              size="small"
              title={t("measurements")}
              className="dash-card-shadow"
              bordered={false}
            >
              <MeasurementsChart />
            </Card>
          </Col>
          <Col flex="400px">
            {user.role === APP_ROLES.RESEARCHER ||
              user.role === APP_ROLES.CLINICIAN ? (
              <Card
                size="small"
                title={t("support")}
                className="dash-card-shadow"
                bordered={false}
              >
                <Support />
              </Card>
            ) : (
              <Card
                size="small"
                title={t("devices")}
                className="dash-card-shadow"
                bordered={false}
              >
                <DevicesChart />
              </Card>
            )}
          </Col>
          <Col flex="auto">
            <Card
              size="small"
              title={t("news-blog")}
              className="dash-card-shadow"
              bordered={false}
            >
              <NewsFeed />
            </Card>
          </Col>
        </Row>

        <Row gutter={[20, 20]}>
          <Col flex="auto">
            <Card
              size="small"
              title={t("bb-community")}
              className="dash-card-shadow"
              bordered={false}
            >
              <CommunityUserChart />
            </Card>
          </Col>
        </Row>

      </div>
    </div>
  );
};

export default DashboardPage;
