import React, { useState } from "react";
import { Row, Col, Slider, InputNumber } from "antd";

const NumberScale = ({
  value,
  min,
  max,
  unit,
  decimals,
  readonly,
  onChange,
}) => {
  const [number, setNumber] = useState(0);

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const onNumberChange = (e) => {
    setNumber(e);
    triggerChange(e);
  };

  return (
    <Row>
      <Col span={20}>
        <Slider
          marks={{
            [min]: min + unit,
            [max]: {
              style: {
                color: "#f50",
              },
              label: <strong>{max + unit}</strong>,
            },
          }}
          value={value || number}
          onChange={onNumberChange}
          disabled={readonly}
          min={min}
          max={max}
          step={decimals ? 1 / Math.pow(10, decimals) : 1}
          precision={decimals ? value.decimals : 0}
        />
      </Col>
      <Col span={4}>
        <InputNumber
          value={value || number}
          onChange={onNumberChange}
          style={{ margin: "0 20px" }}
          disabled={readonly}
          min={min}
          max={max}
          step={decimals ? 1 / Math.pow(10, decimals) : 1}
          precision={decimals ? decimals : 0}
        />
      </Col>
    </Row>
  );
};

export default NumberScale;
