
const handleError = async (error) => {
    return Promise.reject(error && error.message);
}

const handleResponse = async (response) => {
    return new Promise((resolve, reject) => {
        if (response.ok) {
            const contentType = response.headers.get('Content-Type') || '';
            if (contentType && contentType.includes('application/json')) {
                // return json 
                response.json().then(json =>
                    resolve(json)
                ).catch(error => {
                    return Promise.reject('Invalid JSON: ' + error.message);
                });
            }
            else {
                resolve({});
            }
        }
        else {
            // return error message from response body
            response.text().then(text => reject(text));
        }
    });
}

export const createRequest = async (token, url, method = 'GET', mode = 'cors', requestModel = null, bodyModel = null) => {

    const requestOptions = {
        method: method.toUpperCase(),
        mode,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    };

    if (bodyModel)
        requestOptions.bodyModel = bodyModel;

    const queryString = (requestModel ? getQueryString(requestModel) : '');

    return fetch(url + queryString, requestOptions).then(handleResponse, handleError);
}

const getQueryString = (params) => {
    var esc = encodeURIComponent;
    var query = Object.keys(params)
        .filter(function (k) { if (!params[k]) { return false; } return true; })
        .map(k => params[k] ? (esc(k) + '=' + esc(params[k])) : '')
        .join('&');
    return query;
}

