import React, { useState, useEffect } from "react";
import { Space, Tooltip, InputNumber, Select } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import "./question-item-smoke.css";
const { Option } = Select;

const QuestionItemSmoke = React.forwardRef(
  ({
    value = {},
    question,
    onChange,
    calculateTobacco,
    changeTobaccoProduct,
    tobaccoList,
  }) => {
    const { t } = useTranslation();
    const [tobaccoType, setTobaccoType] = useState(0);
    const [amount, setAmount] = useState(0);
    const [years, setYears] = useState(0);

    const triggerChange = (changedValue) => {
      if (onChange) {
        onChange({
          tobaccoType,
          amount,
          years,
          ...value,
          ...changedValue,
        });
      }
    };

    useEffect(() => {
      if (amount && amount > 0) {
        calculateTobacco();
      }
    }, [amount]);

    useEffect(() => {
      if (years && years > 0) {
        calculateTobacco();
      }
    }, [years]);

    useEffect(() => {
      if (tobaccoType && tobaccoType > 0) {
        changeTobaccoProduct();
      }
    }, [tobaccoType]);

    const onTobaccoChanged = (changedValue) => {
      setTobaccoType(changedValue);
      triggerChange({
        tobaccoType: changedValue,
      });
    };

    const onAmountChanged = (changedValue) => {
      setAmount(changedValue);
      triggerChange({
        amount: changedValue,
      });
    };

    const onYearsChanged = (changedValue) => {
      setYears(changedValue);
      triggerChange({
        years: changedValue,
      });
    };

    return (
      <div className="smoke-item">
        <Space direction="vertical">
          {question.questions[0].label && (
            <Space>
              {t("survey:" + question.questions[0].label)}
              {question.questions[0].tooltip && (
                <Tooltip
                  title={parse(t("survey:" + question.questions[0].tooltip))}
                >
                  <InfoCircleOutlined
                    style={{
                      color: question.questions[0].tooltipColor,
                    }}
                  />
                </Tooltip>
              )}
            </Space>
          )}
          <Select
            style={{ width: "100%" }}
            onChange={onTobaccoChanged}
            placeholder={t("survey:" + question.questions[0].placeholder)}
            value={value?.tobaccoType}
          >
            {tobaccoList.map((s) => (
              <Option key={s.value} disabled={s?.isDisabled}>{t("survey:" + s.key)}</Option>
            ))}
          </Select>
        </Space>
        <Space direction="vertical">
          {question.questions[1].label && (
            <Space>
              {t("survey:" + question.questions[1].label)}
              {question.questions[1].tooltip && (
                <Tooltip
                  title={parse(t("survey:" + question.questions[1].tooltip))}
                >
                  <InfoCircleOutlined
                    style={{
                      color: question.questions[1].tooltipColor,
                    }}
                  />
                </Tooltip>
              )}
            </Space>
          )}
          <InputNumber
            style={{ width: "100%" }}
            onChange={onAmountChanged}
            value={value?.amount}
            placeholder={t("survey:" + question.questions[1].placeholder)}
            min={question.questions[1].min}
            max={question.questions[1].max}
            step={
              question.questions[1].decimals
                ? 1 / Math.pow(10, question.questions[1].decimals)
                : 1
            }
            precision={
              question.questions[1].decimals
                ? question.questions[1].decimals
                : 0
            }
          />
        </Space>
        <Space direction="vertical">
          {question.questions[2].label && (
            <Space>
              {t("survey:" + question.questions[2].label)}
              {question.questions[2].tooltip && (
                <Tooltip
                  title={parse(t("survey:" + question.questions[2].tooltip))}
                >
                  <InfoCircleOutlined
                    style={{
                      color: question.questions[2].tooltipColor,
                    }}
                  />
                </Tooltip>
              )}
            </Space>
          )}
          <InputNumber
            style={{ width: "100%" }}
            onChange={onYearsChanged}
            value={value?.years}
            placeholder={t("survey:" + question.questions[2].placeholder)}
            min={question.questions[2].min}
            max={question.questions[2].max}
            step={
              question.questions[2].decimals
                ? 1 / Math.pow(10, question.questions[2].decimals)
                : 1
            }
            precision={
              question.questions[2].decimals
                ? question.questions[2].decimals
                : 0
            }
          />
        </Space>
      </div>
    );
  }
);

export default QuestionItemSmoke;
