import React, { useContext } from "react";
import { Layout } from "antd";
import "./main-page.css";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import FooterContent from "../footer-content/footer-content";
import DashboardPage from "../../pages/dashboard-page/dashboard-page";
import ProfileMenu from "../../components/profile-menu/profile-menu";
import SidebarContent from "../sidebar-content/sidebar-content";
import { ResearchContext } from "../../contexts/ResearchProvider";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import NotFoundPage from "../../pages/not-found-page/not-found-page";
import ProfilePage from "../../pages/profile-page/profile-page";
import DevicesPage from "../../pages/devices-page/devices-page";
import UsersPage from "../../pages/users-page/users-page";
import StudiesPage from "../../pages/studies-page/studies-page";
import HeaderBreadcrumb from "../../components/header-breadcrumb/header-breadcrumb";
import ProtectedRoute from "../../utils/protected-route";
import { APP_ROLES } from "../../utils/utils";
import SurveyQuestionnaire from "../../survey/survey-questionnaire/survey-questionnaire";
import MeasurementsPage from "../../pages/measurements-page/measurements-page";
import CalibrationsPage from "../../pages/calibrations-page/calibrations-page";
import SpiroNosesPage from "../../pages/spironoses-page/spironoses-page";
import SurveyTemplatesPage from "../../pages/survey-templates-page/survey-templates-page";
import EditSurveyPage from "../../pages/measurement-edit-survey-page/edit-survey-page";
import MeasurementDetailsPage from "../../pages/measurement-details-page/measurement-details-page";
import { NewMeasurementContextProvider } from "../../contexts/NewMeasurementContext";
import SurveyCreatorContext from "../../contexts/SurveyCreatorContext";

const { Header, Content } = Layout;

const MainPage = () => {
  const { collapsed, setCollapsed } = useContext(ResearchContext);

  return (
    <BrowserRouter>
      <Layout className="main-layout">
        <SidebarContent />
        <Layout className="site-layout">
          <Header className="header-layout-grid">
            {collapsed ? (
              <MenuUnfoldOutlined
                className="trigger-content"
                onClick={setCollapsed}
              />
            ) : (
              <MenuFoldOutlined
                className="trigger-content"
                onClick={setCollapsed}
              />
            )}
            <HeaderBreadcrumb />
            <ProfileMenu />
          </Header>

          <Content className="site-layout-content">
            <Switch>
              <ProtectedRoute
                exact
                path="/"
                component={DashboardPage}
                roles={[
                  APP_ROLES.CLINICIAN,
                  APP_ROLES.RESEARCHER,
                  APP_ROLES.ADMIN,
                ]}
              />
              <ProtectedRoute
                exact
                path="/new-measurement"
                component={NewMeasurementContextProvider}
                roles={[APP_ROLES.CLINICIAN, APP_ROLES.RESEARCHER]}
              />
              <ProtectedRoute
                exact
                path="/new-measurement/:id"
                component={NewMeasurementContextProvider}
                roles={[APP_ROLES.CLINICIAN, APP_ROLES.RESEARCHER]}
              />
              <ProtectedRoute
                exact
                path="/measurements/edit-survey"
                component={EditSurveyPage}
                roles={[
                  APP_ROLES.CLINICIAN,
                  APP_ROLES.RESEARCHER,
                  APP_ROLES.ADMIN,
                ]}
              />
              <ProtectedRoute
                exact
                path="/measurements/:id"
                component={MeasurementDetailsPage}
                roles={[
                  APP_ROLES.CLINICIAN,
                  APP_ROLES.RESEARCHER,
                  APP_ROLES.ADMIN,
                ]}
              />
              <ProtectedRoute
                exact
                path="/devices"
                component={DevicesPage}
                roles={[
                  APP_ROLES.CLINICIAN,
                  APP_ROLES.RESEARCHER,
                  APP_ROLES.ADMIN,
                ]}
              />
              <ProtectedRoute
                exact
                path="/survey-templates"
                component={SurveyTemplatesPage}
                roles={[APP_ROLES.ADMIN]}
              />
              <ProtectedRoute
                exact
                path="/spironoses"
                component={SpiroNosesPage}
                roles={[APP_ROLES.ADMIN]}
              />
              <ProtectedRoute
                exact
                path="/spironoses/:id"
                component={CalibrationsPage}
                roles={[
                  APP_ROLES.CLINICIAN,
                  APP_ROLES.RESEARCHER,
                  APP_ROLES.ADMIN,
                ]}
              />
              <ProtectedRoute
                exact
                path="/users"
                component={UsersPage}
                roles={[APP_ROLES.ADMIN]}
              />
              <ProtectedRoute
                exact
                path="/studies"
                component={StudiesPage}
                roles={[
                  APP_ROLES.CLINICIAN,
                  APP_ROLES.RESEARCHER,
                  APP_ROLES.ADMIN,
                ]}
              />
              <ProtectedRoute
                exact
                path="/measurements"
                component={MeasurementsPage}
                roles={[
                  APP_ROLES.CLINICIAN,
                  APP_ROLES.RESEARCHER,
                  APP_ROLES.ADMIN,
                ]}
              />
              <ProtectedRoute
                exact
                path="/survey"
                component={SurveyQuestionnaire}
                roles={[APP_ROLES.ADMIN]}
              />
              <ProtectedRoute
                exact
                path="/users/details"
                component={ProfilePage}
                roles={[APP_ROLES.ADMIN]}
              />
              <ProtectedRoute
                exact
                path="/account"
                component={ProfilePage}
                roles={[
                  APP_ROLES.CLINICIAN,
                  APP_ROLES.RESEARCHER,
                  APP_ROLES.ADMIN,
                ]}
              />
              <ProtectedRoute
                exact
                path="/survey-templates/creator/:id"
                component={SurveyCreatorContext}
                roles={[
                  APP_ROLES.ADMIN,
                ]}
              />
              <ProtectedRoute
                exact
                path="/survey-templates/creator"
                component={SurveyCreatorContext}
                roles={[
                  APP_ROLES.ADMIN,
                ]}
              />
              <Route path="/404" component={NotFoundPage} />
              <Redirect to="/404" />
            </Switch>
          </Content>
          <FooterContent />
        </Layout>
      </Layout>
    </BrowserRouter>
  );
};
export default MainPage;
