import React from "react";
import { Layout } from "antd";
import "./navbar.css";
const { Header } = Layout;

const NavBar = () => {
  return (
    <Header className="header-grid">
      <img src="/img/logo-white.png" className="landing-logo" alt="logo" />
    </Header>
  );
};

export default NavBar;
