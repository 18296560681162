import React, { useEffect } from "react";
import "./landing-page.css";
import { Layout } from "antd";
import FooterContent from "../footer-content/footer-content";
import NavBar from "../navbar/navbar";
import LoginContent from "../../components/login-content/login-content";
import { isIE, isFirefox } from "react-device-detect";
import NotificationMessage from "../../components/notification-message/notification-message";
import { useTranslation } from "react-i18next";

const LandingPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isIE || isFirefox) {
      NotificationMessage(
        "warning",
        t("unsupported-browser-title"),
        t("unsupported-browser-desc"),
        "bottomRight"
      );
    }
  }, []);

  return (
    <Layout className="landing-page">
      <NavBar />
      <LoginContent />
      <FooterContent />
    </Layout>
  );
};

export default LandingPage;
