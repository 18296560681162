import "./survey-creator-page.css";
import React, { useContext, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Space,
  Tooltip,
  Switch,
  Spin
} from 'antd';
import { QuestionCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import SurveyCreator from "../../components/survey-creator/survey-creator";
import { useTranslation } from "react-i18next";
import { SurveyCreatorContext } from "../../contexts/SurveyCreatorContext";
import { AuthContext } from "../../contexts/AuthProvider";
import { useHistory } from "react-router-dom";
import {
  API_REQUESTS,
  ENTITY_TYPE,
} from "../../utils/utils";
import i18n from "../../../i18n";
import SimpleMessage from "../../components/simple-components/simple-message/simple-message";
import {
  upsertSurveyTemplate,
} from "../../../services/ApiService";
import { useParams } from "react-router-dom";
import { getSurveyTemplate, softDeleteEntity } from "../../../services/ApiService";
const { confirm } = Modal;


const SurveyCreatorPage = () => {

  const [visible, setVisible] = React.useState(false);
  const { getAccessToken } = useContext(AuthContext);
  const { surveyCreator, setSurveyTemplate, surveyTemplate } = useContext(SurveyCreatorContext);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);


  useEffect(() => {
    const fetchAsync = async () => {
      await fetchData();
    }

    if (id) {
      fetchAsync().catch(console.error);
    }
    else {
      setIsLoading(false);
    }
  }, [])

  const fetchData = async () => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await getSurveyTemplate(
          token,
          id
        );
        if (res && res.result && res.result.success) {
          setSurveyTemplate(res.result.template);
          form.setFieldsValue({ ...res.result.template });
        } else {
          history.push(`/404`);
        }
      }
    } catch (err) {
      SimpleMessage("error", t("load-templates-error"));
      setHasError(true);
    }
    setIsLoading(false);
  }

  const routeChange = () => {
    let path = `/survey-templates`;
    history.push(path);
  }

  const onSave = () => {
    setVisible(true);
  }

  const onDelete = () => {
    if (!surveyTemplate.isDefault) {
      confirm({
        cancelText: t("template-details-cancel"),
        icon: <QuestionCircleOutlined style={{ color: "rgb(24, 144, 255)" }} />,
        title: t("template-details-delete-title"),
        content: t("template-details-delete-content"),
        onOk: deleteTemplate,
      });
    } else {
      SimpleMessage("error", t("default-template-upsert-error"));
    }
  };

  const deleteTemplate = async () => {
    const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
    var data = {
      entityId: id,
      entityType: ENTITY_TYPE.SURVEY_TEMPLATE,
    };
    var res = await softDeleteEntity(data, token);
    if (res.success) {
      SimpleMessage("success", t("delete-template-success"));
      routeChange();
    } else {
      SimpleMessage("error", res.error);
    }
  };

  const closeModal = () => {
    setVisible(false);
  }

  const save = () => {
    form.submit();
  }

  const onFinish = async () => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let values = {
          isDefault: form.getFieldValue("isDefault"),
          isSurveyJS: true,
          name: form.getFieldValue("name"),
          surveyTemplateId: id ?? 0, // if id is null, then it is a new template
          templateJson: JSON.stringify(surveyCreator.JSON)
        }
        let res = await upsertSurveyTemplate(values, token);

        if (res) {
          if (res.result && res.result.success) {
            if (values.surveyTemplateId !== 0) {
              SimpleMessage(
                "success",
                t("update-template-success", {
                  name: values.name,
                })
              );
            } else {
              SimpleMessage(
                "success",
                t("create-template-success", {
                  name: values.name,
                })
              );
            }
            // Redirect to templates page
            routeChange();
          } else if (res.result && !res.result.success) {
            SimpleMessage("error", t(res.result.error));
          } else {
            if (Array.isArray(res.error)) {
              console.error(res.error);
              SimpleMessage(
                "error",
                t("create-template-error", {
                  name: values.name,
                })
              );
            } else {
              SimpleMessage("error", t(res.error));
            }
          }
        }
      }
    } catch (err) {
      SimpleMessage("error", err.message);
    }
    setVisible(false);
  }

  return (
    <>
      <h1>{id && surveyTemplate
        ? t("survey-template-update") + " " + surveyTemplate.name
        : t("survey-template-add")}</h1>

      {(isLoading && (
        <Row justify="space-around" align="middle" style={{ margin: "2em" }}>
          <Col>
            <Spin tip={i18n.t("loading")} spinning={true}></Spin>
          </Col>
        </Row>
      ))}
      {(!isLoading && !hasError &&
        (<SurveyCreator />)
      )}
      {(!isLoading && !hasError && (<Row justify="space-around" align="middle" style={{ margin: "2em" }}>
        <Col>
          <Space>
            {(id && <Button type="danger" onClick={() => onDelete()}>
              {t("delete")}
            </Button>)}
            <Button type="primary" onClick={() => onSave()}>
              {t("save")}
            </Button>
          </Space>
        </Col>
      </Row>))}
      <Modal
        title={(id
          ? t("survey-template-update")
          : t("survey-template-add"))}
        visible={visible}
        onCancel={closeModal}
        footer={[
          <Button onClick={closeModal}>
            {t("cancel")}
          </Button>,
          <Button
            type="primary"
            onClick={save}
          >
            {t("save")}
          </Button>,
        ]}>
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="name"
                label={t("template-details-name")}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: t(
                      "validation:profile-details-validation-required"
                    ),
                  },
                  {
                    max: 31,
                    message: t("template-name-too-long"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="isDefault"
                valuePropName="checked"
                initialValue={form.getFieldValue("isDefault")}
                label={
                  <Space>
                    {t("template-details-default")}
                    <Tooltip title={t("template-details-default")}>
                      <InfoCircleOutlined
                        style={{ color: "var(--default-tooltip-color)" }}
                      />
                    </Tooltip>
                  </Space>
                }
              >
                <Switch
                  checkedChildren={t("yes")}
                  unCheckedChildren={t("no")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default SurveyCreatorPage;