import React, { useContext, useEffect, useState, useRef } from "react";
import DevicesPage from "../../../pages/devices-page/devices-page";
import { useTranslation } from "react-i18next";
import {
  API_REQUESTS,
  DEVICE_MODE,
  getMeasurementTypeName,
  MEASUREMENT_STEPS,
  MEASUREMENT_TYPE,
} from "../../../utils/utils";
import { Button, Row, Col, Select, Spin } from "antd";
import SimpleMessage from "../../simple-components/simple-message/simple-message";
import { AuthContext } from "../../../contexts/AuthProvider";
import { startMeasurement } from "../../../../services/ApiService";
import { NewMeasurementContext } from "../../../contexts/NewMeasurementContext";

const { Option } = Select;

const SelectDeviceMeasurementStep = (props) => {
  const { t } = useTranslation();
  const { getAccessToken } = useContext(AuthContext);
  const {
    currentStep,
    selectedDevice,
    setSelectedDevice,
    measurementType,
    setMeasurementType,
    currentStudy,
    setCurrentStudy,
    next,
    studyList,
    setMeasurement,
    areStudiesLoading,
    setStartOfMeasurement,
  } = useContext(NewMeasurementContext);
  const [isStartMeasurementLoading, setIsStartMeasurementLoading] =
    useState(false);
  const onSelectMeasurementType = (type) => {
    setMeasurementType(type);
  };
  const [availableMeasurementTypes, setAvailableMeasurementTypes] = useState(
    []
  );
  const isMeasurementStartedRef = useRef(false);

  const onSelectStudy = (studyId) => {
    let currentStudy = studyList.find((s) => s.id === studyId);
    setCurrentStudy(currentStudy);
  };

  const selectDevice = (device) => {
    setSelectedDevice(device);
  };

  useEffect(() => {
    isMeasurementStartedRef.current = false;
  }, []);

  useEffect(() => {
    if (!isMeasurementStartedRef.current) {
      // This list is containing the measurement type values (eg. 1, 2, 3) and it's used
      // to be filtered based on the device mode, enose status and active studies.
      // The resulting list is assingned to the availableMeasurementTypes list which
      // is used for measurement type options rendering
      var measurementTypeValues = Object.entries(MEASUREMENT_TYPE).map(
        (item) => item[1]
      );

      // The default selected measurement type will be the first value from the list
      var selectedMeasurementType = measurementTypeValues[0];
      if (selectedDevice) {
        if (selectedDevice.deviceMode === DEVICE_MODE.NORMAL) {
          measurementTypeValues = measurementTypeValues.filter(
            (item) => item !== MEASUREMENT_TYPE.AMBIENT
          );
        }
        if (
          (selectedDevice.isAmbientRequired &&
            selectedDevice.deviceMode === DEVICE_MODE.CONTINUOUS) ||
          !studyList?.length
        ) {
          measurementTypeValues = measurementTypeValues.filter(
            (item) => item !== MEASUREMENT_TYPE.REGULAR
          );
          selectedMeasurementType = MEASUREMENT_TYPE.AMBIENT;
        }
        if (selectedDevice.isCalibrationRequired) {
          measurementTypeValues = measurementTypeValues.filter(
            (item) => item === MEASUREMENT_TYPE.CALIBRATION
          );
          selectedMeasurementType = MEASUREMENT_TYPE.CALIBRATION;
        }
      }
      setAvailableMeasurementTypes(measurementTypeValues);
      setMeasurementType(selectedMeasurementType);
    }
  }, [selectedDevice, studyList]);

  const startMeasurementHandler = async () => {
    try {
      const request = {
        deviceId: selectedDevice?.deviceId,
        measurementType,
        studyId: currentStudy?.id,
      };

      isMeasurementStartedRef.current = true;
      setIsStartMeasurementLoading(true);
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);

      if (token) {
        var response = await startMeasurement(request, token);

        setMeasurement(response);
        setStartOfMeasurement(true);
        next();
      }
    } catch (ex) {
      isMeasurementStartedRef.current = false;
      SimpleMessage("error", t(ex.error));
    } finally {
      setIsStartMeasurementLoading(false);
    }
  };

  return (
    <div className="steps-action" style={{ textAlign: "center" }}>
      {currentStep === MEASUREMENT_STEPS.SelectDevice && (
        <>
          <Row>
            <DevicesPage
              selectDevice={selectDevice}
              onNewMeasurementPage={true}
              selectedDeviceId={selectedDevice?.deviceId}
            ></DevicesPage>
          </Row>
          <Row justify="center" gutter={[24, 16]}>
            <Col>
              {areStudiesLoading && <Spin style={{ minWidth: 150 }}></Spin>}
              {studyList && studyList?.length === 0 && !areStudiesLoading && (
                <label>{t("new-measurement-no-active-studies")}</label>
              )}
              {studyList && studyList?.length > 0 && !areStudiesLoading && (
                <Select
                  id="studySelect"
                  style={{ minWidth: 150 }}
                  dropdownMatchSelectWidth={true}
                  defaultValue={studyList[0]?.id}
                  onChange={onSelectStudy}
                  hidden={!currentStudy?.id}
                  disabled={measurementType === MEASUREMENT_TYPE.CALIBRATION}
                >
                  {studyList.map((s) => (
                    <Option key={s.id} value={s.id}>
                      {s.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Col>
            <Col>
              <Select
                id="measurementSelect"
                style={{ minWidth: 150 }}
                dropdownMatchSelectWidth={true}
                value={measurementType}
                onChange={onSelectMeasurementType}
                disabled={!selectedDevice}
              >
                {availableMeasurementTypes.map((item) => (
                  <Option key={item} value={item}>
                    {getMeasurementTypeName(item, t)}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Button
                type="primary"
                disabled={!selectedDevice?.deviceId}
                onClick={startMeasurementHandler}
                loading={isStartMeasurementLoading}
              >
                {t("measurement-start")}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default SelectDeviceMeasurementStep;
