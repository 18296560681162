import React, { useState, useEffect } from "react";
import { Space, Tooltip, Form, Slider } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const QuestionItemScale = (props) => {
  const { t } = useTranslation();
  const [question, setQuestion] = useState({});

  useEffect(() => {
    setQuestion(props.question);
  }, [props]);

  return (
    <Form.Item
      hasFeedback={question.feedback}
      colon={false}
      key={question.id}
      name={question.id}
      initialValue={question.value}
      label={
        question.label && (
          <Space>
            {t("survey:" + question.label)}
            {question.tooltip && (
              <Tooltip title={parse(t("survey:" + question.tooltip))}>
                <InfoCircleOutlined style={{ color: question.tooltipColor }} />
              </Tooltip>
            )}
          </Space>
        )
      }
      rules={
        question.rules
          ? question.rules.map((r) => {
            r.message = !r.message.startsWith("survey:") ? t("survey:" + r.message) : r.message;
            return r;
          })
          : null
      }
    >
      <Slider
        marks={{
          [question.min]: question.min + question.unit,
          [question.max]: {
            style: {
              color: "#f50",
            },
            label: <strong>{question.max + question.unit}</strong>,
          },
        }}
        disabled={question.readonly}
        min={question.min}
        max={question.max}
        step={question.decimals ? 1 / Math.pow(10, question.decimals) : 1}
        precision={question.decimals ? question.decimals : 0}
      />
    </Form.Item>
  );
};

export default QuestionItemScale;
