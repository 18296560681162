import React from "react";
import "./footer-content.css";
import { Layout } from "antd";
const { Footer } = Layout;


const FooterContent = () => {
  return (
    <Footer className="footer-grid">
      <span className="copyright">
        <img src="/img/footer_icon.png" alt="factory icon" className="footerIcon" />
        Breathomix B.V., Bargelaan 200, 2333 CW Leiden
      </span>
      <span className="version">
        BreathBase System and BreathBase Platform v
        {process.env.REACT_SYS_VERSION}
      </span>
    </Footer>
  );
};

export default FooterContent;
