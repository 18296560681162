import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Tooltip, Space, Typography } from "antd";
import { TOBACCO_TYPES } from "../../utils/utils";

import {
  PlusOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import QuestionItemSmoke from "../question-item-smoke/question-item-smoke";
const { Text } = Typography;

const SmokeCalculator = (props) => {
  const { t } = useTranslation();
  const { question } = props;

  const [packYears, setPackYears] = useState(0);
  const [tobaccoList, setTobaccoList] = useState(question.questions[0].options);
  const [addRowEnabled, setAddRowEnabled] = useState(true);
  const [tabaccoResult, setTabaccoResult] = useState([]);

  useEffect(() => {
    if (props) {
      setTabaccoResult(props.formInstance.getFieldsValue().packYearCalculator);
      calculateTobacco();
    }
  }, [props]);

  useEffect(() => {
    const totalPackYearsElement = document.getElementById("totalPackYears");
    if (totalPackYearsElement) {
      const decimalCount = totalPackYearsElement.value.split(".")[1]?.length || 0;
      const totalPackYears = parseFloat(totalPackYearsElement.value).toFixed(decimalCount);
      const calculatedTotalPackYears = parseFloat(packYears).toFixed(decimalCount);

      if (totalPackYears != calculatedTotalPackYears) {
        totalPackYearsElement.value = calculatedTotalPackYears;
        props.formInstance.setFieldsValue({
          ["totalPackYears"]: calculatedTotalPackYears
        });
        props.onSurveyDataChanged(props.formInstance.getFieldsValue(true));
      }
    }
  }, [packYears, tabaccoResult]);

  const changeTobaccoProduct = () => {
    if (
      props.formInstance.getFieldsValue().hasOwnProperty("packYearCalculator")
    ) {
      let allTobacco = question.questions[0].options; //value
      let selectedTobacco = props.formInstance.getFieldsValue()
        .packYearCalculator; //tobaccoType

      if (allTobacco && selectedTobacco) {
        allTobacco.forEach(x => {
          x.isDisabled = selectedTobacco
            .filter((y) => parseInt(y?.tobaccoType) === x.value).length > 0
        });
        setTobaccoList(allTobacco);
      }
      calculateTobacco();
    }
  };

  const calculateTobacco = () => {
    const tobaccoInformation = props.formInstance.getFieldsValue();
    let totalTobaccoYears = 0;
    if (tobaccoInformation["packYearCalculator"]) {
      for (const item of tobaccoInformation.packYearCalculator) {
        if (item && item.amount > 0 && item.years > 0) {
          switch (item.tobaccoType) {
            case TOBACCO_TYPES.CIGARETTES:
              totalTobaccoYears += (item.amount / 20) * item.years;
              break;
            case TOBACCO_TYPES.CIGARS:
              totalTobaccoYears += ((item.amount * 3) / 20) * item.years;
              break;
            case TOBACCO_TYPES.TOBACCO:
              totalTobaccoYears += ((item.amount * 2) / 7 / 20) * item.years;
              break;
            case TOBACCO_TYPES.PIPES:
              totalTobaccoYears += ((item.amount * 2.5) / 20) * item.years;
              break;
            case TOBACCO_TYPES.VAPING:
              break;
            default:
              break;
          }
        }
      }
      setPackYears(totalTobaccoYears.toFixed(2));
      checkMaxRowsReached();
    }
  };

  const checkMaxRowsReached = () => {
    let packYearData = props.formInstance.getFieldValue("packYearCalculator");

    if (!packYearData) {
      setAddRowEnabled(true);
      return;
    }

    // check if the packYearData has null or undefined immediate children
    if (!packYearData.filter((x) => x == null).length) {
      let hasReachedMaxSize =
        question.questions[0].options.length === packYearData.length;

      // check if any children of packYearData have properties with null/undefined or 0 values (0 for time or amount)
      let emptyFields = packYearData.filter(
        (x) => Object.values(x).filter((y) => y == 0 || y == null).length > 0
      );

      setAddRowEnabled(!(hasReachedMaxSize || emptyFields.length > 0));
    } else setAddRowEnabled(false);
  };

  return (
    <Form.List name={question.id}>
      {(fields, { add, remove }) => {
        return (
          <Space direction="vertical">
            <Space>
              <Text strong>{t("survey:" + question.label)}</Text>
              {question.tooltip && (
                <Tooltip title={parse(t("survey:" + question.tooltip))}>
                  <InfoCircleOutlined
                    style={{ color: question.tooltipColor }}
                  />
                </Tooltip>
              )}
            </Space>

            {fields.map((field, index) => (
              <Row
                gutter={16}
                key={field.key}
                style={{ display: "flex" }}
                align="middle"
              >
                <Col span={20}>
                  <Form.Item
                    {...field}
                    name={[field.name]}
                    fieldKey={[field.key]}
                  >
                    <QuestionItemSmoke
                      question={question}
                      calculateTobacco={calculateTobacco}
                      changeTobaccoProduct={changeTobaccoProduct}
                      tobaccoList={tobaccoList}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(field.name);
                      changeTobaccoProduct();
                    }}
                  />
                </Col>
              </Row>
            ))}
            <Row gutter={16} style={{ display: "flex" }} align="middle">
              <Col>
                <Form.Item>
                  <Button
                    disabled={!addRowEnabled}
                    type="dashed"
                    onClick={() => {
                      add();
                      checkMaxRowsReached();
                    }}
                  >
                    <PlusOutlined />
                    {t("survey:patient-pack-calculator-addfield")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        );
      }}
    </Form.List>
  );
};

export default SmokeCalculator;
