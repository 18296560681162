import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Tooltip, Space } from "antd";
import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/fr";
import moment from "moment";
import "./survey-template-card.css";
import { DISPLAY_DATE_FORMAT } from "../../utils/utils";
import { SettingOutlined, GroupOutlined, EyeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ResearchContext } from "../../contexts/ResearchProvider";
import SurveyTemplatePreview from "../survey-template-preview/survey-template-preview";
import { useHistory } from "react-router-dom";

const { Text } = Typography;

const SurveyTemplateCard = (props) => {
  const { t } = useTranslation();
  const [templateDetails, setTemplateDetails] = useState(null);
  const [dateModified, setDateModified] = useState(null);
  const [isSurveyJS, setIsSurveyJS] = useState(false);
  const { preferredLanguage } = useContext(ResearchContext);
  const [previewVisible, setPreviewVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (props.template) {
      setTemplateDetails(props.template);
      setIsSurveyJS(props.template.isSurveyJS);
      setDateModified(
        props.template.dateModified &&
        moment
          .utc(props.template.dateModified)
          .locale(preferredLanguage)
          .local()
      );
    }
  }, [props]);

  const previewSurveyTemplate = () => {
    setPreviewVisible(true);
  };

  const onPreviewClosed = () => {
    setPreviewVisible(false);
  };

  const editSurveyTemplate = (e) => {
    if (templateDetails.isSurveyJS) {
      let path = `/survey-templates/creator/${templateDetails.surveyTemplateId}`;
      history.push(path);
    }

    //open drawer for templateId
    e.stopPropagation();
    props.openDrawer(templateDetails);
  };

  return (
    <Card
      size="small"
      bordered={false}
      className="survey-template-card-shadow"
      title={
        <Space>
          <GroupOutlined style={{ color: "var(--default-tooltip-color)" }} />
          <Tooltip title={props.template.name}>
            <Text strong ellipsis style={{ maxWidth: "135px" }}>
              {props.template.name}
            </Text>
          </Tooltip>
        </Space>
      }
      extra={
        <Space>
          <Tooltip
            title={t("survey-template-preview")}
            onClick={previewSurveyTemplate}
          >
            <EyeOutlined />
          </Tooltip>
          <Tooltip
            title={t("survey-template-details-properties")}
            onClick={editSurveyTemplate}
          >
            <SettingOutlined />
          </Tooltip>
        </Space>
      }
    >
      <div className="survey-template-card">
        <div style={{ gridRow: "1", justifySelf: "start" }}>
          <Text strong={props.template.isDefault}>
            {props.template.isDefault ? t("default-template") : "-"}
          </Text>
        </div>
        <div style={{ gridRow: "3", justifySelf: "start" }}>
          <Tooltip
            title={
              dateModified
                ? dateModified.format(DISPLAY_DATE_FORMAT)
                : t("none")
            }
          >
            <Text type="secondary" ellipsis>
              {dateModified ? dateModified.fromNow() : t("none")}
            </Text>
          </Tooltip>
        </div>
        <SurveyTemplatePreview
          visible={previewVisible}
          template={templateDetails}
          onPreviewClosed={onPreviewClosed}
          isSurveyJS={isSurveyJS}
        />
      </div>
    </Card>
  );
};

export default SurveyTemplateCard;