import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Skeleton,
  Button,
  Form,
  Row,
  Col,
  Input,
  Space,
  Tooltip,
  Switch,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthProvider";
import {
  API_REQUESTS,
  getAddressString,
  APP_ROLES,
  getFullLanguageString,
} from "../../utils/utils";
import SimpleMessage from "../simple-components/simple-message/simple-message";
import {
  firstNameRules,
  lastNameRules,
  emailRules,
  phoneRules,
  jobRules,
  addressRules,
  organizationRules,
  departmentRules,
} from "./profile-details-rules";
import PropTypes from "prop-types"; // ES6
import {
  updateUserProfile,
  searchAddress,
  getOrganizations,
} from "../../../services/ApiService";
import { QuestionCircleOutlined } from "@ant-design/icons";
import AutoCompleteSearch from "../auto-complete-search/auto-complete-search";

const ProfileDetails = (props) => {
  const { getAccessToken, user } = useContext(AuthContext);
  const [dirty, setDirty] = useState(false);
  const [initialFormData, setInitialFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState(true);
  const [restrictedLanguage, setRestrictedLanguage] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const isAdmin = user.role === APP_ROLES.ADMIN;

  useEffect(() => {
    const fetchData = async () => {
      let addr = getAddressString(props.account);
      setInitialFormData({
        givenName: props.account.givenName,
        surname: props.account.surname,
        organization: props.account.organization,
        department: props.account.department,
        mail: props.account.mail,
        address: addr,
        phone: props.account.phone,
        jobTitle: props.account.jobTitle,
        isFixedLanguage: props.account.isFixedLanguage,
        language: props.account.language,
      });
      form.setFieldsValue({
        givenName: props.account.givenName,
        surname: props.account.surname,
        organization: props.account.organization,
        department: props.account.department,
        mail: props.account.mail,
        address: addr,
        phone: props.account.phone,
        jobTitle: props.account.jobTitle,
        isFixedLanguage: props.account.isFixedLanguage,
        language: props.account.language,
      });
      if (props.account.language != undefined) {
        setRestrictedLanguage(true);
      }
      setLoading(false);
    };
    if (props.account) {
      setLoading(true);
      fetchData();
    }
  }, [props.account]);

  const onFinish = async (values) => {
    //call graph api
    setLoading(true);
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let response = await searchAddress(token, values.address);
        if (response?.result?.data?.length) {
          let found = response.result.data[0]; //get first
          if (found) {
            values.address = found.address;
            values.address.position = found.position;
            values.id = props.account.id;
            let res = await updateUserProfile(values, token);
            if (res && res.result) {
              setDirty(false);
              setViewMode(true);
              props.refreshUserDetails();
              SimpleMessage("success", t("profile-details-success"));
            } else if (res.error && res.error.messages) {
              const errArray = res.error.map((err) => {
                return (
                  "Field " +
                  err.fieldName +
                  ": " +
                  err.messages.map((e) => t(e)).toString()
                );
              });
              console.error(errArray.toString());
              SimpleMessage("error", t("profile-details-error"));
            } else {
              SimpleMessage("error", t("profile-details-error"));
            }
          } else {
            SimpleMessage("error", t("profile-details-address-invalid"));
          }
        } else {
          SimpleMessage("error", t("profile-details-address-invalid"));
        }
      }
    } catch (err) {
      SimpleMessage("error", err.message);
    }
    setLoading(false);
  };

  const onSaveClick = async () => {
    form.submit();
  };

  const onCancelClick = async () => {
    form.resetFields();
    setDirty(false);
    setViewMode(true);
  };

  const onValuesChange = (changedValues) => {
    setDirty(form.isFieldsTouched());
    if (changedValues.isFixedLanguage != undefined) {
      setRestrictedLanguage(changedValues.isFixedLanguage);
    }
  };

  const onEditClick = () => {
    setViewMode(false);
  };

  const headerButtons = (
    <Space>
      {viewMode ? (
        <Button type="primary" onClick={onEditClick} id="edit-user-details">
          {t("profile-contact-edit")}
        </Button>
      ) : (
        <>
          {dirty && (
            <Button
              type="primary"
              onClick={onSaveClick}
              disabled={loading}
              id="save-user-details"
            >
              {t("profile-contact-save")}
            </Button>
          )}
          <Button
            onClick={onCancelClick}
            disabled={loading}
            id="cancel-user-details"
          >
            {t("profile-contact-reset")}
          </Button>
        </>
      )}
    </Space>
  );

  return (
    <Card
      size="small"
      title={t("profile-contact")}
      className="responsive-card-shadow"
      extra={headerButtons}
      bordered={false}
    >
      <Skeleton loading={loading} active paragraph={{ rows: 5 }}>
        <Form
          form={form}
          initialValues={initialFormData}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          layout="vertical"
          colon={false}
          hideRequiredMark
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="givenName"
                label={t("profile-firstname")}
                rules={firstNameRules(t)}
              >
                <Input disabled={viewMode} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="organization"
                label={t("profile-organization")}
                rules={organizationRules(t)}
              >
                <AutoCompleteSearch
                  id={"select-organization"}
                  placeholder={t("placeholder-organization")}
                  onChange={(r) => (form.organization = r)}
                  fetchCall={getOrganizations}
                  fetchArgs={[0, 10]}
                  optionMapper={(it) => ({
                    text: it.name,
                    value: it.name,
                  })}
                  value={form.organization}
                  disabled={viewMode}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="surname"
                label={t("profile-lastname")}
                rules={lastNameRules(t)}
              >
                <Input disabled={viewMode} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="department"
                label={t("profile-department")}
                rules={departmentRules(t)}
              >
                <Input disabled={viewMode} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="mail"
                label={
                  <Space>
                    {t("profile-email")}
                    <Tooltip title={t("profile-email-info")}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Space>
                }
                rules={emailRules(t)}
              >
                <Input disabled={viewMode} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="jobTitle"
                label={t("profile-job")}
                rules={jobRules(t)}
              >
                <Input disabled={viewMode} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="phone"
                label={t("profile-phone")}
                rules={phoneRules(t)}
              >
                <Input disabled={viewMode} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="address"
                label={t("profile-address")}
                rules={addressRules(t)}
              >
                <AutoCompleteSearch
                  id={"select-address"}
                  placeholder={t("placeholder-address")}
                  onChange={(r) => (form.address = r)}
                  fetchCall={searchAddress}
                  optionMapper={(it) => ({
                    value: it.address.freeformAddress,
                    key: it.id,
                  })}
                  value={form.address}
                  disabled={viewMode}
                />
              </Form.Item>
            </Col>
          </Row>
          {isAdmin && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="isFixedLanguage"
                  valuePropName="checked"
                  label={t("set-select-fixed-language")}
                >
                  <Switch
                    disabled={viewMode}
                    checkedChildren={t("yes")}
                    unCheckedChildren={t("no")}
                    defaultChecked={restrictedLanguage}
                  />
                </Form.Item>
              </Col>
              {restrictedLanguage && (
                <Col span={12}>
                  <Form.Item name="language" label={t("select-fixed-language")}>
                    <Select
                      placeholder={getFullLanguageString(t)["EN"]}
                      style={{ width: "40%" }}
                      disabled={viewMode}
                    >
                      <Option key="english" value="en">
                        {getFullLanguageString(t)["EN"]}
                      </Option>
                      <Option key="dutch" value="nl">
                        {getFullLanguageString(t)["NL"]}
                      </Option>
                      <Option key="french" value="fr">
                        {getFullLanguageString(t)["FR"]}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
          )}
        </Form>
      </Skeleton>
    </Card>
  );
};

ProfileDetails.propsTypes = {
  account: PropTypes.object.isRequired,
  refreshUserDetails: PropTypes.func.isRequired,
};

export default ProfileDetails;
