import React, { useState, useEffect, useContext } from "react";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Modal,
  Skeleton,
  DatePicker,
  Select,
  InputNumber,
  Spin,
  Typography,
  Tooltip,
  Tag,
  Badge,
  Descriptions,
  Space,
} from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  QuestionCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  API_REQUESTS,
  APP_ROLES,
  getStudyStatusList,
  STUDY_ROLES,
  getStudyStatusString,
  getStudyDurationType,
  ENTITY_TYPE,
  ENTITY_ACTIONS,
} from "../../utils/utils";
import PropTypes from "prop-types"; // ES6
import SimpleMessage from "../simple-components/simple-message/simple-message";
import { AuthContext } from "../../contexts/AuthProvider";
import SurveyTemplatePreview from "../survey-template-preview/survey-template-preview";
import {
  nameRules,
  principalInvestigatorRules,
  memberRules,
  organizationRules,
  requiredArrayRules,
  requiredNoWhitespaceRules,
} from "./study-details-drawer-rules";
import parse from "html-react-parser";
import {
  getUsers,
  getOrganizations,
  createStudy,
  updateStudyDetails,
  getStudyTypes,
  icdSearch,
  getSurveyTemplates,
  softDeleteEntity,
  getSurveyTemplate,
} from "../../../services/ApiService";
import { requiredRules } from "../device-details-drawer/device-details-drawer-rules";
const { confirm } = Modal;
const { Option } = Select;
const { Text } = Typography;

const StudyDetailsDrawer = (props) => {
  const { getAccessToken, user } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [initialFormData] = useState({
    startDate: moment(),
    durationValue: 1,
    durationType: 1,
  });
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canChangeStatus, setCanChangeStatus] = useState(false);
  const [studyTitle, setStudyTitle] = useState("");
  const [studyId, setStudyId] = useState(null);

  const [foundOrganizations, setFoundOrganizations] = useState([]);
  const [fetchingOrganizations, setFetchingOrganizations] = useState(false);
  const [organizationSearchTerm, setOrganizationSearchTerm] = useState(null);

  const [foundInvestigators, setFoundInvestigators] = useState([]);
  const [fetchingInvestigators, setFetchingInvestigators] = useState(false);
  const [investigatorSearchTerm, setInvestigatorSearchTerm] = useState(null);

  const [foundMembers, setFoundMembers] = useState([]);
  const [fetchingMembers, setFetchingMembers] = useState(false);
  const [memberSearchTerm, setMemberSearchTerm] = useState(null);

  const [foundResearchAreas, setFoundResearchAreas] = useState([]);
  const [fetchingResearchAreas, setFetchingResearchAreas] = useState(false);
  const [researchAreaSearchTerm, setResearchAreaSearchTerm] = useState(null);

  const [foundStudyTypes, setFoundStudyTypes] = useState([]);
  const [fetchingStudyTypes, setFetchingStudyTypes] = useState(false);

  const [foundSurveyTemplates, setFoundSurveyTemplates] = useState([]);
  const [fetchingSurveyTemplates, setFetchingSurveyTemplates] = useState(false);
  const [surveyTemplateSearchTerm, setSurveyTemplateSearchTerm] =
    useState(null);
  const [surveyTemplateId, setSurveyTemplateId] = useState(null);
  const [surveyPreviewVisible, setSurveyPreviewVisible] = useState(false);
  const [surveyTemplate, setSurveyTemplate] = useState(null);
  const [isSurveyJS, setIsSurveyJS] = useState(false);

  const researchAreaColor = "var(--research-area-color)";
  const orgColor = "var(--org-color)";
  const studyTypeColor = "var(--study-type-color)";

  const memberColor = "var(--member-color)";
  const disabledMembercolor = "var(--disabled-member-color)";
  const investigatorColor = "var(--investigator-color)";
  let newStudy = null;

  const { t } = useTranslation();
  const isAdmin = user.role === APP_ROLES.ADMIN;

  useEffect(() => {
    if (props) {
      setVisible(props.visible);
      newStudy = null;

      if (props.study) {
        setCanChangeStatus(true);
        setStudyId(props.study.id);
        setSurveyTemplateId(props.study.surveyTemplateId);
        setStudyTitle(t("update-study"));

        let orgs = [];
        if (props.study.organizations) {
          orgs = props.study.organizations.map((o) => {
            return {
              text: o,
              value: o,
            };
          });
        }
        setFoundOrganizations(orgs);

        let researchAreas = [];
        if (props.study.researchAreas) {
          researchAreas = props.study.researchAreas.map((ra) => {
            return {
              text: `<strong>${ra.split(":")[0]}</strong> ${ra.split(":")[1]}`,
              value: ra,
            };
          });
        }
        setFoundResearchAreas(researchAreas);

        let studyTypes = [];
        if (props.study.studyTypes) {
          studyTypes = props.study.studyTypes.map((st) => {
            return {
              text: t("studytype:" + st),
              value: st,
            };
          });
        }

        let pis = [];
        let members = [];
        if (props.study.team) {
          pis = props.study.team
            .filter((m) => m.role === STUDY_ROLES.PRINCIPAL_INVESTIGATOR)
            .map((m) => {
              return {
                label: m.name,
                text: m.name,
                value: m.userId,
              };
            });
          members = props.study.team
            .filter((m) => m.role === STUDY_ROLES.MEMBER)
            .map((m) => {
              return {
                label: m.name,
                text: m.name,
                value: m.userId,
              };
            });
        }
        setFoundMembers(members);
        setFoundInvestigators(pis);

        let templates = [];
        if (props.study.surveyTemplateId) {
          templates.push({
            text: props.study.surveyTemplateName,
            value: props.study.surveyTemplateId,
          });
        }
        setFoundSurveyTemplates(templates);

        form.setFieldsValue({
          ...props.study,
          startDate: moment.utc(props.study.startDate).local(),
          organizations: orgs,
          researchAreas,
          principalInvestigators: pis,
          teamMembers: members,
          studyTypes,
          status: props.study.status,
        });
      } else {
        setStudyTitle(t("add-study"));
        setStudyId(null);
        setCanChangeStatus(false);
        setFoundOrganizations([]);
        setFoundInvestigators([]);
        setFoundMembers([]);
        setFoundResearchAreas([]);
        setFoundSurveyTemplates([]);
      }
    }
  }, [props]);

  useEffect(() => {
    fetchStudyTypes();
  }, []);
  const getSelectedItems = (fieldName) => {
    if (form.getFieldValue(fieldName)) {
      return Array.from(form.getFieldValue(fieldName));
    }
    return [];
  };

  const fetchStudyTypes = async () => {
    setFetchingStudyTypes(true);
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await getStudyTypes(token);
        if (res && res.result && !res.error) {
          let tempAreas = res.result.map((o) => {
            return {
              text: t("studytype:" + o),
              value: o,
            };
          });
          setFoundStudyTypes(tempAreas);
        }
      }
    } catch (err) {
      console.error(err);
    }
    setFetchingStudyTypes(false);
  };

  useEffect(() => {
    const fetchOrgs = async () => {
      if (organizationSearchTerm && organizationSearchTerm.length > 2) {
        setFetchingOrganizations(true);
        try {
          const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
          if (token) {
            let res = await getOrganizations(
              token,
              0,
              100,
              organizationSearchTerm
            );
            if (res && res.result && !res.error) {
              let tempOrgs = res.result.data.map((o) => {
                return {
                  text: o.name,
                  value: o.name,
                };
              });
              setFoundOrganizations(tempOrgs);
            }
          }
        } catch (err) {
          console.error(err);
        }
        setFetchingOrganizations(false);
      } else {
        setFoundOrganizations([]);
      }
    };
    const timer = setTimeout(() => {
      fetchOrgs();
    }, 1000);
    return () => clearTimeout(timer);
  }, [organizationSearchTerm]);

  useEffect(() => {
    const fetchInvestigators = async () => {
      if (investigatorSearchTerm && investigatorSearchTerm.length > 2) {
        setFetchingInvestigators(true);
        try {
          const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
          if (token) {
            let res = await getUsers(token, null, 100, investigatorSearchTerm);
            if (res && res.data && !res.error) {
              let selectedMembers = getSelectedItems("teamMembers");
              let tempInvestigators = res.data.filter(
                (x) =>
                  !selectedMembers.find((i) => i.value === x.id) &&
                  (x.role === APP_ROLES.RESEARCHER ||
                    x.role === APP_ROLES.CLINICIAN)
              );
              setFoundInvestigators(
                tempInvestigators.map((usr) => {
                  return {
                    text: usr.name,
                    value: usr.id,
                    role: usr.role,
                  };
                })
              );
            }
          }
        } catch (err) {
          console.error(err);
        }
        setFetchingInvestigators(false);
      } else {
        setFoundInvestigators([]);
      }
    };
    const timer = setTimeout(() => {
      fetchInvestigators();
    }, 800);
    return () => clearTimeout(timer);
  }, [investigatorSearchTerm]);

  useEffect(() => {
    const fetchMembers = async () => {
      if (memberSearchTerm && memberSearchTerm.length > 2) {
        setFetchingMembers(true);
        try {
          const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
          if (token) {
            let res = await getUsers(token, null, 100, memberSearchTerm);
            if (res && res.data && !res.error) {
              let selectedInvestigators = getSelectedItems(
                "principalInvestigators"
              );
              let tempMembers = res.data.filter(
                (x) =>
                  !selectedInvestigators.find((i) => i.value === x.id) &&
                  (x.role === APP_ROLES.RESEARCHER ||
                    x.role === APP_ROLES.CLINICIAN)
              );
              setFoundMembers(
                tempMembers.map((usr) => {
                  return {
                    text: usr.name,
                    value: usr.id,
                    role: usr.role,
                  };
                })
              );
            }
          }
        } catch (err) {
          console.error(err);
        }
        setFetchingMembers(false);
      } else {
        setFoundMembers([]);
      }
    };
    const timer = setTimeout(() => {
      fetchMembers();
    }, 800);
    return () => clearTimeout(timer);
  }, [memberSearchTerm]);

  useEffect(() => {
    const fetchResearchAreas = async () => {
      if (researchAreaSearchTerm && researchAreaSearchTerm.length > 2) {
        setFetchingResearchAreas(true);
        try {
          const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
          let res = await icdSearch(token, researchAreaSearchTerm, "en");
          setFoundResearchAreas(
            res.map((icd) => {
              return {
                text: `<strong>${icd.theCode}</strong> ${icd.title}`,
                value:
                  icd.theCode + ":" + icd.title.replace(/(<([^>]+)>)/gi, ""),
              };
            })
          );
        } catch (err) {
          console.error(err);
        }
        setFetchingResearchAreas(false);
      } else {
        setFoundResearchAreas([]);
      }
    };
    const timer = setTimeout(() => {
      fetchResearchAreas();
    }, 800);
    return () => clearTimeout(timer);
  }, [researchAreaSearchTerm]);

  useEffect(() => {
    const fetchSurveyTemplates = async () => {
      if (surveyTemplateSearchTerm && surveyTemplateSearchTerm.length > 2) {
        setFetchingSurveyTemplates(true);
        try {
          const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
          if (token) {
            let res = await getSurveyTemplates(
              token,
              0,
              100,
              surveyTemplateSearchTerm
            );
            if (res && res.result && !res.error) {
              let tempTemplates = res.result.data.map((o) => {
                return {
                  text: o.name,
                  value: o.surveyTemplateId,
                };
              });
              setFoundSurveyTemplates(tempTemplates);
            }
          }
        } catch (err) {
          console.error(err);
        }
        setFetchingSurveyTemplates(false);
      } else {
        setFoundSurveyTemplates([]);
      }
    };
    const timer = setTimeout(() => {
      fetchSurveyTemplates();
    }, 1000);
    return () => clearTimeout(timer);
  }, [surveyTemplateSearchTerm]);

  const onFinish = async (values) => {
    //call graph api
    setLoading(true);
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        values.organizations = values.organizations.map((tm) => tm.value);
        values.principalInvestigators =
          values.principalInvestigators?.map((pi) => pi.value) || [];
        values.teamMembers = values.teamMembers?.map((tm) => tm.value) || [];
        values.researchAreas = values.researchAreas.map((ra) => ra.value);
        values.studyTypes = values.studyTypes.map((st) => st.value);
        if (studyId) {
          values.id = studyId; //update field
        }
        let res = values.id
          ? await updateStudyDetails(values, token)
          : await createStudy(values, token);
        if (res) {
          if (res.result && res.result.success) {
            newStudy = res.result.study ? res.result.study : null;
            if (studyId) {
              SimpleMessage(
                "success",
                t("update-study-success", {
                  name: values.name,
                })
              );
              clearAndClose(ENTITY_ACTIONS.UPDATE);
            } else {
              SimpleMessage(
                "success",
                t("create-study-success", {
                  name: values.name,
                })
              );
              clearAndClose(ENTITY_ACTIONS.CREATE);
            }
          } else if (res.result && !res.result.success) {
            SimpleMessage("error", t(res.result.error));
          } else {
            if (Array.isArray(res.error)) {
              console.error(res.error);
              SimpleMessage(
                "error",
                t("create-study-error", {
                  name: values.name,
                })
              );
            } else {
              SimpleMessage("error", t(res.error));
            }
          }
        }
      }
    } catch (err) {
      SimpleMessage("error", err.message);
    }
    setLoading(false);
  };

  const onValuesChange = (changedValues) => {
    setDirty(form.isFieldsTouched());
  };

  const closeDrawer = () => {
    if (dirty) {
      //show confirm
      confirm({
        cancelText: t("add-study-cancel"),
        icon: (
          <QuestionCircleOutlined
            style={{ color: "var(--question-icon-color)" }}
          />
        ),
        title: t("add-study-leave-title"),
        content: t("add-study-leave-content"),
        onOk: () => clearAndClose(),
      });
    } else {
      clearAndClose();
    }
  };

  const clearAndClose = (action) => {
    setDirty(false);
    form.resetFields();
    props.onClose(newStudy, action);
  };

  const onSaveClick = () => {
    form.submit();
  };

  const tagRenderOrganization = (opt) => {
    const { label, closable, onClose } = opt;

    return (
      <Tooltip title={label}>
        <Tag
          color={orgColor}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          <Text ellipsis={true} style={{ maxWidth: "100px", color: "white" }}>
            {label}
          </Text>
        </Tag>
      </Tooltip>
    );
  };

  const tagRenderPI = (opt) => {
    let { label, closable, onClose } = opt;

    if (Array.isArray(label)) {
      label = label[1];
    }
    let color = !label.includes(t("profile-disabled"))
      ? investigatorColor
      : disabledMembercolor;

    return (
      <Tooltip title={label}>
        <Tag
          color={color}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          <Text ellipsis={true} style={{ maxWidth: "100px", color: "white" }}>
            {label}
          </Text>
        </Tag>
      </Tooltip>
    );
  };

  const tagRenderMembers = (opt) => {
    let { label, closable, onClose } = opt;
    //Already existent options are rendered with just text, but new ones have two nodes within
    if (Array.isArray(label)) {
      label = label[1];
    }
    let color = !label.includes(t("profile-disabled"))
      ? memberColor
      : disabledMembercolor;

    return (
      <Tooltip title={label}>
        <Tag
          color={color}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          <Text ellipsis={true} style={{ maxWidth: "100px", color: "white" }}>
            {label}
          </Text>
        </Tag>
      </Tooltip>
    );
  };

  const tagRenderResearchArea = (opt) => {
    const { label, closable, onClose } = opt;

    return (
      <Tooltip title={label}>
        <Tag
          color={researchAreaColor}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          <Text ellipsis={true} style={{ maxWidth: "100px", color: "white" }}>
            {label}
          </Text>
        </Tag>
      </Tooltip>
    );
  };

  const tagRenderStudyType = (opt) => {
    const { label, closable, onClose } = opt;

    return (
      <Tooltip title={label}>
        <Tag
          color={studyTypeColor}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          <Text ellipsis={true} style={{ maxWidth: "100px", color: "white" }}>
            {label}
          </Text>
        </Tag>
      </Tooltip>
    );
  };

  const clearUsers = (value) => {
    setFoundInvestigators([]);
    setFoundMembers([]);
  };

  const onDeleteClick = () => {
    confirm({
      cancelText: t("add-study-cancel"),
      icon: (
        <QuestionCircleOutlined
          style={{ color: "var(--question-icon-color)" }}
        />
      ),
      title: t("study-delete-title"),
      content: t("study-delete-content"),
      onOk: deleteStudy,
    });
  };

  const deleteStudy = async () => {
    const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
    var data = { entityId: studyId.toString(), entityType: ENTITY_TYPE.STUDY };
    var res = await softDeleteEntity(data, token);
    if (res.success) {
      SimpleMessage("success", t("delete-study-success"));
      clearAndClose(ENTITY_ACTIONS.DELETE);
    } else {
      SimpleMessage("error", res.error);
    }
  };

  const onSelectSurveyTemplate = (value) => {
    setSurveyTemplateId(value);
  };
  const previewSurveyTemplate = async () => {
    const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
    if (token) {
      var res = await getSurveyTemplate(token, surveyTemplateId);
      if (res.result && res.result.success) {
        setSurveyTemplate(res.result.template);
        setSurveyPreviewVisible(true);
        setIsSurveyJS(res.result.template.isSurveyJS);
      }
    }
  };

  const onSurveyPreviewClosed = () => {
    setSurveyPreviewVisible(false);
  };

  return (
    <Drawer
      title={studyTitle}
      width={450}
      onClose={closeDrawer}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div>
          {isAdmin && props.study && (
            <Button
              onClick={onDeleteClick}
              danger
              type="primary"
              id="delete-study"
              style={{ float: "left" }}
            >
              <DeleteOutlined />
              {t("study-details-delete")}
            </Button>
          )}

          {isAdmin && (
            <Button
              disabled={!dirty}
              onClick={onSaveClick}
              type="primary"
              id="save-study"
              style={{ float: "right" }}
            >
              {t("add-study-confirm")}
            </Button>
          )}

          <Button
            onClick={closeDrawer}
            style={{ marginRight: 8, float: "right" }}
            id="cancel-study"
          >
            {t("add-study-cancel")}
          </Button>
        </div>
      }
    >
      <Skeleton loading={loading} active paragraph={{ rows: 5 }}>
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          initialValues={initialFormData}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
        >
          <Row>
            <Col span={24}>
              {isAdmin ? (
                <Form.Item
                  name="name"
                  label={t("study-name")}
                  rules={nameRules(t)}
                >
                  <Input placeholder={t("placeholder-study-name")} />
                </Form.Item>
              ) : (
                <Descriptions layout="vertical" column={1} colon={false}>
                  <Descriptions.Item label={t("study-name")}>
                    {form.getFieldValue("name")}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isAdmin ? (
                <Form.Item
                  name="startDate"
                  label={t("study-date")}
                  rules={requiredRules(t)}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder={t("placeholder-study-date")}
                  />
                </Form.Item>
              ) : (
                <Descriptions layout="vertical" column={1} colon={false}>
                  <Descriptions.Item label={t("study-date")}>
                    {form.getFieldValue("startDate")
                      ? `${moment(form.getFieldValue("startDate")).format(
                        "YYYY-MM-DD"
                      )}`
                      : t("none")}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Col>
          </Row>
          {canChangeStatus && (
            <Row>
              <Col span={24}>
                {isAdmin ? (
                  <Form.Item
                    name="status"
                    label={t("study-status")}
                    rules={requiredRules(t)}
                  >
                    <Select
                      placeholder={t("placeholder-study-status")}
                      style={{ width: "100%" }}
                    >
                      {getStudyStatusList(t).map((d) => (
                        <Option key={d.value} value={d.value}>
                          {d.text}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <Descriptions layout="vertical" column={1} colon={false}>
                    <Descriptions.Item label={t("study-status")}>
                      {getStudyStatusString(form.getFieldValue("status"), t)}
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              {isAdmin ? (
                <Form.Item label={t("study-duration")}>
                  <Input.Group compact>
                    <Form.Item name="durationValue" noStyle>
                      <InputNumber
                        min={1}
                        max={100}
                        placeholder={t("placeholder-study-duration")}
                        style={{ width: "60%" }}
                      />
                    </Form.Item>
                    <Form.Item name="durationType" noStyle>
                      <Select
                        placeholder={t("placeholder-study-duration-type")}
                        style={{ width: "40%" }}
                      >
                        <Option key="weeks" value={0}>
                          {t("study-weeks")}
                        </Option>
                        <Option key="months" value={1}>
                          {t("study-months")}
                        </Option>
                        <Option key="years" value={2}>
                          {t("study-years")}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              ) : (
                <Descriptions
                  layout="vertical"
                  column={1}
                  colon={false}
                  label={t("study-duration")}
                >
                  <Descriptions.Item label={t("placeholder-study-duration")}>
                    {form.getFieldValue("durationValue")} &nbsp;
                    {getStudyDurationType(
                      form.getFieldValue("durationType"),
                      t
                    )}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isAdmin ? (
                <Form.Item
                  name="organizations"
                  label={<Badge color={orgColor} text={t("study-centers")} />}
                  rules={organizationRules(t)}
                >
                  <Select
                    maxTagCount={3}
                    mode="multiple"
                    labelInValue
                    loading={fetchingOrganizations}
                    placeholder={t("placeholder-study-centers")}
                    notFoundContent={
                      fetchingOrganizations ? <Spin size="small" /> : null
                    }
                    tagRender={tagRenderOrganization}
                    filterOption={false}
                    onSearch={(e) => setOrganizationSearchTerm(e)}
                    style={{ width: "100%" }}
                  >
                    {foundOrganizations.map((d) => (
                      <Option key={d.text}>{d.text}</Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Descriptions layout="vertical">
                  <Descriptions.Item
                    label={<Badge color={orgColor} text={t("study-centers")} />}
                  >
                    <Row>
                      {foundOrganizations.map((d) => (
                        <Col flex={3}>
                          <Tag
                            color={orgColor}
                            style={{ margin: 3 }}
                            key={d.text}
                          >
                            <Text
                              ellipsis={true}
                              style={{ maxWidth: "200px", color: "white" }}
                            >
                              {d.text}
                            </Text>
                          </Tag>
                        </Col>
                      ))}
                    </Row>
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isAdmin ? (
                <Form.Item
                  name="principalInvestigators"
                  label={
                    <Badge
                      color={investigatorColor}
                      text={t("study-principal-investigators")}
                    />
                  }
                  rules={principalInvestigatorRules(t)}
                >
                  <Select
                    maxTagCount={3}
                    mode="multiple"
                    labelInValue
                    loading={fetchingInvestigators}
                    placeholder={t("placeholder-study-principal-investigators")}
                    notFoundContent={
                      fetchingInvestigators ? <Spin size="small" /> : null
                    }
                    tagRender={tagRenderPI}
                    filterOption={false}
                    onSearch={(e) => setInvestigatorSearchTerm(e)}
                    onChange={clearUsers}
                    style={{ width: "100%" }}
                  >
                    {foundInvestigators.map((d) => (
                      <Option key={d.value}>
                        <Tag color={`var(--${d.role?.toLowerCase()}-color)`}>
                          {d.role?.charAt(0)}
                        </Tag>
                        {d.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Descriptions layout="vertical">
                  <Descriptions.Item
                    label={
                      <Badge
                        color={investigatorColor}
                        text={t("study-principal-investigators")}
                      />
                    }
                  >
                    <Row>
                      {getSelectedItems("principalInvestigators").length > 0 ? (
                        foundInvestigators.map((d) => (
                          <Col flex={3}>
                            <Tag
                              color={investigatorColor}
                              style={{ margin: 3 }}
                            >
                              <Text
                                key={d.value}
                                ellipsis={true}
                                style={{ maxWidth: "200px", color: "white" }}
                              >
                                {d.text}
                              </Text>
                            </Tag>
                          </Col>
                        ))
                      ) : (
                        <Col>{t("none")}</Col>
                      )}
                    </Row>
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isAdmin ? (
                <Form.Item
                  name="teamMembers"
                  label={
                    <Badge color={memberColor} text={t("study-team-members")} />
                  }
                  rules={memberRules(t)}
                >
                  <Select
                    maxTagCount={6}
                    mode="multiple"
                    labelInValue
                    loading={fetchingMembers}
                    placeholder={t("placeholder-study-members")}
                    notFoundContent={
                      fetchingMembers ? <Spin size="small" /> : null
                    }
                    tagRender={tagRenderMembers}
                    filterOption={false}
                    onSearch={(e) => setMemberSearchTerm(e)}
                    onChange={clearUsers}
                    style={{ width: "100%" }}
                  >
                    {foundMembers.map((d) => (
                      <Option key={d.value}>
                        <Tag color={`var(--${d.role?.toLowerCase()}-color)`}>
                          {d.role?.charAt(0)}
                        </Tag>
                        {d.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Descriptions layout="vertical">
                  <Descriptions.Item
                    label={
                      <Badge
                        color={memberColor}
                        text={t("study-team-members")}
                      />
                    }
                  >
                    <Row>
                      {getSelectedItems("teamMembers").length > 0 ? (
                        foundMembers.map((d) => (
                          <Col flex={3}>
                            <Tag
                              color={memberColor}
                              style={{ margin: 3 }}
                              key={d.value}
                            >
                              <Text
                                ellipsis={true}
                                style={{ maxWidth: "200px", color: "white" }}
                              >
                                {d.text}
                              </Text>
                            </Tag>
                          </Col>
                        ))
                      ) : (
                        <Col>{t("none")}</Col>
                      )}
                    </Row>
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isAdmin ? (
                <Form.Item
                  name="researchAreas"
                  label={
                    <Badge
                      color={researchAreaColor}
                      text={t("study-research-area")}
                    />
                  }
                  rules={requiredArrayRules(t)}
                >
                  <Select
                    maxTagCount={6}
                    mode="multiple"
                    labelInValue
                    loading={fetchingResearchAreas}
                    placeholder={t("placeholder-study-research-area")}
                    notFoundContent={
                      fetchingResearchAreas ? <Spin size="small" /> : null
                    }
                    tagRender={tagRenderResearchArea}
                    filterOption={false}
                    onSearch={(e) => setResearchAreaSearchTerm(e)}
                    style={{ width: "100%" }}
                  >
                    {foundResearchAreas.map((d) => (
                      <Option key={d.value}>{parse(d.text)}</Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Descriptions layout="vertical">
                  <Descriptions.Item
                    label={
                      <Badge
                        color={researchAreaColor}
                        text={t("study-research-area")}
                      />
                    }
                  >
                    <Row>
                      {foundResearchAreas.map((d) => (
                        <Col flex={2}>
                          <Tag
                            color={researchAreaColor}
                            style={{ margin: 3 }}
                            key={d.value}
                          >
                            <Text
                              ellipsis={true}
                              style={{ maxWidth: "400px", color: "white" }}
                            >
                              {parse(d.text)}
                            </Text>
                          </Tag>
                        </Col>
                      ))}
                    </Row>
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isAdmin ? (
                <Form.Item
                  name="studyTypes"
                  label={
                    <Badge color={studyTypeColor} text={t("study-design")} />
                  }
                  rules={requiredArrayRules(t)}
                >
                  <Select
                    maxTagCount={6}
                    mode="multiple"
                    labelInValue
                    loading={fetchingStudyTypes}
                    placeholder={t("placeholder-study-design")}
                    notFoundContent={
                      fetchingStudyTypes ? <Spin size="small" /> : null
                    }
                    tagRender={tagRenderStudyType}
                    filterOption={false}
                    style={{ width: "100%" }}
                  >
                    {foundStudyTypes.map((d) => (
                      <Option key={d.value}>{d.text}</Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Descriptions layout="vertical">
                  <Descriptions.Item
                    label={
                      <Badge color={studyTypeColor} text={t("study-design")} />
                    }
                  >
                    <Row>
                      {getSelectedItems("studyTypes").map((d) => (
                        <Col flex={3}>
                          <Tag
                            color={studyTypeColor}
                            style={{ margin: 3 }}
                            key={d.value}
                          >
                            <Text
                              ellipsis={true}
                              style={{ maxWidth: "200px", color: "white" }}
                            >
                              {parse(d.text)}
                            </Text>
                          </Tag>
                        </Col>
                      ))}
                    </Row>
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isAdmin ? (
                <Form.Item
                  name="sampleSize"
                  label={t("study-sample-size")}
                  rules={requiredNoWhitespaceRules(t)}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={1}
                    max={100000}
                    placeholder={t("placeholder-study-sample-size")}
                  />
                </Form.Item>
              ) : (
                <Descriptions layout="vertical" column={1} colon={false}>
                  <Descriptions.Item label={t("study-sample-size")}>
                    {form.getFieldValue("sampleSize")}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isAdmin ? (
                <Form.Item
                  name="surveyTemplateId"
                  label={
                    <Space>
                      <Text>{t("survey-template")}</Text>
                      {surveyTemplateId && (
                        <Tooltip
                          title={t("survey-template-preview")}
                          onClick={previewSurveyTemplate}
                        >
                          <EyeOutlined />
                        </Tooltip>
                      )}
                    </Space>
                  }
                >
                  <Select
                    placeholder={t("survey-template")}
                    style={{ width: "100%" }}
                    loading={fetchingSurveyTemplates}
                    showSearch
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSelect={onSelectSurveyTemplate}
                    notFoundContent={
                      fetchingSurveyTemplates ? <Spin size="small" /> : null
                    }
                    onSearch={(e) => setSurveyTemplateSearchTerm(e)}
                  >
                    {foundSurveyTemplates.map((d) => (
                      <Option value={d.value}>{d.text}</Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Descriptions layout="vertical" column={1} colon={false}>
                  <Descriptions.Item
                    label={
                      <Space>
                        <Text>{t("survey-template")}</Text>
                        {surveyTemplateId && (
                          <Tooltip
                            title={t("survey-template-preview")}
                            onClick={previewSurveyTemplate}
                          >
                            <EyeOutlined />
                          </Tooltip>
                        )}
                      </Space>
                    }
                  >
                    {props.study?.surveyTemplateName
                      ? props.study.surveyTemplateName
                      : "Empty"}
                  </Descriptions.Item>
                </Descriptions>
              )}
              <SurveyTemplatePreview
                visible={surveyPreviewVisible}
                template={surveyTemplate}
                onPreviewClosed={onSurveyPreviewClosed}
                isSurveyJS={isSurveyJS}
              />
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Drawer>
  );
};

StudyDetailsDrawer.propsTypes = {
  visible: PropTypes.bool.isRequired,
  study: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default StudyDetailsDrawer;
