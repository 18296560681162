import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

const availableLanguages = ["en", "nl", "fr"];
let lang = localStorage.getItem("lang");

if (lang === null) {
  console.info("No language..reverting to browser default");
  if (availableLanguages.includes(navigator.language)) {
    lang = navigator.language;
  } else {
    lang = "en";
  }
  localStorage.setItem("lang", lang);
  console.info("Language set to " + lang);
}

i18n
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(HttpApi)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: `${process.env.REACT_APP_API_URL}/static-files/languages/{{lng}}/{{ns}}`,
    },
    ns: [
      "translation",
      "validation",
      "landing",
      "studytype",
      "survey",
      "notification",
    ],
    lng: lang,
    fallbackLng: "en",
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
