import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Menu, Dropdown, message, Modal } from "antd";

import SimpleMessage from "../simple-components/simple-message/simple-message";
import Icon, {
  EditOutlined,
  SendOutlined,
  CloudDownloadOutlined,
  EllipsisOutlined,
  DeleteOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  StopFilled,
  RollbackOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthProvider";
import {
  APP_ROLES,
  API_REQUESTS,
  MEASUREMENT_STATUS,
  SURVEY_STATUS,
  MEASUREMENT_TYPE,
  ENTITY_TYPE,
  DOWNLOAD_DATA_TYPES,
  DOWNLOAD_EXPORT_TYPES,
} from "../../utils/utils";
import {
  softDeleteEntity,
  setMeasurementSurveyStatus,
  stopMeasurement,
  exportAllMeasurements,
} from "../../../services/ApiService";
import i18n from "i18next";
import StopMeasurementDialog from "../stop-measurement-dialog/stop-measurement-dialog";
import ReassignMeasurementStudyModal from "../reassign-measurement-study/reassign-measurement-study";

const { SubMenu } = Menu;
const { confirm } = Modal;
export const MeasurementItemMenu = (props) => {
  const { user, getAccessToken } = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { record } = props;
  const [canSubmitMeasurement, setCanSubmitMeasurement] = useState(false);
  const [canEditSurvey, setCanEditSurvey] = useState(false);
  const [canDownloadSensor, setCanDownloadSensor] = useState(false);
  const [canViewSurvey, setCanViewSurvey] = useState(false);
  const [canDownloadRaw, setCanDownloadRaw] = useState(false);
  const [canDownloadSurvey, setCanDownloadSurvey] = useState(false);
  const [canDownloadCalibrated, setCanDownloadCalibrated] = useState(false);
  const [canDownloadProcessed, setCanDownloadProcessed] = useState(false);
  const [canDownloadResult, setCanDownloadResult] = useState(false);
  const [canDownloadFactors, setCanDownloadFactors] = useState(false);
  const [canStopMeasurement, setCanStopMeasurement] = useState(false);
  const [isStopModalVisible, setIsStopModalVisible] = useState(false);
  const [isReassignStudyVisible, setIsReassignStudyVisible] = useState(false);
  const [selectedRowMeasurement, setSelectedRowMeasurement] = useState(false);

  const [canWithdrawSurvey, setCanWithdrawSurvey] = useState(false);
  const [canDeleteMeasurement, setCanDeleteMeasurement] = useState(false);
  const [canReassignStudyMeasurement, setCanReassignStudyMeasurement] = useState(false);

  const isAdmin = user.role === APP_ROLES.ADMIN;

  useEffect(() => {
    if (user && record) {
      //can view only if it's submitted
      setCanViewSurvey(
        record.measurementStatus === MEASUREMENT_STATUS.COMPLETED &&
        record.surveyStatus === SURVEY_STATUS.SUBMITTED
      );

      //can submit only if it is completed
      setCanSubmitMeasurement(
        record.measurementStatus === MEASUREMENT_STATUS.COMPLETED &&
        record.surveyStatus === SURVEY_STATUS.COMPLETED
      );
      //can withdraw only if it is submitted
      setCanWithdrawSurvey(
        isAdmin && record.surveyStatus === SURVEY_STATUS.SUBMITTED
      );
      //based on existance of study correlation
      setCanEditSurvey(
        record.measurementType === MEASUREMENT_TYPE.REGULAR &&
        record.measurementStatus !== MEASUREMENT_STATUS.IN_PROGRESS
      );
      //only reserved for admin
      setCanDownloadSensor(
        isAdmin && record.measurementStatus !== MEASUREMENT_STATUS.IN_PROGRESS
      );
      //only reserved for admin or researcher that submitted the measurement (can be submitted only if completed)
      setCanDownloadRaw(
        (isAdmin &&
          record.measurementStatus !== MEASUREMENT_STATUS.IN_PROGRESS) ||
        (!isAdmin && record.surveyStatus === SURVEY_STATUS.SUBMITTED)
      );
      //only for submitted surveys
      setCanDownloadSurvey(
        (isAdmin && record.surveyStatus !== SURVEY_STATUS.EMPTY) ||
        (!isAdmin && record.surveyStatus === SURVEY_STATUS.SUBMITTED)
      );
      //only for calibration measurements
      setCanDownloadFactors(
        isAdmin &&
        record.measurementStatus !== MEASUREMENT_STATUS.IN_PROGRESS &&
        record.measurementType === MEASUREMENT_TYPE.CALIBRATION
      );
      //only for regular/test/ambient measurements
      setCanDownloadCalibrated(
        isAdmin &&
        record.measurementStatus !== MEASUREMENT_STATUS.IN_PROGRESS &&
        record.measurementType !== MEASUREMENT_TYPE.CALIBRATION
      );
      //only for regular/test measurements
      setCanDownloadProcessed(
        isAdmin &&
        record.measurementStatus !== MEASUREMENT_STATUS.IN_PROGRESS &&
        record.measurementType === MEASUREMENT_TYPE.REGULAR
      );
      setCanDownloadResult(
        isAdmin &&
        record.measurementStatus !== MEASUREMENT_STATUS.IN_PROGRESS &&
        record.measurementType === MEASUREMENT_TYPE.REGULAR &&
        record.diagnosticModels
      );
      setCanStopMeasurement(
        (user.id === record.userId || isAdmin) &&
        record.measurementStatus === MEASUREMENT_STATUS.IN_PROGRESS
      );
      setCanDeleteMeasurement(
        (isAdmin ||
          (user.id === record.userId &&
            record.surveyStatus !== SURVEY_STATUS.SUBMITTED)) &&
        record.measurementStatus !== MEASUREMENT_STATUS.IN_PROGRESS
      );
      setCanReassignStudyMeasurement(
        record.surveyStatus !== SURVEY_STATUS.SUBMITTED &&
        record.measurementStatus !== MEASUREMENT_STATUS.IN_PROGRESS &&
        (record.measurementType !== MEASUREMENT_TYPE.CALIBRATION && record.measurementType !== MEASUREMENT_TYPE.TEST) &&
        record.studyName !== undefined
      );
    }
  }, [record, user]);

  const downloadData = async (data) => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await exportAllMeasurements(data, token);
        if (res && res.result) {
          SimpleMessage(
            "success",
            t("notification:measurements-preparing-download", {
              count: res.result.fileCount,
            })
          );
        } else {
          SimpleMessage("error", t("measurements-download-error"));
        }
      }
    } catch (err) {
      SimpleMessage("error", err.message);
    }
  };

  const actionsClick = async ({ key }) => {
    let data = {
      selectedMeasurementIds: [record.id],
    };
    const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
    switch (key) {
      case "measurement-edit-measurement":
        //TODO go to record.id
        break;
      case "withdraw-survey":
        data = {
          measurementId: record.id,
          surveyStatus: SURVEY_STATUS.COMPLETED,
        };
        var res = await setMeasurementSurveyStatus(data, token);
        if (res) {
          SimpleMessage("success", t("survey-withdraw-success"));
          if (props.surveyStatusChanged)
            props.surveyStatusChanged(record.id, SURVEY_STATUS.COMPLETED);
          setCanSubmitMeasurement(
            record.measurementStatus === MEASUREMENT_STATUS.COMPLETED
          );
          setCanWithdrawSurvey(false);
        } else {
          SimpleMessage("error", res.error);
        }
        break;
      case "submit-measurement":
        data = {
          measurementId: record.id,
          surveyStatus: SURVEY_STATUS.SUBMITTED,
        };
        var res = await setMeasurementSurveyStatus(data, token);
        if (res) {
          SimpleMessage("success", t("survey:survey-submit-success"));
          if (props.surveyStatusChanged)
            props.surveyStatusChanged(record.id, SURVEY_STATUS.SUBMITTED);
          setCanWithdrawSurvey(isAdmin);
          setCanSubmitMeasurement(false);
        } else {
          SimpleMessage("error", res.error);
        }
        break;
      case "measurement-edit-survey":
        history.push({
          pathname: "/measurements/edit-survey",
          state: { measurement: record },
        });
        break;
      case "delete-measurement":
        data = {
          entityId: record.id.toString(),
          entityType: ENTITY_TYPE.MEASUREMENT,
        };
        var res = await softDeleteEntity(data, token);
        if (res.success) {
          SimpleMessage("success", t("delete-measurement-success"));
          if (props.refreshMeasurements) {
            props.refreshMeasurements({
              results: props.pageSettings?.pageSize,
              page: props.pageSettings?.current,
            });
          } else {
            history.push({
              pathname: "/measurements",
            });
          }
        } else {
          SimpleMessage("error", t(res.error));
        }
        break;
      case "measurement-sensor-csv":
        data.downloadType = [DOWNLOAD_DATA_TYPES.SENSOR];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.CSV];
        await downloadData(data);
        break;
      case "measurement-sensor-xls":
        data.downloadType = [DOWNLOAD_DATA_TYPES.SENSOR];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.XLSX];
        await downloadData(data);
        break;
      case "measurement-raw-csv":
        data.downloadType = [DOWNLOAD_DATA_TYPES.RAW];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.CSV];
        await downloadData(data);
        break;
      case "measurement-raw-xls":
        data.downloadType = [DOWNLOAD_DATA_TYPES.RAW];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.XLSX];
        await downloadData(data);
        break;
      case "measurement-survey-csv":
        data.downloadType = [DOWNLOAD_DATA_TYPES.SURVEY];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.CSV];
        await downloadData(data);
        break;
      case "measurement-survey-xls":
        data.downloadType = [DOWNLOAD_DATA_TYPES.SURVEY];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.XLSX];
        await downloadData(data);
        break;
      case "measurement-calibrated-json":
        data.downloadType = [DOWNLOAD_DATA_TYPES.CALIBRATED];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.JSON];
        await downloadData(data);
        break;
      case "measurement-calibrated-csv":
        data.downloadType = [DOWNLOAD_DATA_TYPES.CALIBRATED];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.CSV];
        await downloadData(data);
        break;
      case "measurement-calibrated-xls":
        data.downloadType = [DOWNLOAD_DATA_TYPES.CALIBRATED];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.XLSX];
        await downloadData(data);
        break;
      case "measurement-factors-json":
        data.downloadType = [DOWNLOAD_DATA_TYPES.FACTORS];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.JSON];
        await downloadData(data);
        break;
      case "measurement-processed-json":
        data.downloadType = [DOWNLOAD_DATA_TYPES.PROCESSED];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.JSON];
        await downloadData(data);
        break;
      case "measurement-processed-csv":
        data.downloadType = [DOWNLOAD_DATA_TYPES.PROCESSED];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.CSV];
        await downloadData(data);
        break;
      case "measurement-processed-xls":
        data.downloadType = [DOWNLOAD_DATA_TYPES.PROCESSED];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.XLSX];
        await downloadData(data);
        break;
      case "measurement-result-json":
        data.downloadType = [DOWNLOAD_DATA_TYPES.RESULTS];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.JSON];
        data.diagnosticModel = record.diagnosticModels;
        await downloadData(data);
        break;
      case "measurement-result-csv":
        data.downloadType = [DOWNLOAD_DATA_TYPES.RESULTS];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.CSV];
        data.diagnosticModel = record.diagnosticModels;
        await downloadData(data);
        break;
      case "measurement-result-xls":
        data.downloadType = [DOWNLOAD_DATA_TYPES.RESULTS];
        data.exportType = [DOWNLOAD_EXPORT_TYPES.XLSX];
        data.diagnosticModel = record.diagnosticModels;
        await downloadData(data);
        break;
      case "new-measurement-stop-measurement":
        setIsStopModalVisible(true);
        break;
      case "reassign-measurement-study":
        setReassignStudy(record);
        break;
      default:
        break;
    }
  };

  const setReassignStudy = (measurement) => {
    setSelectedRowMeasurement(measurement);
    setIsReassignStudyVisible(true);
  }

  const finishReassignStudy = () => {
    setIsReassignStudyVisible(false);
    if (props.refreshMeasurements) {
      props.refreshMeasurements({
        results: props.pageSettings?.pageSize,
        page: props.pageSettings?.current,
      });
    }
  }

  return (
    <>
      <ReassignMeasurementStudyModal
        visible={isReassignStudyVisible}
        measurement={selectedRowMeasurement}
        onClose={() => finishReassignStudy()}
      />
      <>
        <Dropdown
          overlay={
            <Menu id="measurement-menu-actions" onClick={actionsClick}>

              {canSubmitMeasurement && (
                <Menu.Item
                  id={`submit-measurement-${record.id}`}
                  key="submit-measurement"
                  icon={<SendOutlined style={{ fontSize: "14px" }} />}
                >
                  {t("submit-measurement")}
                </Menu.Item>
              )}
              {canEditSurvey && (
                <Menu.Item
                  id={`measurement-edit-survey-${record.id}`}
                  key="measurement-edit-survey"
                  icon={<EditOutlined style={{ fontSize: "14px" }} />}
                >
                  {!canViewSurvey
                    ? t("measurement-edit-survey")
                    : t("measurement-view-survey")}
                </Menu.Item>
              )}
              {canWithdrawSurvey && (
                <Menu.Item
                  id={`withdraw-survey-${record.id}`}
                  key="withdraw-survey"
                  icon={<RollbackOutlined style={{ fontSize: "14px" }} />}
                >
                  {t("withdraw-survey")}
                </Menu.Item>
              )}
              {canDownloadSensor && (
                <SubMenu
                  id={`submenu-measurement-sensor-${record.id}`}
                  title={t("measurements-sensor-data")}
                  icon={<CloudDownloadOutlined style={{ fontSize: "14px" }} />}
                >
                  <Menu.Item
                    id={`measurement-sensor-csv-${record.id}`}
                    key="measurement-sensor-csv"
                    icon={
                      <FileTextOutlined
                        style={{
                          fontSize: "14px",
                          color: "var(--csv-icon-color)",
                        }}
                      />
                    }
                  >
                    CSV
                  </Menu.Item>
                  <Menu.Item
                    id={`measurement-sensor-xls-${record.id}`}
                    key="measurement-sensor-xls"
                    icon={
                      <FileExcelOutlined
                        style={{
                          fontSize: "14px",
                          color: "var(--xls-icon-color)",
                        }}
                      />
                    }
                  >
                    XLS
                  </Menu.Item>
                </SubMenu>
              )}
              {canDownloadRaw && (
                <SubMenu
                  id={`submenu-measurement-raw-${record.id}`}
                  title={t("measurements-raw-data")}
                  icon={<CloudDownloadOutlined style={{ fontSize: "14px" }} />}
                >
                  <Menu.Item
                    id={`measurement-raw-csv-${record.id}`}
                    key="measurement-raw-csv"
                    icon={
                      <FileTextOutlined
                        style={{
                          fontSize: "14px",
                          color: "var(--csv-icon-color)",
                        }}
                      />
                    }
                  >
                    CSV
                  </Menu.Item>
                  <Menu.Item
                    id={`measurement-raw-xls-${record.id}`}
                    key="measurement-raw-xls"
                    icon={
                      <FileExcelOutlined
                        style={{
                          fontSize: "14px",
                          color: "var(--xls-icon-color)",
                        }}
                      />
                    }
                  >
                    XLS
                  </Menu.Item>
                </SubMenu>
              )}
              {canDownloadSurvey && (
                <SubMenu
                  id={`submenu-survey-${record.id}`}
                  title={t("measurements-survey-data")}
                  icon={<CloudDownloadOutlined style={{ fontSize: "14px" }} />}
                >
                  {/* Downloading Surveys as CSV files is not supported yet
                <Menu.Item
                  id={`measurement-survey-csv-${record.id}`}
                  key="measurement-survey-csv"
                  icon={
                    <FileTextOutlined
                      style={{
                        fontSize: "14px",
                        color: "var(--csv-icon-color)",
                      }}
                    />
                  }
                >
                  CSV
                </Menu.Item> */}
                  <Menu.Item
                    id={`measurement-survey-xls-${record.id}`}
                    key="measurement-survey-xls"
                    icon={
                      <FileExcelOutlined
                        style={{
                          fontSize: "14px",
                          color: "var(--xls-icon-color)",
                        }}
                      />
                    }
                  >
                    XLS
                  </Menu.Item>
                </SubMenu>
              )}
              {canDownloadFactors && (
                <Menu.Item
                  id={`measurement-factors-json-${record.id}`}
                  key="measurement-factors-json"
                  icon={
                    <FileTextOutlined
                      style={{ fontSize: "14px", color: "#52c41a" }}
                    />
                  }
                >
                  {t("measurement-factors-data")}
                </Menu.Item>
              )}
              {canDownloadCalibrated && (
                <SubMenu
                  id={`submenu-analysis-calibrated-${record.id}`}
                  title={t("measurement-analysis-calibrated")}
                  icon={
                    <CloudDownloadOutlined
                      style={{
                        fontSize: "14px",
                        color: "var(--measurement-calibration-color)",
                      }}
                    />
                  }
                >
                  <Menu.Item
                    id={`measurement-calibrated-json-${record.id}`}
                    key="measurement-calibrated-json"
                    icon={<FileTextOutlined style={{ fontSize: "14px" }} />}
                  >
                    JSON
                  </Menu.Item>
                  <Menu.Item
                    id={`measurement-calibrated-csv-${record.id}`}
                    key="measurement-calibrated-csv"
                    icon={
                      <FileTextOutlined
                        style={{
                          fontSize: "14px",
                          color: "var(--csv-icon-color)",
                        }}
                      />
                    }
                  >
                    CSV
                  </Menu.Item>
                  <Menu.Item
                    id={`measurement-calibrated-xls-${record.id}`}
                    key="measurement-calibrated-xls"
                    icon={
                      <FileExcelOutlined
                        style={{
                          fontSize: "14px",
                          color: "var(--xls-icon-color)",
                        }}
                      />
                    }
                  >
                    XLS
                  </Menu.Item>
                </SubMenu>
              )}
              {canDownloadProcessed && (
                <SubMenu
                  id={`submenu-analysis-processed-${record.id}`}
                  title={t("measurement-analysis-processed")}
                  icon={
                    <CloudDownloadOutlined
                      style={{ fontSize: "14px", color: "#52c41a" }}
                    />
                  }
                >
                  <Menu.Item
                    id={`measurement-processed-json-${record.id}`}
                    key="measurement-processed-json"
                    icon={<FileTextOutlined style={{ fontSize: "14px" }} />}
                  >
                    JSON
                  </Menu.Item>
                  <Menu.Item
                    id={`measurement-processed-csv-${record.id}`}
                    key="measurement-processed-csv"
                    icon={
                      <FileTextOutlined
                        style={{
                          fontSize: "14px",
                          color: "var(--csv-icon-color)",
                        }}
                      />
                    }
                  >
                    CSV
                  </Menu.Item>
                  <Menu.Item
                    id={`measurement-processed-xls-${record.id}`}
                    key="measurement-processed-xls"
                    icon={
                      <FileTextOutlined
                        style={{
                          fontSize: "14px",
                          color: "var(--xls-icon-color)",
                        }}
                      />
                    }
                  >
                    XLS
                  </Menu.Item>
                </SubMenu>
              )}
              {canDownloadResult && (
                <SubMenu
                  id={`submenu-analysis-results-${record.id}`}
                  title={t("measurement-analysis-results")}
                  icon={
                    <CloudDownloadOutlined
                      style={{ fontSize: "14px", color: "#52c41a" }}
                    />
                  }
                >
                  <Menu.Item
                    id={`measurement-result-json-${record.id}`}
                    key="measurement-result-json"
                    icon={<FileTextOutlined style={{ fontSize: "14px" }} />}
                  >
                    JSON
                  </Menu.Item>
                  <Menu.Item
                    id={`measurement-result-csv-${record.id}`}
                    key="measurement-result-csv"
                    icon={
                      <FileTextOutlined
                        style={{
                          fontSize: "14px",
                          color: "var(--csv-icon-color)",
                        }}
                      />
                    }
                  >
                    CSV
                  </Menu.Item>
                  <Menu.Item
                    id={`measurement-result-xls-${record.id}`}
                    key="measurement-result-xls"
                    icon={
                      <FileTextOutlined
                        style={{
                          fontSize: "14px",
                          color: "var(--xls-icon-color)",
                        }}
                      />
                    }
                  >
                    XLS
                  </Menu.Item>
                </SubMenu>
              )}
              {canStopMeasurement && (
                <Menu.Item
                  danger
                  id={`new-measurement-stop-measurement-${record.id}`}
                  key="new-measurement-stop-measurement"
                  icon={<StopFilled style={{ fontSize: "14px" }} />}
                >
                  {t("new-measurement-stop-measurement")}
                </Menu.Item>
              )}
              {canReassignStudyMeasurement && (<Menu.Item
                id="reassign-measurement-study"
                key="reassign-measurement-study"
                icon={<SwapOutlined style={{ fontSize: "14px" }} />}
              >
                {t("reassign-measurement-study")}
              </Menu.Item>)}
              {canDeleteMeasurement && (
                <Menu.Item
                  danger
                  id={`delete-measurement-${record.id}`}
                  key="delete-measurement"
                  icon={<DeleteOutlined style={{ fontSize: "14px" }} />}
                >
                  {t("delete-measurement")}
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={["click"]}
        >
          <EllipsisOutlined
            rotate={90}
            style={{ color: "black", fontSize: props.iconSize ?? "14px" }}
          />
        </Dropdown>
        <StopMeasurementDialog
          visible={isStopModalVisible}
          onClose={() => setIsStopModalVisible(false)}
          measurement={record}
        />

      </></>

  );
};

export default MeasurementItemMenu;
