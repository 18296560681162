import React, { useContext, useEffect, useState } from "react";
import { List, Tooltip, Avatar, Skeleton } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { ResearchContext } from "../../contexts/ResearchProvider";
import Parser from "rss-parser";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/fr";
import EmptyDescription from "../simple-components/empty-description/empty-description";
import { useTranslation } from "react-i18next";

const parser = new Parser();

const NewsFeed = () => {
  const { preferredLanguage } = useContext(ResearchContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const url = process.env.REACT_APP_BREATHOMIX_FEED;

  const fetchData = async () => {
    try {
      let res = await fetch(url);
      if (res) {
        let strFeed = await res.text();
        let parsedData = await parser.parseString(strFeed);
        setData(parsedData.items);
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const empty = (
    <EmptyDescription
      text={t("no-news")}
      symbol="😥"
      label="DISAPPOINTED BUT RELIEVED FACE"
    />
  );

  return (
    <Skeleton rows={5} loading={loading} active>
      <List
        style={{ maxHeight: "215px", overflow: "auto" }}
        locale={{ emptyText: empty }}
        size="small"
        itemLayout="vertical"
        dataSource={data}
        renderItem={(item) => {
          let dateString = moment(item.pubDate)
            .locale(preferredLanguage)
            .local()
            .format("YYYY-MM-DD HH:mm");
          let pastStr = moment(item.pubDate)
            .locale(preferredLanguage)
            .local()
            .fromNow();
          return (
            <List.Item key={item.guid}>
              <List.Item.Meta
                title={
                  <a
                    href={item.link}
                    style={{ fontSize: "14px" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.title}
                  </a>
                }
                avatar={<Avatar src="/img/favicon.ico" />}
                description={
                  <Tooltip placement="top" title={dateString}>
                    <span style={{ fontSize: "12px" }}>
                      <ClockCircleOutlined /> {pastStr}
                    </span>
                  </Tooltip>
                }
              />
            </List.Item>
          );
        }}
      />
    </Skeleton>
  );
};

export default NewsFeed;
