import { AutoComplete } from "antd";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import { API_REQUESTS } from "../../utils/utils";

const AutoCompleteSearch = (props) => {
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const { getAccessToken } = useContext(AuthContext);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        if (searchTerm) {
          var newOptions = [];

          const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
          if (!token) throw new Error("No access token available.");

          var finalArgs = [];
          if (props.fetchArgs) {
            finalArgs = [token, ...props.fetchArgs, searchTerm];
          } else {
            finalArgs = [token, searchTerm];
          }

          let response = await props.fetchCall(...finalArgs);

          if (response?.result?.data && !response.error) {
            newOptions = response.result.data.map(props.optionMapper);
          }
          setOptions(newOptions);
        } else {
          setOptions([]);
        }
      } catch (err) {
        console.error(err);
      }
    };
    const timer = setTimeout(() => {
      fetchOptions();
    }, 800);
    return () => clearTimeout(timer);
  }, [props, searchTerm]);

  const onChange = (data) => {
    props.onChange(data);
  };

  return (
    <AutoComplete
      id={props.id}
      disabled={props.disabled}
      dropdownMatchSelectWidth={500}
      value={props.value}
      options={options}
      onChange={onChange}
      onSearch={(term) => setSearchTerm(term)}
      placeholder={props.placeholder}
    />
  );
};

AutoCompleteSearch.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fetchCall: PropTypes.func.isRequired,
  fetchArgs: PropTypes.array,
  optionMapper: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.any,
};

export default AutoCompleteSearch;
