import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import { useTranslation } from "react-i18next";
import SimpleMessage from "../simple-components/simple-message/simple-message";
import {
  API_REQUESTS,
  ENTITY_TYPE,
  ENTITY_ACTIONS,
  getFormattedSurveyTemplate,
} from "../../utils/utils";
import {
  InfoCircleOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Drawer,
  Skeleton,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Tooltip,
  Space,
  Switch,
} from "antd";
import {
  upsertSurveyTemplate,
  getGeneralSurveyTemplate,
  softDeleteEntity,
} from "../../../services/ApiService";
const { TextArea } = Input;
const { confirm } = Modal;

const SurveyTemplateDrawer = (props) => {
  const { getAccessToken } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [templateTitle, setTemplateTitle] = useState(null);
  const [surveyTemplateId, setSurveyTemplateId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  let newTemplate = null;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setVisible(props.visible);
      newTemplate = null;
      if (props.template) {
        setSurveyTemplateId(props.template.surveyTemplateId);
        setTemplateTitle(t("survey-template-update"));

        form.setFieldsValue({
          ...props.template,
          templateJson: getFormattedSurveyTemplate(props.template.templateJson),
        });
      } else {
        setSurveyTemplateId(0);
        form.resetFields();
        await getGeneralSurvey();
        setTemplateTitle(t("survey-template-add"));
      }
    };
    if (props) {
      fetchData();
    }
  }, [props]);

  const getGeneralSurvey = async () => {
    setLoading(true);
    const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
    if (token) {
      let res = await getGeneralSurveyTemplate(token);
      form.setFieldsValue({
        templateJson: getFormattedSurveyTemplate(JSON.stringify(res)),
      });
    }
    setLoading(false);
  };

  const onFinish = async (values) => {
    if (!props.template?.isDefault || values.isDefault) {
      setLoading(true);
      try {
        const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
        if (token) {
          values.surveyTemplateId = surveyTemplateId;
          let res = await upsertSurveyTemplate(values, token);

          if (res) {
            if (res.result && res.result.success) {
              if (values.surveyTemplateId !== 0) {
                newTemplate = values;
                clearAndClose(ENTITY_ACTIONS.UPDATE);
                SimpleMessage(
                  "success",
                  t("update-template-success", {
                    name: values.name,
                  })
                );
              } else {
                newTemplate = res.result.template ? res.result.template : null;
                clearAndClose(ENTITY_ACTIONS.CREATE);
                SimpleMessage(
                  "success",
                  t("create-template-success", {
                    name: values.name,
                  })
                );
              }
            } else if (res.result && !res.result.success) {
              SimpleMessage("error", t(res.result.error));
            } else {
              if (Array.isArray(res.error)) {
                console.error(res.error);
                SimpleMessage(
                  "error",
                  t("create-template-error", {
                    name: values.name,
                  })
                );
              } else {
                SimpleMessage("error", t(res.error));
              }
            }
          }
        }
      } catch (err) {
        SimpleMessage("error", err.message);
      }
      setLoading(false);
    } else {
      SimpleMessage("error", t("default-template-upsert-error"));
    }
  };

  const closeDrawer = () => {
    if (dirty) {
      //show confirm
      confirm({
        cancelText: t("template-details-cancel"),
        icon: <QuestionCircleOutlined style={{ color: "rgb(24, 144, 255)" }} />,
        title: t("template-update-leave-title"),
        content: t("template-update-leave-content"),
        onOk: () => clearAndClose(),
      });
    } else {
      clearAndClose();
    }
  };

  const clearAndClose = (action) => {
    setDirty(false);
    form.resetFields();
    props.onClose(newTemplate, action);
  };

  const onValuesChange = (changedValues) => {
    setDirty(form.isFieldsTouched());
  };

  const onSaveClick = () => {
    form.submit();
  };

  const onDeleteClick = () => {
    if (!props.template.isDefault) {
      confirm({
        cancelText: t("template-details-cancel"),
        icon: <QuestionCircleOutlined style={{ color: "rgb(24, 144, 255)" }} />,
        title: t("template-details-delete-title"),
        content: t("template-details-delete-content"),
        onOk: deleteTemplate,
      });
    } else {
      SimpleMessage("error", t("default-template-upsert-error"));
    }
  };

  const deleteTemplate = async () => {
    const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
    var data = {
      entityId: props.template.surveyTemplateId.toString(),
      entityType: ENTITY_TYPE.SURVEY_TEMPLATE,
    };
    var res = await softDeleteEntity(data, token);
    if (res.success) {
      newTemplate = props.template;
      SimpleMessage("success", t("delete-template-success"));
      clearAndClose(ENTITY_ACTIONS.DELETE);
    } else {
      SimpleMessage("error", res.error);
    }
  };

  return (
    <Drawer
      title={templateTitle}
      width={450}
      onClose={closeDrawer}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <>
          {props.template && (
            <Button
              id="delete-template-details"
              onClick={onDeleteClick}
              danger
              type="primary"
              style={{ float: "left" }}
            >
              <DeleteOutlined />
              {t("template-details-delete")}
            </Button>
          )}

          <Button
            id="save-template-details"
            disabled={!dirty}
            onClick={onSaveClick}
            type="primary"
            style={{ float: "right" }}
          >
            {t("template-details-confirm")}
          </Button>

          <Button
            id="cancel-template-details"
            onClick={closeDrawer}
            style={{ marginRight: 8, float: "right" }}
          >
            {t("template-details-cancel")}
          </Button>
        </>
      }
    >
      <Skeleton loading={loading} active paragraph={{ rows: 5 }}>
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="name"
                label={t("template-details-name")}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: t(
                      "validation:profile-details-validation-required"
                    ),
                  },
                  {
                    max: 31,
                    message: t("template-name-too-long"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="templateJson"
                label={t("template-details-json")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "validation:profile-details-validation-required"
                    ),
                  },
                  ({ getFieldValue }) => ({
                    asyncValidator: (rule, value) => {
                      return new Promise((resolve, reject) => {
                        try {
                          JSON.parse(getFieldValue("templateJson"));
                          resolve();
                        } catch {
                          reject(
                            t(
                              "validation:survey-template-validation-format-invalid"
                            )
                          );
                        }
                      });
                    },
                  }),
                ]}
              >
                <TextArea autoSize={{ minRows: 10, maxRows: 14 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="isDefault"
                valuePropName="checked"
                initialValue={form.getFieldValue("isDefault")}
                label={
                  <Space>
                    {t("template-details-default")}
                    <Tooltip title={t("template-details-default")}>
                      <InfoCircleOutlined
                        style={{ color: "var(--default-tooltip-color)" }}
                      />
                    </Tooltip>
                  </Space>
                }
              >
                <Switch
                  checkedChildren={t("yes")}
                  unCheckedChildren={t("no")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Drawer>
  );
};

export default SurveyTemplateDrawer;
