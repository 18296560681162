import React, { useState, useEffect, useContext } from "react";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Space,
  Tooltip,
  Modal,
  Skeleton,
  Switch,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  firstNameRules,
  lastNameRules,
  requiredRules,
  emailRules,
  phoneRules,
  jobRules,
  addressRules,
  organizationRules,
  departmentRules,
  accountRules,
} from "../profile-details/profile-details-rules";
import "./create-user.css";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  APP_ROLES,
  API_REQUESTS,
  getFullLanguageString,
} from "../../utils/utils";
import SimpleMessage from "../simple-components/simple-message/simple-message";
import { AuthContext } from "../../contexts/AuthProvider";
import {
  createUser,
  searchAddress,
  getOrganizations,
} from "../../../services/ApiService";
import AutoCompleteSearch from "../auto-complete-search/auto-complete-search";
const { Option } = Select;
const { confirm } = Modal;

const CreateUser = (props) => {
  const { getAccessToken } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [restrictedLanguage, setRestrictedLanguage] = useState(false);

  useEffect(() => {
    setVisible(props.visible);
  }, [props]);

  const onFinish = async (values) => {
    //call graph api
    setLoading(true);
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let response = await searchAddress(token, values.address);
        if (response?.result?.data?.length) {
          let found = response.result.data[0]; //get first
          if (found) {
            values.address = found.address;
            values.address.position = found.position;
            let res = await createUser(values, token);
            if (res) {
              if (res.result) {
                clearAndClose();
                SimpleMessage("success", t("create-user-success"));
              } else {
                if (Array.isArray(res.error)) {
                  logError(res.error);
                  SimpleMessage("error", t("profile-details-error"));
                } else {
                  SimpleMessage("error", t(res.error));
                }
              }
            }
          } else {
            SimpleMessage("error", t("profile-details-address-invalid"));
          }
        } else {
          SimpleMessage("error", t("profile-details-address-invalid"));
        }
      }
    } catch (err) {
      SimpleMessage("error", err.message);
    }
    setLoading(false);
  };

  const logError = (error) => {
    const errArray = error.map((err) => {
      return (
        "Field " +
        err.fieldName +
        ": " +
        err.messages.map((e) => t(e)).toString()
      );
    });
    console.error(errArray.toString());
  };

  const closeDrawer = () => {
    if (dirty) {
      //show confirm
      confirm({
        cancelText: t("add-user-cancel"),
        icon: (
          <QuestionCircleOutlined
            style={{ color: "var(--question-icon-color)" }}
          />
        ),
        title: t("add-user-leave-title"),
        content: t("add-user-leave-content"),
        onOk: clearAndClose,
      });
    } else {
      clearAndClose();
    }
  };

  const clearAndClose = () => {
    setDirty(false);
    form.resetFields();
    props.onClose();
  };

  const onSaveClick = () => {
    form.submit();
  };

  const onValuesChange = (changedValues) => {
    setDirty(form.isFieldsTouched());
    if (changedValues.givenName || changedValues.surname) {
      if (form.getFieldValue("givenName") && form.getFieldValue("surname")) {
        let accountName = `${form.getFieldValue(
          "givenName"
        )}.${form.getFieldValue("surname")}`;
        form.setFieldsValue({
          account: accountName.toLowerCase().replace(/\s/g, "."),
        });
        form.validateFields(["account"]);
      }
    } else if (changedValues.isFixedLanguage != undefined) {
      setRestrictedLanguage(changedValues.isFixedLanguage);
    }
  };

  return (
    <Drawer
      title={t("add-user")}
      width={720}
      onClose={closeDrawer}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          id="footer-add-user"
          style={{
            textAlign: "right",
          }}
        >
          <Button
            onClick={closeDrawer}
            style={{ marginRight: 8 }}
            id="cancel-add-user"
          >
            {t("add-user-cancel")}
          </Button>
          <Button
            disabled={!dirty}
            onClick={onSaveClick}
            type="primary"
            id="save-add-user"
          >
            {t("add-user-confirm")}
          </Button>
        </div>
      }
    >
      <Skeleton loading={loading} active paragraph={{ rows: 5 }}>
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="givenName"
                label={t("profile-firstname")}
                rules={firstNameRules(t)}
              >
                <Input placeholder={t("placeholder-firstname")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="surname"
                label={t("profile-lastname")}
                rules={lastNameRules(t)}
              >
                <Input placeholder={t("placeholder-lastname")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="role"
                label={t("profile-role")}
                rules={requiredRules(t)}
              >
                <Select placeholder={t("placeholder-role")}>
                  <Option value={APP_ROLES.ADMIN}>{t("admin")}</Option>
                  <Option value={APP_ROLES.RESEARCHER}>
                    {t("researcher")}
                  </Option>
                  <Option value={APP_ROLES.CLINICIAN}>{t("clinician")}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="account"
                label={t("profile-login")}
                rules={accountRules(t)}
              >
                <Input
                  className="account-input"
                  addonAfter="@breathbase.org"
                  placeholder={t("placeholder-account")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="mail"
                label={
                  <Space>
                    {t("profile-email")}
                    <Tooltip title={t("profile-email-info")}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Space>
                }
                rules={emailRules(t)}
              >
                <Input placeholder={t("placeholder-email")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="mobilePhone"
                label={t("profile-phone")}
                rules={phoneRules(t)}
              >
                <Input placeholder={t("placeholder-phone")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="organization"
                label={t("profile-organization")}
                rules={organizationRules(t)}
              >
                <AutoCompleteSearch
                  id={"select-organization"}
                  placeholder={t("placeholder-organization")}
                  onChange={(r) => (form.organization = r)}
                  fetchCall={getOrganizations}
                  fetchArgs={[0, 10]}
                  optionMapper={(it) => ({
                    text: it.name,
                    value: it.name,
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="jobTitle"
                label={t("profile-job")}
                rules={jobRules(t)}
              >
                <Input placeholder={t("placeholder-job-title")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="department"
                label={t("profile-department")}
                rules={departmentRules(t)}
              >
                <Input placeholder={t("placeholder-department")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="address"
                label={t("profile-address")}
                rules={addressRules(t)}
              >
                <AutoCompleteSearch
                  id={"select-address"}
                  placeholder={t("placeholder-address")}
                  onChange={(r) => (form.address = r)}
                  fetchCall={searchAddress}
                  optionMapper={(it) => ({
                    value: it.address.freeformAddress,
                    key: it.id,
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="isFixedLanguage"
                valuePropName="checked"
                initialValue={false}
                label={t("set-select-fixed-language")}
              >
                <Switch
                  checkedChildren={t("yes")}
                  unCheckedChildren={t("no")}
                />
              </Form.Item>
            </Col>
            {restrictedLanguage && (
              <Col span={12}>
                <Form.Item name="language" label={t("select-fixed-language")}>
                  <Select
                    placeholder={getFullLanguageString(t)["EN"]}
                    style={{ width: "40%" }}
                  >
                    <Option key="english" value="en">
                      {getFullLanguageString(t)["EN"]}
                    </Option>
                    <Option key="dutch" value="nl">
                      {getFullLanguageString(t)["NL"]}
                    </Option>
                    <Option key="french" value="fr">
                      {getFullLanguageString(t)["FR"]}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Skeleton>
    </Drawer>
  );
};

export default CreateUser;
