import React, { useEffect, useState, useContext } from "react";
import "survey-react/survey.css";
import "nouislider/dist/nouislider.css";
import * as Survey from "survey-react";
import SurveySlider from "../../survey/question-item-slider/question-item-nouislider";
import IcdWidget from "../question-item-icd/question-item-icd.js";
import { Skeleton } from "antd";
import { AuthContext } from "../../contexts/AuthProvider";
import { ResearchContext } from "../../contexts/ResearchProvider";
import {
  saveSurveyData,
  setMeasurementSurveyStatus,
} from "../../../services/ApiService";
import {
  API_REQUESTS,
  SURVEYJS_SUPPORTED_LANGUAGES,
  SURVEY_STATUS,
  API_ENDPOINTS,
} from "../../utils/utils";
import SimpleMessage from "../../components/simple-components/simple-message/simple-message";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import $ from "jquery";
import "select2/dist/css/select2.css";
import "select2/dist/js/select2.js";

import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/fr";
import { useHistory } from "react-router-dom";

// IMPORTANT: Don't remove this line. It's needed for the ICD widget to work properly.
window["$"] = window["jQuery"] = $;

const SurveyJSQuestionnaire = (props) => {
  const { t } = useTranslation();
  const [survey, setSurvey] = useState(null);
  const [loadingTemplate, setLoadingTemplate] = useState(true);
  const { preferredLanguage } = useContext(ResearchContext);
  const { getAccessToken } = useContext(AuthContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();

  // IMPORTANT: The ICD widget needs to be in this scope due to the translation
  IcdWidget(
    Survey,
    $,
    t("survey:survey-health-control"),
    API_ENDPOINTS.ICD_SEARCH
  );

  SurveySlider(Survey);

  useEffect(() => {
    if (props.surveyTemplateJson == null) return;
    setSurveyTheme();
    setSurvey(new Survey.Model(props.surveyTemplateJson));
    setLoadingTemplate(false);
  }, [props.surveyTemplateJson]);

  useEffect(() => {
    if (!survey) return;


    survey.onComplete.add(function (sender, options) {
      // sender.data is slightly different from the sender.data in the onCompleting and onCurrentpageChanged events.
      // sender.data in this function does not contain the filled in ICD questions!
      if (Object.keys(sender.data).length === 0 || props.preview) return;
      if (props.surveyStatus === SURVEY_STATUS.SUBMITTED) {
        SimpleMessage(
          "error",
          "This survey is already submitted, changed data will not be saved"
        );
      }
      if (props.fromMeasurementList === false) {
        props.surveyJSSubmit();
      }
      else { history.push("/measurements") }

    });

    survey.onCompleting.add(function (sender, options) {
      if (props.surveyStatus !== SURVEY_STATUS.SUBMITTED) {
        SimpleMessage("success", "Survey is submitted");
      }
      setIsSubmitted(true);
      evaluateSurveyStatus(true);
    });

    // set correct text on button:
    survey.completeText = "Submit";
    survey.checkErrorsMode = "onComplete";
    survey.clearValueOnDisableItems = true;

    // Set the language of the survey
    survey.locale = SURVEYJS_SUPPORTED_LANGUAGES.SUPPORTED.includes(
      preferredLanguage
    )
      ? preferredLanguage
      : "en";

    // to get inputvalidation
    survey.onValueChanged.add(function (sender, options) {
      // We do not want to save the survey data when the surveyData is not loaded in yet.
      if (sender.data.hasOwnProperty('measurementId')) {
        if (!options.question.isReadOnly) { options.question.hasErrors(true); }
        // to get the visitNo that the person fills in. And set it in the variable. 
        if (options.question.name === 'visitNo') {
          props.setVisitNo(options.question.value);
        }
        removeNAFromEnabledQuestions(sender.data);
        updateSurveyData(sender.data, false);
      }
    });

    // Survey-Measurement-Step
    survey.data = props.surveyData;

    // The props.surveyData is only filled after the survey-measurement-step, but empty when you come from the measurement list.
    // Keep this check to assure that the data will be saved.
    if (props.surveyData) {
      setDefaultValueForNouislider();
    }
  }, [survey]);

  useEffect(() => {
    // get the surveydata when you come from the measurementlist.
    if (props?.fromMeasurementList) {
      props.onGetSurveyData();
    }
  }, [props?.fromMeasurementList])

  useEffect(() => {
    // Set the survey data when you come from the measurement list
    if (props.surveyData == null || survey == null) return;
    survey.data = props.surveyData;
    // set the correct defaultvalue for the nouislider when you come from the measurement list.
    setDefaultValueForNouislider();
  }, [props?.surveyData]);

  useEffect(() => {
    // when the user clicks the save button
    if (props?.surveyData == null || survey == null) return;
    updateSurveyData(survey.data, true);

  }, [props?.saveOrSubmit]);

  // Save measurement data to the database
  const updateSurveyData = async (surveyData, viaSaveButton) => {
    // you don't need to save the survey automatically when the survey is already submitted
    if (props?.surveyStatus === SURVEY_STATUS.SUBMITTED) return;
    fillDisabledQuestionsWithNA(surveyData);
    if (props.preview) return;
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      let postData = {
        deviceId: props.deviceId,
        measurementId: props.measurementId,
        noseId: props.noseId,
        data: JSON.stringify(surveyData),
      };
      if (token) {
        // actual saving of the data
        let res = await saveSurveyData(postData, token);
        await evaluateSurveyStatus(isSubmitted, viaSaveButton);
        if (res && viaSaveButton) {
          SimpleMessage(
            "success",
            t("measurement-edit-survey-save-survey-success")
          );
          history.push("/measurements");
        }
      } else {
        SimpleMessage("error", t("measurement-edit-survey-save-survey-error"));
      }
    } catch {
      SimpleMessage("error", t("measurement-edit-survey-save-survey-error"));
    }
  };

  // fill disabled questions with NA
  const fillDisabledQuestionsWithNA = (surveyData) => {
    let allQuestions = survey.getAllQuestions(false);
    for (const q of allQuestions) {
      // if a question is disabled (aka has an enableIf option and is readOnly)
      // panels do not have an enableIf option, but do not have a readonly property.
      if (
        q.isReadOnly &&
        (!q.readOnly || q.expressionInfo.enableIf.runner !== undefined)
      ) {
        surveyData[`${q.name}`] = "N/A";
        q.clearErrors();
      }
    }
  };

  // remove NA from re-enabled questions
  const removeNAFromEnabledQuestions = (surveyData) => {
    let allQuestions = survey.getAllQuestions(false);
    for (const q of allQuestions) {
      if (!q.isReadOnly && q.expressionInfo.enableIf.runner !== undefined && surveyData[q.name] == ["N/A"]) {
        q.clearValue();
      }
    }
  }

  // check if all questions are answered:
  const isAllQuestionsAnswered = (questions) => {
    let isAllAnswered = true;
    for (const q of questions) {
      if (
        q.isRequired &&
        (q.isEmpty() || (q.getType() == "tagboxmedical" && !q.questionValue))
      ) {
        isAllAnswered = false;
        break;
      }
    }
    return isAllAnswered;
  };

  // determine the correct surveystatus
  const evaluateSurveyStatus = async (surveyIsSubmitted, viaSaveButton) => {
    // first check if the survey is submitted. When the user clicks on submit (and all the questions are filled in),
    // some properties of the survey change. isCompleted will therefore become false and the wrong surveystatus will occur.
    // therefore we should do the if surveyIsSubmitted check first.
    if (surveyIsSubmitted) {
      setCorrectSurveyStatus(SURVEY_STATUS.SUBMITTED);
      return;
    }
    let isCompleted = isAllQuestionsAnswered(survey.getAllQuestions(true));
    if (!isCompleted) {
      setCorrectSurveyStatus(SURVEY_STATUS.IN_PROGRESS);
    } else {
      if (props?.surveyStatus !== SURVEY_STATUS.COMPLETED && viaSaveButton) {
        SimpleMessage("success", t("survey:survey-completed-success"));
      }
      setCorrectSurveyStatus(SURVEY_STATUS.COMPLETED);
    }
  };

  // set the correct surveyStatus
  const setCorrectSurveyStatus = async (surveyStatus) => {
    // check if the surveystatus is different from the one that it currently is
    if (!surveyStatus || surveyStatus === props.surveyStatus) return;
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let data = {
          measurementId: props.measurementId,
          surveyStatus,
        };
        let res = await setMeasurementSurveyStatus(data, token);
        if (res && !res.error && surveyStatus === SURVEY_STATUS.SUBMITTED) {
          SimpleMessage("success", t("survey:survey-status-updated-success"));
        }
      }
    } catch (ex) {
      SimpleMessage("error", t("survey:survey-error-submit"));
    }
  };

  // To set the nouisliders on their defaultvalue
  const setDefaultValueForNouislider = () => {
    survey.onAfterRenderQuestion.add((sender, options) => {
      if (
        options.question.getType() === "nouislider" &&
        !props.surveyData.hasOwnProperty(options.question.name) &&
        (options.question.getDefaultValue() !== undefined || null)
      ) {
        survey.setValue(
          options.question.name,
          options.question.getDefaultValue()
        );
        props.surveyData[options.question.name] =
          options.question.getDefaultValue();
      }
    });
  };

  // Set the survey theme
  const setSurveyTheme = () => {
    var defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
    defaultThemeColors["$main-color"] = "var(--main-color-yellow)";
    defaultThemeColors["$main-hover-color"] = "var(--main-color-yellow-light)";
    defaultThemeColors["$header-color"] = "var(--main-color-yellow)";
    Survey.StylesManager.applyTheme("defaultThemeColors");
  };

  return (
    <div>
      <div id="surveymodel">
        <Skeleton active loading={loadingTemplate}>
          {survey && <Survey.Survey model={survey}></Survey.Survey>}
        </Skeleton>
      </div>
    </div>
  );
};

SurveyJSQuestionnaire.propsTypes = {
  preview: PropTypes.bool,
  deviceId: PropTypes.string,
  measurementId: PropTypes.string,
  noseId: PropTypes.string,
  surveyData: PropTypes.object,
};

// Preview is for the Study/Survey Page preview
SurveyJSQuestionnaire.defaultProps = {
  preview: false,
};

export default SurveyJSQuestionnaire;
