import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Typography, Button, Table, Input, Space, Tooltip } from "antd";
import {
  APP_ROLES,
  API_REQUESTS,
  MEASUREMENT_STATUS,
  SURVEY_STATUS,
  NOTIFICATION_TYPES,
  getSortField,
} from "../../utils/utils";
import { AuthContext } from "../../contexts/AuthProvider";
import { NotificationContext } from "../../contexts/NotificationProvider";
import { useTranslation } from "react-i18next";
import {
  getMeasurements,
  exportAllMeasurements,
} from "../../../services/ApiService";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import EmptyDescription from "../../components/simple-components/empty-description/empty-description";
import SimpleMessage from "../../components/simple-components/simple-message/simple-message";
import { ResearchContext } from "../../contexts/ResearchProvider";
import { getMeasurementColumns } from "./measurements-columns";
import "./measurements-page.css";
import i18n from "i18next";
import { PageSettingsContext } from "../../contexts/PageSettingsProvider";
import MeasurementDownloadDropdown from "../../components/measurement-download-dropdown/measurement-download-dropdown";
const { Title, Text } = Typography;
const { Search } = Input;

const MeasurementsPage = () => {
  let history = useHistory();
  const accessedViaBackAction = history.action == "POP";
  const { user, getAccessToken } = useContext(AuthContext);
  const { preferredLanguage } = useContext(ResearchContext);
  const { measurementsFilterState, setMeasurementsFilterState, resetFilters } =
    useContext(PageSettingsContext);
  const {
    subscribeTo,
    isConnected,
    unsubscribeFrom,
    joinNotificationGroup,
    leaveNotificationGroup,
  } = useContext(NotificationContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [onlyEmptySurveys, setOnlyEmptySurveys] = useState(false);
  const [noSubmittedSurveys, setNoSubmittedSurveys] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [datasource, setDatasource] = useState([]);
  const [searchTerm, setSearchTerm] = useState(
    accessedViaBackAction ? measurementsFilterState.searchTerm : null
  );
  const [pageSettings, setPageSettings] = useState({
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} of ${total} ${t("measurements")}`,
    pageSize: accessedViaBackAction ? measurementsFilterState.pageSize : 10,
    current: accessedViaBackAction ? measurementsFilterState.currentPage : 1,
  });

  const [selectedMeasurements, setSelectedMeasurements] = useState([]);
  const [selectedMeasurementsFull, setSelectedMeasurementsFull] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [wasComponentInitialized, setWasComponentInitialized] = useState(false);
  const [subscribedUserIdList, setSubscribedUserIdList] = useState([]);

  const isAdmin = user.role === APP_ROLES.ADMIN;

  const clearAll = () => {
    setSelectedMeasurements([]);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    if (!accessedViaBackAction) {
      resetFilters();
    }
  }, []);

  useEffect(() => {
    if (isConnected) {
      subscribeTo(
        NOTIFICATION_TYPES.MEASUREMENT_CHANGED,
        onMeasurementStatusChangedRef.current
      );
      subscribedUserIdList.forEach((userId) => {
        joinNotificationGroup(userId);
      });
    }
    return () => {
      if (!isConnected) {
        unsubscribeFrom(
          NOTIFICATION_TYPES.MEASUREMENT_CHANGED,
          onMeasurementStatusChangedRef.current
        );
        subscribedUserIdList.forEach((userId) => {
          leaveNotificationGroup(userId);
        });
      }
    };
  }, [isConnected]);

  useEffect(() => {
    let dataSourceIds = datasource.map((a) => a.id);

    let intersection = selectedMeasurements.filter((x) =>
      dataSourceIds.includes(x)
    );
    setSelectedRowKeys(intersection);

    // Used to filter every user ID for which the measurements are still in progress.
    // These users IDs are used to join the notification groups used for measurement live updates.
    // Current user ID is filtered out because it has been used already to join a notification group
    // in NotificationContext and using it here will be intefering with that notification group.
    const userIdList = datasource
      .filter(
        (it) =>
          it.measurementStatus === MEASUREMENT_STATUS.IN_PROGRESS &&
          it.userId != user.id
      )
      .map((it) => it.userId);
    initUserSubscriptions(userIdList);
  }, [datasource]);

  const handleOnSelect = (selected) => {
    if (selectedMeasurements.length > 0) {
      const index = selectedMeasurements.indexOf(selected.id);
      if (index > -1) {
        selectedMeasurements.splice(index, 1);
        selectedMeasurementsFull.splice(index, 1);

        setSelectedMeasurements([...selectedMeasurements]);
        setSelectedMeasurementsFull([...selectedMeasurementsFull])
        setSelectedRowKeys([...selectedMeasurements]);
      } else {
        let measurements = [...selectedMeasurements, selected.id];
        setSelectedMeasurementsFull([...selectedMeasurementsFull, selected])
        setSelectedMeasurements(measurements);
        setSelectedRowKeys(measurements);
      }
    } else {
      setSelectedMeasurements([selected.id]);
      setSelectedRowKeys([selected.id]);
      setSelectedMeasurementsFull([selected])

    }
  };

  useEffect(() => {
    canDownloadSurvey(selectedMeasurements);
  }, [selectedMeasurementsFull]);

  const canDownloadSurvey = (selectedMeasurementIds) => {
    let measurements = selectedMeasurementsFull.filter(m => selectedMeasurementIds.includes(m.id));
    let canDownload = isAdmin
      ? measurements.some(m => m.surveyStatus > 0)
      : measurements.some(m => m.surveyStatus === 3)
    setOnlyEmptySurveys(!canDownload)
  }

  const handleOnSelectAll = (selected, selectedRows, changeRows) => {
    let changeRowsIds = changeRows.map((m) => m.id);
    if (selected) {
      setSelectedMeasurements([...selectedMeasurements, ...changeRowsIds]);
      setSelectedRowKeys([...selectedMeasurements, ...changeRowsIds]);
      setSelectedMeasurementsFull([...selectedMeasurementsFull, ...changeRows])
    } else {
      let difference = selectedMeasurements.filter(
        (x) => !changeRowsIds.includes(x)
      );
      setSelectedMeasurements(difference);
      setSelectedRowKeys(difference);
      setSelectedMeasurementsFull(selectedMeasurementsFull.filter(m => !changeRowsIds.includes(m.id)))
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    getCheckboxProps: (record) => ({
      disabled:
        (!isAdmin &&
          (record.measurementStatus < MEASUREMENT_STATUS.COMPLETED ||
            record.surveyStatus < SURVEY_STATUS.SUBMITTED)) ||
        record.measurementStatus === MEASUREMENT_STATUS.IN_PROGRESS,
    }),
  };

  useEffect(() => {
    const searchMeasurements = async () => {
      if (wasComponentInitialized) {
        await fetchMeasurements({
          results: pageSettings.pageSize,
          page: 1,
        });
      } else {
        await fetchMeasurements({
          results: pageSettings.pageSize,
          page: pageSettings.current,
        });
        setWasComponentInitialized(true);
      }
    };
    searchMeasurements();
  }, [searchTerm]);

  const handleTableChange = async (pagination, filters, sorter) => {
    let pager = { ...pageSettings };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    setPageSettings(pager);
    sorter.field = mapSortField(getSortField(sorter));
    setMeasurementsFilterState({
      ...measurementsFilterState,
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
    await fetchMeasurements({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
  };

  const mapSortField = (sortField) => {
    switch (sortField) {
      case "id":
        return "measurementId";
      case "performedBy":
        return "createdBy";
      default:
        return sortField;
    }
  };

  const fetchMeasurements = async (params = {}) => {
    setLoading(true);
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await getMeasurements(
          token,
          params.page - 1,
          params.results,
          searchTerm,
          params.sortField,
          params.sortOrder
        );
        if (res && res.result && !res.error) {
          const pager = { ...pageSettings };
          pager.pageSize = params.results;
          pager.total = res.result.total;
          pager.current = params.page;
          setDatasource(res.result.data);
          setTotalCount(res.result.total);
          setNoSubmittedSurveys(res.result.hasNoSubmittedSurveys);
          setPageSettings(pager);
          if (selectedMeasurements.length === 0) {
            setOnlyEmptySurveys(res.result.hasOnlyEmptySurveys);
          }
        } else {
          SimpleMessage("error", t("load-measurements-error"));
        }
      }
    } catch (err) {
      SimpleMessage("error", t("load-measurements-error"));
    }
    setLoading(false);
  };

  const onMeasurementStatusChangedRef = useRef(async (messageString) => {
    const messageJson = JSON.parse(messageString);
    setDatasource((measurements) =>
      measurements
        .filter(
          (it) => it.id !== messageJson.MeasurementId || !messageJson.IsDeleted
        )
        .map((it) =>
          it.id === messageJson.MeasurementId
            ? { ...it, measurementStatus: messageJson.MeasurementStatus }
            : it
        )
    );
  });

  const initUserSubscriptions = (newUserIdList = []) => {
    newUserIdList.forEach((userId) => {
      if (!subscribedUserIdList.includes(userId)) {
        // join newer UserId group
        joinNotificationGroup(userId);
      }
    });

    subscribedUserIdList.forEach((userId) => {
      if (!newUserIdList.includes(userId)) {
        // leave older UserId group that are not in newUserIdList
        leaveNotificationGroup(userId);
      }
    });

    // add newUserIdList items to subscribedUserIdList
    setSubscribedUserIdList(newUserIdList);
  };

  const handleDownload = async (downloadType, exportType) => {
    await handleDownloadAllMeasurements(downloadType, exportType);
  };

  const handleDownloadAllMeasurements = async (downloadType, exportType) => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let data = {
          selectedMeasurementIds: selectedMeasurements,
          downloadType: [downloadType],
          exportType: [parseInt(exportType)],
          searchValue: searchTerm,
        };
        let res = await exportAllMeasurements(data, token);
        if (res && res.result) {
          SimpleMessage(
            "success",
            t("notification:measurements-preparing-download", {
              count: res.result.fileCount,
            })
          );
        } else {
          SimpleMessage("error", t("measurements-download-error"));
        }
      }
    } catch (err) {
      SimpleMessage("error", err.message);
    }
  };

  const surveyStatusChanged = async (measurementId, surveyStatus) => {
    let measurementsList = [...datasource];
    measurementsList.find((m) => m.id == measurementId).surveyStatus =
      surveyStatus;
    setDatasource(measurementsList);
  };

  const searchClick = (term) => {
    setSearchTerm(term);
    setMeasurementsFilterState({
      ...measurementsFilterState,
      searchTerm: term,
    });
  };

  const isSelectionDownload =
    selectedMeasurements.length > 0 &&
    (isAdmin || selectedMeasurements.SURVEY_STATUS !== SURVEY_STATUS.SUBMITTED);

  return (
    <div className="measurements-grid">
      <Title level={4} style={{ margin: "0px" }}>
        {isAdmin ? t("measurements") : t("my-measurements")} ({totalCount})
      </Title>
      <div className="download-grid">
        <Space>
          {!isAdmin && (
            <Link to="/new-measurement">
              <Button
                id="btnMeasurementStart"
                style={{ justifySelf: "start" }}
                type="primary"
                icon={<PlusOutlined />}
              >
                {t("measurement-start")}
              </Button>
            </Link>
          )}
          <MeasurementDownloadDropdown
            id="download-all-measurements"
            buttonType="primary"
            disabled={!isAdmin && noSubmittedSurveys}
            hasOnlyEmptySurveys={onlyEmptySurveys}
            hasNoSubmittedSurveys={noSubmittedSurveys}
            handleDownload={handleDownload}
            text={
              isSelectionDownload
                ? t("measurement-download")
                : t("measurement-download-all")
            }
          />
        </Space>
        {selectedMeasurements.length > 0 && (
          <Space style={{ gridColumn: 3 }}>
            <Text>
              {selectedMeasurements.length === 1
                ? t("measurement-selected")
                : t("measurements-selected", {
                  count: selectedMeasurements.length,
                })}
            </Text>
            <Button
              id="measurements-clear-selection"
              shape="circle"
              size="small"
              icon={
                <Tooltip title={t("measurements-clear-selected")}>
                  <CloseOutlined />
                </Tooltip>
              }
              onClick={clearAll}
            ></Button>
          </Space>
        )}
      </div>
      <Search
        defaultValue={searchTerm}
        loading={loading}
        placeholder={t("search-measurements")}
        onSearch={searchClick}
        allowClear
        enterButton
      />
      <Table
        locale={{
          emptyText: (
            <EmptyDescription
              text={t("no-measurements")}
              symbol="😥"
              label="DISAPPOINTED BUT RELIEVED FACE"
            />
          ),
        }}
        onChange={handleTableChange}
        pagination={pageSettings}
        loading={loading}
        rowKey="id"
        columns={
          isAdmin
            ? getMeasurementColumns(
              preferredLanguage,
              surveyStatusChanged,
              fetchMeasurements,
              pageSettings
            )
            : getMeasurementColumns(
              preferredLanguage,
              surveyStatusChanged,
              fetchMeasurements,
              pageSettings
            ).filter((col) => col.key !== "performedBy")
        }
        dataSource={datasource}
        size="middle"
        bordered
        rowSelection={rowSelection}
        preserveSelectedRowKeys={true}
      />
    </div>
  );
};

export default MeasurementsPage;
