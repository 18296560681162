import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import * as signalR from "@microsoft/signalr";
import SimpleMessage from "../components/simple-components/simple-message/simple-message";
import { v4 as uuidv4 } from "uuid";
import {
  API_REQUESTS,
  NOTIFICATION_TYPES,
  APP_ROLES,
  getStudyRoleLongString,
  NOTIFICATION_GROUPS,
  NOTIFICATION_GROUP_TYPES,
  EXPORT_JOB_STATUS,
  humanReadableFileSize,
} from "../utils/utils";
import { AuthContext } from "./AuthProvider";
import {
  CloseCircleOutlined,
  DownloadOutlined,
  LoadingOutlined,
  SolutionOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { downloadExport } from "../../services/ApiService";
import LoadButton from "../components/simple-components/load-button/load-button";
import moment from "moment";
const { Text } = Typography;

export const NotificationContext = createContext();

const NotificationProvider = (props) => {
  const [notificationHub, setNotificationHub] = useState(null);
  const { user, getAccessToken, logout } = useContext(AuthContext);
  const { t } = useTranslation();
  const [isConnected, setIsConnected] = useState(false);
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    if (user && user.id) {
      connectHub();
    }
  }, [user]);

  useEffect(() => {
    //default subscriptions
    if (notificationHub && user) {
      subscribeToDefaults(notificationHub);
    }
  }, [notificationHub, user]);

  const subscribeToDefaults = async (connection) => {
    connection.send(
      NOTIFICATION_GROUP_TYPES.JOIN_GROUP,
      NOTIFICATION_GROUPS.JOIN_GROUP_ALL_USERS
    );
    connection.send(NOTIFICATION_GROUP_TYPES.JOIN_GROUP, user.id);
    switch (user.role) {
      case APP_ROLES.CLINICIAN:
      case APP_ROLES.RESEARCHER:
        subscribeTo(
          NOTIFICATION_TYPES.STUDY_MEMBER_ADDED,
          onStudyMemberAdded
        );
        subscribeTo(
          NOTIFICATION_TYPES.SURVEY_STATUS_CHANGED,
          onSurveyWithdrawn
        );
        subscribeTo(NOTIFICATION_TYPES.STUDY_USER_ASSIGNED, onStudyAssigned);
        subscribeTo(
          NOTIFICATION_TYPES.MEASUREMENT_CHANGED,
          onMeasurementChanged
        );
        break;
      case APP_ROLES.ADMIN:
        subscribeTo(NOTIFICATION_TYPES.STUDY_CREATED, onStudyCreated);
        connection.send(
          NOTIFICATION_GROUP_TYPES.JOIN_GROUP_ADMINS,
          NOTIFICATION_GROUPS.JOIN_GROUP_ADMINS
        );
        break;
      default:
        break;
    }
    subscribeTo(NOTIFICATION_TYPES.EXPORT_JOB_READY, onJobReady);
  };

  const downloadClick = async (jobId) => {
    try {
      const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await downloadExport(jobId, token);
        if (res && res.result) {
          let a = document.createElement("a");
          a.href = res.result;
          a.click();
        } else {
          SimpleMessage("error", t("measurements-download-error"));
        }
      }
    } catch (err) {
      SimpleMessage("error", err.message);
    }
  };
  const addNotification = useCallback(
    (notification, jobId) => {
      notification.date = moment.utc();
      setNotificationList((notifs) => {
        //check notification item exists
        let item = { ...notification, id: jobId ? jobId : uuidv4() };
        let arr = [item, ...notifs];

        if (jobId) {
          let foundIndex = notifs.findIndex((n) => n.id === jobId);
          if (foundIndex != -1) {
            //found one!
            notifs[foundIndex] = item;
            arr = [...notifs];
          }
        }
        return arr;
      });
    },
    [setNotificationList]
  );

  const onJobReady = (m) => {
    if (typeof m === "string") {
      m = JSON.parse(m);
    }

    switch (m.Status) {
      case EXPORT_JOB_STATUS.STARTED:
        SimpleMessage(
          "loading",
          t("notification:measurements-download-inprogress")
        );
        addNotification(
          {
            icon: <LoadingOutlined style={{ fontSize: "18px" }} />,
            title: t("notification:measurements-preparing-download", {
              count: m.FileCount,
            }),
            content: t("notification:notifications-downloading-files", {
              count: m.FileCount,
            }),
          },
          m.JobId
        );
        break;
      case EXPORT_JOB_STATUS.COMPLETED:
        const notification = {
          icon: <DownloadOutlined style={{ fontSize: "18px" }} />,
          title: t("notifications-export-ready"),
        };

        if (m.DownloadLimitReached) {
          notification.title += `. ${t(
            "notification:measurements-download-limit",
            {
              count: m.FileCount,
            }
          )}`;
        }
        SimpleMessage("success", t("measurements-download-success"));

        if (m.FileCount > 0) {
          const normalizedUnits = humanReadableFileSize(m.SizeKB);
          notification.content = t("notification:notification-export-ready", {
            fileCount: m.FileCount,
            size: normalizedUnits.size,
            units: normalizedUnits.units,
          });

          if (m.SkippedMeasurementCount) {
            notification.content += `, ${t(
              "notification:notification-not-exported",
              {
                count: m.SkippedMeasurementCount,
              }
            )}`;
          }

          var buttonType = "primary";
          if (m.FileCount == 1) {
            downloadClick(m.JobId);
            buttonType = "dashed";
          }

          notification.button = (
            <LoadButton
              id={m.JobId}
              text={t("measurement-download")}
              buttonType={buttonType}
              onClick={downloadClick}
            />
          );
        } else {
          notification.content = t(
            "notification:notification-export-archive-is-empty"
          );
        }

        addNotification(notification, m.JobId);
        break;
      case EXPORT_JOB_STATUS.FAILED:
        SimpleMessage("error", t("measurements-download-error"));
        addNotification(
          {
            icon: (
              <CloseCircleOutlined style={{ fontSize: "18px", color: "red" }} />
            ),
            title: t("measurements-download-error"),
          },
          m.JobId
        );
        break;
      default:
        SimpleMessage("error", t("measurements-download-error"));
    }
  };

  const onMeasurementChanged = useCallback(
    async (messageString) => {
      const messageJson = JSON.parse(messageString);
      if (messageJson.ModifiedByUserId !== messageJson.CreatedByUserId) {
        var notificationMessage = "";
        if (messageJson.IsDeleted) {
          notificationMessage = t(
            "new-measurement-stopped-and-deleted-by-admin",
            {
              measurementId: messageJson.MeasurementId,
              userName: messageJson.ModifiedByName,
            }
          );
        } else {
          notificationMessage = t(
            "new-measurement-stopped-and-saved-by-admin",
            {
              measurementId: messageJson.MeasurementId,
              userName: messageJson.ModifiedByName,
            }
          );
        }
        SimpleMessage("info", notificationMessage);
        addNotification({
          icon: <InfoCircleOutlined style={{ fontSize: "18px" }} />,
          title: t("new-measurement-stopped"),
          content: <Text>{notificationMessage}</Text>,
        });
      }
    },
    [addNotification]
  );

  const onStudyMemberAdded = (message) => {
    let m = JSON.parse(message);
    SimpleMessage(
      "info",
      t("notification:notification-study-member-added", {
        user: m.createdBy,
        study: m.studyName,
        role: getStudyRoleLongString(m.role, t),
      })
    );
    addNotification({
      icon: <SolutionOutlined style={{ fontSize: "18px" }} />,
      title: t("notifications-added-team-member"),
      content: (
        <Text>
          {t("notification:notification-study-member-added", {
            user: m.createdBy,
            study: m.studyName,
            role: getStudyRoleLongString(m.role, t),
          })}
        </Text>
      ),
    });
  };

  const onSurveyWithdrawn = (measurementId) => {
    SimpleMessage(
      "info",
      t("notifications-survey-withdrawn", {
        id: measurementId,
      })
    );
    addNotification({
      icon: <SolutionOutlined style={{ fontSize: "18px" }} />,
      title: t("notifications-survey-available", {
        id: measurementId,
      }),
      content: (
        <Text>
          {t("notifications-survey-withdrawn", {
            id: measurementId,
          })}
        </Text>
      ),
    });
  };

  const onStudyAssigned = (message) => {
    let m = JSON.parse(message);
    if (m.addedStudyCount !== 0) {
      SimpleMessage(
        "info",
        t("notification:notification-study-user-assigned", {
          user: m.createdBy,
          count: m.addedStudyCount,
        })
      );
      addNotification({
        icon: <SolutionOutlined style={{ fontSize: "18px" }} />,
        title: t("notifications-study-assigned"),
        content: (
          <Text>
            {t("notification:notification-study-user-assigned", {
              user: m.createdBy,
              count: m.addedStudyCount,
            })}
          </Text>
        ),
      });
    }
    if (m.removedStudyCount !== 0) {
      SimpleMessage(
        "info",
        t("notification:notification-study-user-removed", {
          user: m.createdBy,
          count: m.removedStudyCount,
        })
      );
      addNotification({
        icon: <SolutionOutlined style={{ fontSize: "18px" }} />,
        title: t("notifications-study-assigned"),
        content: (
          <Text>
            {t("notification:notification-study-user-removed", {
              user: m.createdBy,
              count: m.removedStudyCount,
            })}
          </Text>
        ),
      });
    }
  };

  const onStudyCreated = (message) => {
    let m = JSON.parse(message);
    SimpleMessage(
      "info",
      t("notification:notification-study-added", {
        user: m.createdBy,
        study: m.studyName,
      })
    );
    addNotification({
      icon: <SolutionOutlined style={{ fontSize: "18px" }} />,
      title: t("notifications-study-created"),
      content: (
        <Text>
          {t("notification:notification-study-added", {
            user: m.createdBy,
            study: m.studyName,
          })}
        </Text>
      ),
    });
  };

  const subscribeTo = (type, callback) => {
    console.log(
      `%csubscribeTo ${type}, ${callback?.name}`,
      "background: orange;"
    );
    notificationHub?.on(type, callback);
  };

  const unsubscribeFrom = (type, callback) => {
    console.log(
      `%cunsubscribeFrom ${type}, ${callback?.name}`,
      "background: orange;"
    );
    notificationHub?.off(type, callback);
  };

  // TODO: replace old notification group join with this
  const joinNotificationGroup = (group) => {
    console.log(`%cjoining group ${group}`, "background: orange;");
    notificationHub?.send(NOTIFICATION_GROUP_TYPES.JOIN_GROUP, group);
  };

  // TODO: replace old notification group leave with this
  const leaveNotificationGroup = (group) => {
    console.log(`%cleaving group ${group}`, "background: orange;");
    notificationHub?.send(NOTIFICATION_GROUP_TYPES.LEAVE_GROUP, group);
  };

  const connectHub = async () => {
    try {
      console.log("connecting to notification hub...");
      let conn = await connect();
      setNotificationHub(conn);
    } catch (err) {
      console.log(err);
    }
  };

  const connect = async () => {
    let connection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_NOTIFICATIONS_URL, {
        accessTokenFactory: () => {
          return getAccessToken(API_REQUESTS.USER_IMPERSONATION);
        },
      })
      .withAutomaticReconnect([
        0, 5000, 10000, 15000, 20000, 25000, 30000, 35000,
      ])
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.serverTimeoutInMilliseconds = 1000 * 60 * 10;

    connection.onreconnecting((error) => {
      setIsConnected(false);
      console.error(error);
      SimpleMessage("warning", `Connection lost. Reconnecting...`);
    });

    connection.onreconnected((connectionId) => {
      subscribeToDefaults(connection);
      setIsConnected(true);
      console.info(`Connected with connectionId "${connectionId}"`);
      SimpleMessage("info", "Connection reestablished");
    });

    const start = async () => {
      try {
        await connection.start();
        console.assert(
          connection.state === signalR.HubConnectionState.Connected
        );
        console.log("connected");
        setIsConnected(true);
      } catch (err) {
        console.assert(
          connection.state === signalR.HubConnectionState.Disconnected
        );
        console.log(err);
      }
    };

    connection.onclose((error) => {
      SimpleMessage("error", `Server unavailable. Logging out...`);
      logout();
    });

    await start();
    return connection;
  };

  const sendMessage = async (type, ...params) => {
    try {
      if (
        notificationHub &&
        notificationHub.state === signalR.HubConnectionState.Connected
      ) {
        console.log("sendMessage", type, ...params);
      }
      notificationHub.invoke(type, ...params);
    } catch (err) {
      console.log(err);
    }
  };


  const deleteNotification = (id) => {
    setNotificationList(notificationList.filter((n) => n.id != id));
  };

  const deleteAllNotifications = () => {
    setNotificationList([]);
  };

  return (
    <NotificationContext.Provider
      value={{
        sendMessage,
        subscribeTo,
        unsubscribeFrom,
        joinNotificationGroup,
        leaveNotificationGroup,
        notificationHub,
        isConnected,
        notificationList,
        addNotification,
        deleteNotification,
        deleteAllNotifications,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
