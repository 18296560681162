import React, {
    useState,
    useEffect,
    useContext
} from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getStudies, reassignMeasurementStudy } from "../../../services/ApiService";
import {
    Form,
    Col,
    Row,
    Modal,
    Select,
    Spin,
    Alert,
    Space,
    Button,
    Typography
} from "antd";
import {
    APP_ROLES,
    API_REQUESTS,
} from "../../utils/utils";
import { AuthContext } from "../../contexts/AuthProvider";
import SimpleMessage from "../simple-components/simple-message/simple-message";

const { Option } = Select;
const { Text } = Typography;

const ReassignMeasurementStudyModal = (props) => {
    const { t } = useTranslation();
    const [foundStudies, setFoundStudies] = useState([]);
    const [fetchingStudies, setFetchingStudies] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedStudy, setSelectedStudy] = useState(null);
    const { getAccessToken, user } = useContext(AuthContext);
    const isAdmin = user.role === APP_ROLES.ADMIN;
    const [form] = Form.useForm();
    const [canSave, setCanSave] = useState(false);

    useEffect(() => {
        if (!props.visible || !props.measurement) return;
        fetchStudiesAsync(props.measurement?.userId, 100);
    }, [props]);

    const fetchStudiesAsync = async (userId, count) => {
        setFetchingStudies(true);
        try {
            const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
            if (token) {
                let res = await getStudies(
                    token,
                    0,
                    count,
                    "", userId
                );
                if (res && res.result && !res.error) {
                    let studies = res.result.data.map((o) => {
                        return {
                            text: o.name,
                            value: o.id
                        };
                    });

                    // Remove current assigned study from list
                    studies = studies.filter(function (item) {
                        return item.value !== props.measurement.studyId;
                    })
                    setFoundStudies(studies);
                }
            }
        } catch (err) {
            console.error(err);
        }
        setFetchingStudies(false);
    };

    const onSelectStudyId = (value) => {
        let study = foundStudies.find((o) => (o.value.toString() === value || o.text === value));
        setSelectedStudy({ id: study.value, name: study.text });
        setCanSave(true);
    };

    const handleCancel = () => {
        props.onClose();
    };


    const save = async (values) => {
        try {
            let data = {
                StudyId: selectedStudy.id,
                MeasurementId: props.measurement?.id,
            }
            const token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
            if (token) {
                let res = await reassignMeasurementStudy(token, data);
                if (res && res.success) {
                    SimpleMessage(
                        "success",
                        t("reassign-study-success-message", {
                            studyId: selectedStudy.name,
                            measurementId: props.measurement?.id,
                        })
                    );
                } else if (res && res.error) {
                    SimpleMessage("error", t(res.error));
                }
            }
        } catch (err) {
            SimpleMessage("error", err.message);
        }
        setIsSaving(false);
        props.onClose();
    }

    const onSaveClick = () => {
        setIsSaving();
        form.submit();
    };



    return (
        <Modal
            title={t("reassign-study-title", {
                measurementId:
                    props.measurement?.id
            })}
            centered
            closable={false}
            visible={props.visible}
            onOk={props.onModalClosed}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={handleCancel}
            footer={[
                <Button
                    onClick={handleCancel}
                >
                    {t("cancel")}
                </Button>,
                <Button
                    type="primary"
                    disabled={!canSave}
                    onClick={onSaveClick}
                >
                    {t("save")}
                </Button>
            ]}
        >

            {!isSaving ? (<Form
                layout="vertical"
                hideRequiredMark
                form={form}
                onFinish={save}
            >
                <Row>
                    <Col span={24}>
                        <Alert
                            message={isAdmin ? t("reassign-study-warning") : t("reassign-study-warning-user")}
                            type="warning"
                            showIcon
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Text strong>{t("reassign-study-current")}</Text>
                    </Col>
                    <Col span={24}>
                        <Text>{props.measurement?.studyName}</Text>

                    </Col>
                </Row>
                <Row>
                    <Col span={24}>

                        <Form.Item
                            name="surveyId"
                            label={
                                <Space>
                                    <Text strong>{t("select-study")}</Text>
                                </Space>
                            }
                        >
                            <Select
                                placeholder={t("select-study")}
                                style={{ width: "100%" }}
                                loading={fetchingStudies}
                                showSearch
                                onSelect={onSelectStudyId}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {foundStudies.map((d) => ((<Option
                                    value={d.value.toString()}>
                                    {d.text}</Option>))

                                )}
                            </Select>
                        </Form.Item>

                    </Col>
                </Row>
            </Form>
            ) : (<Spin />)}
        </Modal>
    );
};

ReassignMeasurementStudyModal.propTypes = {
    visible: PropTypes.bool.isRequired,
};

export default ReassignMeasurementStudyModal;
