import React, { useContext, useState, useEffect } from "react";
import { Avatar, Upload, Tooltip, Typography, Card, Skeleton } from "antd";
import { AuthContext } from "../../contexts/AuthProvider";
import PropTypes from "prop-types"; // ES6
import {
  getUserProfilePhoto,
  updateUserProfilePhoto,
} from "../../../services/ApiService";
import "./upload-photo.css";
import SimpleMessage from "../simple-components/simple-message/simple-message";
import { useTranslation } from "react-i18next";
import { VALIDATIONS, API_REQUESTS } from "../../utils/utils";
const { Title } = Typography;

const UploadPhoto = (props) => {
  const { user, getAccessToken, setUserPhoto } = useContext(AuthContext);
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [validUpload, setValidUpload] = useState(false);

  const [photo, setPhoto] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const loadUserPhoto = async () => {
    try {
      const accessToken = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (accessToken) {
        // Get the user's photo from Graph
        let photoBlob = await getUserProfilePhoto(props.id, accessToken);
        if (photoBlob && photoBlob.result && photoBlob.result.size > 0) {
          setPhoto(URL.createObjectURL(photoBlob.result));
        }
      }
    } catch (err) {
      if (err.code && err.code !== "MailboxNotEnabledForRESTAPI") {
        console.error("%c" + err.code, "background: red;");
      } else {
        console.warn(err.code);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setName(props.name);
      await loadUserPhoto();
      setLoading(false);
    };
    if (props.id) {
      setLoading(true);
      fetchData();
    }
  }, [props]);

  const beforeUpload = async (file) => {
    const isJpg = file.type === "image/jpeg";
    if (!isJpg) {
      SimpleMessage("error", t("validation:profile-validation-photo-types"));
    }
    const isLt2M =
      file.size / 1024 < VALIDATIONS.MAX_PROFILE_PHOTO_FILE_SIZE_KB;
    if (!isLt2M) {
      SimpleMessage(
        "error",
        t("validation:profile-validation-max-photo-size", {
          maxFileSize: VALIDATIONS.MAX_PROFILE_PHOTO_FILE_SIZE_KB,
          fileSizeUnit: "KB",
        })
      );
    }
    setValidUpload(isJpg && isLt2M);
    return isJpg && isLt2M;
  };

  const updatePhoto = async ({ onSuccess, onError, file }) => {
    if (validUpload) {
      setUploading(true);
      let token = await getAccessToken(API_REQUESTS.USER_IMPERSONATION);
      if (token) {
        let res = await updateUserProfilePhoto(props.id, token, file);
        if (res.result) {
          onSuccess();
        } else {
          onError(res.error);
        }
      } else {
        onError();
      }
    }
  };

  const onChange = (info) => {
    if (info.file.status === "uploading") {
      console.log("uploading");
    } else {
      setUploading(false);
      if (info.file.status === "done") {
        SimpleMessage("success", t("profile-upload-success"));
        //set user photo here
        setPhoto(URL.createObjectURL(info.file.originFileObj));
        if (user.id === props.id) {
          //propagate changes on profile menu as well
          setUserPhoto(URL.createObjectURL(info.file.originFileObj));
        }
        //notify provider of changes !!!!
      } else if (info.file.status === "error") {
        console.log("error");
        SimpleMessage("error", t("profile-upload-error"));
      }
    }
  };

  return (
    <Card size="small" className="responsive-card-shadow" bordered={false}>
      <div className="profile-photo">
        <Upload
          showUploadList={false}
          accept=".jpg,.jpeg"
          disabled={uploading}
          name="file"
          beforeUpload={beforeUpload}
          customRequest={updatePhoto}
          onChange={onChange}
          id="upload-user-photo"
        >
          {loading ? (
            <Skeleton.Avatar active loading={loading} size={200} />
          ) : (
            <Tooltip
              className="upload-control"
              title={t("profile-photo-change")}
            >
              <Avatar
                src={photo ? photo : "/img/default-image-avatar.png"}
                alt="profile-photo"
                size={200}
              />
            </Tooltip>
          )}
        </Upload>
        <Title
          level={4}
          style={{
            maxWidth: "240px",
          }}
          ellipsis
        >
          {name}
        </Title>
      </div>
    </Card>
  );
};

UploadPhoto.propsTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default UploadPhoto;
