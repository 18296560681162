import React, { useEffect, useContext } from "react";
import "./App.css";
import LandingPage from "./layouts/landing-page/landing-page";
import MainPage from "./layouts/main-page/main-page";
import { AuthContext } from "./contexts/AuthProvider";
import { SplashScreen } from "./pages/splashscreen/splashscreen";
import useLocalStorage from "react-use-localstorage";
import CookieConsent from "./components/cookie-consent/cookie-consent";
import { useTranslation } from "react-i18next";
import { ResearchContext } from "./contexts/ResearchProvider";
import MaintenancePage from "./layouts/maintenance-page/maintenance-page"

const App = () => {
  const { t } = useTranslation();
  const [cookieAgree, setCookieAgree] = useLocalStorage("allow-cookies", false);
  const { isAuthenticated, appLoading } = useContext(AuthContext);
  const { isInMaintenance } = useContext(ResearchContext);

  const onAgreed = () => {
    setCookieAgree(true);
  };

  useEffect(() => {
    if (!cookieAgree) {
      CookieConsent(onAgreed, t);
    }
  }, []);

  return (
    <div className="App">
      {appLoading ? (
        <SplashScreen />
      ) :
        !isInMaintenance ?
          !isAuthenticated ? (
            <LandingPage />
          ) : (
            <MainPage />
          )
          : (<MaintenancePage />)}
    </div>
  );
};

export default App;
