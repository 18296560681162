import React, { useContext, useEffect } from "react";
import * as SurveyKo from "survey-knockout";
import * as SurveyJSCreator from "survey-creator";
import * as Survey from "survey-react";
import { SurveyCreatorContext } from "../../contexts/SurveyCreatorContext";
import IcdWidget from "../../survey/question-item-icd/question-item-icd.js";
import SurveySlider from "../../survey/question-item-slider/question-item-nouislider";
import $ from "jquery";
import { API_ENDPOINTS } from "../../utils/utils";
import "survey-creator/survey-creator.css";
import "survey-react/survey.css";
import "nouislider/dist/nouislider.css";
import "select2/dist/css/select2.css";
import "select2/dist/js/select2.js";

SurveyJSCreator.StylesManager.applyTheme("default");
Survey.StylesManager.applyTheme("default");

const SurveyCreator = () => {
  const { surveyCreator, setSurveyCreator, surveyTemplate } =
    useContext(SurveyCreatorContext);

  useEffect(() => {
    if (surveyCreator) {
      if (surveyTemplate) {
        surveyCreator.text = surveyTemplate.templateJson;
      }
      surveyCreator.render("surveyCreatorContainer");
      return;
    }

    // Set Custom Widgets
    // IMPORTANT: Don't remove the following line. It's needed for the ICD widget to work properly.
    window["$"] = window["jQuery"] = $;

    // Prevent duplicate widgets in the toolbox
    if (!SurveyKo.CustomWidgetCollection.Instance.widgetsValues.some(w => w.name == "tagboxmedical")) {
      IcdWidget(SurveyKo, $, "Health Control", API_ENDPOINTS.ICD_SEARCH);
    }

    // Prevent duplicate widgets in the toolbox
    if (!SurveyKo.CustomWidgetCollection.Instance.widgetsValues.some(w => w.name == "nouislider")) {
      SurveySlider(SurveyKo);
    }

    // set the theme & colors for the surveycreator
    var defaultThemeColorsSurvey = Survey.StylesManager.ThemeColors["default"];
    defaultThemeColorsSurvey["$primary-color"] = "var(--secondary-bkg-color)";
    defaultThemeColorsSurvey["$secondary-color"] = "var(--main-color-yellow)";
    Survey.StylesManager.applyTheme();

    var defaultThemeColorsEditor =
      SurveyJSCreator.StylesManager.ThemeColors["default"];
    defaultThemeColorsEditor["$primary-color"] = "var(--secondary-bkg-color)";
    defaultThemeColorsEditor["$secondary-color"] = "var(--main-color-yellow)";
    defaultThemeColorsEditor["$primary-icon-color"] =
      "var(--main-color-yellow)";
    defaultThemeColorsEditor["$secondary-bg-color"] =
      "var(--white-background-color)";
    SurveyJSCreator.StylesManager.applyTheme();

    // customize the surveycreator
    let customizeOptions = {
      showSimulatorInTestSurveyTab: false,
      showEmbededSurveyTab: false,
      showToolbox: "right",
      allowControlSurveyTitleVisibility: false,
      showTranslationTab: true,
      haveCommercialLicense: true,
      showPageSelectorInToolbar: false,
      showInvisibleElementsInTestSurveyTab: false,
      questionTypes: [
        "text",
        "checkbox",
        "dropdown",
        "expression",
        "boolean",
        "rating",
        "panel",
        "radiogroup",
        "matrixdynamic",
      ],
      allowEditExpressionsInTextEditor: false,
      isAutoSave: true,
    };

    const initCreator = new SurveyJSCreator.SurveyCreator(
      null,
      customizeOptions
    );

    addQuestions(initCreator);

    // CUSTOMIZING THE PROPERTIES TAB:
    // remove surveySettings link
    var surveySettingsAction = initCreator
      .toolbarItems()
      .filter(function (item) {
        return item.id === "svd-survey-settings";
      })[0];
    initCreator.toolbarItems.remove(surveySettingsAction);

    // remove properties that we will never use
    var propertyStopList = [
      "requiredIf",
      "bindings",
      "defaultValueExpression",
      "hideIfChoicesEmpty",
      "choicesVisibleIf",
      "choicesEnableIf",
      "minValueExpression",
      "maxValueExpression",
      "calculatedValues",
      "triggers",
      "width",
      "minWidth",
      "maxWidth",
      "indent",
      "titleLocation",
      "descriptionLocation",
      "state",
      "startWithNewLine",
      "requiredErrorText",
      "validators",
      "minErrorText",
      "maxErrorText",
      "maxLength",
      "visible",
      "hasComment",
      "choicesFromQuestion",
      "choicesFromQuestionMode",
      "autoComplete",
      "dataList",
      "displayStyle",
      "currency",
      "maximumFractionDigits",
      "minimumFractionDigits",
      "useGrouping",
      "correctAnswer",
      "useDisplayValuesInTitle",
      "valueName",
      "logo",
      "logoWidth",
      "logoHeight",
      "logoFit",
      "logoPosition",
      "widthMode",
      "showOptionsCaption",
      "choicesMin",
      "choicesMax",
      "choicesStep",
    ];

    initCreator.onShowingProperty.add(function (sender, options) {
      // remove the properties from propertyStopList from the "properties" tab
      options.canShow =
        options.property.name == "tooltip" ||
        propertyStopList.indexOf(options.property.name) == -1;
      // show no properties when "survey" or "page" is selected
      if (options.obj.getType() == "survey") {
        options.canShow = options.property.name == "";
      }
      if (options.obj.getType() == "page") {
        options.canShow = options.property.name == "";
      }
    });

    // Set different defaultvalues for questions that you will add:
    var questionCounter = 1;
    initCreator.onQuestionAdded.add(function (sender, options) {
      if (!!options.question) {
        options.question.isRequired = true;
        options.question.hideNumber = true;
        var q = options.question;
        var t = q.getType();
        q.name =
          "Question" + t[0].toUpperCase() + t.substring(1) + questionCounter;
        questionCounter++;
      }
    });

    if (!surveyTemplate) {
      initCreator.JSON = require("../../survey/survey-questionnaire/general-survey-complete-translation.json");
    }

    initCreator.render("surveyCreatorContainer");
    setSurveyCreator(initCreator);
  }, [surveyCreator, surveyTemplate]);

  // Add prefined questions to the survey
  const addQuestions = (creator) => {
    // Add custom questions for in the toolbox
    // Example 1: add basic spirometry questions
    creator.toolbox.addItem({
      name: "spirometry",
      isCopied: true,
      iconName: "icon-default",
      title: "Spirometry",
      json: {
        type: "panel",
        title: "Spirometry values today",
        elements: [
          {
            type: "text",
            inputType: "number",
            title: "FVC",
            min: "0",
            max: "12",
          },
          {
            type: "text",
            inputType: "number",
            title: "FEV1",
            min: "0",
            max: "12",
          },
          {
            type: "text",
            inputType: "number",
            title: "FEV1/FVC",
            min: "0",
            max: "100",
          },
        ],
      },
    });

    // Example 2: add not-available box question
    creator.toolbox.addItem({
      name: "textWithIsNotAvailable",
      isCopied: true,
      iconName: "icon-boolean",
      title: "Not Available + text",
      json: {
        type: "panel",
        elements: [
          {
            type: "text",
            name: "QuestionTextWithCheckbox1",
            title: "Write your question here",
            hideNumber: true,
            isRequired: true,
            enableIf: "{QuestionCheckbox1} empty",
          },
          {
            type: "checkbox",
            name: "QuestionCheckbox1",
            title: " ",
            hideNumber: true,
            choices: [
              {
                value: "N/A",
                text: "Not available",
              },
            ],
          },
        ],
      },
    });

    // Example 3: Add the country of birth question type
    creator.toolbox.addItem({
      name: "countries",
      isCopied: true,
      iconName: "icon-dropdown",
      title: "Birth country of parent X",
      json: {
        type: "dropdown",
        title: "Birth Country of parent X",
        optionsCaption: "Select a country...",
        choicesByUrl: { url: "https://surveyjs.io/api/CountriesExample" },
      },
    });
  };

  return (
    <div>
      <div id="surveyCreatorContainer" />
    </div>
  );
};

export default SurveyCreator;
