import React, { Suspense } from "react";
import { render } from "react-dom";
import "./index.css";
import App from "./app/App";
import ResearchProvider from "./app/contexts/ResearchProvider";
import AuthProvider from "./app/contexts/AuthProvider";
import TelemetryProvider from "./app/contexts/TelemetryProvider";
import "./i18n";
import { BrowserRouter } from "react-router-dom";
import NotificationProvider from "./app/contexts/NotificationProvider";
import PageSettingsProvider from "./app/contexts/PageSettingsProvider";
import * as serviceWorker from "./sw";
import { onSWUpdate } from "./app/components/app-update-notification/app-update-notification";

if (module.hot) {
  module.hot.accept();
}

serviceWorker.register({
  onUpdate: (registration) => onSWUpdate(registration),
});

render(
  <Suspense fallback={null}>
    <BrowserRouter>
      <TelemetryProvider>
        <AuthProvider>
          <ResearchProvider>
            <NotificationProvider>
              <PageSettingsProvider>
                <App />
              </PageSettingsProvider>
            </NotificationProvider>
          </ResearchProvider>
        </AuthProvider>
      </TelemetryProvider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);
