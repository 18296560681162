import React, { useState, useEffect } from "react";
import { Space, Tooltip, Form, InputNumber } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const QuestionItemNumber = (props) => {
  const { t } = useTranslation();
  const [question, setQuestion] = useState({});

  useEffect(() => {
    setQuestion(props.question);
  }, [props]);

  return (
    <Form.Item
      hasFeedback={question.feedback}
      colon={false}
      key={question.id}
      name={question.id}
      initialValue={question.value}
      label={
        question.label && (
          <Space>
            {t("survey:" + question.label)}
            {question.tooltip && (
              <Tooltip
                title={t("survey:" + question.tooltip, {
                  min: question.min,
                  max: question.max,
                })}
              >
                <InfoCircleOutlined style={{ color: question.tooltipColor }} />
              </Tooltip>
            )}
          </Space>
        )
      }
      rules={
        question.rules
          ? [
            ...question.rules.map((r) => {
              r.message = !r.message.startsWith("survey:") ? t("survey:" + r.message) : r.message;
              return r;
            }),
            {
              validator(rule, value) {
                if (value > question.max || value < question.min) {
                  props.formInstance.setFieldsValue({ [question.id]: "" });
                  return Promise.reject(
                    t("validation:survey-value-outside-bounds")
                  );
                }
                return Promise.resolve();
              },
            },
          ]
          : null
      }
    >
      <InputNumber
        style={{ width: "auto" }}
        placeholder={t("survey:" + question.placeholder)}
        disabled={question.readonly}
        step={question.decimals ? 1 / Math.pow(10, question.decimals) : 1}
        precision={question.decimals ? question.decimals : 0}
      />
    </Form.Item>
  );
};

export default QuestionItemNumber;
