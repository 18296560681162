import React, { useContext, useEffect, useState } from "react";
import "./profile-page.css";
import { Typography, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthProvider";
import UploadPhoto from "../../components/upload-photo/upload-photo";
import ProfileDetails from "../../components/profile-details/profile-details";
import { APP_ROLES, API_REQUESTS } from "../../utils/utils";
import ProfileAccount from "../../components/profile-account/profile-account";
import { getUserProfileDetails } from "../../../services/ApiService";
import { useHistory } from "react-router-dom";
import LocationCard from "../../components/location-card/location-card";
const { Title } = Typography;

const ProfilePage = (props) => {
  const { getAccessToken, user, refreshUser } = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();

  const [userDetails, setUserDetails] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [myself, setMyself] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let id = null;
      if (props.location.id) {
        id = props.location.id;
        localStorage.setItem("userId", id);
      } else {
        id = localStorage.getItem("userId");
      }
      setIsAdmin(user.role === APP_ROLES.ADMIN);
      setMyself(id === user.id);

      //user details
      try {
        if (id === userDetails) {
          setUserDetails(user);
        } else {
          if (id) {
            const accessToken = await getAccessToken(
              API_REQUESTS.USER_IMPERSONATION
            );
            if (accessToken) {
              let details = await getUserProfileDetails(id, accessToken);
              if (details.result) {
                setUserDetails(details.result);
              }
            }
          } else {
            //redirect to user list page
            history.push("/users");
          }
        }
      } catch (err) {
        if (err.code && err.code !== "MailboxNotEnabledForRESTAPI") {
          console.error("%c" + err.code, "background: red;");
        } else {
          console.error(err);
        }
      }
      setLoading(false);
    };
    fetchData();
  }, [props]);

  return (
    <div className="profile-grid">
      <Title level={4}>
        {isAdmin && !myself ? t("profile-title-admin") : t("profile-title")}
      </Title>
      <div style={{ gridRow: "2" }}>
        <Row gutter={[20, 20]}>
          <Col flex="300px">
            <UploadPhoto id={userDetails.id} name={userDetails.name} />
          </Col>
          <Col flex="350px">
            <ProfileAccount account={userDetails} loading={loading} />
          </Col>
          <Col
            flex="calc(100% - 700px)"
            style={{ maxHeight: "300px", overflowY: "hidden" }}
          >
            <LocationCard lat={userDetails.lat} lon={userDetails.lon} />
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col flex="auto">
            <ProfileDetails
              account={userDetails}
              refreshUserDetails={refreshUser}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProfilePage;
