import React from "react";
import "./splashscreen.css";

export const SplashScreen = () => {
  return (
    <div className="splash-grid">
      <div
        style={{
          display: "grid",
          gridTemplateRows: "auto auto",
          alignSelf: "center",
          justifySelf: "center",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <img src="/img/logo-white.png" width="180" alt="logo" />
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
